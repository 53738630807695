import React, { useContext, useEffect, useState } from 'react';
import { Modal, Tabs, Row, Col } from 'antd';
import ImageViewerContext from '../../ContextFiles/ImageViewerContext';
const { TabPane } = Tabs;

const ImageViewerTabs = (props) => {
    const { isVisible, handleOk, handleCancel, tabs, defaultActiveTabKey } = useContext(ImageViewerContext);
    const [tabActiveKey, setTabActiveKey] = useState(null);

    useEffect(() => {
        if (defaultActiveTabKey) {
            setTabActiveKey(defaultActiveTabKey);
        }
    }, [defaultActiveTabKey]);

    return (
        <Modal
            className='scene-render-lifestyleimage-viewer-modal'
            visible={isVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={'95%'}
            footer={null}>
            <Row>
                <Col span={24}>
                    <Tabs
                        className='items-table'
                        tabBarGutter={32}
                        activeKey={tabActiveKey}
                        size='large'>
                        {tabs?.map((tab, index) => {
                            return <TabPane
                                tab={<span className={`manrope f-14 asset-tab-heading`}>{tab.title}</span>}
                                key={tab.type}
                                className='pd-8'>
                                {tab.component}
                            </TabPane>
                        })}
                    </Tabs>
                </Col>
            </Row>
        </Modal>
    );
};

export default ImageViewerTabs;
