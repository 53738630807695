import SceneInfoExporter from "./SceneInfoExporter.js"
import SceneLoader from './SceneCreator/SceneLoader/SceneLoader.js'
import * as THREE from "three";
import _ from "lodash"
import KeyboardState from "./KeyboardState.js";
import CameraControls from "./CameraControls.js"
import SunControls from "./SceneCreator/SunControls/SunControls.js"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TransformControls } from './TransformControls.js';
import { RotationControls } from "./RotationControls"
import CONSTANTS from './environments'
import { NavigationControl } from "./NavigationControl"
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { VirtualTourInfoExporter } from './VirtualTourInfoExporter';
import Constants from "./SceneCreator/Constants.js"
import ObjectPlacementManager from "./SceneCreator/ObjectPlacement/ObjectPlacementManager.js"
import SpaceConfigurator from "./SceneCreator/SpaceConfigurator/SpaceConfigurator.js"
import MeasurementTool from "./SceneCreator/MeasurementTool/MeasurementTool.js"
import RaycastManager from "./SceneCreator/RaycastManager/RaycastManager.js"
import DebugEngine from "./SceneCreator/DebugEngine/DebugEngine.js"
import GridViewManager from "./GridViewManager.js"
import SwapManager from "./SceneCreator/SwapManager/SwapManager.js";
import ActionManager from "./SceneCreator/ActionManager/ActionManager.js";
import FileConstants from "./FileConstants.js";
import {degrees_to_radians, removeElementFromArray, retreivePositions, roundedEqual, getRootNodeFromObject, getObjectFromRootByName, setHighlightedState} from "./SceneCreator/HelperFunctions.js"

var CameraHelper = function (camera, areaCenter, activeCamera, screenWidth, screenHeight, previewRenderer ) {


    // initalize values
    var _helperImage = document.getElementById( 'camera' );
    var _sceneCreator = document.getElementById('scene-creator');
	THREE.Object3D.call( this );
    this._camera = camera;
    var _activeCamera = activeCamera;
    var _sceneWidth = screenWidth;
    var _sceneHeight = screenHeight;
    var _areaCenter = areaCenter;
    var _previewContainer = document.getElementById('preview-container');
    var _selectedCameraBtn = document.getElementById('selected-camera');
    var _defaultCameraBtn = document.getElementById('default-camera');
    var _enlargePreview = document.getElementById('enlarge-preview');
    var _closePreview = document.getElementById('close-preview');
    var _previewRenderer = previewRenderer;
    var _isEnlarged = false;
    var _previewState = true;
    var _active = false;
    var _lookAt = new THREE.Vector3(0,0,0);

    // hook update matrix world function
	this.updateMatrixWorld = function () {
        if ( this._camera != null ) {
            this.updateCameraHelperUI(this._camera.position);

        }
        THREE.Object3D.prototype.updateMatrixWorld.call( this );
    };

    // set the camera helper / change settings
    this.setCameraHelper = function(camera)
    {
        this._camera = camera;
        this.updateMatrixWorld();

    };

    this.getCameraHelper = function()
    {
        return this._camera;
    }

    // update the UI / icon position of the cameraIcon according to 3d world position
    this.updateCameraHelperUI = function (position) {
        // Update camera helper image position here w.r.t this.position;
        let ndcPoint = this.worldToNDC( position , _activeCamera);
        let screenPoint = this.NDCToScreenCoordinates(ndcPoint);
        _helperImage.style.display = "inline";
        _helperImage.style.position = "absolute";
        _helperImage.style.left = screenPoint.x + 'px';
        _helperImage.style.top = screenPoint.y + 'px';

    };


    // Convert worldPoint to normal device coordinates
    this.worldToNDC = function ( worldPoint , activeCamera) {
        let NDCPoint = worldPoint.clone();
        activeCamera.updateMatrixWorld();
        NDCPoint.project( activeCamera );
        return NDCPoint;
    };

    // convert from ndc to screen coordinates
    this.NDCToScreenCoordinates = function (NDCPoint) {
        let NDCToPixelX = ((NDCPoint.x + 1) / 2) * _sceneWidth;
        let NDCToPixelY = (1 - (NDCPoint.y + 1) / 2) * _sceneHeight;
        let screenPoint = new THREE.Vector2(NDCToPixelX, NDCToPixelY);
        return screenPoint;
    };


    this.updateRendererPos = function()
    {
        if(_previewState == true && _active == false)
        {
            if(_isEnlarged == false)
            {
                _previewContainer.style.position = "absolute";
                _previewContainer.style.display = "flex";
                _previewContainer.style.border = "10px solid white";
                _enlargePreview.style.display = "block";
                _closePreview.style.display = "none";
                _selectedCameraBtn.style.display = "none";
                _defaultCameraBtn.style.display = "none";
                _previewRenderer.setPixelRatio( window.devicePixelRatio );
                _previewRenderer.setSize(_sceneWidth/4, _sceneHeight/4);
                _previewContainer.style.left =  _helperImage.offsetLeft - _sceneWidth/8 +  "px";
                _previewContainer.style.top =  _helperImage.offsetTop - (_sceneHeight/4) - 20 + "px";
                if(_previewContainer.offsetTop<20)
                {
                    _previewContainer.style.top =  _helperImage.offsetTop + _helperImage.clientHeight + "px";
                }
                _previewContainer.style.transform = "translate( 0%, 0%)";
            }
            else
            {
                _enlargePreview.style.display = "none";
                _closePreview.style.display = "block";
                _selectedCameraBtn.style.display = "flex";
                _defaultCameraBtn.style.display = "flex";
                _helperImage.style.display = "none";
                _previewRenderer.setPixelRatio( window.devicePixelRatio );
                _previewRenderer.setSize(_sceneWidth/1.2, _sceneHeight/1.2);
                _previewContainer.style.left = "50%";
                _previewContainer.style.top = "50%";
                _previewContainer.style.transform = "translate( -50%, -50%)";
            }
        }
    }

    this.addHover = function(e){

        _previewState = true;
        this.updateRendererPos();

    }

    this.removeHover = function(e){

        if( _isEnlarged == false && e.target != _previewRenderer.domElement && e.target.id != "camIcon")
        {
            this.closeWindow();
        }
    }

    this.enlargePreviewWindow = function( event ){

        event.preventDefault();
        _isEnlarged = true;
       // change icon to cross;
       this.updateRendererPos();


    }

    this.setSceneWidthHeight = function(width, height)
    {
        _sceneWidth = width;
        _sceneHeight = height;
    }

    this.closePreviewWindow = function( event ) {
        event.preventDefault();
        _isEnlarged = false;
        this.updateRendererPos();
    }

    this.closeWindow = function()
    {
        _previewContainer.style.display = "none";
        this._camera.rotation.set(0, 0 ,0);
        this._camera.lookAt(_lookAt.set(_areaCenter.x, _areaCenter.y, _areaCenter.z ));
        _previewState = false;
        _active = false;
        _isEnlarged = false;
    }

    this.setPreviewWindowState = function()
    {
        _previewState = true;
        _active = false;
        _isEnlarged = false;
    }

    this.checkStatus = function()
    {
        return _previewState;
    }

    _helperImage.addEventListener('mouseover', event => this.addHover(event), false);
    _previewContainer.addEventListener('mouseover', event => this.addHover(event), false);
    _sceneCreator.addEventListener('click', event => this.removeHover(event), false);
    _enlargePreview.addEventListener('click', event => this.enlargePreviewWindow(event), false);
    _closePreview.addEventListener('click', event => this.closePreviewWindow(event), false);

};

CameraHelper.prototype = Object.assign( Object.create( THREE.Object3D.prototype ), {
    constructor: CameraHelper,
    isCameraHelper: true
} );

var TransformHelperPlane = function () {

    'use strict';

    THREE.Mesh.call( this,
        new THREE.PlaneBufferGeometry( 1000, 1000, 2, 2 ),
        new THREE.MeshBasicMaterial( { visible: false, wireframe: true, side: THREE.DoubleSide, transparent: true, opacity: 0.1 } )
    );

    this._item = null;

    var unitY = new THREE.Vector3( 0, 1, 0 );
    var unitZ = new THREE.Vector3( 0, 0, 1 );

    var tempVector = new THREE.Vector3();
    var dirVector = new THREE.Vector3();
    var alignVector = new THREE.Vector3();
    var tempMatrix = new THREE.Matrix4();
    var identityQuaternion = new THREE.Quaternion();

    this.position.set(0,0,0);
    unitY.set( 0, 1, 0 ).applyQuaternion( identityQuaternion );
    unitZ.set( 0, 0, 1 ).applyQuaternion( identityQuaternion );

    // Align the plane for current transform mode, axis and space.

    alignVector.copy( unitZ );
    dirVector.copy( unitY );

    tempMatrix.lookAt( tempVector.set( 0, 0, 0 ), dirVector, alignVector );
    this.quaternion.setFromRotationMatrix( tempMatrix );

    this.attachHelperPlane = function ( item ) {
        this._item = item;
    };

    this.detachHelperPlane = function () {
        this._item = null;
    };
};

TransformHelperPlane.prototype = Object.assign( Object.create( THREE.Mesh.prototype ), {
    constructor: TransformHelperPlane,
} );

const  _unit = {
        X: new THREE.Vector3( 1, 0, 0 ),
        Y: new THREE.Vector3( 0, 1, 0 ),
        Z: new THREE.Vector3( 0, 0, 1 ),
        aX: new THREE.Vector3( -1, 0, 0 ),
        aY: new THREE.Vector3( 0, -1, 0 ),
        aZ: new THREE.Vector3( 0, 0, -1 ),
};

export default class SceneCreator {
    sceneContainer 	= null;
    sceneWidth 		= null;
    sceneHeight 	= null;
    aspect			= null;
    frustumSize		= null;
    envMap 			= null;
    scene 			= null;
    sceneRenderer 	= null;
    clock 			= null;

    spaceItemsList = null;
    sceneType = null;

    mouse 			= null;

    // orbitControls 		= null;
    rotationControls 	= null;
    transformControls   = null;
    navControl         = null;
    cameraControls 		= null;
    sunControls			= null;

    // Managers
    assetManager = null;
    objectPlacementManager = null;
    raycastManager = null;
    debugEngine = null;
    LFAAreaManager = null;
    gridViewManager = null;
    sceneLoader = null;
    swapManager = null;
    actionManager = null;

    managersDict = {};

    sceneCameras 	= [];
    activeCamera 	= null;

    loader 			= null;
    sceneInfoExporter = null;
    grid            = null;

    duplicateButton = null;
    expandButton = null;
    deleteButton = null;
    sizeButton = null;
    freezeButton = null;
    hotspotCameraImage = null;
    cameraPositionLength = null;
    selected_area_space =  null;
    directionsList = null;
    directionArray = null;
    cameraHotspots = null;
    nextAreaCamera = null;
    cameraHelper = null;
    defaultPosition = [];
    selectedHotspot = {};
    areaCameraPosition = {
        key: null,
        value: null
    };
    restrictSlider = false;
    previewRenderer = null;
    previewContainer = null;
    enlargePreview = null;

    sceneRoom = {
        ceiling : null,
        floor 	: null,
        walls	: [],
        miscItemsParent : null,
        doors   : []
    };

    sceneAssets 	= [];
    sceneLights		= [];
    sceneSurfaces   = [];
    resetAssetsState = [];

    sceneToLoadInfo = null;
    sunAngleSlider 	= null;
    keyboard 		= new KeyboardState();

    isPanning		= false;
    isPanned        = false;
    panningEnabled  = true;
    maxCameraHeight = 0;
    minCameraHeight = 0.1;
    minCameraHeightCappingPercentage = 0.15;
    maxOrthoZoom = 0;

    mouseDown = false;
    doubleClickDown = false;
    enableDoubleClickZoom = false;
    lastMouseMovementX = 0;
    lastMouseMovementY = 0;


    helperPlane = null;
    removeLoader = null;
    setSliderPosition2D = null;
    setSliderValue = null;
    showDeleteConfirm = null;
    handleSwapProductClick = null;
    customTransformControls = null;
    hoverImage = null;

    hoverPos = new THREE.Vector3();
    cameraTargetPos = new THREE.Vector3();
    animateCameraMovement = false;
    animateCameraRotation = false;
    editModeOn = true;
    customAspect = 1;
    targetFOV = 45;
    isFOVAnimating = false;
    snappingEnabled = true;
    runHardCodedPlacement = false;
    previewModeOn = false;
    resetModeOn = false;
    rotateCamera = false;
    normalModeOn = true;
    cameraHotspotsSaved = [];
    save_scene_edit = false;
    previewRotate = false;
    prevRotateX = 0;
    prevRotateY = 0;
    sceneState = 'design';

    movementSpeed = 7.0;
    accumulator = 0;
    fixedDeltaTime = 1 / 360;
    roomHeight = 0;

    currentAspectType = Constants.AspectType.HORIZONTAL;

    currentArea = null;

    shouldInvertNormals = true;
    allowedOverlapThreshold = 0;    // Percentage value between 0-1

    relationshipsList = {};
    lastUpdatedTime = 0;
    itemMaterialTypes = {};
    itemsPlacementList = {};
    itemProductPlatforms = {};

    setLoadingProgressMessage = null;
    loading360ProgressMessage = null;
    itemCategoryList = {};

    selection = null;

    statsEnabled = false;
    stats;
    spaceItemsList = {};
    sceneType = null;

    directionArray = null;
    directionsList = null;
    enableCameraControls = true;
    sceneRendererGrid = null;
    gridSnapshot = null;

    validBaseItemCategories = null;

    spaceConfiguratorEnabled = false;
    spaceConfigurator = null;
    measurementTool = null;

    isClipping = false;
    lastCameraPositionOnFloor = 0;
    isBabylonExported = false;

    FrustumObjects = []
    
    clonedActiveCamera = null;

    currentSpaceLights = [];
    deletedAssets = [];

    cameraLocked = false;
    lastMouseClickTime = 0;

    Direction = {
        UP: "up",
        DOWN: "down",
        LEFT: "left",
        RIGHT: "right",
    };

    documentIsFocused = true;
    controlPressed = false;
    objectMovement = false;

    selectedObjectPosition = null;
    selectedObjectRotation = null;

    constructor(sceneJObj, aspectRatio = 0.55,
        removeLoader = null, setSliderPosition2D = null,
        setSliderValue = null, showDeleteConfirm = null,
        editModeOn = true, spaceItemsList = {},
        itemCategoryList = {}, itemsPlacementList = {},
        itemMaterialTypes = {}, itemProductPlatforms = {},
        setLoadingProgressMessage = null,
        setValidBaseCategories = null, setDisclaimer = null,
        reset3DCameraHeight = null, setSpaceConfiguratorState = null,
        setSpaceConfigurationOfSelection = null, setSpaceTypeOfSelection = null,
        setSpaceSelectionColorConfigurable = null, handleSwapProductClick = null, showProductSizeControls = null, 
        showFreeModeState = null, updateCameraHeight = null, applyLookAtObjectHeadOn = null,
        setSunControls = null, setCameraHeightProp = null,
        setCameraStateHeight = null, setIsSelectedAsset= null, restoreAssetsScene = null) {

        this.sceneContainer 	= document.getElementById('scene-creator');
        this.gridSnapshot = document.getElementById("grid-snapshot");
        this.grid = document.getElementById("grid");
        this.gridState = false;
        this.frustumSize		= 10;
        this.mouse 			= new THREE.Vector2();
        this.clock			= new THREE.Clock();
        this.loader 		= this.buildGLTFLoader();
        this.sunAngleSlider = document.getElementById('sunAngleSlider');
        this.sceneInfoExporter = new SceneInfoExporter();
        this.removeLoader = removeLoader;
        this.setLoadingProgressMessage = setLoadingProgressMessage;
        this.setSliderPosition2D = setSliderPosition2D;
        this.setSliderValue = setSliderValue;
        this.showDeleteConfirm = showDeleteConfirm;
        this.handleSwapProductClick = handleSwapProductClick;
        this.aspectRatio    = aspectRatio;
        this.editModeOn = editModeOn;
        this.customAspect = 0.5;
        this.targetFOV = 45;
        this.isFOVAnimating = false;
        this.spaceItemsList = spaceItemsList;
        this.itemsPlacementList = itemsPlacementList;
        this.setDisclaimer = setDisclaimer;
        this.reset3DCameraHeight = reset3DCameraHeight;
        this.spaceConfiguratorEnabled = false;
        this.setSpaceConfiguratorState = setSpaceConfiguratorState;
        this.setSpaceConfigurationOfSelection = setSpaceConfigurationOfSelection;
        this.setSpaceTypeOfSelection = setSpaceTypeOfSelection;
        this.setSpaceSelectionColorConfigurable = setSpaceSelectionColorConfigurable;
        this.showProductSizeControls = showProductSizeControls;
        this.showFreeModeState = showFreeModeState;
        this.cameraNavigation = true;

        this.directionsList = [Constants._unit.Y, Constants._unit.aY];
        this.directionArray = [ Constants._unit.X, Constants._unit.aX, Constants._unit.Z, Constants._unit.aZ];
        this.sceneType = sceneJObj.type;
        this.itemCategoryList = itemCategoryList;
        this.itemMaterialTypes = itemMaterialTypes;
        this.imgResolutions = [];
        this.imgWidth = 1;
        this.imgHeight = 1;
        this.aspect_applied = false;
        this.enableKeyCheck = true;
        this.spaceObj = {}
        this.spaceObj["name"] = sceneJObj.assetName;
        this.spaceObj["url"] = sceneJObj.assetURL;
        this.setValidBaseCategories = setValidBaseCategories
        this.cameraHotspots = {};
        this.nextAreaCamera = 1;
        this.previewModeOn = false;
        this.updateCameraHeight = updateCameraHeight;
        this.applyLookAtObjectHeadOn = applyLookAtObjectHeadOn;
        this.setSunControls = setSunControls;
        this.setCameraHeightProp = setCameraHeightProp;
        this.setCameraStateHeight = setCameraStateHeight;
        this.setIsSelectedAsset = setIsSelectedAsset;
        this.restoreAssetsScene = restoreAssetsScene;

        this.itemProductPlatforms = itemProductPlatforms;

        if(this.statsEnabled) {
            this.stats = new Stats();
            this.sceneContainer.appendChild( this.stats.dom );
        }
        this.setValidBaseCategories();
        this.updateScreenProps();
        this.setupSceneInfoString(sceneJObj);
        this.setupScene();
        this.loadSpace();
        this.previewContainer = document.getElementById('preview-container');
        this.previewRenderer 	= this.buildPreviewRenderer( this.sceneWidth, this.sceneHeight);
        this.previewContainer.appendChild( this.previewRenderer.domElement );
        this.previewRenderer.domElement.addEventListener( 'mousedown', this.onMouseDownPreview, false );
        this.previewRenderer.domElement.addEventListener( 'mousemove', this.onMouseMovePreview, false );
        this.previewRenderer.domElement.addEventListener( 'mouseout', this.onMouseOutPreview, false );
        let animate = () => {
            requestAnimationFrame( animate );
            this.render();
            this.update();
        }

        animate();
    }

    onMouseDownPreview = ( event ) => {
        this.previewRotate = true;
        this.prevRotateX = event.pageX;
        this.prevRotateY = event.pageY;
        }

    onMouseMovePreview = ( event ) => {
        if(this.previewRotate == true)
        {
            var deltaX = event.pageX - this.prevRotateX;
            var deltaY = event.pageY - this.prevRotateY;
            this.cameraHelper.getCameraHelper().rotation.y -= degrees_to_radians(deltaX*0.1 );
            this.cameraHelper.getCameraHelper().rotation.x -= degrees_to_radians(deltaY*0.1 );
            this.prevRotateX = event.pageX;
            this.prevRotateY = event.pageY;


        }
    }

    enableKey() {
        this.enableKeyCheck = true;
    }

    disableKey() {
        this.enableKeyCheck = false;
    }

    onMouseOutPreview = ( event ) => {
        this.previewRotate = false;
    }


    showGridForSnapshot() {

        if (this.gridState == false) {
			this.setGridForSnapshot(true);
		}
        else {
            this.setGridForSnapshot(false);
        }
	}

	updateGridForSnapshot() {
		if  (this.gridState == true) {
			this.setGridForSnapshot(false);
			this.setGridForSnapshot(true);
		}
	}

	setGridForSnapshot(state) {
		if (state == true) {
			this.gridSnapshot.height = this.sceneHeight;
			this.gridSnapshot.width = this.sceneWidth;
			this.gridSnapshot.style.display = "inline";
			this.grid.style.margin = "0 auto"
			this.grid.style.display = "flex";
            this.grid.style.justifyContent = "center";
            this.gridState = state;
		}
		else {
            this.disableGridForSnapshot();
		}

	}

    disableGridForSnapshot() {
        this.gridSnapshot.style.display = "none";
        this.gridState = false;
    }

    buildPreviewRenderer( width, height ) {
        const renderer 				= new THREE.WebGLRenderer({  alpha: true });
        //renderer.setViewport(0,0, width/2, height/2);
        renderer.gammaOutput 		= true;
        renderer.shadowMap.enabled	= true;
        renderer.shadowMap.type 	= THREE.PCFSoftShadowMap;
        renderer.setClearColor( 0xffffff, 1.0 );
        renderer.setPixelRatio( window.devicePixelRatio );
        return renderer;
    }

    buildSceneLoader(spaceObj) {
        const controls = new SceneLoader( this, this.scene, spaceObj, this.sceneType, this.spaceItemsList, this.itemCategoryList, this.itemsPlacementList, this.itemMaterialTypes, this.sceneToLoadInfo, this.managersDict, this.setLoadingProgressMessage);
        this.managersDict[Constants.Manager.SceneLoader] = controls;
        return controls;
    }

    buildScene() {
        const scene 		= new THREE.Scene();
        // scene.background 	= new THREE.Color("#989898");
        return scene;
    }

    buildSceneRenderer( width, height ) {
        const renderer 				= new THREE.WebGLRenderer( { antialias: true, preserveDrawingBuffer: true, alpha: true } );
        renderer.gammaOutput 		= true;
        renderer.gammaFactor 		= 2.2;
        renderer.shadowMap.enabled	= true;
        renderer.shadowMap.type 	= THREE.PCFSoftShadowMap;
        renderer.setClearColor( 0x000000, 0.0 );
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( width, height );
        return renderer;
    }

    buildGLTFLoader() {
        const gltfLoader 	= new GLTFLoader();
        const dracoLoader 	= new DRACOLoader();
        dracoLoader.setDecoderPath( '/js/libs/draco/gltf/' );
        gltfLoader.setDRACOLoader( dracoLoader );
        return gltfLoader;
    }

    buildOrbitControls( camera, renderer ) {
        const controls = new OrbitControls( camera, renderer.domElement );
        return controls;
    }

    buildRotationControls( camera, renderer ) {
        const controls = new RotationControls( camera, renderer.domElement );
        this.managersDict[Constants.Manager.RotationControls] = controls;
        return controls;
    }

    buildTransformControls( camera, renderer, actionManager ) {
        const controls = new TransformControls( camera, renderer.domElement, actionManager );
        this.managersDict[Constants.Manager.TransformControls] = controls;
        return controls;
    }

    buildNavControl( camera, renderer ) {
        const controls = new NavigationControl( camera, this.space.floors, this.space.walls, this.sceneAssets, this.space.miscNodes, renderer.domElement );
        this.managersDict[Constants.Manager.NavigationControl] = controls;
        return controls;
    }

    buildCameraControls( camera, renderer ) {
        const controls = new CameraControls( camera, renderer );
        this.managersDict[Constants.Manager.CameraControls] = controls;
        return controls;
    }

    buildSunControls( sun ) {
        const controls = new SunControls( sun, this.managersDict );
        this.managersDict[Constants.Manager.SunControls] = controls;
        return controls;
    }

    buildOrbitCamera( width, height ) {
        const orbitCamera = new THREE.PerspectiveCamera( 45, width / height, 0.25, 20 );
        orbitCamera.position.set( 0, 0, 0 );
        return orbitCamera;
    }

    buildTopDownCamera( width, height ) {
        const topDownCamera  		= new THREE.PerspectiveCamera(  45, width / height, 0.01, 10000 );
        topDownCamera.name 			= "topDown"
        topDownCamera.rotation.x 	= -Math.PI/2;
        return topDownCamera;
    }

    buildTopDownOrthoCamera( width, height ) {
        const topDownOrthoCamera  		= new THREE.OrthographicCamera( width / - 1, width / 1, height / 1, height / - 1, 0.01, 10000 );
        topDownOrthoCamera.name = "topDownOrtho";
        topDownOrthoCamera.rotation.x = -Math.PI / 2;
        return topDownOrthoCamera;
    }

    buildCustomCamera( cameraName, camera_mode, cameraType = "still", fov = 45,
                        cameraPosition = new THREE.Vector3( 0, 1.73, 0) ,
                        target = new THREE.Vector3( 0, 0, -1 ) ,
                        imageFormat = 'jpg' ,
                        imageWidth = 1600 ,
                        imageHeight = 900, clipping_value = 0.01, display_name = "Untitled", dpi = 300) {
        var camObj 			= new THREE.PerspectiveCamera( fov, this.sceneWidth / this.sceneHeight, 0.01, 10000 );
        camObj.name 		= cameraName;
        camObj.position.copy( cameraPosition );
        camObj.lookAt( target.add(cameraPosition) );
        camObj.userData.imageFormat = imageFormat;
        camObj.userData.imageWidth = imageWidth;
        camObj.userData.imageHeight = imageHeight;
        camObj.userData.dpi = dpi;
        camObj.userData.clipping_value = FileConstants.cameraNearDefaultValue;
        camObj.userData.camera_mode = camera_mode;
        console.log("scene render name", display_name)
        if (display_name != "Untitled")
            camObj.userData.display_name = display_name
        if(clipping_value != undefined)
            camObj.userData.clipping_value = clipping_value;
        this.addCameraToScene( cameraName, camObj, Constants.CameraProjectionType.PERSPECTIVE, cameraType);
        return camObj;
    }

    buildObjectPlacementManager() {
        const manager = new ObjectPlacementManager(this, this.scene, this.sceneContainer, this.sceneAssets, !this.isBabylonExported, this.mouse, this.managersDict, this.setDisclaimer, this.actionManager, this.setIsSelectedAsset);
        this.managersDict[Constants.Manager.ObjectPlacementManager] = manager;
        this.selection = manager.getSelection();
        return manager;
    }

    buildRaycastManager() {
        const manager = new RaycastManager(this, this.mouse, this.managersDict);
        this.managersDict[Constants.Manager.RaycastManager] = manager;
        return manager;
    }

    buildGridViewManager() {
        const manager = new GridViewManager(this.managersDict, !this.isBabylonExported);
        this.managersDict[Constants.Manager.GridViewManager] = manager;
        return manager;
    }

    buildDebugEngine() {
        const manager = new DebugEngine(this.scene);
        this.managersDict[Constants.Manager.DebugEngine] = manager;
        return manager;
    }

    buildSpaceConfigurator() {
        const manager = new SpaceConfigurator(this, this.scene, this.mouse, this.managersDict, this.setSpaceConfiguratorState,
                this.setSpaceConfigurationOfSelection, this.setSpaceTypeOfSelection, this.setSpaceSelectionColorConfigurable);
        this.managersDict[Constants.Manager.SpaceConfigurator] = manager;
        return manager;
    }

    buildMeasurementTool() {
        const manager = new MeasurementTool(this, this.scene, this.mouse, this.managersDict, !this.isBabylonExported);
        this.managersDict[Constants.Manager.MeasurementTool] = manager;
        return manager;
    }

    addEventListeners( renderer ) {
        window.addEventListener( 'resize', this.onWindowResize, false );
        renderer.domElement.addEventListener( 'contextmenu', this.onContextMenu, false );
        renderer.domElement.addEventListener( 'mousedown', this.onMouseDown, false );
        renderer.domElement.addEventListener( 'mouseup', this.onMouseUp, false );
        renderer.domElement.addEventListener( 'mousemove', this.onMouseMove, false );
        renderer.domElement.addEventListener( 'wheel', this.onWheel, false );
        renderer.domElement.addEventListener( 'dblclick', this.onDoubleClick, false );
        window.addEventListener( 'mouseup', this.onMouseUp, false );
        window.addEventListener('click', this.onClick , false );
        // Add event listeners for focus and blur events
    window.addEventListener('focus', () => {
        this.documentIsFocused = true;
    });

    window.addEventListener('blur', () => {
        this.documentIsFocused = false;
        // Reset key states and any accumulated movement on blur
        this.keyboard.unPress();
        this.accumulator = 0;
        this.resetKeys();
    });

    }

    resetKeys = () => {
        this.keyboard.unPress();
    };

    loadEnvironment( url ) {
        const format 	= '.jpg';
        const env 		= new THREE.CubeTextureLoader().load( [
            url + 'px' + format, url + 'nx' + format,
            url + 'py' + format, url + 'ny' + format,
            url + 'pz' + format, url + 'nz' + format
        ] );
        return env;
    }

    setupSceneLights() {
        let ambientLight 		= new THREE.AmbientLight( 0xffffff );
        ambientLight.intensity 	= 0.5;
        this.scene.add( ambientLight );
    }

    setupSpaceLights() {
        // const sphere = new THREE.SphereBufferGeometry( 0.25, 16, 8 );
        // const c1 = 0xff0040;
        for (let key in this.space.areas) {
            const area = this.space.areas[key];

            const box 		= new THREE.Box3().setFromObject( area.root );
            const size      = box.getSize( new THREE.Vector3() );
            const length 	= size.length();
            const center 	= box.getCenter( new THREE.Vector3() );

            // const helper = new THREE.Box3Helper( box, 0xffff00 );
            // this.scene.add( helper );

            // Use some dynamic algo for calculating the currently hard-coded values here
            let intensity = length / 10;
            if(length < 5) intensity = 0.1;

            // Set light intensity and distance according to area size
            // Color is set to greyish, so that white areas are not overexposed
            const light = new THREE.PointLight( 0xD5D8DC, intensity , length );
            this.currentSpaceLights.push({"light": light, "intensity": intensity});
            // light.add(new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color : c1 } ) ));

            center.y = center.y + ((size.y / 2) - 0.25) ;
            light.position.copy(center);

            this.scene.add(light);
        }
    }

    addCameraToScene( cameraName, cameraNode, cameraProjectionType, cameraType ) {
        this.sceneCameras.push({
            name 	      : cameraName,
            cam 	      : cameraNode,
            projection    : cameraProjectionType,
            type 	      : cameraType
        });
        this.scene.add(cameraNode);
    }

    setupSceneCameras() {
        // let orbitCamera 	= buildOrbitCamera( sceneWidth, sceneHeight );
        let topDownCamera = this.buildTopDownCamera(this.sceneWidth, this.sceneHeight);
        let topDownOrthoCamera = this.buildTopDownOrthoCamera(this.sceneWidth, this.sceneHeight);
        this.activeCamera	= topDownCamera;

        // addCameraToScene( "orbit", orbitCamera, cameraType.PERSPECTIVE );
        this.addCameraToScene( "topDown", topDownCamera, Constants.CameraProjectionType.PERSPECTIVE, "navigation" );
        this.addCameraToScene( "topDownOrtho", topDownOrthoCamera, Constants.CameraProjectionType.ORTHOGRAPHIC, "navigation" );


        let helperCamFoundInSceneInfo = false;
        if (this.sceneToLoadInfo != null) {
            this.sceneToLoadInfo.cameras.forEach(camera => {
                if(camera.camera_name === 'helper') {
                    helperCamFoundInSceneInfo = true;
                }
            });
        }

        // Set up helper navigation camera for custom cameras control, if new room is being setup or if helper cam is not included in sceneInfo
        if (!helperCamFoundInSceneInfo) {
            this.buildCustomCamera( "helper", FileConstants.SCENE_CREATOR_CAMERA_MODES.ThreeD, "navigation" );
        }
    }

    setupControls() {
        // orbitControls 		= buildOrbitControls( activeCamera, sceneRenderer );
        this.actionManager = new ActionManager();
        this.rotationControls 	= this.buildRotationControls( this.activeCamera, this.sceneRenderer );
        this.transformControls  = this.buildTransformControls(this.activeCamera, this.sceneRenderer, this.actionManager);
        this.cameraControls 	= this.buildCameraControls( this.activeCamera, this.sceneRenderer );

        this.rotationControls.enabled = false;
        this.scene.add( this.rotationControls );

        this.transformControls.enabled = false;
        this.scene.add( this.transformControls );
        this.transformControls.showE = false;
    }

    setupPreSpaceInitManagers() {
        this.debugEngine = this.buildDebugEngine();
        this.sceneLoader = this.buildSceneLoader( this.spaceObj );
    }

    setupPostSpaceInitManagers(){
        this.raycastManager = this.buildRaycastManager();
        this.gridViewManager = this.buildGridViewManager();
        this.assetManager = this.managersDict[Constants.Manager.AssetLoadingManager];
        this.spaceConfigurator = this.buildSpaceConfigurator();
    }

    setupPostScenePlacementManagers() {
        this.objectPlacementManager = this.buildObjectPlacementManager();
        this.swapManager = new SwapManager(this.scene, this.space, this.sceneAssets, this.sceneLoader, this.isBabylonExported);
        this.measurementTool = this.buildMeasurementTool();
        this.addEventListeners( this.sceneRenderer );
    }

    setupScene() {
        this.scene 		= this.buildScene();
        this.sceneRenderer 	= this.buildSceneRenderer( this.sceneWidth, this.sceneHeight );
        this.envMap			= this.loadEnvironment( '/models/maps/' );

        this.setupSceneLights();
        this.setupSceneCameras();
        this.setupControls();
        this.setupPreSpaceInitManagers();

        if (this.sceneContainer != undefined) {
            this.sceneContainer.appendChild( this.sceneRenderer.domElement );
        }
    }

    setupSceneInfoString(sceneJObj) {
        if (sceneJObj.type === "load") {
            this.sceneToLoadInfo = this.setupDeletedAssets(JSON.parse( sceneJObj.sceneInfoStr ));
        }
    }

    setupDeletedAssets(sceneDetails) {
        // move hidden assets to deleted assets array
        this.deletedAssets = sceneDetails.assets.filter((asset)=>{
            return !asset.asset_visible;
        })

        // remove hidden assets from scene load info
        sceneDetails.assets = sceneDetails.assets.filter((asset)=>{
            return asset.asset_visible;
        })

        return sceneDetails
    }
    
    setAssetStoredRotation(assetObj, assetRotation) {
        if ( assetRotation.type == undefined ) {
            let assetEuler = new THREE.Euler( assetRotation.x, assetRotation.y, assetRotation.z );
            assetObj.setRotationFromEuler( assetEuler );
        }
        else {
            assetObj.quaternion.set( assetRotation.x, assetRotation.y, assetRotation.z, assetRotation.w);
        }
    }

    setAssetStoredPosition(assetObj, assetPosition) {
        assetObj.position.set( assetPosition.x, assetPosition.y, assetPosition.z );
    }
    
    
    getSceneAssetByName(name, asset_counter) {
        let counter = 0;
        for (let i in this.sceneAssets){
            if (this.sceneAssets[i].name == name) {
                counter ++
                if (asset_counter == counter) {
                    return this.sceneAssets[i]        
                }
            }
        }
        return null
    }

    placeAssetOnNewPosition (name, position, rotation, visible, asset_counter) {
        let assetObj = this.getSceneAssetByName(name, asset_counter);
        if (assetObj != null) {
            this.setAssetStoredRotation(assetObj,rotation)
            this.setAssetStoredPosition(assetObj,position)
            assetObj.visible = visible
        }
    }
    
    isCustomLighting() {

        return this.space.isCustomLighting();
    }

    setValidBaseItems(validBaseItemCategories) {
        this.validBaseItemCategories = validBaseItemCategories;
    }

    enableMeasurementTool(state) {
        this.measurementTool.enableMeasurementTool(state);
    }

    // ****************** Space Configuration ************************

    setSpaceConfiguratorModeState(state) {
        this.spaceConfiguratorEnabled = state;
        if (!this.spaceConfiguratorEnabled) {
            this.deactivateSpaceConfigurator();
        }
    }

    deactivateSpaceConfigurator() {
        this.spaceConfigurator.deactivateConfigurator();
    }

    resetSpaceConfiguratorFocus() {
        if (this.spaceConfigurator) {
            this.spaceConfigurator.resetSpaceConfiguratorFocus();
        }
    }

    applyColorToSpaceSelection(hexColorCode) {
        this.spaceConfigurator.applyColorToSelection(hexColorCode);
    }

    applyColorToAllSpaceSelection(hexColorCode) {
        this.spaceConfigurator.applyColorToAllSelection(hexColorCode);
    }
    
    setSpaceConfiguratorTargetRequest(request) {
        this.spaceConfigurator.setTargetRequest(request);
    }

    getSpaceConfiguratorTargetRequest() {
        return this.spaceConfigurator.getTargetRequest();
    }

    setPreserveTextureOption( preserveTexture ) {
        this.spaceConfigurator.setPreserveTextureOption( preserveTexture );
    }

    applyMaterialToSpaceSelection( texture, horizontalRepeat, verticalRepeat, roughness, horizontalOffset, verticalOffset, rotation, onMaterialApplied = null) {
        let scope = this;
        if (texture) {
            scope.setSpaceConfiguratorTargetRequest(texture);
            this.assetManager.loadTexture(texture, (textureRequest, textureData)=> {
                if (textureRequest == scope.getSpaceConfiguratorTargetRequest()) {
                    scope.spaceConfigurator.applyMaterialToSelection(textureData,  horizontalRepeat, verticalRepeat, roughness, horizontalOffset, verticalOffset, rotation, onMaterialApplied)
                }
            })
        }
    }

    applyMaterialToAllSpaceSelection( texture, horizontalRepeat, verticalRepeat, roughness, horizontalOffset, verticalOffset, rotation, onMaterialApplied = null) {
        let scope = this;
        if (texture) {
            scope.setSpaceConfiguratorTargetRequest(texture);
            this.assetManager.loadTexture(texture, (textureRequest, textureData)=> {
                if (textureRequest == scope.getSpaceConfiguratorTargetRequest()) {
                    scope.spaceConfigurator.applyMaterialToAllSelection(textureData,  horizontalRepeat, verticalRepeat, roughness, horizontalOffset, verticalOffset, rotation, onMaterialApplied)
                }
            })
        }
    }

    restoreMaterialToAllSpaceSelection() {
        this.spaceConfigurator.restoreMaterialToAllSelection();
    }


    loadSpaceConfiguration() {
        this.sceneLoader.loadSpaceConfiguration(this.space.hideCeiling);
    }

    discardConfigurationToSpaceSelection() {
        this.spaceConfigurator.discardConfigurationToSelection();
    }

    resetConfigurationToSpaceSelection() {
        this.spaceConfigurator.resetConfigurationToSelection();
    }

    updateConfigurationToSpaceSelection(configInfo) {
        this.spaceConfigurator.updateConfigurationToSelection(configInfo);
    }

    // ******************Camera placement ************************
     // for testing only, to get hotspots for any room
     getNextCameraPosition(flag = 0)
     {
         if (this.activeCamera.name!= "topDown" && this.activeCamera.name != "topDownOrtho")
         {
             if(this.nextAreaCamera == 0)
             {
                 this.nextAreaCamera++;
             }
             let cameraPositions = this.cameraHotspots[this.activeCamera.name];
             if (flag ==1)
             {
                 this.nextAreaCamera = cameraPositions.length-1;
             }
             let currentPosition = cameraPositions[this.nextAreaCamera].clone();
             this.nextAreaCamera = (this.nextAreaCamera + 1) % cameraPositions.length;
             this.activeCamera.position.set(currentPosition.x, currentPosition.y, currentPosition.z);
             this.activeCamera.lookAt(cameraPositions[0].clone());
         }
     }

    // Setup camera helper for integrating dragging control for the camera
    setupCameraHelper(area = Object.keys(this.space.areas)[0], imageID) { //for first room
        let index = this.sceneCameras.findIndex(x => x.name == area && x.type == "360");
        if (index != -1) {
            if(this.cameraHelper == null)
            {

                this.cameraHelper = new CameraHelper(this.sceneCameras[index].cam, this.cameraHotspots[area][0], this.activeCamera, this.sceneWidth, this.sceneHeight, this.previewRenderer); // pass camera

            }
            else
            {
                this.cameraHelper.setCameraHelper(this.sceneCameras[index].cam);

            }

            this.cameraHelper.updateMatrixWorld();
        }
    }

    resetPreviewCamera() {
        if(this.cameraHelper != null)
        {
            this.cameraHelper.getCameraHelper().lookAt(this.getCenterlookAt(this.cameraHelper.getCameraHelper().name, this.cameraHelper.getCameraHelper().position));
        }
    }


    setCameraHotspot(hotspots) {
        this.cameraHotspotsSaved = hotspots;
    }

    // Functions to manipulate the scene creator states according to the frontend
    enablePreview() {
        this.previewModeOn = true;
        this.editModeOn = false;
        this.rotateCamera = true;
        this.normalModeOn = false;
        this.resetModeOn = false;
    }

    disablePreviewMode() {
        this.previewModeOn = false;
    }

    disablePreview() {
        this.previewModeOn = false;
        this.editModeOn = true;
        this.rotateCamera = false;
        this.normalModeOn = true;
        this.resetModeOn = true;
    }

    alignCameraForSnapshot() {
        if (this.activeCamera.name != "topDown" && this.activeCamera.name != "topDownOrtho") {
            this.setCameraPolarAngle(90);
            if (this.setSliderValue != null) {
                this.setSliderValue(this.getCameraPolarAngle());
            }
        }
    }

    /* We compute the angle nearest to the 4 cardinal directions (North, East, West, South) and move the camera to face that side exactly */
    alignCameraHorizontallyForSnapshot() {

        /* Taking the direction which camera is facing */
        let cameraWorldDirection = this.activeCamera.getWorldDirection();

        let East = new THREE.Vector3(1,0,0);
        let West = new THREE.Vector3(-1,0,0);
        let North = new THREE.Vector3(0,0,1);
        let South = new THREE.Vector3(0,0,-1);

        let distanceMatrix = {
            "East" : East,
            "West" : West,
            "North" : North,
            "South" : South,
        }

        let minDistanceVector = "";
        let minDistance = 0;
        /* Calculating the difference of the camera angle to the cardinal sides and calculating the minimum angle*/
        for (var key in distanceMatrix) {
            let distanceTo = cameraWorldDirection.angleTo(distanceMatrix[key])
            if ( minDistanceVector == "" ) {
                minDistanceVector = key;
                minDistance = distanceTo;
            }
            else {
                if ( distanceTo < minDistance){
                    minDistanceVector = key;
                    minDistance = distanceTo
                }
            }
        }

        /*Setting the camera angle to the cardinal side with the minimum angle*/
        const camPositionX = this.activeCamera.position.x;
        const camPositionY = this.activeCamera.position.y;
        const camPositionZ = this.activeCamera.position.z;
        const camPolarAngle = this.getCameraPolarAngle();
        this.activeCamera.position.set(0, camPositionY, 0)
        this.activeCamera.lookAt(distanceMatrix[minDistanceVector]);
        this.activeCamera.position.set(camPositionX, camPositionY,camPositionZ)
        this.setCameraPolarAngle(camPolarAngle);
        this.activeCamera.updateProjectionMatrix();

    }

    lookAtObjectCenter (middleCameraY = false) {
    /* set look at of the camera at the object selected */
        if (this.selection.object == null)
            return

        // Getting center of object
        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject( this.selection.object );
        const center = boundingBox.getCenter();

        this.activeCamera.up = new THREE.Vector3(0,1,0);

        // Adjust camera Y if needed ( in  case of looking at the object head on )
        if ( middleCameraY == true )
            this.activeCamera.position.y = center.y;

        // Look at the center of the camera
        this.activeCamera.lookAt(center);

        this.activeCamera.updateProjectionMatrix();
    }

    lookAtObjectHeadOn() {
        if (!this.selection.object) return;
    
        // Get the bounding box and its size
        let box = new THREE.Box3().setFromObject(this.selection.object);
        let boxSize = box.getSize(new THREE.Vector3());
        let bBoxCenter = box.getCenter(new THREE.Vector3());
    
        // Calculate which dimension is largest in the horizontal plane (x or z)
        const horizontalDimension = Math.max(boxSize.x, boxSize.z);
        const verticalDimension = boxSize.y;
    
        // Determine FOV to use based on camera aspect and object dimensions
        const aspectRatio = this.activeCamera.aspect;
        let fovInRad;
    
        if (horizontalDimension > verticalDimension) {
            // Use horizontal FOV if the object is wider in the horizontal plane
            fovInRad = 2 * Math.atan(Math.tan(THREE.MathUtils.degToRad(this.activeCamera.fov) / 2) * aspectRatio);
        } else {
            // Use vertical FOV if the object is taller
            fovInRad = THREE.MathUtils.degToRad(this.activeCamera.fov);
        }
    
        // Calculate optimal distance to fit the largest dimension
        const largestDimension = Math.max(horizontalDimension, verticalDimension);
        let optimalDistance = (largestDimension / 2) / Math.tan(fovInRad / 2);
        optimalDistance *= 1.3;

        if( horizontalDimension > verticalDimension && aspectRatio > 1) {
            optimalDistance *= 1.35    
        } 

        if( horizontalDimension < verticalDimension && aspectRatio < 1) {
            optimalDistance *= 1.35
        }
        
        // Get world position of the object
        let worldPos = this.selection.object.getWorldPosition(new THREE.Vector3());
    
        // Calculate the direction vector from the camera to the object's world position
        let objectDir = this.selection.object.getWorldDirection();
    
        // Set new camera position to place it at the calculated optimal distance
        let newCamPosx = worldPos.x + (objectDir.x * optimalDistance);
        let newCamPosz = worldPos.z + (objectDir.z * optimalDistance);
        let newCamPosy = bBoxCenter.y + (optimalDistance); // Adjust y-axis for vertical alignment as needed
    
        // Update camera position
        this.activeCamera.position.set(newCamPosx, newCamPosy, newCamPosz);
    
        // Center object within the frame
        this.lookAtObjectCenter(true);
    }

    applyLookAtObjectTopDown() {
        const focusedObject = this.objectPlacementManager.focusedAsset;        
        const boundingBox = new THREE.Box3().setFromObject(focusedObject);
        const boundingSize = boundingBox.getSize(new THREE.Vector3());
        const boundingCenter = boundingBox.getCenter(new THREE.Vector3());
        const distanceMultiplier = 1.5; 
    
        this.activeCamera.position.set(boundingCenter.x, this.activeCamera.position.y, boundingCenter.z);
    
        if (this.activeCamera.isPerspectiveCamera) {

            const objectHeight = boundingSize.y;
            const largestSide = Math.max(boundingSize.x, boundingSize.z);
            const fovInRadians = THREE.MathUtils.degToRad(this.activeCamera.fov);
            const verticalDistance = (objectHeight * distanceMultiplier) / (2 * Math.tan(fovInRadians / 2));
            const aspectRatio = this.activeCamera.aspect;
            const horizontalDistance = (largestSide * distanceMultiplier) / (2 * Math.tan(fovInRadians / 2 * aspectRatio));
            const requiredDistance = Math.max(verticalDistance, horizontalDistance);
            this.activeCamera.position.y = boundingCenter.y + requiredDistance;

        } else if (this.activeCamera.isOrthographicCamera) {

            const boundingRadius = boundingSize.length() / 2;
            const requiredHeight = boundingRadius * distanceMultiplier;            
            this.activeCamera.zoom = (this.activeCamera.top * 2) / requiredHeight;  

        }

        this.updateCameraHeight(this.getActualCameraHeight());
        this.activeCamera.lookAt(boundingCenter.x, boundingCenter.y, boundingCenter.z);
        this.activeCamera.updateProjectionMatrix();
    }

    // Zoom the Camera into any area / room
    zoomCamera(area = Object.keys(this.space.areas)[0]) {
        // set first area by default;

        if (this.activeCamera.name == "topDown" || this.activeCamera.name == "topDownOrtho") {
            this.resetZoom();
            this.setZoom(this.space.areas[area].root);
            if(this.cameraHelper != null) {
                this.cameraHelper.setPreviewWindowState();
            }
        }
    }

    // reset the zoom to whole scene
    resetZoom() {
        this.setZoom(this.space.scene, 1);
    }

    // set to zoom for the passed area object
    setZoom(object, flag = 0) {
        // reset zoom and default settings of the top down camera
        if(this.activeCamera.name == "topDown" || this.activeCamera.name == "topDownOrtho") {
            const box 		= new THREE.Box3().setFromObject( object );
            const size 		= box.getSize( new THREE.Vector3() ).length();
            const center 	= box.getCenter( new THREE.Vector3() );
            const bbSize = new THREE.Vector3();
            box.getSize(bbSize);
            if(flag == 1) {
                this.maxPanX = object.position.x + bbSize.x/2;
                this.minPanX = object.position.x - bbSize.x/2;
                this.maxPanZ = object.position.z + bbSize.z/2;
                this.minPanZ = object.position.z - bbSize.z/2;
            }
            else {
                this.maxPanX = object.position.x + bbSize.x;
                this.minPanX = object.position.x - bbSize.x;
                this.maxPanZ = object.position.z + bbSize.z;
                this.minPanZ = object.position.z - bbSize.z;
            }
            this.maxCameraHeight = size + size / 3;
            this.activeCamera.zoom = 1;
            this.activeCamera.updateProjectionMatrix();
            this.activeCamera.position.copy( center );
            this.activeCamera.position.y += size;
            if (this.activeCamera.name == "topDownOrtho") {
                this.maxOrthoZoom = bbSize.y / this.activeCamera.top;
                this.activeCamera.zoom = this.maxOrthoZoom;
                this.activeCamera.updateProjectionMatrix();
            }
       }
    }

    setHotspotPosition (hotspotCameraImage) {
        this.hotspotCameraImage = hotspotCameraImage;
    }

    getCameraPosLength(area) {
        let cameraPositions = this.cameraHotspots[area];

        if ((this.cameraHotspotsSaved !== undefined  && this.cameraHotspotsSaved !== null && this.cameraHotspotsSaved.length !== 0 && this.cameraHotspotsSaved !== []) && this.save_scene_edit == false) {

           if (typeof (this.cameraHotspotsSaved) == "object" && Object.keys(this.cameraHotspotsSaved).length == 0) {
            }
            else if (typeof (this.cameraHotspotsSaved) == "object" && Object.keys(this.cameraHotspotsSaved).length != 0) {
               cameraPositions = this.cameraHotspotsSaved[area];
            }
            else if (typeof (this.cameraHotspotsSaved) !== "object"){
               cameraPositions = this.cameraHotspotsSaved[area];
            }

        }

        if (cameraPositions !== undefined) {
            return (cameraPositions.length - 1);
        }
        else {
            return 0;
        }
    }

    getAllHotspots(area) {
        let cameraPositions = this.cameraHotspots[area];
        if ((this.cameraHotspotsSaved !== undefined  && this.cameraHotspotsSaved !== null && this.cameraHotspotsSaved.length !== 0 && this.cameraHotspotsSaved !== []) && this.save_scene_edit == false ) {
                if (typeof (this.cameraHotspotsSaved) == "object" && Object.keys(this.cameraHotspotsSaved).length == 0) {
                }
                else if (typeof (this.cameraHotspotsSaved) == "object" && Object.keys(this.cameraHotspotsSaved).length != 0) {
                    cameraPositions = this.cameraHotspotsSaved[area];
                }
                else if (typeof (this.cameraHotspotsSaved) !== "object"){
                    cameraPositions = this.cameraHotspotsSaved[area];
                }
        }
        return cameraPositions;
    }

    setAreaCamera(area = Object.keys(this.space.areas)[0]){
        // set 360 camera of the area passed in the argument
        let index = this.sceneCameras.findIndex(x => x.name == area && x.type == "360");
        if (this.sceneCameras[index] != undefined) {
            this.sceneCameras[index].cam.lookAt(this.getCenterlookAt(area, this.sceneCameras[index].cam.position.clone()));
            this.switchToCameraByName(area);
        }

    }

    setPreviewSelectedHotspot(area = Object.keys(this.space.areas)[0]) {
        this.setAreaCamera(area);
    }

    setSelectedHotspot(area = Object.keys(this.space.areas)[0], hotspotPosition) {
        let hotspotPos = new THREE.Vector3();
        let hotspotInfo = [];
        let index = this.sceneCameras.findIndex(x => x.name == area && x.type == "360");
        hotspotPos.copy(this.sceneCameras[index].cam.position);
        hotspotInfo.push(hotspotPos);
        this.selectedHotspot[area] = hotspotInfo;
        this.sceneCameras[index].cam.position.copy(hotspotPosition);
        this.sceneCameras[index].cam.lookAt(this.getCenterlookAt(area, this.sceneCameras[index].cam.position.clone()));

    }

    getDefaultPositionValue(area) {
        if ((this.cameraHotspotsSaved !== undefined  && this.cameraHotspotsSaved !== null && this.cameraHotspotsSaved.length !== 0 && this.cameraHotspotsSaved !== []) && this.save_scene_edit == false) {
            return this.defaultPosition[area];
        }
        else {
            let hotspot = null;
            hotspot = this.cameraHotspots[area];
            if (hotspot !== undefined){
                return hotspot[hotspot.length-1];
            }
        }
    }

    setDefaultSettings() {
        if ((this.cameraHotspotsSaved !== undefined  && this.cameraHotspotsSaved !== null && this.cameraHotspotsSaved.length !== 0 && this.cameraHotspotsSaved !== []) && this.save_scene_edit == false) {

                for(let area in this.space.areas) {
                    if (this.selectedHotspot[area] != undefined){
                        this.setSelectedHotspot(area, this.defaultPosition[area]);
                    }
                }
        }
        else {
            let hotspot = null;
            for(let area in this.space.areas) {
                hotspot = this.cameraHotspots[area];
                if (hotspot !== undefined){
                    this.setSelectedHotspot(area, hotspot[hotspot.length-1]);
                }
            }

        }
    }

    getSelectedHotspot(area = Object.keys(this.space.areas)[0]) {
        let hotspot = this.selectedHotspot[area];
        let index = this.sceneCameras.findIndex(x => x.name == area && x.type == "360");
        this.debugEngine.debugLog("Log", area, index, this.sceneCameras);
        if (this.sceneCameras[index] !== undefined) {
            return this.sceneCameras[index].cam.position.clone();
        }

        return null;

    }

    getDefaultPosition(area = Object.keys(this.space.areas)[0]) {
        let hotspot = this.selectedHotspot[area];
        let index = this.sceneCameras.findIndex(x => x.name == area && x.type == "360");

        if (this.sceneCameras[index] !== undefined) {
            this.defaultPosition[area] = this.sceneCameras[index].cam.position.clone();
        }


        return null;

    }


    getAreasCameraPosition(area = Object.keys(this.space.areas)[0]){
        let index = this.sceneCameras.findIndex(x => x.name == area && x.type == "360");
        let camInfo = this.sceneCameras[ index ];
        let screenPointActive = null;
        if( camInfo != null && camInfo != undefined ){
            screenPointActive = this.NDCToScreenCoordinates( this.worldToNDC( camInfo.cam.position, this.activeCamera ) );
            this.areaCameraPosition = {
                key: area,
                //position: camInfo.cam.position,
                x: camInfo.cam.position.x,
                y: camInfo.cam.position.y
            };
        }
        return this.areaCameraPosition;
    }

    updateHotspotPosition(currentPosition) {
        let screenPoint = this.NDCToScreenCoordinates( this.worldToNDC( currentPosition, this.activeCamera ) );
        if (this.hotspotCameraImage !== undefined && this.hotspotCameraImage !== null){
            this.hotspotCameraImage.style.position = "absolute";
            this.hotspotCameraImage.style.left = screenPoint.x + 'px';
            this.hotspotCameraImage.style.top = screenPoint.y + 'px';
            this.hotspotCameraImage.style.display = 'inline';

        }
    }

    selectedAreaSpace (space) {
        this.selected_area_space = space;
    }

    getAreaCameraPos(area) {
        let index = this.sceneCameras.findIndex(x => x.name == area && x.type == "360");
        return this.sceneCameras[index].cam.position.clone();

    }

    setUpHotspot(area) {
        let hotspotCamera = [];
        let cameraLength = 0;
        let cameraPos = []
        if (this.space != undefined) {
            if ((area == "Outdoor" || area == "Outside") && this.isOutdoorCamera()) {
                hotspotCamera[0] = document.getElementById(`hotspot-camera0${area}`);
                if (hotspotCamera[0] != undefined){
                    this.setHotspotPosition(hotspotCamera[0]);
                    if (this.getAreaCameraPos(area) != undefined) {
                        this.updateHotspotPosition(this.getAreaCameraPos(area));
                        this.setupCameraHelper(area, "Image ID");

                    }
                }
                return;
            }
            if (area == "Bath" || area == "Closet") {
                hotspotCamera[0] = document.getElementById(`hotspot-camera0${area}`);
                if (hotspotCamera[0] != undefined){
                    this.setHotspotPosition(hotspotCamera[0]);
                    if (this.getAreaCameraPos(area) != undefined) {
                        this.updateHotspotPosition(this.getAreaCameraPos(area));
                        this.setupCameraHelper(area, "Image ID");

                    }
                }
                return;
            }
            cameraLength = this.getCameraPosLength(area);
            cameraPos = this.getAllHotspots(area);
            if (cameraLength != undefined && cameraPos != undefined) {
                for (let i = 1; i <= cameraLength; i++){
                    hotspotCamera[i] = document.getElementById(`hotspot-camera${i}${area}`);
                    if (hotspotCamera[i] != undefined){
                        this.setHotspotPosition(hotspotCamera[i]);
                        if (cameraPos[i] != undefined) {
                            this.updateHotspotPosition(cameraPos[i]);
                            this.setupCameraHelper(area, "Image ID");

                        }
                    }
                }

            }
        }
    }
    // **************** camera placement logic *****************//



    // check for floor
    floorCheck(cam, areaObj) {
        let allObjects = areaObj.root.children.filter((x) => { return x.name.includes("Structure")});
        let floors = allObjects[0].children.filter((x) => { return x.name.includes("Floor")});
        let floorCheck = this.raycastManager.setAndIntersect(cam, this.directionsList[1].clone(), floors);
        if(floors && !floorCheck) {
            return false;
        }
        return true;
    }

    // check for wall
    wallCheck(cam, areaObj, direction, threshold) {
        let allObjects = areaObj.root.children.filter((x) => { return x.name.includes("Structure")});
        let walls = allObjects[0].children.filter((x) => { return x.name.includes("Wall")});
        let wallCheck = this.raycastManager.setAndIntersect(cam, direction, walls);
        if(walls && wallCheck && wallCheck.distance < threshold ) {
            return true;
        }
        return false;
    }

    // check if the view is clear or not around the camera i.e no furniture on the way.
    viewClear(allObjects, cam, threshold) {
        let resultsObj = [];
        // check for vertical collision first with _unit.aY and _unit.Y directions
        for (let i = 0; i < 2; i++) {
            resultsObj.push(this.raycastManager.setAndIntersect(cam, this.directionsList[i].clone(), allObjects.filter((x) => { this.debugEngine.debugLog("name", x.name); return x.name instanceof String &&!x.name.includes("Structure"); })));
        }
        if ( resultsObj[0] != false ){
            // camera lies on an object - vertical collision;
            return 0;
        }
        else if (resultsObj[1]!= false) {
            let rootNode = getRootNodeFromObject(this.scene, resultsObj[1].object);
            // if the not a rug asset or not an item then collision detected
            if (!rootNode || (rootNode && !rootNode.userData.isRug)) {
                return 0;
            }
        }


        // if no vertical collision then check for collisions on other direction
        //check obstruction with objects;
        let results = null;
        var direction = new THREE.Vector3(0,0,0);
        // ray cast to angles around the camera with an interval of 30 degrees;
        for (let i = 0; i < 360; i=i+30) {
            // assume a circle around the camera with radius taken as 0.1
            // and find  points on the camera circle at varying angles
            direction.set(Math.cos( degrees_to_radians(i)), direction.y, Math.sin( degrees_to_radians(i)));
            // find direction from circle center i.e camera position and point found on the circle;
            // raycast to check for objects in the scene
            results = this.raycastManager.setAndIntersect(cam, direction, allObjects);
            // if item is at a distance less than a certain threshold from the camera
            // then consider it an obstruction
            if (results && results.distance <= threshold) {
                return 1;
            }
        }
        return 2;
    }

    // find the direction to face the camera towards
    getCenterlookAt(area, vectorPos) {
        // get center of the area
        let areaCenter = new THREE.Box3().setFromObject(this.space.areas[area].root).getCenter( new THREE.Vector3() );
        areaCenter.set(areaCenter.x, vectorPos.y, areaCenter.z);
        // if center is too close then chose another direction that covers most of the area
        if (areaCenter.distanceTo(vectorPos) < 0.2) {
            let areaObj = this.space.areas[area];
            let distances = [Infinity, Infinity, Infinity, Infinity];
            let result = null;
            let max = 0;
            for (let i = 0; i < this.directionArray.length; i++) {
                result = this.raycastManager.setAndIntersect(vectorPos, this.directionArray[i].clone(), areaObj.root.children);
                if(result) {
                    distances[i] = result.distance;
                }
            }
            // get the direction in which the closest element is farthest;
            max = distances.indexOf(Math.max(...distances));
            if(distances[max]!=Infinity) {
                result = this.directionArray[max].clone();
                result.set(result.x, vectorPos.y, result.z);
                return result;
            }
        }
        return areaCenter;
    }

    // set 360 camera / build camera
    set360Camera(area, camPos) {
        let cam = null;
        if(camPos) {
            let camObj = this.getCameraByName( area );
            if( camObj == null ) {
                cam = this.buildCustomCamera( area, FileConstants.SCENE_CREATOR_CAMERA_MODES.ThreeD, "360", 60, camPos);
            }
            else {
                cam = camObj.cam;
                cam.position.set(camPos.x, camPos.y, camPos.z);
                cam.lookAt(new THREE.Vector3( 0, 0, -1 ).add(camPos));
            }
        }
        return cam;
    }

    // get door direction towards which to move the camera to;
    getDoorDirection(doorPos, areaCenter, walls, distance) {
        let currPos = doorPos.clone();
        var direction = new THREE.Vector3();
        let xResult = null;
        let zResult = null;
        let xzValues = null;
        let dirPriority = [];
        direction.subVectors(areaCenter, currPos ).normalize();
        xzValues =  retreivePositions(direction.x, direction.z);
       // check for camera position moved to x direction
       currPos.set(doorPos.x + (distance * xzValues[0].x), doorPos.y, doorPos.z);
       xResult = this.raycastManager.setAndIntersect(currPos,xzValues[0].negate(), walls);
       // check for camera position moved to z direction
       currPos.set(doorPos.x , doorPos.y, doorPos.z + (distance * xzValues[1].z));
       zResult = this.raycastManager.setAndIntersect(currPos, xzValues[1].negate(), walls);
       if(!xResult || (xResult && zResult && xResult.distance >= zResult.distance)) {
           // if there is no intersection along the x direction or the distance is greater than distance
           // of intersected objected object in the z direction then set x as the direction
           dirPriority.push(xzValues[0]);
           dirPriority.push(xzValues[1]);
       }
       else if (!zResult || (xResult && zResult && xResult.distance < zResult.distance)) {
           // if there is no intersection along the z direction or the distance is greater than distance
           // of intersected objected object in the x direction then set z as the direction
           dirPriority.push(xzValues[1]);
           dirPriority.push(xzValues[0]);
       }
       return dirPriority;
    }

    // set camera at the door of the bedroom.
    setBedroomCamera(areaCenter, camPos, cam, areaObj, area) {
        // get bounding box of the area
        let allObjects = areaObj.root.children;
        let allClear = false;
        let currPos = new THREE.Vector3();
        var direction = new THREE.Vector3();
        let dirIndex = 0;
        let dirQueue = [];
        let threshold = 0.7;
        let walls = areaObj.walls;
        let isFloor = true;
        let isWall = true;
        areaCenter.set(areaCenter.x, camPos.y, areaCenter.z);
        let dirPriority = this.getDoorDirection(camPos.clone(), areaCenter.clone(), walls, 1.5);
        // set directions for moving along this room so that the next direction can be picked on hitting a wall;
        dirQueue.push(dirPriority[0]);
        dirQueue.push(dirPriority[1]);
        dirQueue.push(dirPriority[0].negate());
        dirQueue.push(dirPriority[1].negate());
        dirIndex = 0;
        direction = dirQueue[0].clone();
        allObjects = allObjects.concat(this.sceneAssets);
        // move the camera to initial point
        cam.translateOnAxis(direction.clone(), 1.5); // 2.5 feet
        currPos.copy(cam.position.clone());
        // while there is intersections/ obstructions around the camera keep moving to find clear view
        do {
            this.debugEngine.debugLog(" Scene: ", area);
            isFloor = this.floorCheck(currPos, areaObj);
            isWall = this.wallCheck(currPos, areaObj, direction.clone(), threshold);
            // if view towards the room center and around the camera is clear, then pick this position for the camera
            if (isFloor == true && isWall == false && this.viewClear(allObjects, currPos, threshold)==2) {
                cam.position.copy(currPos);
                cam.lookAt(this.getCenterlookAt(area, currPos));
                allClear = true ;
            }
            else {
                if (isFloor == false || isWall == true) {
                    if(isFloor == false) {
                        currPos.set(currPos.x - (threshold + 0.5) * direction.x, currPos.y, currPos.z - (threshold + 0.5) * direction.z);
                    }
                    else {
                        currPos.set(currPos.x - (threshold) * direction.x, currPos.y, currPos.z - (threshold) * direction.z);
                    }
                    dirIndex = (dirIndex + 1 ) % 4;
                    direction = dirQueue[dirIndex].clone();
                    if (dirIndex  == 0) {
                        // bring the position back to initial point
                        threshold = threshold - 0.1;
                        if(threshold < 0.1) {
                            if (!this.floorCheck(currPos, areaObj) || this.wallCheck(currPos, areaObj, direction.clone(), threshold)) {
                                return null;
                            }
                            cam.lookAt(this.getCenterlookAt(area, cam.position.clone()));
                            return cam;
                        }
                    }
                }
                // move the position of camera
                currPos.set(currPos.x + threshold * direction.x, currPos.y, currPos.z + threshold * direction.z);
            }
        } while ( !allClear );
        this.debugEngine.debugLog(" Scene Completed: ", area);
        return cam;
    }

    // find the door of the bedroom and set camera
    placeBedroomCamera(area, areaObj, floorPos, areaCenter) {
        //find primary door / open door pick the first door
        // for multiple doors pick one farthest from the window
        // multiple windows or no window, pick any door at random at random
        let doorsList = [];
        let cam = null;
        let doorObj = null;
        let windowsAreas = [];
        let countDoors = 0;
        let countWindows = 0;
        let windowsIndex = 0;
        let distList = [];
        let camPos = null;
        let selectedWindow = null;
        doorsList = this.getDoors(areaObj.doors);
        countDoors = doorsList.length;
        //let countDoors = 1;
        if( countDoors == 0) {
            this.debugEngine.debugLog("No doors found");
            return null;
        }
        doorObj = doorsList[0];
        if (countDoors > 1) {    // or multiple doors;
            // find windows;
            windowsIndex =  areaObj.root.children.findIndex(item => item.name === 'Windows');
            if(windowsIndex != -1) {
            windowsAreas = areaObj.root.children[windowsIndex];
            countWindows = windowsAreas.children.length;
            }
            if (countWindows == 1) { // if only one window, pick one farthest away from the window

                selectedWindow = null;
                windowsIndex = windowsAreas.children[0].children.findIndex(item => item.name.includes("Frame") );
                selectedWindow = windowsAreas.children[0].children[windowsIndex];
                // calculate distance of the window from every door
                for ( let door in doorsList) {
                    distList.push(doorsList[door].position.distanceTo(selectedWindow.position ));

                }
                // get index of the door that is farthest from the window
                doorObj = doorsList[distList.indexOf(Math.min(...distList))];
            }
        }
        if ( doorObj != null ) {
            camPos = new THREE.Vector3();
            // doorObj.getWorldPosition( camPos );
            camPos = this.getDoorPosition(doorObj);

            // 45 inches high from ground 1.143
            camPos.set(camPos.x, floorPos.y + 0.5, camPos.z);
            cam = this.set360Camera(area, camPos);
            if(cam) {
                // set the camera once the door is picked
                cam = this.setBedroomCamera(areaCenter, camPos, cam, areaObj, area);
                if (cam) {
                    cam.position.set(cam.position.x, floorPos.y+1.27, cam.position.z);
                    return cam.position.clone();
                }
            }
        }
        this.debugEngine.debugLog("Unable to set cameras in", area);
        return null;
    }


    //place camera for living and other rooms;

    // pick the optimal direction
    pickOptimal(cam, allObjects) {
        // find min dist for every direction
        let distances = [Infinity, Infinity, Infinity, Infinity];
        let result = null;
        for (let i=0; i<this.directionArray.length; i++) {
            result = this.raycastManager.setAndIntersect(cam, this.directionArray[i].clone(), allObjects);
            if(result) {
                distances[i] = result.distance;
            }
        }
        return distances.indexOf(Math.max(...distances));;
    }

    // compare two vectors if equal or not.
    compareVectors(vector1, vector2 ) {
        if(vector1 && vector2 &&  roundedEqual(vector1, vector2)) {
            return true;
        }
        return false;
    }

    // place camera in the living room;
    placeLivingCamera(area, areaObj, floorPos, areaCenter, threshold ) {
        let allObjects = areaObj.root.children.filter((x) => { return !x.name.includes("Lights"); }).concat(this.sceneAssets);
        let cam = null;
        let centerPoint = areaCenter.clone();
        let allClear = false;
        let dirIndex = 0;
        let direction = this.directionArray[dirIndex].clone();
        let currPos = null;
        let prevPos = null;
        let flag = 0;
        let loopIter = 0;
        let isFloor = true;
        let distance = 0.5;
        let defaultPoint = null;
        let isWall = null;
        centerPoint.set(centerPoint.x, floorPos.y + 0.5, centerPoint.z);
        cam = this.set360Camera(area, centerPoint);
        currPos = cam.position.clone();
        if(cam) {
            do {
                loopIter = (loopIter + 1) % 50;
                this.debugEngine.debugLog("Scene: ", area);
                isFloor = this.floorCheck(currPos, areaObj);
                isWall = this.wallCheck(currPos, areaObj, direction.clone(), threshold);
                flag = this.viewClear(allObjects, currPos, threshold);
                if (isFloor == true && isWall == false) {
                    if(flag == 2) {
                        cam.position.set(currPos.x, floorPos.y + 1.27, currPos.z);
                        cam.lookAt(this.getCenterlookAt(area, cam.position.clone()));
                        allClear = true ;
                        break;
                    }
                    else if (flag == 0 && defaultPoint == null) {
                        defaultPoint = currPos.clone();
                    }
                }
                else {
                    if(isWall == true) {
                        currPos.set(currPos.x - (distance) * direction.x, currPos.y,  currPos.z - (distance) * direction.z );
                    }
                    else {
                        currPos.set(currPos.x - (distance+1.5) * direction.x, currPos.y,  currPos.z - (distance+1.5) * direction.z );
                    }
                    direction.set(direction.x * Math.cos( degrees_to_radians(90)) - direction.z * Math.sin( degrees_to_radians(90)), direction.y, direction.x * Math.sin( degrees_to_radians(90)) + direction.z * Math.cos( degrees_to_radians(90)));
                }
                if(loopIter == 0) {
                    threshold = threshold - 0.05;
                    currPos = cam.position.clone();
                    if(threshold < 0.2 && defaultPoint!=null) {
                        cam.position.set(defaultPoint.x, floorPos.y + 1.27, defaultPoint.z);
                        cam.lookAt(this.getCenterlookAt(area, cam.position.clone()));
                        threshold = 0.6;
                        break;
                    }
                }
                if (flag == 0) { // vertical collision then select randomly might stuck into a loop otherwise

                    dirIndex = Math.floor(Math.random() * 3);
                }
                else {
                    // select optimal direction
                    dirIndex = this.pickOptimal(currPos, areaObj.root.children.concat(this.sceneAssets));
                }

                prevPos = direction;
                direction = this.directionArray[dirIndex].clone();
                while (prevPos && this.compareVectors(direction, prevPos.negate())) {
                    dirIndex = Math.floor(Math.random() * 3);
                    direction = this.directionArray[dirIndex].clone();
                }
                // add offset to camera position before moving it
                currPos.set(currPos.x + distance * direction.x, currPos.y,  currPos.z + distance * direction.z );
            } while ( !allClear);
            this.debugEngine.debugLog(" Scene Completed: ", area);
            return [cam.position.clone(), threshold];
        }
        return null;
    }

    // get minimum distance of vector from the doors;
    minDist(vectorPos, doors) {
        let distances = [];
        let position = new THREE.Vector3();
        for (let i in doors) {
            doors[i].getWorldPosition(position);
            distances.push(position.distanceTo(vectorPos));
        }
        if (Math.min(...distances) <= 1.0) {
            return false;
        }
        return true;
    }

    isOutdoorCamera() {
        // check if outdoor is a proper area and needs a camera or just a boundary around the space structure by
        // checking if the center of outdoor is calculated to lie in any other area of the space
        if(this.space == undefined) {
            return false;
        }
        let areaObj = null;
        areaObj = this.space.areas["Outdoor"];
        if(areaObj == null || areaObj == undefined) {
            areaObj = this.space.areas["Outside"];
        }
        let flag = true;
        let rootobj = null;
        let floorPos = null;
        let centerPoint = null;
        if (areaObj) {
            floorPos = new THREE.Vector3();
            areaObj.floor.getWorldPosition( floorPos );
            centerPoint = new THREE.Box3().setFromObject(areaObj.root).getCenter( new THREE.Vector3()).clone();
            centerPoint.set(centerPoint.x, floorPos.y + 0.5, centerPoint.z);
            rootobj = null;
            for (let area in this.space.areas) {
                rootobj = new THREE.Box3().setFromObject(this.space.areas[area].root);
                if (area!="Outdoor" && area!="Outside" && rootobj.containsPoint(centerPoint)) {
                    flag = false;
                }
            }
        }
        else {
            flag = false;
        }
        return flag;
    }

    // place camera for the outdoor area
    placeOutdoorCamera(area, areaObj, floorPos, areaCenter)
    {
        // check if there is a need to place a camera in the outdoor.
        if (area == "Patio" || this.isOutdoorCamera()) {
            let centerPoint = areaCenter.clone();
            centerPoint.set(areaCenter.x, floorPos.y + 0.5, areaCenter.z );
            let allObjects = areaObj.root.children.filter((x) => { return !x.name.includes("Lights"); }).concat(this.sceneAssets);
            let cam = null;
            let direction = null;
            let threshold = 0.5;
            let currPos = new THREE.Vector3();
            let results= null;
            let allClear = false;
            // implementation
            cam = this.set360Camera(area, centerPoint);
            currPos.copy(cam.position);
            results = this.raycastManager.setAndIntersect(cam.position.clone(), this.directionsList[1].clone(), areaObj.root.children);
            do{
                // steer clear of furniture
                if (results && this.viewClear(allObjects, cam.position.clone(), threshold)==2) {
                    cam.lookAt(this.getCenterlookAt(area, cam.position));
                    cam.position.set(cam.position.x, floorPos.y + 1.27, cam.position.z);
                    allClear = true;
                }
                else {
                direction = this.directionArray[Math.floor(Math.random() * 3)];
                currPos.set(cam.position.x + 0.5 * direction.x, cam.position.y, cam.position.z + 0.5 * direction.z) ;
                cam.translateOnAxis(direction, 0.5);
                results = this.raycastManager.setAndIntersect(currPos, this.directionsList[1], areaObj.root.children);
                }
            }
            while(!allClear)
            return cam.position.clone();
        }
        return null;
    }

    // place bathroom/closet camera
    placeBathroomCamera(area, areaObj, floorPos, areaCenter) {
        // place the camera at a certain distance from the door of the bathroom
        let centerPoint = areaCenter.clone();
        let cam = null;
        let doors = this.getDoors(areaObj.doors)
        if (doors.length > 0) {
            let direction = new THREE.Vector3();
            let vectorPos = this.getDoorPosition(doors[0]);
            direction.subVectors(areaCenter, vectorPos ).normalize();
            vectorPos.set(vectorPos.x + 0.8* direction.x, floorPos.y + 1.27, vectorPos.z + 0.8* direction.z);
            cam = this.set360Camera(area, vectorPos);
            if(cam) {
                centerPoint.set(centerPoint.x, floorPos.y + 1.27, centerPoint.z);
                cam.lookAt(centerPoint);
                return cam.position.clone();
            }
        }
        else {
            let targetPos = new THREE.Vector3(centerPoint.x, floorPos.y + 1.27, centerPoint.z);
            cam = this.set360Camera(area, targetPos);
            if (cam) {
                return cam.position.clone();
            }
        }
        return null;
    }

    getDoorPosition(doorObj) {
        let doorBounding =  new THREE.Box3().setFromObject( doorObj )
        let doorCenter = new THREE.Vector3();
        doorBounding.getCenter(doorCenter);
        return doorCenter;

    }

     getDoors(doors) {
        let doorsPos = [];
        for (let i in doors) {
            if (doors[i].type != "Mesh") {
                if (doors[i].children != null && doors[i].children != undefined) {
                    if (doors[i].children.length > 1) {
                        for ( let child in doors[i].children) {
                            if (doors[i].children[child].type == "Mesh" && doors[i].children[child].name.includes("Frame")) {
                                doorsPos.push(doors[i].children[child]);
                            }
                        }
                    }
                    else if (doors[i].children.length == 1) {
                        doorsPos.push(doors[i].children[0]);
                    }

                }


            }
            else if (doors[i].type == "Mesh" && doors[i].name.includes("Frame") && !doorsPos.includes(doors[i])) {
                doorsPos.push(doors[i]);
            }
        }
        return doorsPos;
    }


// calculate hotspots for doors in area
findDoorsHotspots(floorPos, areaCenter, areaObj, objectsCheck, optimalPos) {
    if(optimalPos != null && optimalPos.length!=0) {
        objectsCheck = objectsCheck.concat(optimalPos);
    }
    let doorList = [];
    let vectorPos = null;
    let doors = this.getDoors(areaObj.doors);
    this.debugEngine.debugLog("doors retreived", doors);
    let result = null;
    let minDist = 0;
    let centerPoint = areaCenter.clone();
    let dirPriority = null;
    let defaultDoor = null;
    centerPoint.set(centerPoint.x, floorPos.y + 0.5, centerPoint.z);
    for (let i in doors) {
        this.debugEngine.debugLog(doors[i].name)
        vectorPos = new THREE.Vector3(0,0,0);
        // doors[i].getWorldPosition( vectorPos );
        vectorPos = this.getDoorPosition(doors[i]);
        dirPriority = this.getDoorDirection(vectorPos, centerPoint, areaObj.walls, 0.8);
        vectorPos.set(vectorPos.x + 0.8 * dirPriority[0].x, vectorPos.y, vectorPos.z + 0.8 * dirPriority[0].z);
        vectorPos.set(vectorPos.x + 0.2 * dirPriority[1].x, vectorPos.y, vectorPos.z + 0.2 * dirPriority[1].z);
        if(this.floorCheck(vectorPos, areaObj)== true) {
            result = this.viewClear(areaObj.root.children.concat(this.sceneAssets), vectorPos, 0.2);
            if(result == 2) {
                minDist = Infinity;
                vectorPos.set(vectorPos.x , floorPos.y + 1.27, vectorPos.z );
                if(defaultDoor == null) {
                    defaultDoor = vectorPos.clone();
                }
                // discard the hotspot if it is too close to any previously calculated hotspot.

                for ( let j = 1; j < objectsCheck.length; j++) {
                    minDist = Math.min(minDist, objectsCheck[j].distanceTo(vectorPos));
                }
                if (minDist > 0.7) {
                    doorList.push(vectorPos);
                    objectsCheck.push(vectorPos);
                }
            }
        }
    }
    // this.debugEngine.debugLog("DoorsList", doorList, optimalPos);
    if(doorList.length == 0 && optimalPos == null) {
        doorList.push(defaultDoor);
    }
    this.debugEngine.debugLog("doors hotspots", doorList);
    return doorList;
}


    // find hotspots for corners of any area
    findCornersHotspots(areaCenter, areaObj, floorPos, objectsCheck, optimalPos) {
        this.debugEngine.debugLog("find corner hotspots");
        if(optimalPos!=null && optimalPos.length!=0) {
            objectsCheck = objectsCheck.concat(optimalPos);
        }
        var direction = new THREE.Vector3(0,0,0);
        let updatedPos = areaCenter.clone();
        let centerPoint = areaCenter.clone();
        let corners = [];
        let allObjects = areaObj.root.children.filter((x) => { return !x.name.includes("Lights"); }).concat(this.sceneAssets);
        let minDistance = 0;
        let minCornerDist= 0;
        let threshold = 0.2;
        var bbox = null;
        var wallCenter = new THREE.Vector3();
        let xDist = 0;
        let zDist = 0;
        let flag = true;
        centerPoint.set(centerPoint.x, floorPos.y + 0.5, centerPoint.z);
        for (let i in areaObj.walls) {
            // for every wall in the area
            bbox = new THREE.Box3().setFromObject(areaObj.walls[i]);
            wallCenter = bbox.getCenter(new THREE.Vector3());
            wallCenter.set(wallCenter.x, floorPos.y + 0.5, wallCenter.z);
            xDist = (bbox.max.x - bbox.min.x)/2;
            zDist = (bbox.max.z - bbox.min.z)/2;
            direction = new THREE.Vector3();
            // get direction from area center to wall center;
            direction.subVectors(wallCenter, centerPoint).normalize();
            updatedPos = wallCenter.clone();
            // move in 90 deree direction to the direction from area center to wall center to locate the corner
            direction.set(direction.x * Math.cos( degrees_to_radians(90)) - direction.z * Math.sin( degrees_to_radians(90)), direction.y, direction.x * Math.sin( degrees_to_radians(90)) + direction.z * Math.cos( degrees_to_radians(90)));
            // get wall corner;
            updatedPos.set(updatedPos.x + xDist * direction.x, updatedPos.y, updatedPos.z + zDist * direction.z);
            direction.subVectors(centerPoint, updatedPos).normalize();
            updatedPos.set(updatedPos.x + 1.5 * direction.x, updatedPos.y, updatedPos.z + 1.5 * direction.z);
            flag = true;
            while (flag && this.floorCheck(updatedPos, areaObj) == false && this.viewClear(allObjects.concat(areaObj.walls), updatedPos, threshold ) != 2) {
                // move from corner towards the center till obstuction or till the distance covered by the corner is less from the center
                updatedPos.set(updatedPos.x + 0.5 * direction.x, centerPoint.y, updatedPos.z + 0.5 * direction.z);
                flag = updatedPos.distanceTo(centerPoint) < 0.5;

            }
            if (this.floorCheck(updatedPos, areaObj) == true && this.viewClear(allObjects.concat(areaObj.walls), updatedPos, threshold )==2) {
                updatedPos.set(updatedPos.x, floorPos.y + 1.27, updatedPos.z);
                minDistance = Infinity;
                minCornerDist = Infinity;
                // discard if corner is too close to existing hotspots
                for(let j =1; j < objectsCheck.length; j++) {

                    minDistance = Math.min(minDistance, objectsCheck[j].distanceTo( updatedPos));
                }
                if(minDistance > 0.8) {
                    // Discard the corner if it is too close to any other previously calculated corner
                    if (i>0 && corners.length>0) {
                        for (let j = 0; j< corners.length; j++) {
                            minCornerDist = Math.min(minCornerDist, updatedPos.distanceTo( corners[j]));
                        }
                    }
                    if (minCornerDist > 0.8) {
                        corners.push(updatedPos);
                    }
                }
            }
        }
        return corners;
    }



    // place adjoining area's hotspots
    findAdjoiningHotspot( areaCenter, areaObj, floorPos, optimalPos) {
        let result = null;
        let updatedPos = areaCenter.clone();
        let minDist = Infinity;
        let allObjects = areaObj.root.children.filter((x) => { return x.name.includes("Structure"); });
        let centerPoint = updatedPos.clone();
        updatedPos.set(updatedPos.x, floorPos.y + 0.5, updatedPos.z);
        centerPoint.set(centerPoint.x, floorPos.y + 1.0, centerPoint.z);
        for (let i = 0; i<this.directionArray.length; i++) {
            // from the center of area, raycast in every direction to locate the adjoining spot in any direction in which the ray doe not hit any walls / room structure
            result = this.raycastManager.setAndIntersect(centerPoint, this.directionArray[i], allObjects);
            if (!result) {
                // keep moving ahead until it is to the edge of the area box
                while(this.floorCheck(updatedPos, areaObj) == true && this.minDist(updatedPos, areaObj.doors) == true) {
                    updatedPos.set(updatedPos.x + 0.1 * this.directionArray[i].x, floorPos.y + 0.5, updatedPos.z + 0.1 * this.directionArray[i].z);
                }
            }
        }
        minDist = Infinity;
        if (this.viewClear(this.sceneAssets, updatedPos, 0.6) == 2) {
            updatedPos.set(updatedPos.x, floorPos.y + 1.27, updatedPos.z);
            if(optimalPos!=null) { // check if point is too close to existing hotspots;
                for (let i =0; i < optimalPos.length; i++) {
                    minDist = Math.min(minDist, updatedPos.distanceTo(optimalPos[i]));
                }
            }
            if (minDist > 1.0) {
                return updatedPos;
            }

        }
        return null;
    }

    placeHotspots(area, areaObj, floorPos, areaCenter, optimalPos, flag = 0) {
        // corners
        // doors
        // adjoining areas
        // optimal position
        let cameraPositions = [];
        let corners = [];
        let doors = [];
        let adjoining = null;
        let centerPoint = areaCenter.clone();
        // get center of the space
        centerPoint.set(centerPoint.x, floorPos.y + 1.27, centerPoint.z );
        cameraPositions.push(centerPoint);
        if (flag == 1) {
            adjoining = this.findAdjoiningHotspot( areaCenter, areaObj, floorPos, optimalPos);
        }
        if(adjoining) {
            // add adjoining hotspot to the list
            cameraPositions.push(adjoining);
        }
        doors = this.findDoorsHotspots(floorPos, centerPoint, areaObj, cameraPositions, optimalPos);
        if(doors && doors.length > 0) {
            // add doors hotspots to the list
            cameraPositions = cameraPositions.concat(doors);
        }
        corners = this.findCornersHotspots(areaCenter, areaObj, floorPos, cameraPositions, optimalPos);
        if(corners && corners.length > 0) {
            // add corners hotspots to the list
            cameraPositions = cameraPositions.concat(corners);
        }
        if (optimalPos && optimalPos.length>0) {
            // adding optimal position calculated from the auto camera placement algorithm
            cameraPositions = cameraPositions.concat(optimalPos);
        }
        this.setLoading360ProgressMessage( "Setting camera hotspots in : ", area);
        return cameraPositions;

    }

    setLoading360ProgressMessage(value) {
        this.loading360ProgressMessage = value;
    }

    getSaveSceneEditFlag() {
        return this.save_scene_edit;
    }

    // driver function for camera placement
    place360Cameras (callbackMethod = null) {
        // place 360 camera / run auto camera
        this.debugEngine.debugLog("Start camera placement");
         if (this.cameraHotspotsSaved !== undefined  &&
            this.cameraHotspotsSaved !== null && this.cameraHotspotsSaved.length !== 0 && this.cameraHotspotsSaved !== [] && this.save_scene_edit == false) {
                this.debugEngine.debugLog(this.cameraHotspots);
                this.debugEngine.debugLog("show saved");
                this.cameraHotspots = this.cameraHotspotsSaved;
        }
        else {
            let camPos = [];
            let retrievedPos = null;
            let secondPos = null;
            let areaObj = null;
            let areaCenter = null;
            let areaName = null;
            let floorPos = null;
            let flag = 0;
            let threshold = 0;
            let results = null;
            var hotspotsRet = null;

            for ( var area in this.space.areas ) {
                // get information of area
                camPos = [];
                retrievedPos = null;
                secondPos = null;
                areaObj = this.space.areas[ area ];
                areaCenter = new THREE.Box3().setFromObject(areaObj.root).getCenter( new THREE.Vector3() );
                floorPos = new THREE.Vector3();
                areaObj.floor.getWorldPosition( floorPos );
                areaName = area.toLowerCase();
                // this.debugEngine.debugLog("area", areaName);
                if ( areaName.includes('bed') || areaName.includes('kids')) {
                    // set camera positions for bedrooms and kids rooms
                    retrievedPos = this.placeBedroomCamera(area, areaObj, floorPos, areaCenter.clone());
                    if(retrievedPos != null) {
                        camPos = [];
                        camPos.push(retrievedPos);
                    }
                    hotspotsRet = this.placeHotspots(area, areaObj, floorPos, areaCenter.clone(), camPos );
                    if (hotspotsRet != null) {
                        this.cameraHotspots[area] = hotspotsRet;
                        if(retrievedPos == null) {
                            this.set360Camera(area, hotspotsRet[hotspotsRet.length - 1]);
                        }
                    }
                    else {
                        this.debugEngine.debugLog("Unable to set cameras in ", area);
                    }
                }
                else if( areaName.includes('living') || areaName.includes('kitchen') ||
                        areaName.includes('dining') || areaName.includes('dinning') || areaName.includes('default')  || areaName.includes("patio")) {
                    // set camera positions for Living and other open spaces
                    threshold = 0.6;
                    results =  this.placeLivingCamera(area, areaObj, floorPos, areaCenter.clone(), threshold)
                    if(results != null) {
                        secondPos = results[0];
                    }
                    results = this.placeLivingCamera(area, areaObj, floorPos, areaCenter.clone(), results[1]);
                    if (results != null) {
                        retrievedPos = results[0];
                        camPos = [];
                        camPos.push(retrievedPos);
                    }
                    if((secondPos && !retrievedPos) || (secondPos && retrievedPos && this.compareVectors(retrievedPos, secondPos) == false &&
                        retrievedPos.distanceTo(secondPos) > 1.0)) {
                        if(camPos == null) {
                            camPos = [];
                        }
                        camPos.push(secondPos);
                    }

                    flag = 1;
                    if(areaName.includes('default')) {
                        flag = 0;
                    }
                    hotspotsRet = this.placeHotspots(area, areaObj, floorPos, areaCenter.clone(), camPos, flag );
                    if(hotspotsRet!=null) {
                        this.cameraHotspots[area] = hotspotsRet;
                        if(camPos == null) {
                            this.set360Camera(area, hotspotsRet[hotspotsRet.length-1]).lookAt(hotspotsRet[0]);
                        }
                    }
                    else {
                        this.debugEngine.debugLog("Unable to set cameras in ", area);
                    }
                }
                else if(areaName.includes("outdoor") || areaName.includes("outside") ) {
                    // place camera positions for outdoor area
                    retrievedPos = this.placeOutdoorCamera(area, areaObj, floorPos, areaCenter.clone());
                    if(retrievedPos == null) {
                        this.debugEngine.debugLog("Unable to set cameras in ", area)
                    }
                    else {
                        areaCenter.set(areaCenter.x, floorPos.y + 1.27, areaCenter.z);
                        this.cameraHotspots[area] = [areaCenter, retrievedPos];
                    }
                }
                else {
                    // place camera positions for the bathroom and closets
                    retrievedPos = this.placeBathroomCamera(area, areaObj, floorPos, areaCenter.clone());
                    if(retrievedPos == null) {
                        this.debugEngine.debugLog("Unable to set cameras in ", area)
                    }
                    else {
                        areaCenter.set(areaCenter.x, floorPos.y + 1.27, areaCenter.z);
                        this.cameraHotspots[area] = [areaCenter, retrievedPos];
                    }
                }
            }
            this.setDefaultSettings();
            this.debugEngine.debugLog("End camera placement");
        }

        if (callbackMethod != null) {
            callbackMethod();
        }
    }


    loadSpace ( ) {

        var scope = this;
        this.sceneLoader.loadSpace(() => {
            scope.configureScene();
        })

    }

    autoPlaceLostAssets(items) {
        this.objectPlacementManager.setBabylonExported(this.isBabylonExported);
        const itemsFiltered = this.sceneAssets.filter((item)=>{
            return items.includes(item.name) || items.includes(parseInt(item.name))
        }) 

        itemsFiltered.map((item)=>{
            if (item.userData.isStacked) {
                item.userData.isStacked = false;
                this.scene.attach(item);
            }
            if (item.userData.currentPlacement) {
                item.userData.placementType = item.userData.originalPlacement;
                item.userData.currentPlacement = null;
                this.objectPlacementManager.buildPVBObject(item);
            }
            item.rotation.set( 0, 0, 0 )
        }) 

        this.objectPlacementManager.autoPlaceLostAssets(itemsFiltered)
    }

    swapSpace (spaceObj, isResetSwap, callbackMethod = null) {
        var scope = this;
        this.removeOldSpace();
        this.removeOldManagers();
        this.removeNavControls();
        this.removeOldSpaceLights();
        this.resetSelection();
        if (isResetSwap) {
            this.resetAssetsPosition();
        } else {
            this.resetAssetsState = _.cloneDeep(scope.sceneAssets);
        }
        this.sceneLoader.loadSpace(() => {
            scope.configureSpace(isResetSwap);
            if (callbackMethod != null) {
                callbackMethod();
            }
        }, spaceObj)
    }

    resetAssetsPosition() {
        const scope = this;
        const sceneAssetsMap = {};
        for (const asset of this.sceneAssets) {
            sceneAssetsMap[asset.uuid.toString()] = asset;
        }

        for (const i in scope.resetAssetsState) {
            const resetAsset = scope.resetAssetsState[i];
            if (resetAsset.userData.visible) {
                const asset = sceneAssetsMap[resetAsset.uuid.toString()];
                if (asset) {
                    asset.position.copy(resetAsset.position);
                    asset.rotation.copy(resetAsset.rotation);
                    asset.scale.copy(resetAsset.scale);
                    
                    if (asset.children.length > 1 && resetAsset.children.length > 0) {
                        asset.children[0].scale.copy(resetAsset.children[0].scale);
                    }

                    asset.updateMatrixWorld();
                }
            }
        }
    }

    disposeScene = (scene) => {
        // Dispose of geometries
        scene.traverse((object) => {
            if (object.isMesh) {
                // Dispose of geometry
                if (object.geometry) {
                    object.geometry.dispose();
                }
                // Dispose of materials
                if (object.material) {
                    // If the material is an array of materials (MultiMaterial), dispose each of them
                    if (Array.isArray(object.material)) {
                        object.material.forEach((material) => {
                            if (material.map) {
                                material.map.dispose();
                            }
                            if (material.roughnessMap) {
                                material.roughnessMap.dispose();
                            }
                            if (material.metalnessMap) {
                                material.metalnessMap.dispose();
                            }
                            if (material.normalMap) {
                                material.normalMap.dispose();
                            }
                            if (material.bumpMap) {
                                material.bumpMap.dispose();
                            }
                            if (material.emissiveMap) {
                                material.emissiveMap.dispose();
                            }
                            if (material.lightMap) {
                                material.lightMap.dispose();
                            }
                            material.dispose();
                        });
                    } else {
                        // Dispose of a single material
                        if (object.material.map) {
                            object.material.map.dispose();
                        }
                        if (object.material.roughnessMap) {
                            object.material.roughnessMap.dispose();
                        }
                        if (object.material.metalnessMap) {
                            object.material.metalnessMap.dispose();
                        }
                        if (object.material.normalMap) {
                            object.material.normalMap.dispose();
                        }
                        if (object.material.bumpMap) {
                            object.material.bumpMap.dispose();
                        }
                        if (object.material.emissiveMap) {
                            object.material.emissiveMap.dispose();
                        }
                        if (object.material.lightMap) {
                            object.material.lightMap.dispose();
                        }
                        object.material.dispose();
                    }
                }
            }
        });
    }

    removeOldSpace () {
        const oldSpace = this.space.scene;
        this.swapManager.setOldSpaceObject(oldSpace);
        this.disposeScene(oldSpace);
        this.scene.remove(oldSpace);
    }

    removeOldManagers () {
        const helperPlane = this.scene.getObjectByName('helperPlane');
        this.disposeScene(helperPlane);
        this.scene.remove(helperPlane);

        const snapGuideManger = this.scene.getObjectByName('snapGuide');
        this.disposeScene(snapGuideManger);
        this.scene.remove(snapGuideManger);
    }

    removeOldSpaceLights () {
        for (let lightObject of this.currentSpaceLights) {
            this.disposeScene(lightObject["light"]);
            this.scene.remove(lightObject["light"]);
        }
        this.currentSpaceLights = [];
    }

    enableGrayMode() {
        for (let lightObject of this.currentSpaceLights) {
            lightObject["light"].intensity = 0;
        }
        this.space.enableGrayMode();
    }

    disableGrayMode() {
        for (let lightObject of this.currentSpaceLights) {
            lightObject["light"].intensity = lightObject["intensity"];
        }
        this.space.disableGrayMode();
    }

    removeNavControls () {
        const navControls = this.navControl;
        this.disposeScene(navControls);
        this.scene.remove(navControls);
    }

    /**
     * Parse and extract placement area and types info for assets list
     * @param {Array} assetsList - The list of assets 
     * @param {string} defaultArea - The default area to allocate against an asset
     */
    parseAssetsPlacementInfo( assetsList, defaultArea ) {
        let assetsPlacementInAreas = {}
        let assetsInScene = []
        let assetsPlacementTypes = {}
        let assetsCategories = {}
        let assetsMaterialTypes = {}
        let assetsPlatform = {}
        for(let asset of assetsList) {
            let area = defaultArea;
            if (asset.area)
            {
                area = asset.area
            }
            if (asset.placement_type) {
                assetsPlacementTypes[asset.product_id] = asset.placement_type
            }
            if (asset.category) {
                assetsCategories[asset.product_id] = asset.category
            }
            if (asset.material_type) {
                assetsMaterialTypes[asset.product_id] = asset.material_type
            }
            if (asset.platform) {
                assetsPlatform[asset.product_id] = asset.platform 
            }
            if (!assetsPlacementInAreas[area]) {
                assetsPlacementInAreas[area] = [];
            }
            assetsPlacementInAreas[area].push(asset.product_id.toString());
            assetsInScene.push(asset.product_id.toString())
        }
        // set placement area and type info for assets
        let assetsInfo = {
            "assetsPlacementAreas": assetsPlacementInAreas,
            "assetsList": assetsInScene,
            "assetsPlacementTypes": assetsPlacementTypes,
            "assetsCategories": assetsCategories,
            "assetsMaterialTypes": assetsMaterialTypes,
            "assetsPlatform": assetsPlatform
        }
        return assetsInfo;
    }

    /**
     * Add Products to Running Scene
     * @param {Object} assetsPlacementInAreas - The dictionary containing assets-areas placement information
     * @param {Object} assetsPlacementTypes - The dictionary containing placement type floor/wall/ceiling for assets
     * @param {callbackMethod} onPlacedAction - The callback to be called on load operation completion
     */
    addProductsToScene(assetsPlacementInAreas, assetsPlacementTypes, assetsCategories, assetsMaterialTypes, onPlacedAction) {
        this.sceneLoader.updateItemsPlacementList(assetsPlacementTypes);
        this.sceneLoader.updateItemsCategoryList(assetsCategories);
        this.sceneLoader.updateItemsMaterialTypesList(assetsMaterialTypes);
        this.sceneLoader.setupAssetWithAutoPlacement(assetsPlacementInAreas, onPlacedAction, true);
    }

    /**
     * Swap Asset in Scene
     * Get New Asset category, material type and placement type to update in scene data, call the swap functoinality and trigger callback
     */
    swapProductInScene(assetId, newAssetCategory, newAssetMaterialType, assetPlacementTypeMapping, onSwappedAction) {
        this.sceneLoader.updateItemsCategoryList(newAssetCategory);
        this.sceneLoader.updateItemsMaterialTypesList(newAssetMaterialType);
        this.sceneLoader.updateItemsPlacementList(assetPlacementTypeMapping);
        this.assetManager.loadCollection([assetId], () => {
            this.objectPlacementManager.swapSelectedAsset(assetId);
            onSwappedAction();
        } );
        
    }

    updateProductPlatforms(itemProductPlatforms) {
        this.itemProductPlatforms = itemProductPlatforms;
    }

    setProductSize (length, height, depth) {
        if (this.objectPlacementManager) {
            this.objectPlacementManager.setProductSize(length, height, depth);
        }
    }

    getProductSize () {
        return this.objectPlacementManager.getProductSize();
    }

    resetProductSize() {
        return this.objectPlacementManager.resetProductSize();
    }

    reshowFreeModeStateTransform(mode) {
        this.objectPlacementManager.reshowFreeModeStateTransform(mode);
    }

    configureSpace (isReset = false) {
        this.space = this.managersDict[Constants.Manager.SpaceManager];

        this.currentArea = null; 

        this.isBabylonExported = false;

		if ( this.space == undefined || this.space == null ) {
			return;
		}
        
		let object 	= this.space.scene;
        this.swapManager.setSpace(this.space);
        console.log("Space Current:", this)
        object.updateMatrixWorld();

        let sunNode = object.getObjectByName( this.space.sun.sunName );
        let sunTargetNode = object.getObjectByName( this.space.sun.sunTargetName );
        if (sunNode) {
            object.remove(sunNode)
        }
        if (sunTargetNode) {
            object.remove(sunTargetNode)
        }

        let babylonNode = object.getObjectByName('UsingBabylon')
        if (babylonNode) {
            this.isBabylonExported = true;
            object.remove(babylonNode)
        }


		const box 		= new THREE.Box3().setFromObject( object );
		const size 		= box.getSize( new THREE.Vector3() ).length();
		const center 	= box.getCenter( new THREE.Vector3() );
		// let orbitCam 	= getCameraByName( 'orbit' ).cam;
        let topDownCam 	= this.getCameraByName( 'topDown' ).cam;
        let topDownOrthoCam = this.getCameraByName('topDownOrtho').cam;
        let helperCam = this.getCameraByName( 'helper' );

        if (helperCam) {
            helperCam.cam.position.copy(center);
            helperCam.cam.y = 1.73;
        }

		const bbSize = new THREE.Vector3();
		box.getSize(bbSize);
		this.cameraControls.maxPanX = center.x + bbSize.x/2.15;
		this.cameraControls.minPanX = center.x - bbSize.x/2.15;
		this.cameraControls.maxPanZ = center.z + bbSize.z/2.15;
		this.cameraControls.minPanZ = center.z - bbSize.z/2.15;

        this.maxCameraHeight = size + size / 3;
        this.minCameraHeight = bbSize.y/4.0;

        topDownCam.updateProjectionMatrix();
		topDownCam.position.copy( center );
        topDownCam.position.y += size;
        
        topDownOrthoCam.updateProjectionMatrix();
		topDownOrthoCam.position.copy( center );
		topDownOrthoCam.position.y += size;

        topDownOrthoCam.updateProjectionMatrix();
		topDownOrthoCam.position.copy( center );
		topDownOrthoCam.position.y += size;

		this.scene.add( object );

        this.navControl = this.buildNavControl( this.activeCamera, this.sceneRenderer );
        this.scene.add( this.navControl );

        this.cameraControls.setNavPlanes(this.space.floors);
        if (!this.isRenderCamActive()) {
            this.navControl.enabled = false;
        }

        this.onWindowResize();
        this.setupPostSpaceInitManagers();
        this.setupSpaceLights();
        this.loadSpaceConfiguration();
        this.loadAssetManagers(isReset);
    }

	configureScene () {

        this.space = this.managersDict[Constants.Manager.SpaceManager];

		if ( this.space == undefined || this.space == null ) {
			return;
		}

		let object 	= this.space.scene;
        console.log("Space Current:", this)
        object.updateMatrixWorld();

        let sunNode = object.getObjectByName( this.space.sun.sunName );
        let sunTargetNode = object.getObjectByName( this.space.sun.sunTargetName );
        if (sunNode) {
            object.remove(sunNode)
        }
        if (sunTargetNode) {
            object.remove(sunTargetNode)
        }

        let babylonNode = object.getObjectByName('UsingBabylon')
        if (babylonNode) {
            this.isBabylonExported = true;
            object.remove(babylonNode)
        }

		const box 		= new THREE.Box3().setFromObject( object );
		const size 		= box.getSize( new THREE.Vector3() ).length();
		const center 	= box.getCenter( new THREE.Vector3() );
		// let orbitCam 	= getCameraByName( 'orbit' ).cam;
        let topDownCam = this.getCameraByName('topDown').cam;
        let topDownOrthoCam = this.getCameraByName('topDownOrtho').cam;
        let helperCam = this.getCameraByName( 'helper' );

        if (helperCam) {
            helperCam.cam.position.copy(center);
            helperCam.cam.y = 1.73;
        }


		const bbSize = new THREE.Vector3();
		box.getSize(bbSize);
		this.cameraControls.maxPanX = center.x + bbSize.x/2.15;
		this.cameraControls.minPanX = center.x - bbSize.x/2.15;
		this.cameraControls.maxPanZ = center.z + bbSize.z/2.15;
		this.cameraControls.minPanZ = center.z - bbSize.z/2.15;

        this.maxCameraHeight = size + size / 3;
        this.minCameraHeight = bbSize.y/4.0;

		topDownCam.updateProjectionMatrix();
		topDownCam.position.copy( center );
        topDownCam.position.y += size;
        
        topDownOrthoCam.updateProjectionMatrix();
		topDownOrthoCam.position.copy( center );
		topDownOrthoCam.position.y += size;

		this.scene.add( object );
        this.scene.add( this.space.sun.sunObj );
        this.debugEngine.debugLog("sun former pos", this.space.sun.sunObj.position);

		this.navControl = this.buildNavControl( this.activeCamera, this.sceneRenderer );
        this.scene.add( this.navControl );

        this.sunControls = this.buildSunControls( this.space.sun.sunObj );
        this.setSunControls(this.space.sun.enabled);

        this.cameraControls.setNavPlanes(this.space.floors);
        this.navControl.enabled = false;

        // Load Cameras
        if ( this.sceneToLoadInfo != null ) {
            this.sceneToLoadInfo.cameras.forEach( ( cameraObj ) => {
                if (cameraObj.camera_name == "helper" && !box.containsPoint(new THREE.Vector3(cameraObj.camera_position.x,cameraObj.camera_position.y,cameraObj.camera_position.z))) {
                    this.buildCustomCamera("helper", FileConstants.SCENE_CREATOR_CAMERA_MODES.ThreeD, "navigation");
                }
                else {
                    this.debugEngine.debugLog("-- Log",cameraObj);
                    let display_name = "Untitled";
                    if(cameraObj.display_name) {
                        display_name = cameraObj.display_name
                        console.log("cameras info", display_name)

                    }
                    this.buildCustomCamera(cameraObj.camera_name,
                    cameraObj.camera_mode,
                    cameraObj.camera_type,
                    cameraObj.camera_fov,
                    new THREE.Vector3( cameraObj.camera_position.x, cameraObj.camera_position.y, cameraObj.camera_position.z ),
                    new THREE.Vector3( cameraObj.camera_target.x, cameraObj.camera_target.y, cameraObj.camera_target.z ) ,
                    cameraObj.image_format,
                    cameraObj.image_width,
                    cameraObj.image_height,
                    cameraObj.clipping_value,
                    display_name
                    );
                }
            } );
        }

        this.onWindowResize();
        this.setupPostSpaceInitManagers();
        this.setupSpaceLights();
        this.loadSpaceConfiguration();
        this.loadAssets();
    }

    loadAssetManagers(isReset = false) {
        var scope = this;
        this.sceneLoader.setAssetsAfterSpaceSwap(()=>{
            if (!isReset) {
                this.swapManager.checkAutoPlaceAssetsOnSpaceSwap();
            }
            scope.sceneAssets = this.sceneLoader.getSceneAssets();
            scope.onWindowResize();
            this.objectPlacementManager = this.buildObjectPlacementManager();
            scope.isReady = true;
        })
    }

    loadAssets() {
        var scope = this;
        this.sceneLoader.loadAssets( () => {
            scope.sceneAssets = this.sceneLoader.getSceneAssets();
            scope.onWindowResize();
            scope.setupPostScenePlacementManagers();
            scope.isReady = true;
            if(this.removeLoader != null){
                this.removeLoader();
            }
        })
	}

    getItemByName( itemName ) {
        let retVal = null;
        this.sceneAssets.forEach( ( item ) => {
            if( item.name === itemName ) {
                retVal = item;
                return;
            }
        });
        return retVal;
    }

    getCameraByName( cameraName ) {
        let retVal = null;

        this.sceneCameras.forEach( ( sceneCamera ) => {
            if( sceneCamera.name === cameraName ) {
                retVal = sceneCamera;
                return;
            }
        });
        return retVal;
    }

    getCameraInfoJSON ( cameraName ) {
        return this.sceneInfoExporter.getCameraInfo(cameraName);
    }

    removeCameraByName ( cameraName ) {
        let camera = this.getCameraByName(cameraName)
        if(camera != null) {
            let cameraNode = camera.cam;
            let cameraRemoved =  removeElementFromArray(this.sceneCameras, camera);
            if (cameraRemoved) {
                // If camera to remove is the active camera then switch active camera to helper camera.
                if(this.activeCamera.name === cameraName) {
                    this.switchToCameraByName('helper');
                }

                // Also remove camera from scene
                this.scene.remove(cameraNode);

                return true;
            }
        }

        return false;
    }

    switchToCameraByName( cameraName ) {

        this.keyboard.unPress();
        let camInfo = this.getCameraByName( cameraName );
        if( camInfo != null ){
            this.activeCamera 				= camInfo.cam;
            this.rotationControls.camera 	= camInfo.cam;
            this.transformControls.camera   = camInfo.cam;
            if ( this.navControl != null && this.enableCameraControls){
                this.navControl.camera      = camInfo.cam;
            }

            if( camInfo.name === 'orbit' ) {
                // orbitControls.enabled = true;
            }
            else if ( camInfo.name === 'topDown' || camInfo.name === 'topDownOrtho') {
                // orbitControls.enabled = false;
                this.navControl.enabled = false;
                this.attachCameraControls( camInfo.cam );
            }
            else {
                // orbitControls.enabled = false;
                this.attachCameraControls( camInfo.cam );
                this.navControl.enabled = this.enableCameraControls && !this.cameraLocked && this.cameraNavigation;
            }

            if ( this.selection.object != null &&
                this.selection.object.userData.isStacked == true &&
                this.selection.object.userData.isPillow &&
                camInfo.name != 'topDown' && camInfo.name != 'topDownOrtho' ) {
                this.rotationControls.showAxis( "X", true );
            }
            else {
                this.rotationControls.showAxis( "X", false );
            }
            if (cameraName != "topDown" && cameraName != "topDownOrtho") {
                this.gridViewManager.showGridsOnCeilings();
                this.space.enableSolidCeiling();
            }
            else {
                this.gridViewManager.hideGridsOnCeilings();
                this.space.disableCeiling();
            }

        }
    }

    getCameraInfo(index) {
        let camInfo = this.sceneCameras[ index ];
        let screenPointActive = null;
        if( camInfo != null && camInfo != undefined ){
            screenPointActive = this.NDCToScreenCoordinates( this.worldToNDC( camInfo.cam.position, this.activeCamera ) );

            this.areaCameraPosition = {
                key: this.space.areas[index],
                position: camInfo.cam,
                x: screenPointActive.x,
                y: screenPointActive.y
            };
            this.debugEngine.debugLog(this.areaCameraPosition);
        }
    }

    switchToCameraAtIndex( index ) {
        let camInfo = this.sceneCameras[ index ];

        if( camInfo != null && camInfo != undefined ){

            this.activeCamera               = camInfo.cam;
            this.rotationControls.camera    = camInfo.cam;
            this.navControl.camera          = camInfo.cam;
            if( camInfo.name === 'orbit' ) {
                // orbitControls.enabled = true;
            }
            else if ( camInfo.name === 'topDown') {
                // orbitControls.enabled = false;
            }
            else {
                // orbitControls.enabled = false;
                this.attachCameraControls( camInfo.cam );
            }

            if ( this.selection.object != null &&
                this.selection.object.userData.isStacked == true &&
                this.selection.object.userData.isPillow &&
                camInfo.name != 'topDown' && camInfo.name != 'topDownOrtho' ) {
                this.rotationControls.showAxis( "X", true );
            }
            else {
                this.rotationControls.showAxis( "X", false );
            }


        }
    }

    worldToNDC ( worldPoint , activeCamera) {
        let NDCPoint = new THREE.Vector3();
        NDCPoint.copy(worldPoint);
        //let NDCPoint = worldPoint.clone();
        activeCamera.updateMatrixWorld();
        NDCPoint.project( activeCamera );
        return NDCPoint;
    }

    NDCToScreenCoordinates (NDCPoint) {
        let NDCToPixelX = ((NDCPoint.x + 1) / 2) * this.sceneWidth;
        let NDCToPixelY = (1 - (NDCPoint.y + 1) / 2) * this.sceneHeight;
        let screenPoint = new THREE.Vector2(NDCToPixelX, NDCToPixelY);
        return screenPoint;
    }

    setSelectedAssetUIButtons ( duplicateButton, deleteButton, expandButton, sizeButton, freezeButton ) {

        this.duplicateButton = duplicateButton;
        this.deleteButton = deleteButton;
        this.expandButton = expandButton;
        this.sizeButton = sizeButton;
        this.freezeButton = freezeButton;

        let ref = this;

        if (this.duplicateButton) {
            this.duplicateButton.style.position = "absolute";
            this.duplicateButton.style.display = "none";
            this.duplicateButton.addEventListener("click", function(){
                ref.objectPlacementManager.cloneSelectedAsset();
            });
            this.duplicateButton.addEventListener( 'mouseup', this.onMouseUp, false );
            this.duplicateButton.addEventListener( 'mousemove', this.onMouseMove, false );
        }

        if (this.deleteButton) {
            this.deleteButton.style.display = "none";
            this.deleteButton.addEventListener("click", function(){
                ref.deleteSelectedAsset(true);
            });
            this.deleteButton.addEventListener( 'mouseup', this.onMouseUp, false );
            this.deleteButton.addEventListener( 'mousemove', this.onMouseMove, false );
        }

        if (this.expandButton) {
            this.expandButton.style.display = "none";
            this.expandButton.addEventListener("click", function(){
                ref.toggleSwapAssetModal();
            });
            this.expandButton.addEventListener( 'mouseup', this.onMouseUp, false );
            this.expandButton.addEventListener( 'mousemove', this.onMouseMove, false );
        }

        if (this.freezeButton) {
            this.freezeButton.style.display = "none";
            this.freezeButton.addEventListener("click", function(event){
                ref.toggleFreezeSelectedProduct();

                // Remove focus from the button
                event.target.blur();
            });
            this.expandButton.addEventListener( 'mouseup', this.onMouseUp, false );
            this.expandButton.addEventListener( 'mousemove', this.onMouseMove, false );
            if(this.selection?.object?.userData?.isFrozen) {
                this.freezeButton.src = '/img/lock.svg';
            } else {
                this.freezeButton.src = '/img/unlock.svg';
            }
        }

        if (this.sizeButton) {
            this.sizeButton.style.position = "absolute";
            this.sizeButton.style.display = "none";
            this.sizeButton.addEventListener("click", function(){
                ref.showProductSizeControls(true);
            });
            this.sizeButton.addEventListener( 'mouseup', this.onMouseUp, false );
            this.sizeButton.addEventListener( 'mousemove', this.onMouseMove, false );
        }
    }

    getSelectionPlacementType () {
        const selection = this.objectPlacementManager.getSelection();
        return selection.object.userData.placementType;
    }

    getSelectionOriginalPlacement () {
        const selection = this.objectPlacementManager.getSelection();
        return selection.object.userData.originalPlacement;
    }

    //function takes in changed placement for selected object
    changePlacementOfObject (placementType) {
        this.objectPlacementManager.changeObjectPlacementType(placementType);
    }

    // flip the product vertically 90 degrees
    flipProduct () {
        this.objectPlacementManager.flipProduct();
    }

    setHoverImage ( hoverImage ) {
        this.hoverImage = hoverImage;

        this.hoverImage.style.position = "absolute";
        this.hoverImage.style.display = "none";
    }

    updateHoverImagePosition(item) {
        if (item != null) {
            this.selection.refreshSelectionTransform();
            let bBox = new THREE.Box3().setFromObject(item);
            let bBoxSize = new THREE.Vector3();
            bBox.getSize(bBoxSize);
            let uiPosition = new THREE.Vector3();
        
            let bBoxCenter = new THREE.Vector3();
            bBox.getCenter(bBoxCenter);
            uiPosition.copy(bBoxCenter);
        
            let cameraHeight = 1;
            if (!this.isOrthoCamActive()) {
                cameraHeight = this.getCameraHeight();
            }
        
            // Offset the uiPosition to the right of the bounding box
            // Adjusted to use a multiple for more precise positioning
            uiPosition.y += bBoxSize.y * 0.5; // Half of the bounding box width to the right
            uiPosition.y += bBoxSize.y * 0.1; // Additional offset to slightly move the image to the right
        
            // Convert world position to screen coordinates
            let screenPoint = this.NDCToScreenCoordinates(this.worldToNDC(uiPosition, this.activeCamera));
        
            // Position the hover image to the right side of the bounding box
            this.hoverImage.style.position = "absolute";
            this.hoverImage.style.left = screenPoint.x + 'px';
            this.hoverImage.style.top = screenPoint.y + 'px';
        }
    }
    
    

    showHoverImage( name ) {
        if ( !this.isRenderCamActive() && this.hoverImage != null) {
            this.hoverImage.src = CONSTANTS.getBaseURL(this.itemProductPlatforms[name]) + CONSTANTS.THUMBNAIL_URI + name + "_FrontAngled.jpg";
            this.hoverImage.style.display = "block";
            this.hoverImage.style.pointerEvents = "none";
        }
    }

    hideHoverImage() {
        if (this.hoverImage != null) {
            this.hoverImage.style.display = "none";
        }
    }

    updateSelectedAssetUIPosition() {
        let halfViewportWidth = window.innerWidth / 70;
        if (this.selection.object != null) {
            this.selection.refreshSelectionTransform();
            let bBox = new THREE.Box3().setFromObject(this.selection.object);
            let bBoxSize = new THREE.Vector3();
            bBox.getSize(bBoxSize);
            let uiPosition = new THREE.Vector3();
    
            let bBoxCenter = new THREE.Vector3();
            bBox.getCenter(bBoxCenter);
            uiPosition.copy(bBoxCenter);
    
            // Minimum offset to avoid overlap for small objects
            let offset = Math.max(bBoxSize.z, bBoxSize.x) / 5;
            if (this.isRenderCamActive()){ 
                offset = bBoxSize.y / 5
            }
    
            if (this.selection.placementType == Constants.PlacementType.FLOOR) {
                if (!this.isRenderCamActive()) {
                    uiPosition.z -= (bBoxSize.z / 2) + offset;
                } else {
                    uiPosition.y += bBoxSize.y / 2 + offset;
                }
            } else if (this.selection.placementType == Constants.PlacementType.CEILING) {
                if (!this.isRenderCamActive()) {
                    uiPosition.z -= (bBoxSize.z / 2) + offset;
                } else {
                    uiPosition.y -= bBoxSize.y / 2 + offset;
                }
            } else if (this.selection.placementType == Constants.PlacementType.WALL) {
                if (!this.isRenderCamActive()) {
                    uiPosition.z -= (bBoxSize.z / 2) + offset;
                } else {
                    uiPosition.y += bBoxSize.y / 2 + offset;
                }
            }
    
            let screenPoint = this.NDCToScreenCoordinates(this.worldToNDC(uiPosition, this.activeCamera));
            const sceneRendererPosition = this.sceneRenderer.domElement.getBoundingClientRect();
            screenPoint.x += sceneRendererPosition.left;
    
            // Adjust final position according to buttons icon sizes
            if (this.duplicateButton) {
                this.duplicateButton.style.position = "absolute";
                this.duplicateButton.style.left = (screenPoint.x - halfViewportWidth - 5) + 'px';
                this.duplicateButton.style.top = screenPoint.y - 20 + 'px';
            }
    
            if (this.deleteButton) {
                this.deleteButton.style.position = "absolute";
                this.deleteButton.style.left = (screenPoint.x + halfViewportWidth * 2 + 10) + 'px';
                this.deleteButton.style.top = screenPoint.y - 20 + 'px';
            }
    
            if (this.expandButton) {
                this.expandButton.style.position = "absolute";
                this.expandButton.style.left = screenPoint.x + 'px';
                this.expandButton.style.top = screenPoint.y - 20 + 'px';
            }
    
            if (this.sizeButton) {
                this.sizeButton.style.position = "absolute";
                this.sizeButton.style.left = (screenPoint.x + halfViewportWidth + 5) + 'px';
                this.sizeButton.style.top = screenPoint.y - 20 + 'px';
            }

            if (this.freezeButton) {
                this.freezeButton.style.position = "absolute";
                this.freezeButton.style.left = (screenPoint.x - halfViewportWidth * 2 - 10) + 'px';
                this.freezeButton.style.top = screenPoint.y - 20 + 'px';
            }
        }
    }

    showSelectedAssetUI() {
        if (this.duplicateButton) {
            this.duplicateButton.style.display = "block";
        }
        if (this.deleteButton) {
            this.deleteButton.style.display = "block";
        }
        if (this.expandButton) {
            this.expandButton.style.display = "block";
        }
        if (this.sizeButton) {
            this.sizeButton.style.display = "block";
        }
        if (this.freezeButton) {
            this.freezeButton.style.display = "block";
        }        
    }

    hideSelectedAssetUI() {
        if (this.duplicateButton) {
            this.duplicateButton.style.display = "none";
        }
        if (this.deleteButton) {
            this.deleteButton.style.display = "none";
        }
        if (this.sizeButton) {
            this.sizeButton.style.display = "none";
        }
        if (this.expandButton) {
            this.expandButton.style.display = "none";
        }
        if (this.hoverImage) {
            this.hoverImage.style.display = "none";
        }
        if (this.freezeButton) {
            this.freezeButton.style.display = "none";
        }
    }

    disableSelectedAssetUI() {
        if (this.duplicateButton) {
            this.duplicateButton.style.pointerEvents = 'none';
        }
        if (this.deleteButton) {
            this.deleteButton.style.pointerEvents = 'none';
        }
        if (this.sizeButton) {
            this.sizeButton.style.pointerEvents = 'none';
        }
        if (this.expandButton) {
            this.expandButton.style.pointerEvents = 'none';
        }
        if (this.hoverImage) {
            this.hoverImage.style.pointerEvents = 'none';
        }
    }

    enableSelectedAssetUI() {
        if (this.duplicateButton) {
            this.duplicateButton.style.pointerEvents = 'auto';
        }
        if (this.deleteButton) {
            this.deleteButton.style.pointerEvents = 'auto';
        }
        if (this.sizeButton) {
            this.sizeButton.style.pointerEvents = 'auto';
        }
        if (this.expandButton) {
            this.expandButton.style.pointerEvents = 'auto';
        }
        if (this.hoverImage) {
            this.hoverImage.style.pointerEvents = 'auto';
        }
    }


    getCountForAssetID(ID) {
        let count = 0
        for (let index = 0; index < this.sceneAssets.length; index++) {
            const element = this.sceneAssets[index];
            if(element.name == ID) {
                count++;
            }
        }

        return count;
    }

    setResolutions(width,height,type) {
		// calculate high, medium, low resolutions;
		if (type == 'Portrait') {
			let res4k = Math.round(4096 * (width/height));
			let res2k = Math.round(2048 * (width/height));
			let res1k = Math.round(1024 * (width/height));
			this.imgResolutions = [];
			this.imgResolutions.push(res4k + 'x4096'); // high
			this.imgResolutions.push(res2k + 'x2048'); // medium
			this.imgResolutions.push(res1k + 'x1024'); // low
		}
		else {
			let res4k = Math.round(4096 * (height/width));
			let res2k = Math.round(2048 * (height/width));
			let res1k = Math.round(1024 * (height/width));
			this.imgResolutions = [];
			this.imgResolutions.push('4096x' + res4k); // high
			this.imgResolutions.push('2048x' + res2k); // medium
			this.imgResolutions.push('1024x' + res1k); // low
		}

		return this.imgResolutions;
    }

    deleteSelectedAsset(addActionToActionManager = false) {
        if(this.selection.object != null) {
            if(this.showDeleteConfirm != null){
                this.showDeleteConfirm(this.selection.object.name);
                this.save_scene_edit = true;
                let asset = {
                    id: this.selection.object.name,
                    placement_type: this.selection.object.userData.placementType,
                    platform: this.selection.object.userData.assetPlatform,
                    category: this.selection.object.userData.assetCategory,
                    material_type: this.selection.object.userData.assetMaterial,
                }
                if (addActionToActionManager) {
                    this.actionManager.addAction({
                        transformation: "delete",
                        callback: () => {
                            this.restoreAssetsScene([asset]);
                            // if (this.sceneAssets.find((sceneAsset)=> sceneAsset.name.toString() == asset.id.toString())) {
                            //     this.unHideTrigger(asset.id.toString());
                            // }
                        },
                        resetTransform: () => this.selection.refreshSelectionTransform()
                    })
                    console.log("action manager", this.actionManager.actionStack)
                }
            }
        }
    }

    toggleSwapAssetModal() {
        if(this.handleSwapProductClick != null){
            this.handleSwapProductClick(this.selection.object.name);
        }
    }

    toggleFreezeSelectedProduct() {
        let selectionObj = getObjectFromRootByName(this.selection.object, this.selection.object.name)
        if( this.selection?.object?.userData?.isFrozen ) {
            this.selection.object.userData.isFrozen = false;
            setHighlightedState( selectionObj, true, Constants.defaultHighLightColor );
            this.freezeButton.src = '/img/unlock.svg'
        }
        else {
            this.selection.object.userData.isFrozen = true;
            setHighlightedState( selectionObj, true, Constants.invalidHighLightColor );
            this.freezeButton.src = '/img/lock.svg'
        }
    }
    

    attachRotationControls( target ) {

        this.rotationControls.enabled = true;
        this.rotationControls.attach( target );

        if ( target.userData.isPillow && this.activeCamera.name != 'topDown' &&  this.activeCamera.name != 'topDownOrtho' && target.userData.isStacked  ) {
            this.rotationControls.showAxis( "X", true );
        }
        else {
            this.rotationControls.showAxis( "X", false );
        }
    }

    detachRotationControls() {
        this.rotationControls.detach();
        this.rotationControls.enabled = false;
    }

    attachCameraControls( cam ) {
        this.cameraControls.setCamera( cam );
    }

    setFreeControlsSize(object) {
        let minSize = 0.7;
        let maxSize = 1.3;
        let adjustFactor = 0.5;
		let sizeVector = new THREE.Vector3(object.userData.size.x * object.userData.scale.x,
            object.userData.size.y * object.userData.scale.y,
            object.userData.size.z * object.userData.scale.z
		)
        let size = Math.max(sizeVector.x * adjustFactor, sizeVector.y * adjustFactor, sizeVector.z * adjustFactor);
        if (size < minSize) {
            size = minSize;
        }
        else if (size > maxSize) {
            size = maxSize;
        }
		this.transformControls.setSize(size);
    }

    attachFreeModeControls ( mode , target ) {
        this.detachRotationControls();
        if (target) {
            this.transformControls.enabled = true;
            this.transformControls.attach( target );
            this.transformControls.setMode(mode);
            this.setFreeControlsSize(target);
        }
        this.enableKeyCheck = false;
        this.navControl.enabled = false;
        this.showFreeModeState(mode);
    }

    detachFreeModeControls ( ) {
        this.transformControls.enabled = false;
        this.transformControls.detach();
        this.enableKeyCheck = true;
        this.navControl.enabled = this.isRenderCamActive() && !this.cameraLocked && this.cameraNavigation;   
        
        this.showFreeModeState(Constants.FreeModeStates.OFF);
    }

    setFreeModeControls = (mode) => {
        if ( mode == Constants.FreeModeStates.OFF) {
            this.objectPlacementManager.detachFreeModeControls();
        }
        else {
            this.objectPlacementManager.attachFreeModeControls( mode );
        }
    }

    resetFreeModeTransform = (mode) => {
        this.objectPlacementManager.resetFreeModeTransform( mode );
    }

    showTransformMenu = (state) => {
        this.objectPlacementManager.showTransformMenu(state);
    }

    applyColor( target, color ) {
        target.material.color = color;
    }

    applyTexture( target, texture ) {
        target.material.color.setRGB(1.0,1.0,1.0);
        target.material.map 		= texture;
        target.material.needsUpdate = true;
    }

    defaultRenderer() {
        this.aspect_applied = true;
        let height = window.innerHeight - 320;
        let width = this.sceneContainer.clientWidth;
        if(width/height >= 1.78) {
            this.sceneHeight = window.innerHeight - 320;
            this.sceneWidth  = this.sceneHeight * 1.78;
        }
        else {
            this.sceneWidth 		= this.sceneContainer.clientWidth;
            this.sceneHeight 	    = this.sceneWidth*0.5625;
        }
			this.aspect = this.sceneWidth/this.sceneHeight;
            this.sceneRenderer.setSize( this.sceneWidth, this.sceneHeight );
    }

    updateScreenProps() {
        if( this.aspectRatio ==  0.55){
            if(this.currentAspectType == Constants.AspectType.HORIZONTAL) {
                // this.sceneWidth 		= this.sceneContainer.clientWidth;
                // this.sceneHeight 	= this.sceneWidth*0.5625;

                let height = window.innerHeight - 140;
                let width = this.sceneContainer.clientWidth;
                if(width/height >= 1.78){
                    this.sceneHeight = window.innerHeight - 140;
                    this.sceneWidth  = this.sceneHeight * 1.78;
                }
                else{
                    this.sceneWidth 		= this.sceneContainer.clientWidth;
                    this.sceneHeight 	    = this.sceneWidth*0.5625;
                }
            }
            else if (this.currentAspectType == Constants.AspectType.VERTICAL) {
                this.sceneHeight = this.sceneContainer.clientHeight;
                this.sceneWidth = this.sceneHeight*0.5625;
            }
            else if (this.currentAspectType == Constants.AspectType.SQUARE) {
                if (this.sceneContainer.clientWidth < this.sceneContainer.clientHeight){
                    this.sceneWidth = this.sceneContainer.clientWidth;
                    this.sceneHeight = this.sceneContainer.clientWidth;
                }
                else {
                    this.sceneWidth = this.sceneContainer.clientHeight;
                    this.sceneHeight = this.sceneContainer.clientHeight;
                }
            }
            else if (this.currentAspectType == Constants.AspectType.CUSTOM) {
                this.aspect_applied = true;
                if(this.customAspect > 1)
                {
                    let height = window.innerHeight - 140;
                    let width = this.sceneContainer.clientWidth;
                    if(width/height >= this.customAspect){
                        this.sceneHeight = window.innerHeight - 140;
                        this.sceneWidth  = this.sceneHeight * this.customAspect;
                    }
                    else{
                        this.sceneWidth 		= this.sceneContainer.clientWidth;
                        this.sceneHeight 	    = this.sceneWidth*(1/this.customAspect);
                    }
                }
                else{
                    this.sceneHeight = this.sceneContainer.clientHeight;
                    this.sceneWidth = this.sceneHeight*this.customAspect;
                }
            }
            this.aspect = this.sceneWidth / this.sceneHeight;
        }
        else{
            this.aspect_applied = true;
            this.sceneWidth = window.innerWidth;
            // this.sceneWidth 		= this.sceneContainer.clientWidth;
            let calculatedAspect = (window.innerHeight - 140) / window.innerWidth;
            this.sceneHeight 	 = this.sceneWidth * calculatedAspect;
            this.aspect 		 = this.sceneWidth / this.sceneHeight;
        }
    }

    isRenderCamActive() {
        if( this.activeCamera.name === 'orbit' || this.activeCamera.name === 'topDown' || this.activeCamera.name === 'topDownOrtho' ) {
            return false;
        }
        else {
            return true;
        }
    }

    isOrthoCamActive() {
        if( this.activeCamera.name === 'topDownOrtho' ) {
            return true;
        }
        else {
            return false;
        }
    }

    setEnableCameraControls(state) {
        this.enableCameraControls =  state
    }

    onContextMenu = ( event ) => {
        event.preventDefault();
    }

    disconnectCameraControls() {
        this.enableCameraControls = false;
        this.enableKeyCheck = false;
        this.navControl.enabled = false;
        let renderer = this.sceneRenderer;
        renderer.domElement.removeEventListener( 'mousedown', this.onMouseDown, false );
        renderer.domElement.removeEventListener( 'mouseup', this.onMouseUp, false );
        renderer.domElement.removeEventListener( 'mousemove', this.onMouseMove, false );
        renderer.domElement.removeEventListener( 'wheel', this.onWheel, false );
    }

    connectCameraControls() {
        if (this.enableCameraControls == false) {
            this.enableKeyCheck = true;
            this.navControl.enabled = !this.cameraLocked && this.cameraNavigation;
            let renderer = this.sceneRenderer;
            renderer.domElement.addEventListener( 'mousedown', this.onMouseDown, false );
            renderer.domElement.addEventListener( 'mouseup', this.onMouseUp, false );
            renderer.domElement.addEventListener( 'mousemove', this.onMouseMove, false );
            renderer.domElement.addEventListener( 'wheel', this.onWheel, false );
            this.enableCameraControls = true;
        }

    }

    onMouseUp = ( event ) => {
        // To make sure only one callback is received, as we have registered mouse up callbacks both for renderer and window
        event.preventDefault();

        this.resetKeys();
        
        if (this.measurementTool.enabled) {
            this.measurementTool.events.onMouseUp();
        }
        else if (!this.spaceConfiguratorEnabled && event.target.id != 'delete-button' && event.target.id != 'freeze-button' && event.target.id != 'duplicate-button' && event.target.id != 'size-button' && event.target.id != 'vertical-rotate-button') {
            this.enableSelectedAssetUI();
            this.objectPlacementManager.events.onMouseUp();
        }

        if (this.isRenderCamActive() && this.editModeOn && this.spaceConfiguratorEnabled && this.mouseDown && !this.isPanning ) {
            this.spaceConfigurator.events.onMouseUp();
        }
        else if (this.isRenderCamActive() && this.editModeOn && !this.spaceConfiguratorEnabled && this.mouseDown && !this.isPanning
                && this.objectPlacementManager.selection.object != null) {
                    this.deactivateSpaceConfigurator();
        }

         // Move camera to target position if nav controls are not hidden & mouse down occured in scene creator div
        if (this.mouseDown && !this.isPanning && this.navControl.enabled && !this.navControl.hide){
            let targetPos = this.navControl.getCurrentTargetPosition();

            // this.reset3DCameraHeight();
            this.cameraTargetPos.copy(targetPos);
            this.cameraTargetPos.y = this.activeCamera.position.y;
            this.animateCameraMovement = true;
        }


        this.previewRotate = false;

        if ( this.cameraControls != undefined && this.cameraControls.isConnected ){
            this.cameraControls.disconnect();

                // if(Math.abs(this.lastMouseMovementX) > 1 || Math.abs(this.lastMouseMovementY) > 1) {
                //     this.animateCameraRotation = true;
                // }
        }

        this.mouseDown = false;
        this.isPanning = false;
        this.isPanned = false;

    }

    onDoubleClick = ( event ) => {
        event.preventDefault();
        if( (this.activeCamera.name == "topDown" || this.activeCamera.name == "topDownOrtho") && this.objectPlacementManager.focusedAsset == null && this.selection.object == null ) {
            let direction = this.hoverPos.clone().sub( this.activeCamera.position ).normalize();
            let offset = direction.multiplyScalar( 5 );
            let newPos = this.activeCamera.position.clone().add( offset );
            if (newPos.y < this.minCameraHeight) newPos.y = this.minCameraHeight;
            if ( newPos.y >= this.minCameraHeight && newPos.y <= this.maxCameraHeight) {
                this.cameraTargetPos.copy( newPos );
                this.animateCameraMovement = true;
            }

            if(this.setSliderPosition2D != null){
                this.setSliderPosition2D(this.getCameraHeight());
            }
        }
        this.enableDoubleClickZoom = false;
        this.doubleClickDown = false;
        
    }

    onClick = (event) => {
        if (document.activeElement.tagName.toLowerCase() != "input") {
            document.activeElement.blur()
        }
    }

    onMouseDown = ( event ) => {
        event.preventDefault();
        this.hideHoverImage();
        this.resetKeys();

        this.doubleClickDown = !this.doubleClickDown;
        if (this.doubleClickDown) {
            this.enableDoubleClickZoom = this.selection.object && this.objectPlacementManager.focusedAsset == this.selection.object;
        }

        if (this.measurementTool.enabled) {
            this.measurementTool.events.onMouseDown();
        }
        else if (!this.spaceConfiguratorEnabled && event.target.id != 'delete-button' && event.target.id != 'freeze-button' && event.target.id != 'duplicate-button' && event.target.id != 'size-button' && event.target.id != 'vertical-rotate-button') {
            this.objectPlacementManager.events.onMouseDown();
        }
        
        this.mouseDown = true;
        this.animateCameraRotation = false;

        let timeClickedNow = Date.now();

        if (timeClickedNow - this.lastMouseClickTime < 300 && !this.cameraLocked) {
            if(this.selection.object != null) {
                if (this.isRenderCamActive()) {
                    this.applyLookAtObjectHeadOn();   
                }
                else if (this.activeCamera.name == "topDown" || this.activeCamera.name == "topDownOrtho") {
                    this.applyLookAtObjectTopDown();
                }    
            }
            
        }

        this.lastMouseClickTime = timeClickedNow;
    }


    onMouseMove = ( event ) => {
        //this.debugEngine.debugLog("On move called");
        if (!this.enableCameraControls) {
            return;
        }
        event.preventDefault();

        if( !this.animateCameraRotation ) {
            this.lastMouseMovementX = event.movementX;
            this.lastMouseMovementY = event.movementY;
        }

        let offsetX = event.layerX;
        let offsetY = event.layerY;

        if ( event.target.id === 'delete-button' || event.target.id === 'freeze-button' || event.target.id === 'duplicate-button' || event.target.id === 'size-button' || event.target.id === 'vertical-rotate-button' ) {
            offsetX += parseInt( event.target.style.left, 10 );
            offsetY += parseInt( event.target.style.top, 10 );
            this.objectPlacementManager.resetFocusedAsset();
        }

        // Adding to  the offset the distance from left and top of the screen to make sure mouse coordinates are accurate.
        offsetX -= this.sceneRenderer.domElement.offsetLeft
        offsetY += this.sceneRenderer.domElement.offsetTop

        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components
        this.mouse.x = ( offsetX / this.sceneWidth ) * 2 - 1;
        this.mouse.y = - ( offsetY / this.sceneHeight ) * 2 + 1;

        if ( this.isReady == undefined || !this.isReady ) { return; }

        if( (this.activeCamera.name == "topDown" || this.activeCamera.name == "topDownOrtho") && this.space != undefined ) {
            let result = this.raycastManager.cameraIntersectFloor();

            if ( result ) {
                this.hoverPos.copy( result.point );
            }

        }

        let isFocusedAssetSelected = this.objectPlacementManager.isFocusedAssetSelected();

        if(!this.cameraLocked && this.mouseDown && !this.rotationControls.active && !this.transformControls.dragging && !this.measurementTool.startPoint && (this.objectPlacementManager.focusedAsset == null || !isFocusedAssetSelected)) {
            // Go into panning state
            if( this.isRenderCamActive()) {
                if(!this.cameraControls.isConnected) {
                    this.cameraControls.connect();
                }
            }

            if (this.panningEnabled) {
                this.isPanning = true;
                this.measurementTool.reset();
            }
        }

        // Panning in 2D mode
        if( this.isPanning && !this.isRenderCamActive() && !this.cameraLocked ) {
            // Calculate the new camera position
            let newCameraPosition = new THREE.Vector3().copy(this.activeCamera.position);

            let vec = new THREE.Vector3();
            let xDistance = -( event.movementX / ( 1000.0 / this.activeCamera.position.y) );
            let yDistance = ( event.movementY / ( 1000.0 / this.activeCamera.position.y) );

            vec.setFromMatrixColumn( this.activeCamera.matrix, 0 );
            vec.crossVectors( this.activeCamera.up, vec );
            newCameraPosition.addScaledVector( vec, yDistance );

            vec.setFromMatrixColumn( this.activeCamera.matrix, 0 );
            newCameraPosition.addScaledVector( vec, xDistance );

            this.cameraControls.setCameraPosition (newCameraPosition, true);
        }
        if (this.measurementTool.enabled) {
            this.measurementTool.events.onMouseMove();
        }
        else if ( !this.spaceConfiguratorEnabled && event.target.id != 'delete-button' && event.target.id != 'freeze-button' && event.target.id != 'duplicate-button' && event.target.id != 'size-button' && event.target.id != 'vertical-rotate-button' ) {
            if (this.mouseDown) {
                this.disableSelectedAssetUI();
            }
            this.objectPlacementManager.events.onMouseMove();
        }
        if(this.cameraControls.isConnected){
            if(this.setSliderValue != null){
                this.setSliderValue(this.getCameraPolarAngle());
            }
        }

        if (this.isRenderCamActive() && this.editModeOn && this.spaceConfiguratorEnabled) {
            this.spaceConfigurator.events.onMouseMove();
        }
        else if (this.isRenderCamActive() && this.editModeOn && !this.spaceConfiguratorEnabled  ) {
            this.spaceConfigurator.dehighlightFocusedAsset();
        }
    }

    onWheel = ( event ) => {
        event.preventDefault();
        if ( this.cameraLocked) {
            return;
        }
        if (this.activeCamera.name == 'topDown')
        {
            if ( event.shiftKey ) {
                // this.activeCamera.rotation.z += ( ( event.deltaY / 5.0 ) * this.clock.getDelta() );
            } else {
                let direction = this.hoverPos.clone().sub( this.activeCamera.position ).normalize();
                let offset = direction.multiplyScalar( ( - event.deltaY / 3.0 ) * this.clock.getDelta() );
                let newYPos = this.activeCamera.position.clone().add( offset ).y;
                if ( newYPos >= this.minCameraHeight && newYPos <= this.maxCameraHeight) {
                    this.activeCamera.position.add( offset );
                }
                this.updateCameraHeight(this.getActualCameraHeight());
            }
        }
        else if (this.activeCamera.name == "topDownOrtho") {
            this.activeCamera.zoom = this.activeCamera.zoom + (- event.deltaY / 3.0) * this.clock.getDelta();
            if (this.activeCamera.zoom < this.maxOrthoZoom) {
                this.activeCamera.zoom = this.maxOrthoZoom;
            }
            this.updateCameraHeight(this.convertOrthoZoomToCamHeight());
            this.activeCamera.updateProjectionMatrix();
        }
        if (this.normalModeOn) {
            if( this.isRenderCamActive() ) {
                let clockDelta = this.clock.getDelta();
                this.cameraControls.moveForward ( - ( event.deltaY/10 ) * clockDelta);
                this.cameraControls.moveRight ( ( ( event.deltaX/10 ) * clockDelta) );
                return;
            }
            if(this.setSliderPosition2D != null){
                this.setSliderPosition2D(this.getCameraHeight());
            }
        }


    }
    zoomIn = () => {
        if( this.isRenderCamActive() ) {
            this.cameraControls.moveForward( - ( ( -200 / 10 ) * this.clock.getDelta() ) );
        }
        else if (this.isOrthoCamActive()) {
            this.activeCamera.zoom = this.activeCamera.zoom + (5.0 * this.clock.getDelta());
            this.activeCamera.updateProjectionMatrix();
        }
        else {
            let yPos = this.activeCamera.position.y + ( ( -200 / 3.0 ) * this.clock.getDelta() );
            if ( yPos >= this.minCameraHeight ) {
                this.activeCamera.position.y = yPos;
            }
            else {
                this.activeCamera.position.y = this.minCameraHeight;
            }

            if(this.setSliderPosition2D != null){
                this.setSliderPosition2D(this.getCameraHeight());
            }
        }
        
    }

    zoomOut = () => {
        if( this.isRenderCamActive() ) {
            this.cameraControls.moveForward( - ( ( 200 / 10 ) * this.clock.getDelta() ) );
        }
        else if (this.isOrthoCamActive()) {
            this.activeCamera.zoom = this.activeCamera.zoom - (5.0 * this.clock.getDelta());
            if (this.activeCamera.zoom < 1) {
                this.activeCamera.zoom = 1;
            }
            this.activeCamera.updateProjectionMatrix();
        }
        else {
            let yPos = this.activeCamera.position.y + ( ( 200 / 3.0 ) * this.clock.getDelta() );
            if ( yPos <= this.maxCameraHeight ) {
                this.activeCamera.position.y = yPos;
            }
            else {
                this.activeCamera.position.y = this.maxCameraHeight;
            }

            if(this.setSliderPosition2D != null){
                this.setSliderPosition2D(this.getCameraHeight());
            }
        }

    }

    getSceneItemIndex ( item ) {
        var i;
        for ( i = 0; i < this.sceneAssets.length; i++ ) {
            if ( item == this.sceneAssets[i] ) {
                return i;
            }
        }
        return -1;
    }

    getSceneInfoJSON () {
        this.resetPreviewCamera()

        this.sceneInfoExporter.clearSceneInfo();

        this.sceneInfoExporter.setRoomInfo( 111, "TestRoom" );
        this.sceneCameras.forEach( (sceneCamera) => {
            if( sceneCamera.name != 'orbit' && sceneCamera.name != 'topDown' && sceneCamera.name != 'topDownOrtho' ){
                let camPos = {
                    x : sceneCamera.cam.position.x,
                    y : sceneCamera.cam.position.y,
                    z : sceneCamera.cam.position.z
                }

                let camDirection = sceneCamera.cam.getWorldDirection( new THREE.Vector3() );

                let camTarget = {
                    x : camDirection.x,
                    y : camDirection.y,
                    z : camDirection.z
                }

                let imageFormat = 'jpg';
                if (sceneCamera.cam.userData.imageFormat != undefined)
                {
                    imageFormat = sceneCamera.cam.userData.imageFormat;
                }
                let imageWidth = 1600;
                if (sceneCamera.cam.userData.imageWidth != undefined)
                {
                    imageWidth = sceneCamera.cam.userData.imageWidth;
                }
                let imageHeight = 900;
                if (sceneCamera.cam.userData.imageHeight != undefined)
                {
                    imageHeight = sceneCamera.cam.userData.imageHeight;
                }
                let dpi = 300;
                if (sceneCamera.cam.userData.dpi != undefined)
                {
                    dpi = sceneCamera.cam.userData.dpi;
                }
                let clipping_value = 0.01;
                if( sceneCamera.cam.userData.clipping_value != undefined )
                {
                    clipping_value = sceneCamera.cam.userData.clipping_value;
                }
                let camera_mode = sceneCamera.cam.userData.camera_mode;
                let display_name = "Untitled";
                if( sceneCamera.cam.userData.display_name != undefined )
                {
                    display_name = sceneCamera.cam.userData.display_name;
                }
                this.sceneInfoExporter.addCameraInfo( sceneCamera.name, sceneCamera.cam.fov, camPos, camTarget, sceneCamera.type, camera_mode, imageFormat, imageWidth, imageHeight,dpi, clipping_value, display_name);

            }
        } );
        this.sceneInfoExporter.addCameraHotspots (this.cameraHotspots);

        this.sceneLights.forEach( ( sceneLight ) => {

            // TO DO addLightInfo( "VrayLight001", true, 2.0 );

        } );

        this.sceneAssets.forEach ( ( sceneItem, index ) => {

            let worldPos = new THREE.Vector3( 0,0,0 );
            let worldQuaternion = new THREE.Quaternion();
            sceneItem.getWorldPosition( worldPos );
            sceneItem.getWorldQuaternion( worldQuaternion );

            let assetVisible = sceneItem.userData.visible;
            let isSwapped = sceneItem.userData.isSwapped;
            let currentPlacement = sceneItem.userData.currentPlacement;
            let originalPlacement = sceneItem.userData.originalPlacement;

            if(!isSwapped && this.LFAAreaManager == null || (this.LFAAreaManager != null && !this.LFAAreaManager.box.containsPoint (worldPos))) {
                let itemID = index;
                let parentID = -1;

                if ( sceneItem.parent != this.scene ) {
                parentID = this.getSceneItemIndex( sceneItem.parent );
                }

                let itemPos = {
                x : sceneItem.position.x,
                y : sceneItem.position.y,
                z : sceneItem.position.z
                }

                let itemRotation = {
                    x : sceneItem.quaternion.x,
                    y : sceneItem.quaternion.y,
                    z : sceneItem.quaternion.z,
                    w : sceneItem.quaternion.w
                }

                let itemWorldPosition = {
                x : worldPos.x,
                y : worldPos.y,
                z : worldPos.z
                }

                let itemWorldRotation = {
                    x : worldQuaternion.x,
                    y : worldQuaternion.y,
                    z : worldQuaternion.z,
                    w : worldQuaternion.w
                }

                let scale = this.objectPlacementManager.getProductScale(sceneItem);

                let itemScale = {
                    x: scale.x,
                    y: scale.y,
                    z: scale.z
                }

                let assetTransform = {
                localPosition : itemPos,
                localRotation : itemRotation,
                worldPosition : itemWorldPosition,
                worldRotation : itemWorldRotation,
                scale: itemScale
                }

                this.sceneInfoExporter.addAssetInfo( itemID, "item", sceneItem.name, parentID, assetVisible, assetTransform, currentPlacement, originalPlacement );
            }
        } );
        
        let sunPos = {
            x : this.space.sun.sunObj.position.x,
            y : this.space.sun.sunObj.position.y,
            z : this.space.sun.sunObj.position.z
        }
        
        let sunTargetPos = {
            x : this.space.sun.targetPosition.x,
            y : this.space.sun.targetPosition.y,
            z : this.space.sun.targetPosition.z
        }
        
        this.sceneInfoExporter.addSunInfo( sunPos, sunTargetPos);
        
        this.sceneInfoExporter.addSpaceConfigInfo( this.spaceConfigurator.exportSpaceConfiguration() );
        
        this.sceneInfoExporter.addDeletedAssets(this.deletedAssets);

        return this.sceneInfoExporter.generateSceneInfoJSON();
    }



    // check if two areas are adjacent or not
    findTargetHotspot(origin, target ) {
        let originCenter = new THREE.Box3().setFromObject(this.space.areas[origin].root).getCenter(new THREE.Vector3());
        let targetCenter = new THREE.Box3().setFromObject(this.space.areas[target].root).getCenter(new THREE.Vector3());
        let direction = new THREE.Vector3().subVectors(targetCenter, originCenter).normalize();
        this.raycastManager.updateRaycasterProperties(originCenter, direction, originCenter.distanceTo(targetCenter));
        let result = this.raycastManager.setAndIntersectAll(originCenter, direction, this.space.walls);
        this.raycastManager.resetFarValue();
        if (result.length > 0 && this.space.areas[origin].walls.includes(result[0].object)) {
            result = result.filter((x) => { return !this.space.areas[origin].walls.includes(x.object)});
            if (result.length > 0 && this.space.areas[target].walls.includes(result[0].object)) {
                let targetHotspot = this.findConnectingDoor(origin, target);
                if ( targetHotspot == null) {
                    targetHotspot = this.findConnectingDoor(target, origin);
                }
                if (targetHotspot == null) {
                    targetHotspot = this.findOpening(origin, target);
                }
                if (targetHotspot == null) {
                    targetHotspot = this.findOpening(target, origin);
                }
                return targetHotspot;
            }
        }
        else if (result.length == 0) {
            this.raycastManager.updateRaycasterProperties(originCenter, direction, originCenter.distanceTo(targetCenter));
            let intersectableObjects = this.space.doors;
            result = this.raycastManager.setAndIntersect(originCenter, direction, intersectableObjects);
            this.raycastManager.resetFarValue();
            if (result) {
                let targetHotspot = this.findConnectingDoor(origin, target);
                if ( targetHotspot == null) {
                    targetHotspot = this.findConnectingDoor(target, origin);
                }
                if (targetHotspot == null) {
                    targetHotspot = this.findOpening(origin, target);
                }
                if (targetHotspot == null) {
                    targetHotspot = this.findOpening(target, origin);
                }
                return targetHotspot;
            }
            else {
                return this.getCameraByName( target ).cam.position;;
            }

        }
        return null;
    }

    // find connecting door between two areas;
    findConnectingDoor(origin, target) {
        let originDoors = this.getDoors(this.space.areas[origin].doors);
        let originBox = new THREE.Box3().setFromObject(this.space.areas[origin].root);
        let targetBox= new THREE.Box3().setFromObject(this.space.areas[target].root);
        let targetCenter = targetBox.getCenter(new THREE.Vector3());
        let originCenter = originBox.getCenter(new THREE.Vector3());
        let doorPosition = null;
        let doorsElements = [];
        for (let i in originDoors) {
            doorPosition = this.getDoorPosition(originDoors[i]);
            let bbox = new THREE.Box3().setFromObject(originDoors[i]);
            let minCorner = new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.min.z);
            let maxCorner = new THREE.Vector3(bbox.min.x, bbox.max.y, bbox.min.z);
            let center = bbox.getCenter(new THREE.Vector3());
            let backwardDirection = ( center.clone().sub( minCorner ) ).cross( maxCorner.clone().sub( minCorner) ).normalize();
            let doorOffsetPosition = doorPosition.clone().add(backwardDirection.multiplyScalar(0.5));
            if (originCenter.distanceTo(doorPosition) > originCenter.distanceTo(doorOffsetPosition)) {
                backwardDirection = backwardDirection.negate();
            }
            this.raycastManager.updateRaycasterProperties(doorPosition, backwardDirection, originCenter.distanceTo(targetCenter));
            let result = this.raycastManager.setAndIntersect(doorPosition, backwardDirection, this.space.areas[origin].walls);
            this.raycastManager.resetFarValue();
            if (!result) {
                doorOffsetPosition = doorPosition.clone().add(backwardDirection.multiplyScalar(0.5));
                result = this.raycastManager.setAndIntersect(doorOffsetPosition, _unit.aY, [this.space.areas[target].floor]);
                this.raycastManager.resetFarValue();
                if (result) {
                    doorsElements.push(bbox);
                }
            }
        }
        if (doorsElements.length == 0) {
            return null;
        }
        let largestElement = [];
        // since using meshes so largest element out of the probable door elements should be the frame
        for (let i in doorsElements) {
            largestElement.push(doorsElements[i].max.y - doorsElements[i].min.y);
        }
        let index = largestElement.indexOf(Math.max(...largestElement));
        return doorsElements[index].getCenter(new THREE.Vector3());
    }
    
    getParent(child){
        if (child.parent != undefined){
            if(child.parent.userData != undefined && child.parent.userData.assetType == 'item'){
                return child.parent.name;   
            }
            return this.getParent(child.parent)
        }
        return ""
    }
    
    // Function to throw systematic rays over the object's surface with depth testing
    throwSystematicRaysWithDepthTesting(object, numRays) {
        
        const raycaster = new THREE.Raycaster();
        
        // getting direction in which camera is looking
        let direction = new THREE.Vector3();
        this.clonedActiveCamera.getWorldDirection( direction );
        
        let cameraPosition = new THREE.Vector3();
        cameraPosition.copy(this.clonedActiveCamera.position)
        
        // Get center of where the near plane is 
        cameraPosition.add(direction.multiplyScalar(this.clonedActiveCamera.near))
            
        // Set the raycaster's origin to the near camera position
        raycaster.set(cameraPosition,new THREE.Vector2(0, 0))
        // raycaster.setFromCamera(new THREE.Vector2(0, 0), this.clonedActiveCamera);

        // this.scene.add(new THREE.ArrowHelper(raycaster.ray.direction, raycaster.ray.origin, 300, 0xff0000) );
        
        // Calculate the step size for the systematic grid
        const stepSize = 1 / Math.sqrt(numRays);
        
        const intersectableObjects = [this.space.scene];
        intersectableObjects.push(...this.FrustumObjects);

        // Loop through the systematic grid
        for (let x = 0; x < 1; x += stepSize) {
            for (let y = 0; y < 1; y += stepSize) {
                const pointOnSurface = new THREE.Vector3(x, y, 0).applyMatrix4(object.matrixWorld);
                raycaster.ray.set(cameraPosition, pointOnSurface.clone().sub(cameraPosition).normalize());

                // this.scene.add(new THREE.ArrowHelper(raycaster.ray.direction, raycaster.ray.origin, 300, 0xff0000) );
                // Check for intersections with the object's geometry
                const intersections = raycaster.intersectObjects(intersectableObjects, true);

                // If there is an intersection and the depth test passes, the object is considered visible
                if (intersections.length > 0 ) {
                    let objName = this.getParent(intersections[0].object);
                    if (objName == object.name){
                        return object.name;
                    }
                }
            }
        }

        // No intersections found, or depth test failed, the object is not visible
        return "";
    }
    
    calculateNumRays(object, minRays, maxRays) {
        const boundingBox = new THREE.Box3().setFromObject(object);
        const objectSize = boundingBox.getSize(new THREE.Vector3());
        let maxSize = Math.max(objectSize.x, objectSize.y, objectSize.z);
        return (Math.round(THREE.MathUtils.clamp(maxSize, minRays, maxRays))*36);
    }
    
    updatingClonedCamera() {
        
        // Cloning the active camera to get a new camera
        this.clonedActiveCamera = this.activeCamera.clone();
        
        // decoupling the cloned camera from the active camera
        this.clonedActiveCamera.position.copy(this.activeCamera.position.clone());
        this.clonedActiveCamera.quaternion.copy(this.activeCamera.quaternion.clone());
 
    }
    
    getObjectsInCamera () {
        
        this.activeCamera.updateProjectionMatrix();
        this.FrustumObjects = []
        let mapped_products = []
        
        // const helper = new THREE.CameraHelper( this.clonedActiveCamera );
        // this.scene.add( helper );
        
        // Get frustum of camera and check which objects intersect the frustum
        const _frustum = new THREE.Frustum();
        const _projScreenMatrix = new THREE.Matrix4();
        
        _projScreenMatrix.multiplyMatrices( this.clonedActiveCamera.projectionMatrix, this.clonedActiveCamera.matrixWorldInverse );
        _frustum.setFromProjectionMatrix( _projScreenMatrix );
        this.scene.children.map( (obj) => {
            if (obj.userData != {} && obj.userData.assetType == 'item' && obj.userData.visible == true){
                const boundingBox = new THREE.Box3().setFromObject(obj)
                if ( _frustum.intersectsBox( boundingBox ) ) {
                    this.FrustumObjects.push(obj);
                }
            }
        });
        
        console.log("products frustum",this.FrustumObjects )
        
        // Minimum and Maximum amount of rays thrown at an object
        const minRays = 5;
        const maxRays = 20;
        this.FrustumObjects.map( (obj) => {
            const numRays = this.calculateNumRays(obj, minRays, maxRays);
            // Check if the GLB model is visible with systematically thrown rays
            let product_id = this.throwSystematicRaysWithDepthTesting(obj, numRays)
            if (!mapped_products.includes(product_id) && product_id != '' && product_id != null)
                mapped_products.push(product_id)
            
            obj.traverse((child) => {
                if (child.userData != {} && child.userData.assetType == 'item' && child.userData.visible == true){
                    if (!mapped_products.includes(child.name) && child.name != '' && child.name != null)
                        mapped_products.push(child.name)
                }
            })
        })

        console.log("products mapped after raycasting", mapped_products )

        return mapped_products
    }

    getOpening(doors) {
        let doorsPos = [];
        for (let i in doors) {
            if (doors[i].type != "Mesh" && doors[i].name.includes("Opening")) {
                doorsPos.push(doors[i]);
            }
        }
        return doorsPos;
    }

    findOpening(origin, target) {
        let originOpenings = this.getOpening(this.space.areas[origin].doors)
        let targetOpenings = this.getOpening(this.space.areas[target].doors)
        for (let i in originOpenings) {
            let originPosition = originOpenings[i].getWorldPosition(new THREE.Vector3())
            for (let j in targetOpenings) {
                let targetPosition = targetOpenings[j].getWorldPosition(new THREE.Vector3());
                if (!roundedEqual(originPosition, targetPosition)) {
                    let backwardDirection = new THREE.Vector3().subVectors(targetPosition,originPosition).normalize();
                    this.raycastManager.updateRaycasterProperties(originPosition, backwardDirection, originPosition.distanceTo(targetPosition));
                    let result = this.raycastManager.setAndIntersect(originPosition, backwardDirection, this.space.walls);
                    this.raycastManager.resetFarValue();
                    if (!result) {
                        let doorOffsetPosition = originPosition.clone().add(backwardDirection.multiplyScalar(0.5));
                        result = this.raycastManager.setAndIntersect(doorOffsetPosition, Constants._unit.aY, [this.space.areas[target].floor]);
                        this.raycastManager.resetFarValue();
                        if (result) {
                            return originPosition;
                        }
                    }
                }
                else {
                    return originPosition;
                }

            }

        }
        return null;

    }

    getTourInfoJSON ( areas ) {

        var tourInfoExporter = new VirtualTourInfoExporter();

        if ( areas.length < 2 ) {
            this.debugEngine.debugLog( "A virtual tour needs at least two or more areas" );
            return;
        }

        for ( let origin of areas ) {
            if (this.getCameraByName( origin ) != undefined && this.getCameraByName( origin ) != null) {
                let originCam = this.getCameraByName( origin ).cam;
                let hotspots = [];
                for ( let target of areas ) {
                    this.debugEngine.debugLog("area", origin);
                    if ( target != origin && this.getCameraByName( target ) != undefined && this.getCameraByName( target ) != null) {
                        let targetPosition = this.findTargetHotspot(origin, target);
                        // if areas are connected then add their hotspots
                        if (targetPosition != null) {
                            let direction = targetPosition.clone().sub( originCam.position );
                            direction.normalize();
                            direction.multiplyScalar( 500.0 );
                            direction.applyQuaternion( originCam.quaternion.clone().inverse() );
                            let hotspot = {};
                            hotspot.title = target;
                            hotspot.position = {
                                x: direction.x,
                                y: direction.y,
                                z: direction.z
                            }
                            hotspots.push( hotspot );
                        }
                        else {
                            this.debugEngine.debugLog("areas  not connected ", origin, " to ", target);
                        }
                    }
                }
                // this.debugEngine.debugLog("hotspots", hotspots);
                tourInfoExporter.addPano( origin, hotspots );
                tourInfoExporter.addCameraHotspots (this.cameraHotspots);
            }
        }
        return tourInfoExporter.generateTourInfoJSON();

    }

    getUnlitAreas() {

        let unlitAreas = [];

        for ( let area in this.space.areas ) {

            let hasWindows = false;
            let hasLights = false;

            for ( let obj of this.space.areas[area].root.children ) {

                let objName = obj.name.toLowerCase();

                if ( objName == "windows" ) {
                    hasWindows = true;
                }

                else if ( objName == "lights" ) {
                    hasLights = true;
                }

            }

            if ( !hasWindows && !hasLights ) {
                unlitAreas.push( area );
            }

        }

        return unlitAreas;

    }

    changeAspectType(aspectType) {
        this.currentAspectType = aspectType;
        this.onWindowResize();
    }

	focusArea( area ) {

		let bBox;

		if( area == null ) {

			bBox = new THREE.Box3().setFromObject( this.space.scene );

		}

		else {

			bBox = new THREE.Box3().setFromObject( area.root );

		}

		let size = bBox.getSize( new THREE.Vector3() ).length();
		let center = bBox.getCenter( new THREE.Vector3() );

		if ( this.activeCamera.name == 'topDown' || this.activeCamera.name == 'topDownOrtho' ) {

			this.cameraTargetPos.copy( center );
			this.cameraTargetPos.y += size
			this.animateCameraMovement = true;

		}

		else {

			this.cameraTargetPos.copy( center );
			this.animateCameraMovement = true;
			this.activeCamera.rotation.set( -Math.PI / 8.0, Math.PI / 2.0, 0, 'YXZ' );

		}

	}

	updateCurrentArea() {

		if ( this.space != null && this.raycastManager != null ) {
            let floorIntersection = this.raycastManager.setAndIntersect(this.activeCamera.position, Constants._unit.aY, this.space.floors);

			if ( floorIntersection ) {

				if ( this.currentArea != null ) {

					if ( floorIntersection.object == this.space.areas[ this.currentArea ].floor ||
						floorIntersection.object.parent == this.space.areas[ this.currentArea ].floor ) {

						return;

					}

				}

				for ( var area in this.space.areas ) {

					if ( floorIntersection.object ==  this.space.areas[ area ].floor ||
					floorIntersection.object.parent == this.space.areas[ area ].floor ) {

						this.currentArea = area;

					}

				}

			}

		}

	}

    switchToNext360Camera() {

        if ( this.current360CamIndex == undefined ) {
            this.current360CamIndex = 0;
        }

        let nextCamIndex = this.current360CamIndex;

        do {
            nextCamIndex++;

            if ( nextCamIndex == this.sceneCameras.length ) {

                if ( this.current360CamIndex == 0 ) {
                    this.debugEngine.debugLog("No 360 Cameras in the scene yet!");
                    return; // No 360 Cameras in the scene yet
                }
                else {
                    nextCamIndex = 0; // Reached the end, start over
                }
            }
        } while ( this.sceneCameras[nextCamIndex].type != '360');

        this.nextAreaCamera = 1;
        this.switchToCameraAtIndex( nextCamIndex );
        this.current360CamIndex = nextCamIndex;
    }

    onWindowResize = () => {
        this.updateScreenProps();
        this.sceneRenderer.domElement.style.margin = "0 auto"
        this.sceneRenderer.domElement.style.display = "flex";
        this.sceneRenderer.domElement.style.flexDirection = "row";
        this.sceneRenderer.domElement.style.justifyContent = "center";
        this.sceneRenderer.domElement.style.alignItems = "center";
        this.sceneRenderer.setSize( this.sceneWidth, this.sceneHeight );
        if(this.cameraHelper)
        {
            this.cameraHelper.setSceneWidthHeight(this.sceneWidth, this.sceneHeight);
            this.cameraHelper.updateRendererPos();
        }

        this.sceneCameras.forEach( ( sceneCamera )=>{
            if( sceneCamera.projection === Constants.CameraProjectionType.PERSPECTIVE ){
                sceneCamera.cam.aspect = this.aspect;
                sceneCamera.cam.updateProjectionMatrix();
            }
            else
            {
                sceneCamera.cam.left 	=  -this.frustumSize  * this.aspect / 2;
                sceneCamera.cam.right 	= this.frustumSize  * this.aspect / 2;
                sceneCamera.cam.top 	= this.frustumSize  / 2;
                sceneCamera.cam.bottom 	= - this.frustumSize  / 2;
                sceneCamera.cam.updateProjectionMatrix();
            }
        });
        if (this.isReady) {
            this.updateGridForSnapshot();
            const overlayDiv  = document.getElementById( 'composite-overlay' );
            overlayDiv.style.width = `${this.sceneWidth}px`;
            overlayDiv.style.height = `${this.sceneHeight}px`;
        }

    }

    // Common movement handler with delta time and linear interpolation
    handleMovement(direction, movementFn) {
        const delta = this.clock.getDelta(); // Get time since last frame

        let moveCamera = true;
        
        // Check if the selected object is frozen
        if (!this.selection?.object?.userData?.isFrozen) {
            moveCamera = this.objectPlacementManager.moveSelectedObject(direction);
        }

        // Move camera if allowed and active camera is "helper"
        if (!this.cameraLocked && moveCamera && this.activeCamera.name === "helper") {
            movementFn(delta * this.movementSpeed);
        }
    }

    update() {

        this.updateCurrentArea();
        this.keyboard.update();

        if( this.keyboard.down("control") || this.keyboard.down("meta") ) {
            this.controlPressed = true;
        }

        if ( this.keyboard.up("control") || this.keyboard.up("meta")) {
            this.controlPressed = false;
        }

        if( (this.keyboard.down("z") || this.keyboard.down("Z")) && this.controlPressed ) {
            this.actionManager.undo();
        }

        if(this.documentIsFocused)
        {
            
            const moveForward = (speed) => this.cameraControls.moveForward(speed);
            const moveBackward = (speed) => this.cameraControls.moveForward(-speed);
            const moveLeft = (speed) => this.cameraControls.moveRight(-speed);
            const moveRight = (speed) => this.cameraControls.moveRight(speed);
            // Key checks for movement
            if (this.enableKeyCheck) {

                if (this.keyboard.down("up") || this.keyboard.down("down") || this.keyboard.down("left") || this.keyboard.down("right") || this.keyboard.down("a") || this.keyboard.down("s") || this.keyboard.down("d") || this.keyboard.down("w") || this.keyboard.down("W") ||  this.keyboard.down("A") || this.keyboard.down("S") || this.keyboard.down("D")) {
                    if(this.selection.object != null) {
                        this.selectedObjectRotation = this.selection.object.rotation.clone();
                        if (!this.selection.object?.userData?.isFrozen)
                            this.selectedObjectPosition = this.selection.object.position.clone();
                    }
                }

                if (this.keyboard.up("up") || this.keyboard.up("down") || this.keyboard.up("left") || this.keyboard.up("right") || this.keyboard.up("a") || this.keyboard.up("s") || this.keyboard.up("d") || this.keyboard.up("w") || this.keyboard.up("W") ||  this.keyboard.up("A") || this.keyboard.up("S") || this.keyboard.up("D")) {
                    if(this.selection.object != null && !this.selection.object?.userData?.isFrozen && !this.selectedObjectPosition.equals(this.selection.object.position)) {
                        this.actionManager.addAction({  
                            target : this.selection.object,
                            transformation : "position",
                            previousState : {
                                position : this.selectedObjectPosition,
                                rotation : this.selectedObjectRotation
                            },
                            newState : {
                                position : this.selection.object.position
                            },
                            resetTransform : () => this.selection.refreshSelectionTransform()
                        })
                        this.selectedObjectPosition = this.selection.object.position.clone();
                        console.log("action manager", this.actionManager.actionStack)
                    }
                }

                if ( this.keyboard.pressed("up") || this.keyboard.pressed("W") || this.keyboard.pressed("w") ) {
                    this.handleMovement(this.Direction.UP, moveForward);
                }
            
                if (this.keyboard.pressed("left") || this.keyboard.pressed("A") || this.keyboard.pressed("a")) {
                    this.handleMovement(this.Direction.LEFT, moveLeft);
                }
            
                if (this.keyboard.pressed("down") || this.keyboard.pressed("S") || this.keyboard.pressed("s")) {
                    this.handleMovement(this.Direction.DOWN, moveBackward);
                }
            
                if (this.keyboard.pressed("right") || this.keyboard.pressed("D") || this.keyboard.pressed("d")) {
                    this.handleMovement(this.Direction.RIGHT, moveRight);
                }

                if(this.keyboard.pressed("]") && this.activeCamera.name === "helper") {
                    let cameraHeight = this.getActualCameraHeight();
                    let heightDifference = 0.00254 / 10 * this.movementSpeed;
                    let newHeight = cameraHeight + heightDifference;
                    let newHeightInches= newHeight * 3 * this.getSpaceHeight()
                    this.setCameraHeightProp(newHeight);
                    this.setCameraStateHeight((newHeightInches * 39.37).toFixed(1));
                }

                if(this.keyboard.pressed("[") && this.activeCamera.name === "helper") {
                    let cameraHeight = this.getActualCameraHeight();
                    let heightDifference = 0.00254 / 10 * this.movementSpeed;
                    let newHeight = cameraHeight - heightDifference;
                    let newHeightInches= newHeight * 3 * this.getSpaceHeight()
                    this.setCameraHeightProp(newHeight);
                    this.setCameraStateHeight((newHeightInches * 39.37).toFixed(1));
                }

            }
        }
        else {
            this.keyboard.unPress();
            this.accumulator = 0;
        }

        if (this.measurementTool && this.measurementTool.enabled) {
            this.measurementTool.update();
        }
    }


    render() {

        let delta = this.clock.getDelta();

        if(this.isReady) {
            // Update position of selected asset UI
            this.updateSelectedAssetUIPosition();

            if (this.selected_area_space != null){
                this.setUpHotspot(this.selected_area_space);
                // this.setupCameraHelper(this.selected_area_space, "Image ID");
            }

            if ( this.animateCameraMovement ) {
                this.activeCamera.position.lerp( this.cameraTargetPos, 0.2 );
                if (  roundedEqual ( this.activeCamera.position, this.cameraTargetPos ) ) {
                    this.animateCameraMovement = false;
                }
            }

            if ( this.animateCameraRotation ) {
                if( Math.abs(this.lastMouseMovementX) > 0.25 || Math.abs(this.lastMouseMovementY) > 0.25) {
                    this.lastMouseMovementX = THREE.MathUtils.lerp(this.lastMouseMovementX, 0, 0.15);
                    this.lastMouseMovementY = THREE.MathUtils.lerp(this.lastMouseMovementY, 0, 0.15);

                    this.cameraControls.rotateCamera(- this.lastMouseMovementX, - this.lastMouseMovementY);
                }
                else {
                    this.animateCameraRotation = false
                }
            }

            if(this.stats) {
                this.stats.update();
            }
        }

        if ( this.isFOVAnimating ) {

            // this.activeCamera.fov = THREE.MathUtils.lerp( this.activeCamera.fov, this.targetFOV, 0.2);
            this.activeCamera.updateProjectionMatrix();

            if ( Math.abs ( this.activeCamera.fov - this.targetFOV ) < 0.1 ) {
                // this.activeCamera.fov = this.targetFOV;
                this.activeCamera.updateProjectionMatrix();
                this.isFOVAnimating = false;
            }
        }

        // orbitControls.update();
        this.sceneRenderer.clear();
        this.sceneRenderer.render( this.scene, this.activeCamera );
        if(this.cameraHelper != null && this.sceneState == 'create360')
        {
            this.cameraHelper.getCameraHelper().rotation.order = "YXZ";
            if(this.cameraHelper.checkStatus() == false)
            {
                this.cameraHelper.getCameraHelper().lookAt(this.getCenterlookAt(this.cameraHelper.getCameraHelper().name, this.cameraHelper.getCameraHelper().position));
            }
            else
            {
                this.cameraHelper.getCameraHelper().rotation.set(this.cameraHelper.getCameraHelper().rotation.x ,this.cameraHelper.getCameraHelper().rotation.y + degrees_to_radians(delta + 0.25) , this.cameraHelper.getCameraHelper().rotation.z);
            }
            this.cameraHelper.getCameraHelper().updateProjectionMatrix();
            this.cameraHelper.updateRendererPos();
            this.previewRenderer.clear();
            this.previewRenderer.render(this.scene, this.cameraHelper.getCameraHelper());
        }
        else if (this.cameraHelper != null  && this.sceneState != 'create360') {
            this.cameraHelper.closeWindow();
        }

        if(this.stats) {
            this.stats.update();
        }
    }

    setSceneState(state) {
        this.sceneState = state;
    }

    getSceneCamerasCount() {
        return this.sceneCameras.length;
    }

    setSceneToLoadInfoToNull() {
        this.sceneToLoadInfo = null;
    }

    moveCustomCameraUp() {
        this.cameraControls.getObject().position.y += 10 * this.clock.getDelta();
    }

    moveCustomCameraDown() {
        this.cameraControls.getObject().position.y -= 10 * this.clock.getDelta();
    }

    moveCustomCameraLeft() {
        this.cameraControls.moveRight(-10 * this.clock.getDelta());
    }

    moveCustomCameraRight() {
        this.cameraControls.moveRight(10 * this.clock.getDelta());
    }

    convertCameraHeightToOrthoZoom (cameraHeight) {
        let heightToUse = this.getSpaceHeight();
        let minZoom = heightToUse;
        let maxZoom = this.maxOrthoZoom;
        let normalizedValue = (cameraHeight) / (0.3333);
        let zoom = minZoom + (maxZoom - minZoom) * normalizedValue;
        return zoom;
    }

    convertOrthoZoomToCamHeight () {
        let heightToUse = this.getSpaceHeight();
        let minZoom = heightToUse;
        let maxZoom = this.maxOrthoZoom;
        
        // Calculate the normalized value
        let normalizedValue = (this.activeCamera.zoom - minZoom) / (maxZoom - minZoom);

        // Reverse the normalization to get the camera height
        let cameraHeight = normalizedValue * 0.3333;

        return cameraHeight;
    }

     // Value should be between 0 and 1
    setCameraHeight(value) {
        if(value >= 0 && value <= 1)
        {
            if (this.isOrthoCamActive()) {
                this.activeCamera.zoom = this.convertCameraHeightToOrthoZoom(value);
                this.activeCamera.updateProjectionMatrix();
            }
            else {
                let heightToUse = this.getSpaceHeight();
                let cappedMinHeight = 0.25;
                value *= 3;
                this.activeCamera.position.y = cappedMinHeight + (value * (heightToUse - cappedMinHeight));
            }
        }
    }

    getActualCameraHeight()
    {
        if (this.isOrthoCamActive()) {
            return this.convertOrthoZoomToCamHeight();
        }
        else {
            let heightToUse = this.getSpaceHeight();
            let cappedMinHeight = 0.25;
            let value = (this.activeCamera.position.y - cappedMinHeight) /(heightToUse - cappedMinHeight);
            return value/3
        }
        
    }

    // restrictSlider()
    isRestrictSlider()
    {
        this.restrictSlider = true;

    }

    releaseRestrictSlider()
    {
        this.restrictSlider = false;
    }


    getSunAzimuthal() {
        return this.sunControls.getSphericalAngles().azimuthal;
    }

    getSunPolar() {
        return this.sunControls.getSphericalAngles().polar;
    }

    resetSun () {
        this.space.resetSun();
        this.sunControls.update();
    }

    getSpaceHeight() {
        let heightToUse = 0;
        if(this.isRenderCamActive()) {

            let ceilingIntersect = this.raycastManager.setAndIntersect(this.activeCamera.position, new THREE.Vector3(0,1,0), this.space.ceilings);
            if (ceilingIntersect != false ) {
                heightToUse = ceilingIntersect.point.y.toFixed(2) - 0.1;
                this.roomHeight = heightToUse;
            }
            else if (ceilingIntersect == false && this.isClipping && this.roomHeight != 0)  {
                heightToUse = this.roomHeight;
            }
            else {
                // if there is no ceiling, set default ceiling height
                heightToUse = 10;
            }
        }
        else {
            heightToUse = this.maxCameraHeight;
        }

        return heightToUse;
    }

    getCameraHeight() {
        let heightToUse = this.getSpaceHeight();

        let cappedMinHeight = this.minCameraHeightCappingPercentage * heightToUse;
        return (this.activeCamera.position.y - cappedMinHeight) / (heightToUse - cappedMinHeight);
    }


    getCameraFocal(){
        return(this.activeCamera.getFocalLength())
    }

    getCameraFov(){
        return this.activeCamera.fov;
    }

    setCameraFocal(value){
        this.activeCamera.setFocalLength(value);
        return this.activeCamera.fov
    }

    setUpCameraFromHelper( name, camera_mode, imageFormat = 'jpg', imageWidth = 1600, imageHeight = 900, dpi = 300, clipping_value = 0.01, scene_render_name = "Untitled")
    {
        let objCamera = this.getCameraByName('helper');
        let camDirection = objCamera.cam.getWorldDirection();

        let camTarget = {
            x : camDirection.x,
            y : camDirection.y,
            z : camDirection.z
        }

        this.buildCustomCamera(name,
            camera_mode,
            "still",
            objCamera.cam.fov,
            new THREE.Vector3( objCamera.cam.position.x, objCamera.cam.position.y, objCamera.cam.position.z ),
            new THREE.Vector3( camTarget.x, camTarget.y, camTarget.z ),
            imageFormat,
            imageWidth,
            imageHeight,
            clipping_value,
            scene_render_name,
            dpi
        );
    }

    setUpCameraFromTopDown( name, camera_mode, imageFormat = 'jpg', imageWidth = 1600, imageHeight = 900, dpi = 300, clipping_value = 0.01, scene_render_name = "Untitled")
    {
        let objCamera = this.getCameraByName('topDown');
        let camDirection = objCamera.cam.getWorldDirection();

        let camTarget = {
            x : camDirection.x,
            y : camDirection.y,
            z : camDirection.z
        }

        this.buildCustomCamera(name,
            camera_mode,
            "still",
            objCamera.cam.fov,
            new THREE.Vector3( objCamera.cam.position.x, objCamera.cam.position.y, objCamera.cam.position.z ),
            new THREE.Vector3( camTarget.x, camTarget.y, camTarget.z ),
            imageFormat,
            imageWidth,
            imageHeight,
            clipping_value,
            scene_render_name,
            dpi
        );
    }

    updateCustomCameraFromHelper( name )
    {
        let objCamera = this.getCameraByName( 'helper' );

        let camDirection = objCamera.cam.getWorldDirection();

        let camTarget = {
            x : camDirection.x,
            y : camDirection.y,
            z : camDirection.z
        }

        let position = new THREE.Vector3( objCamera.cam.position.x, objCamera.cam.position.y, objCamera.cam.position.z );
        let target = new THREE.Vector3( camTarget.x, camTarget.y, camTarget.z );

        let updateCamera = this.getCameraByName( name );
        updateCamera.cam.fov = objCamera.cam.fov;
        updateCamera.cam.position.copy( position );
        updateCamera.cam.lookAt( target.add(position) );

        updateCamera.cam.updateProjectionMatrix();

    }

    updateHelperFromCustomCamera( name )
    {
        let objCamera = this.getCameraByName(name);

        let camDirection = objCamera.cam.getWorldDirection();

        let camTarget = {
            x : camDirection.x,
            y : camDirection.y,
            z : camDirection.z
        }

        let position = new THREE.Vector3( objCamera.cam.position.x, objCamera.cam.position.y, objCamera.cam.position.z );
        let target = new THREE.Vector3( camTarget.x, camTarget.y, camTarget.z );

        let updateCamera = this.getCameraByName( 'helper' );
        updateCamera.cam.fov = objCamera.cam.fov;
        updateCamera.cam.position.copy( position );
        updateCamera.cam.lookAt( target.add(position) );

        updateCamera.cam.updateProjectionMatrix();

    }

    updateTopDownFromCustomCamera( name )
    {
        let objCamera = this.getCameraByName(name);

        let camDirection = objCamera.cam.getWorldDirection();

        let camTarget = {
            x : camDirection.x,
            y : camDirection.y,
            z : camDirection.z
        }

        let position = new THREE.Vector3( objCamera.cam.position.x, objCamera.cam.position.y, objCamera.cam.position.z );
        let target = new THREE.Vector3( camTarget.x, camTarget.y, camTarget.z );

        let updateCamera = this.getCameraByName( 'topDown' );
        updateCamera.cam.fov = objCamera.cam.fov;
        updateCamera.cam.position.copy( position );
        updateCamera.cam.lookAt( target.add(position) );

        updateCamera.cam.updateProjectionMatrix();

    }

    getSceneRender() {
        return this.sceneRenderer;
    }

    getSceneScreenShot() {
        this.hideGrids();
        this.navControl.setOpacity(0);
        this.navControl.hide = true;

        this.sceneRenderer.clear();
        this.sceneRenderer.render( this.scene, this.activeCamera );
        let imgData = this.getSceneRender().domElement.toDataURL();

        this.navControl.setOpacity(1);
        this.navControl.hide = false;
        this.showGrids();
        this.sceneRenderer.render( this.scene, this.activeCamera );
        return imgData;
    }

    getCameraPolarAngle() {

        let camDirection = new THREE.Vector3();
        let spherical = new THREE.Spherical();

        this.activeCamera.getWorldDirection(camDirection);
        spherical.setFromVector3( camDirection );
        return THREE.MathUtils.radToDeg( spherical.phi );
    }

    setCameraPolarAngle( polarAngle ) {
        this.debugEngine.debugLog("AngleCheck", polarAngle);
        if (this.restrictSlider == true && polarAngle<75)
        {
            return;
        }
        let camDirection = new THREE.Vector3();
        let spherical = new THREE.Spherical();

        this.activeCamera.getWorldDirection(camDirection);
        spherical.setFromVector3( camDirection );

        spherical.phi = THREE.MathUtils.degToRad( polarAngle );

        camDirection.setFromSpherical( spherical );

        this.activeCamera.lookAt( this.activeCamera.position.clone().add( camDirection ) );
    }


    resetCamera () {
        if ( this.activeCamera.name == "helper" ) {
            this.activeCamera.position.set( 0,1,0 );
            this.activeCamera.lookAt( new THREE.Vector3( 0,1, -1 ) );
            if( this.setSliderValue != null ){
                this.setSliderValue(90);
            }
        }
    }


    setHorizontalAspect() {
        this.changeAspectType(Constants.AspectType.HORIZONTAL);
    }

    setVerticalAspect () {
        this.changeAspectType(Constants.AspectType.VERTICAL);
    }

    setSquareAspect() {
        this.changeAspectType(Constants.AspectType.SQUARE);
    }

    setCustomAspect(value) {
        this.defaultRenderer();
        this.customAspect = value;
        this.changeAspectType(Constants.AspectType.CUSTOM);
    }

    setCustomResolutionWidth(height,new_aspect) {
		if (new_aspect != null) {
			this.customAspect = new_aspect;
		}
		this.imgHeight = height;
		this.imgWidth =  height * this.customAspect;
		return this.imgWidth;
    }

    setCustomResolutionHeight(width,new_aspect) {
		if (new_aspect != null) {
			this.customAspect = new_aspect;
		}
		this.imgWidth = width;
		this.imgHeight = width / this.customAspect;
		return this.imgHeight;
	}

    setCameraFOV(value) {
        this.targetFOV = value;
        this.isFOVAnimating = true;
    }

   setActiveCameraFOV(value) {
       this.activeCamera.fov = value;
       this.activeCamera.updateProjectionMatrix();
    }

    addDisplayName(camera_name, display_name) {
        // This function adds the given display_name to the selected camera
        this.debugEngine.debugLog('displa name function is called')
        this.sceneCameras.forEach((camera) => {
            this.debugEngine.debugLog('display name camer = ', camera);
            if(camera.name == camera_name){
                this.debugEngine.debugLog('adding the display name = ', display_name)
                camera['display_name'] = display_name
            }
        })
        this.debugEngine.debugLog('update sceneCameras = ', this.sceneCameras);
    }


    setEditMode(value) {
        this.editModeOn = value;
    }

    setAspectRatio(value) {
        this.aspectRatio = value;
        this.onWindowResize();
    }

    handleApplyGridSettings(showGrid, tileSize, color, lineWidth, unit) {
        let mode = this.activeCamera.name != "topDown" && this.activeCamera.name != 'topDownOrtho';
        this.gridViewManager.setupGridView(showGrid, mode, tileSize, color, lineWidth, unit);

    }

    hideGrids(){
        if (this.gridViewManager.enableGrid) {
            this.gridViewManager.hideGridsOnObjects();
            this.gridViewManager.hideGridsOnCeilings();
            this.gridViewManager.setEnabledState(true);
        }
    }

    showGrids(){
        if (this.gridViewManager.getEnabledState()) {
            this.gridViewManager.enableGridsInScene();
            if (this.activeCamera.name != "topDown" && this.activeCamera.name != "topDownOrtho") {
                this.gridViewManager.enableCeilingGridsInScene();
            }
        }
    }

    resetSelection(){
        this.objectPlacementManager.resetSelection();
        this.objectPlacementManager.resetFocusedAsset();
        this.setIsSelectedAsset(false);
    }

    unHideSelectedAsset(itemToUnHide) {
        if ( itemToUnHide != null ) {
            itemToUnHide.userData.visible = true;

            itemToUnHide.traverse( function ( child ) {
                if ( child.isMesh ) {
                    // Make visible
                    child.visible = true;
                }
            });

            this.raycastManager.buildFocusTargetList();
        }
    }

    unHideTrigger(itemId, count) {
        this.debugEngine.debugLog(itemId)
        let unhideCount = count;
        if (!unhideCount) {
            unhideCount = 1
        }
        for(let asset of this.sceneAssets) {
            if (asset.name.toString() == itemId.toString() && !asset.userData.isSwapped && !asset.userData.visible) {
                this.debugEngine.debugLog('found')
                this.unHideSelectedAsset(asset);
                unhideCount = unhideCount -1;

                if (unhideCount <= 0) {
                    break;
                }
            } 
        }
        return unhideCount;
    }

    updateDeletedAssetsList = (assetsList, assetsRestoreCount) => {
        let updatedDeletedAssets = [...this.deletedAssets];
    
        for (let asset of assetsList) {
            const assetIdStr = asset.id.toString();
            let removeCount = 1
            if (assetsRestoreCount) {
                removeCount = assetsRestoreCount[assetIdStr] || 1;
            }

            let instancesRemoved = 0;
            updatedDeletedAssets = updatedDeletedAssets.filter(deletedAsset => {
                if (deletedAsset.asset_name === assetIdStr && instancesRemoved < removeCount) {
                    instancesRemoved++;
                    return false;
                }
                return true;
            });
        }
    
        this.deletedAssets = updatedDeletedAssets;
    }

    bringAssetOnTop(asset) {
        this.sceneAssets.splice(this.sceneAssets.indexOf(asset), 1);
        this.sceneAssets.unshift(asset);
    }

    restoreAssets(assetsList, assetsPlacementTypes, assetsCategories, assetsMaterialTypes, assetsRestoreCount, onPlacedAction) {
        let loadAssets = [];
        this.sceneLoader.updateItemsPlacementList(assetsPlacementTypes);
        this.sceneLoader.updateItemsCategoryList(assetsCategories);
        this.sceneLoader.updateItemsMaterialTypesList(assetsMaterialTypes);

        for (let asset of assetsList) {

            let unhideCount = 1
            if (assetsRestoreCount) {
                unhideCount = assetsRestoreCount[asset.id.toString()] || 1
            }

            // if asset still exists in scene unhide it
            if (this.sceneAssets.find((sceneAsset)=> sceneAsset.name.toString() == asset.id.toString())) {
                unhideCount = this.unHideTrigger(asset.id.toString(), unhideCount);
            } 

            if (unhideCount <= 0) {
                continue;
            }

            // Filter the deleted assets to match the asset ID and limit to the count
            let deletedAssetsFound = this.deletedAssets
                .filter(deletedAsset => deletedAsset.asset_name === asset.id.toString())
                .slice(0, unhideCount);

            if (deletedAssetsFound.length) {
                loadAssets = loadAssets.concat(deletedAssetsFound);
            }
        }

        // reload assets that are not in scene
        if (loadAssets.length) {
            this.sceneLoader.loadDeletedAssets(loadAssets, () => {
                this.updateDeletedAssetsList(assetsList,assetsRestoreCount);
                onPlacedAction();
            });
        } else {
            onPlacedAction();
        }
    }

    

    toggleClipping (clip) {
        this.isClipping = clip;
        this.objectPlacementManager.toggleClipping(clip);
        this.cameraControls.toggleClipping(clip);
    }

    setCameraNear(near) {
        this.cameraControls.setNear(near);
    }

    getCameraNear() {
        return this.activeCamera.near;
    }

    resetCameraPositionToLastFloorPosition(){
        this.lastCameraPositionOnFloor = this.cameraControls.getLastCameraPositionOnFloor()
        if (this.lastCameraPositionOnFloor == 0){
            let helperCamera = this.getCameraByName('helper');
            this.lastCameraPositionOnFloor = helperCamera.cam.position;
            this.cameraControls.setLastCameraPositionOnFloor(this.lastCameraPositionOnFloor);
        }
        this.cameraControls.resetCameraPositionToLastFloorPosition();
    }

    setObjectMovementSpeed(speed) { 
        this.objectPlacementManager.setObjectMovementSpeed(speed)
    }

    setCameraMovementSpeed(speed) {
        this.movementSpeed = speed;
    }

    setCameraLocked = (value) => {
        this.cameraLocked = value;
        this.navControl.enabled = this.isRenderCamActive() && !value && this.cameraNavigation;
    }

    setCameraNavigation = (value) => {
        this.cameraNavigation = value;
        this.navControl.enabled = this.isRenderCamActive() && value && !this.cameraLocked;
    }
}



