import React from 'react';

import ProductDetails from '../ProductDetails/ProductDetails';
import CustomerMainLayout from '../CustomerMainLayout';

const CUSTOMER_USERNAME = localStorage.getItem("customer_username");
const COMPANY_ID = localStorage.getItem("company_id");
const OtherProductPageRedirect = (props) => {
    const { match: { params } } = props;

    return (<CustomerMainLayout selected='3' product_page={true}>
        <ProductDetails props={props} match={props.match} params={params} />
    </CustomerMainLayout>)

}

export default OtherProductPageRedirect;