import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
  Card, Menu, Dropdown, Button
} from "antd";
import "@ant-design/compatible/assets/index.css";
import {
  ClockCircleOutlined,
} from "@ant-design/icons";
import { ReactComponent as CollaborationIcon } from '../../../../icons/collaboration-icon.svg'
import "../../../../styles/helper.scss";
import { Link } from "react-router-dom";
import * as Utilities from "../../Utilities";
import FileConstants from "../../../../FileConstants";
import _ from "lodash";
import { reorderData } from "../../../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LibraryGrid from "../LibraryGrid/LibraryGrid";
import ENVIRONMENT from "../../../../environments";
import ProjectConstants from "./Constants";
import "./DashboardProjects.scss";
import { ReactComponent as MoreIcon } from '../../../../icons/more.svg'

const { SubMenu } = Menu;

const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;

const ProjectsList = (props) => {
  const { data, tabActiveKey } = props;
  const [columnCount, setColumnCount] = useState(4);
  const [rowCount, setRowCount] = useState(1);
  const maxColumnWidth = 280;
  const rowSize = 520;

  useEffect(() => {
    setRowCount(Math.ceil(data.length / columnCount));
  }, [data]);

  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const itemIndex = rowIndex * columnCount + columnIndex;
    const ele = data[itemIndex] && data[itemIndex];

    if (!ele) {
      return null;
    }

    return (
      <div key={key} style={style}>
        <ProjectItems key={key} ele={ele} setDeleteModal={props.setDeleteModal}
        setCurrentProjectId={props.setCurrentProjectId} setCurrentProjectAccessLevel={props.setCurrentProjectAccessLevel} tabActiveKey={tabActiveKey}/>
      </div>
    );
  };

  return (
    <>
      {data.length == 0 ? (
        <div
          className="empty-section"
          style={{
            marginTop: "10%",
            position: "relative",
            textAlign: "-webkit-center",
          }}
        >
          <img
            id="empty-section-image"
            style={{ width: "10%" }}
            src="../../../../img/Collections.svg"
          />
          <p className="empty-section-text manrope f-14 black-55">
            Nothing to show
          </p>
        </div>
      ) : (
        <LibraryGrid
          setColumnCount={setColumnCount}
          setRowCount={setRowCount}
          maxColumnWidth={maxColumnWidth}
          data={data}
          cellRenderer={cellRenderer}
          columnCount={columnCount}
          getColumnWidth={() => {}}
          rowCount={rowCount}
          getEstimatedRowSize={rowSize}
          handleColumnWidthChange={() => {}}
        />
      )}
    </>
  );
};

const ProjectItems = (props) => {
  const { ele, tabActiveKey } = props;
  let updateTime = Utilities.timestampToTimeSince(ele.last_modified);
  
  let options = (id, deleteProject, access_level) => {
    return <Menu>
        <SubMenu title={<span>Add to Project</span>} popupOffset={[0, 0]} >
          <Menu.Item>
              <a className="manrope f-14" href={`/new_project?origin=/projects/${id}?tab=active&isEdit=true&projectId=${id}&step=${3}`}>Products and Props</a>
          </Menu.Item>
          <Menu.Item>
              <a className="manrope f-14" href={`/new_project?origin=/projects/${id}?tab=active&isEdit=true&projectId=${id}&step=${2}`}>Spaces</a>
          </Menu.Item>
          <Menu.Item>
              <a className="manrope f-14" href={`/new_project?origin=/projects/${id}?tab=active&isEdit=true&projectId=${id}&step=${4}`}>Scenes</a>
          </Menu.Item>
        </SubMenu>
        <Menu.Item>
            <a className="manrope f-14" onClick={() => deleteProject(id, access_level)}>Delete Project</a>
        </Menu.Item>
    </Menu>
  };
  
  const deleteProject = (id, access_level) => {
    props.setCurrentProjectAccessLevel(access_level)
    props.setCurrentProjectId(id);
    props.setDeleteModal(true);
}

  const getCombinedRendersCount = (count_key) => {
    let count = 0;
    if(ele.meta_data && ele.meta_data[count_key]){
      count = Object.values(ele.meta_data[count_key]).reduce((a, b) => a+b, 0)
    }
    return count
  }

  const spaceText = (ele.meta_data?.room_count > 1 || ele.meta_data?.room_count == 0)  ? `${ele.meta_data?.room_count ?? 0} spaces` : `${ele.meta_data?.room_count ?? 0} space`;
  const productText = (ele.meta_data?.product_count > 1 ||  ele.meta_data?.product_count == 0) ? `${ele.meta_data?.product_count ?? 0} products` : `${ele.meta_data?.product_count ?? 0} product`;
  const sceneText = (ele.meta_data?.scene_count > 1 ||  ele.meta_data?.scene_count == 0) ? `${ele.meta_data?.scene_count ?? 0} scenes` : `${ele.meta_data?.scene_count ?? 0} scene`;
  const siloCount = (ele.meta_data?.silo_count ? getCombinedRendersCount('silo_count'): 0)
  const lifestyleCount = (ele.meta_data?.lifestyle_count ? getCombinedRendersCount('lifestyle_count'): 0)


  if (updateTime.includes("h")) {
    updateTime = "Today";
  }
  const thumbnail_link =
    ENVIRONMENT.getBaseURL("google") +
    ENVIRONMENT.PROJECT_THUMBNAIL_URI +
    ele.thumbnail;

  const statusData = ProjectConstants.status_data;

  return (
    <Card
      bodyStyle={{ padding: "5px" }}
      className={`card-margins card-shadow-product`}
      bordered={false}
      data-tut="reactour__approved_scene"
      style={{ width: "94%", height:'490px' }}
    >
        <Dropdown className="group-settings more-icon" overlay={options(ele.id, deleteProject, ele.access_level)} trigger={['click']}>
            <Button
                shape="circle"
                size={"large"}
                className="more-option-library font-14 w-900"
                icon={<MoreIcon />}
              ></Button>
        </Dropdown>

      <div className="stacked-project-card-inner">
        <Link to={`/projects/${ele.id}?tab=${tabActiveKey}`}>
          <div
            className="suggested-product"
            style={{ zIndex: "owned", cursor: "pointer" }}
          >
            <div className="box">
              <img
                src={
                  ele.thumbnail
                    ? thumbnail_link
                    : require("../../../../assets/images/empty_project.svg")
                }
                style={{ objectFit: "contain", width: "100%", height: 290 }}
              />
              {ele.meta_data.collaboration_count ?
              <div className="meta-data">
                <>
                <CollaborationIcon className="mr-5"/>
                {ele.meta_data.collaboration_count}
                </>
              </div> : ''
              }
            </div>
            <div style={{ margin: "0px 12px 0px" }}>
              <div className="tag-section">
                <div className="tag">
                  <span>Project</span>
                </div>
                <div className={`tag ${updateTime == "Today" && "today-tag"}`}>
                  <ClockCircleOutlined />
                  <span className="ml-4">{updateTime}</span>
                </div>
              </div>
              <div className="project-name">
                <span className="manrope f-14 w-500 clamp-text-400">{ele.name}</span>

                <span className="manrope f-14 w-400 mt-10" style={{display: 'block', color: '#8C8C8C'}}>Models</span>
                <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{productText}</span>
                <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{spaceText}</span>
                <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{sceneText}</span>

                <span className="manrope f-14 w-400 mt-10" style={{display: 'block', color: '#8C8C8C'}}>Images</span>
                {
                  siloCount + lifestyleCount == 0 ? 
                    <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>None</span>
                  :
                    <>
                      <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{siloCount} {siloCount == 1 ? ' silo' : 'silos'}</span>
                      <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{lifestyleCount} {lifestyleCount == 1 ? ' lifestyle' : 'lifestyles'}</span>
                    </>
                }

              </div>
              {statusData[ele.status] && 
              <div className="status mt-80">
                <span>
                  {statusData[ele.status].icon}
                  <span className="manrope f-14 w-700 ml-8">
                    {statusData[ele.status].title}
                  </span>
                </span>
              </div>
              }
            </div>
          </div>
        </Link>
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => ({
  reorderData: (payload) => {
    dispatch(reorderData(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectsList)
);
