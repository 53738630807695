import React, { useEffect, useState  } from 'react';
import CustomerMainLayout from '../CustomerMainLayout'
import '../../../../styles/helper.scss'
import '@ant-design/compatible/assets/index.css';
import axios from 'axios';
import { LoadingOutlined,  ShareAltOutlined, PlusOutlined, PlusCircleFilled } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments';
import {
  Input,
  Row,
  Button,
  message,
  Card,
  Col,
  Modal,
  Tooltip,
  Dropdown,
  Radio,
  Select,
  Form,
  Menu,
  Tabs,
  Empty
} from 'antd';
import { ReactComponent as MoreIcon } from '../../../../icons/more.svg'
import DottedLoader from "../../DottedLoader";
import * as Utilities from "../../Utilities";
import MaterialDetailModal from '../MaterialDetailModal/MaterialDetailModal';
import SuccessModal from '../../SuccessModal/SuccessModal';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import FileConstants from '../../../../FileConstants';
import EmailSelect from '../../FormUtilities/EmailSelect';
import BatchSharingModal from '../BatchSharinModal/BatchSharingModal';

const categoryStyle = {
    maxHeight: 400,
    overflow: 'auto',
    fontFamily: 'Manrope',
    fontSize: 12,
    color: "#555555"
}
const { TabPane } = Tabs;
const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;


let get_options = (deleteMaterial, id, customerUsername, companyID) => {
    return <Menu>
    <Menu.Item>
      <a className="manrope f-14" onClick={() => deleteMaterial(id,customerUsername, companyID)}>Delete Material</a>  
    </Menu.Item>
    
  </Menu>
};

const MaterialCards = (props) => {

    return (
    <>
        
        <Col  lg={6} sm={24} md={8} >
            {props.selectable ? 
                <span className={`group-settings-checkbox`} style={{right: 47}}>
                    <Checkbox className="black-checkbox" onChange={() => props.selectMaterialCards(props.material)} checked={props.selectedMaterialIDs.includes(props.material.id)}/>
                </span> : 
                    props.managed_customer ? "" :
                    <Dropdown className="group-settings more-icon materials" overlay={get_options(props.deleteMaterial, props.material.id,props.material.username,props.material.company_id)} trigger={['click']}>
                        <Button shape="circle" size={"large"} className="more-option-library font-14 w-900" icon={<MoreIcon/>}>
                        </Button>
                    </Dropdown>
            }
            <Card className="material-card library" style={{border: props.selectedMaterialIDs.includes(props.material.id) ? "1px solid #333333" : "1px solid #e3e3e3"}} onClick={() => props.selectable ? props.selectMaterialCards(props.material) : props.selectMaterial(props.material)} bodyStyle={{ padding: "5px",width:"100%" }}>   
                {props.material.status == 5 && props.material.renders && props.material.renders.data && props.material.renders.data.thumbnail ?
                <img src={ENVIRONMENT.getBaseURL(props.material.platform) + ENVIRONMENT.MATERIAL_FILE_URI + props.material.id + '/' +  encodeURIComponent(props.material.renders.data.thumbnail)} className={props.material.status == 5 ? 'material-img library' : 'material-img library blur'} />
                : props.material.material_files[0] && !props.material.is_scanned ?
                <img src={ENVIRONMENT.getBaseURL(props.material.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(props.material.material_files[0].uid + '/' + props.material.material_files[0].name)} className={props.material.status == 5 ? 'material-img library' : 'material-img library blur'}/> 
                :  
                <img src={'/img/placeholder.svg'} className={props.material.status == 5 ? 'material-img library' : 'material-img library'} />
                }
                {props.material.status == 5 ? '' : 
                <div className="thumbnail-btn-bg blur mat"><span className="manrope f-14 white">In Progress</span></div>}
                <Tooltip title={<span className='manrope f-12 white'>{props.material.name}</span>}>
                    <div className="manrope f-14 w-500 black-00 clamp-text w-100" style={{margin: "12px 12px 8px 12px"}}>{props.material.name}</div>
                </Tooltip>
                <div className="justify-in-start" style= {{marginLeft:"12px",marginRight:"12px",marginBottom:"4px",textAlign:"left"}}> 
                    <span className="manrope f-12 grey-77 capitalize" style={{marginRight: 4}}>Category:</span>
                    <span className="manrope f-12 black-33 capitalize">{props.material.category}</span>
                </div>
                {(props.material.width && props.material.length) && (props.material.width != 0 && props.material.length != 0) ?
                <div className="justify-in-start" style= {{marginLeft:"12px",marginRight:"12px",marginBottom:"8px",textAlign:"left"}}> 
                    <span className="manrope f-12 grey-77 capitalize" style={{marginRight: 4}}>Dimensions:</span>
                    <span className="manrope f-12 black-33 capitalize">W {props.material.width}" H {props.material.length}"</span>
                </div>: ''}
               
            </Card>
        </Col>   
    </>)
};

const CustomerMaterials = (props) => {
    let create_new_material_options = () => {
        return <Menu>
            <Menu.Item>
                <a className="manrope f-14" onClick={() => props.history.push('/create-new-material')}>Create New Material</a>
            </Menu.Item>
            <Menu.Item>
                <a className="manrope f-14" onClick={() => props.history.push('/upload-scanned-material')}>Upload Scanned Material</a>
            </Menu.Item>
        </Menu>
      }
    const [searchValue, setSearchValue] = useState('');
    const [materialModal, setMaterialModal] = useState(false);
    const [materialInfo, setMaterialInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [materialSearchValue, setMaterialSearchValue] = useState('');
    const [editable, setEditable] = useState(false);
    const [materialValue, setMaterialValue] = useState('');
    const [materialsData, setMaterialsData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveButtonLoader, setSaveButtonLoader] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedID, setSelectedID] = useState(-1);
    const [selectedUsername, setSelectedUsername] = useState('');
    const [selectable, setSelectable] = useState(false);
    const [selectedMaterialIDs, setSelectedMaterialIDs] = useState([]);
    const [sharingModal, setSharingModal] = useState(false);
    const [sharingLoader, setSharingLoader] = useState(false);
    const [selectedPermission, setSelectedPermission] = useState('download_and_embed');
    const [shareSuccess, setShareSuccess] = useState(false);
    const [defaultMaterialIDs, setDefaultMaterialIDs] = useState([]);
    const [companyID, setCompanyID] = useState(-1);
    const [savedMaterial,setSavedMaterial] = useState(new URLSearchParams(window.location.search).get("material_id"));
    const [selectedCustomerMaterials, setSelectedCustomerMaterials] = useState([])
    const [formRef] = Form.useForm();
    const [materialTab, setMaterialTab] = useState("owned");

    useEffect(() => {
        let payload = {
            username: CUSTOMER_USERNAME,
            status: "5"
        };

        if (SHARED_ENTITIES != undefined && COMPANY_ID) {
            if (SHARED_ENTITIES.includes('material')) {
                payload.company_id = COMPANY_ID;
            }
        }     

        if (FileConstants.isMSProvider) {
            payload.company_id = null
        }

        axios.post(ENVIRONMENT.MATERIAL_GET_BATCH, payload)
        .then(res => {
            let parsedData = JSON.parse(res.data.body);
            parsedData = getMaterialsDataWithKeywords(parsedData)
            console.log(parsedData);
            if (savedMaterial) {
                parsedData.map((mat) => {
                    if (mat.id == savedMaterial) {
                        setSelectedMaterial(mat);
                        setMaterialModal(true);
                    }
                })
            }
            setLoading(false);
            setMaterialInfo(parsedData);
            forceUpdate();
        });
    },[]);

    useEffect(() => {
        getMSPCustomerData()
    }, []);

    const getMSPCustomerData = () => {
        if (FileConstants.isMSProvider && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
            let payload = {
                username: MANAGED_CUSTOMER_USERNAME,
                status: "5",
            }
            if (FileConstants.MANAGED_COMPANY_ID) {
                payload['company_id'] = (FileConstants.MANAGED_COMPANY_ID)
            }
          axios.post(ENVIRONMENT.MATERIAL_GET_BATCH, payload)
          .then(res => {
            if (res.data) {
              let owned_data = getMaterialsDataWithKeywords(JSON.parse(res.data.body));
              setSelectedCustomerMaterials(owned_data)
              setMaterialTab("customer_selected");
            }
          });
        }
    }

    useEffect(() => {
        applySearchFromQueryString();
      }, [materialInfo]);

    const applySearchFromQueryString = () => {
        const url = new URL(window.location.href);
        const searchValue = url.searchParams.get('search');
        if (searchValue !== null) {
            let cleanedSearchValue = decodeURIComponent(searchValue);
            if (cleanedSearchValue) {
                setSearchValue(cleanedSearchValue);
            }
        }
      };

    const deleteMaterial = (id, customer_username, company_id) => {
        console.log(id,customer_username,company_id)
        setSelectedID(id);
        setSelectedUsername(customer_username);
        setCompanyID(company_id);
        let companyid = COMPANY_ID;
        setDeleteModal(true);
    }

    const getMaterialsDataWithKeywords = (materials) => {
        let result = materials.map(obj => {
          let keywords = []
          for(let keyword of obj.name.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
            if (!keywords.includes(keyword)) {
              if (keyword.length > 1) {
                keywords.push(keyword.toLowerCase())
              }
            }
          }
    
          for(let keyword of obj.category.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
            if (!keywords.includes(keyword)) {
              if (keyword.length > 1) {
                keywords.push(keyword.toLowerCase())
              }
            }
          }
    
          if (obj.type != "") {
            keywords.push(obj.type.toLowerCase());
          }
    
          obj.tags.map(keyword => {
            if (!keywords.includes(keyword)) {
                if (keyword.length > 1) {
                    // should we split tags or not based on spaces?
                    keywords = keywords.concat(keyword.toLowerCase().split(" "))
                    // keywords.push(keyword.toLowerCase())  
                }
            }
        })

          return { ...obj, 'keywords': keywords };
        });
        return result
      }

    const onDelete = () => {
        setDeleteLoader(true);
        console.log(selectedID,selectedUsername, companyID)

        let api_end_point = ENVIRONMENT.UPDATE_MATERIAL;
        let payload = {
            "material_id": selectedID,
            "is_hidden": true,
        };
        
        let username = CUSTOMER_USERNAME;
        let company_id = COMPANY_ID;
        if ((username !=  selectedUsername) && (company_id != companyID)) {//if the user name is not same and if the company ids arent the same
            //case of customer deleting the material added from store or shared by some other customer
            api_end_point = ENVIRONMENT.DELETE_SHARED_ENTITY;
            payload = {
                "material_id": selectedID,
                "username": username,
            };
        }
        console.log(api_end_point);
        console.log(payload)
       
        axios.post(api_end_point, payload)
        .then( (response) => {
            message.success('Material Deleted.');
            window.location.href = '/material-library';
        })
        .catch((error) => {
            console.log(error);
            message.error('Error in deleting material!');
            setDeleteLoader(false);
        
        });
    };

    const onFinishForm = (values) => {
        console.log(values);
        
        if (values.material_category && values.material_name) {
            if (values.material_category == selectedMaterial.category && values.material_name == selectedMaterial.name && values.tags == selectedMaterial.tags) {
                setErrorMessage("You have not made any change to your material's data. Kindly make changes to the data and 'Save'.")
            } else {
                setErrorMessage('');
                setSaveButtonLoader(true);
                let payload = {
                    material_id: selectedMaterial.id,
                    category: values.material_category,
                    name: values.material_name,
                    tags: values.tags
                };
                axios.post(ENVIRONMENT.UPDATE_MATERIAL, payload)
                .then(res => {
                    setSaveButtonLoader(false);
                    setMaterialModal(false);
                    setSaveSuccess(true);
        
        
                }).catch(err => {
                    setSaveButtonLoader(false);
                    
                })
            }
            
        } else {
            setErrorMessage('You cannot leave any field empty.')
        }
        

    }

    const changeSearchValue = (e) => {
        setSearchValue(e.target.value);
        const url = new URL(window.location.href);
        if (e.target.value) {
          url.searchParams.set('search', encodeURIComponent(e.target.value));
        }
        else {
          url.searchParams.delete('search');
        }
        window.history.replaceState({}, "", url);
    }

    useEffect(() => {
        let data = [];
        axios.post(ENVIRONMENT.ADMIN_MATERIAL_BATCH, {
        })
        .then(res => {
            let categoriesData = Utilities.getMaterialsData( res.data );
            for (let i = 1; i <= (res.data).length; i++) {
                if( res.data[i - 1].parent_id == "")
                {
                    data.push({
                        key: res.data[i - 1]['material_id'],
                        title: res.data[i - 1]['name'],
                        value: res.data[i - 1]['name'],
                        children: categoriesData[res.data[i - 1]['material_id']],
                    });
                }
                
            }
            setMaterialsData(data);
        });
    },[]);

    const addMaterialOption = (e) => {
        if(e.keyCode == 13){
            if(!materialsData.some(e => e.key == materialSearchValue)){
                setMaterialsData(materialsData.concat([{
                    key: materialSearchValue,
                    value: materialSearchValue,
                    title: materialSearchValue,
                    children: [],
                }]));
            }
        }
    }


    const makeEditable = () => {
        setEditable(!editable);
        setSaveButtonLoader(false);
        setErrorMessage('');
    }

    const selectMaterial = (material) => {
        setSelectedMaterial(material);
        setMaterialModal(true);
    }

    const statusMessage = (status) => {
       if (status == 5) {
            return 'Complete';
        } else {
            return 'In Progress';
        }
    }

    const onMouseOut = (evt) => {
        let element = document.getElementById('edit-icon');
        if (element) {
            element.setAttribute("src","/img/edit-w-hover.png");
        }
    }

    const onMouseOver = (evt) => {
        let element = document.getElementById('edit-icon')
        if (element) {
            element.setAttribute("src","/img/edit-icon.png");
        }
    }

    

    const closeMaterialModal = () => {
        setMaterialModal(false);
        setSaveButtonLoader(false);
        setErrorMessage(false);
        setEditable(false);
    }

    const onChangeSelectable = () => {
        setSelectable(!selectable);
    }

    const selectMaterialCards = (material) => {
        
        let selected_materials = selectedMaterialIDs;
        if (selectedMaterialIDs.length > 0 && selectedMaterialIDs.includes(material.id)) {
            selected_materials = selectedMaterialIDs.filter((ele )=> ele != material.id )
        } else { //if the user selects the state
            selected_materials.push(material.id);
        }
        setSelectedMaterialIDs(selected_materials); 

        console.log(selected_materials);
        forceUpdate();
       
    };

    useEffect(() => {
        if (!selectable) {
            setSelectedMaterialIDs([]);
            let ids = materialInfo.map((mat) => {
                return mat.id;
            })
            console.log(ids);
        
            if(ids.length != 0){
                setDefaultMaterialIDs(ids);
            }
        }
    },[materialInfo,selectable]);

  const getSelectedIds = () => {
    let material_ids = []
    if (!selectable) {
        material_ids = defaultMaterialIDs;
      }
    else {
        material_ids = selectedMaterialIDs;
        if (material_ids.length === 0) 
        {
            material_ids = defaultMaterialIDs;
        }
    }

    return material_ids
  }

  const handleShare = values => {
    setSharingLoader(true);
    let body = values;
    if (!selectable) {
      body.material_id = defaultMaterialIDs;
    }
    else {
      body.material_id = selectedMaterialIDs;
      if (body.material_id.length === 0) 
      {
        body.material_id = defaultMaterialIDs;
      }
    }
    body.username = CUSTOMER_USERNAME;
    let permissions = {}
    permissions[selectedPermission] = 1;
    body.permissions = permissions;
    console.log(body);
    console.log(JSON.stringify(body));
    axios.post(ENVIRONMENT.SHARE_MATERIAL, { body })
        .then(res => {
            setShareSuccess(true);
            setSharingModal(false);
            setSharingLoader(false);
        });
    };

    const checkIfCompanyExisits = (company_id) => {
        if (company_id != "" && company_id != null) {
            return true;
          } 
        return false;
      }
    
      const companyMatch = (company_id, local_company_id) => {
        if (checkIfCompanyExisits(local_company_id)) {
          if (company_id == local_company_id)
            return true;
          else 
            return false;
        }
        return false;
      }

    const getMaterials = (type) => {
        if (type == 'owned') {
          return materialInfo.filter((material) =>  companyMatch(material.company_id, COMPANY_ID) || material.username == CUSTOMER_USERNAME)
        } else if (type == 'selected_customer') {
            return selectedCustomerMaterials
        } else {
          return materialInfo.filter((material) => material.username != CUSTOMER_USERNAME && !companyMatch(material.company_id, COMPANY_ID))
        }
    }

    const getSearchedMaterials = (materials) => {
        let result = []
        result = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, materials)
        return result
    }

    const getMaterialsLength = () => {
        if (FileConstants.isMSProvider && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
            return materialInfo.length + selectedCustomerMaterials.length;
        } else {
            return materialInfo.length;
        }
    }

    const getTabTotalCount = () => {
        if (FileConstants.isMSProvider && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
            return getMaterialsCount('customer_selected') + getMaterialsCount('owned')  + getMaterialsCount('shared');
        }
        return getMaterialsCount('owned')  + getMaterialsCount('shared');
      }
    

    const changeMaterialTab = (key) => {
        setMaterialTab(key);
    }

    const getMaterialsCount = (type) => {
        if (searchValue === '') {
            return getMaterials(type).length;
        } else {
            return getSearchedMaterials(getMaterials(type)).length;
        }
    };

    const EmptyDiv = () => {
        return (
         <div className="no-models-div" style={{ marginTop: "25%"}}>
            <Empty description={"No Materials To Show"} className="manrope f-14 no-models-span" />
        </div>
        );
    }

    return (
          <CustomerMainLayout selected='16'>
            {loading ? <DottedLoader/> :
            <div>
                <Row className="justify-space-between"  style={{marginTop:"20px",marginBottom: 20}}>
                    <Col lg={10} xl={10}>
                        <div className='manrope f-24 w-700' 
                        style={{display:'flex',alignItems:"flex-end"}}>
                            <span>Material Library</span>
                            <span style={{marginLeft: 4,paddingBottom: 2}} 
                        className="manrope f-20">({(searchValue != '')&&<span className="manrope grey-6b">{getTabTotalCount()} of&nbsp;</span>}{getMaterialsLength()} materials)</span></div>
                    </Col>
                    <Col lg={14} xl={14} className="justify-in-end" >
                    <div className="search-bar-width mr-8">
                       {materialInfo && getMaterialsLength() > 0 ?
                       <Input.Group compact size="large" className="d-inline">
                            <Input placeholder="Search All Materials" value={searchValue} onChange={e => {changeSearchValue(e)}} className="manrope f-14 mat-search-bar"></Input>
                        </Input.Group>: ''}
                    </div>
                    {getMaterialsLength() > 0 ?
                        <div>
                            <Tooltip 
                            placement="bottom"
                            title={
                            <span className="manrope f-12 white">
                                Share Materials
                            </span>}
                            >
                                <span>
                                    <Button
                                    className="icon-button-container mr-8" 
                                    onClick={onChangeSelectable}
                                    >
                                        <ShareAltOutlined/>
                                    </Button>
                                </span>
                            </Tooltip>
                        </div> : ''
                    }
                    <div>
                    <Tooltip title={<span className="manrope f-12 white">New Material</span>}>
                    <Dropdown  
                    overlay={create_new_material_options()}
                    trigger={["click"]}>
                        <Button className='new-product-btn black f-14'>
                            <PlusCircleFilled />&nbsp;New Material
                        </Button>
                    </Dropdown>
                    </Tooltip>
                </div>
                    </Col>
                </Row>
                <Col span={24}>
                    {getMaterialsLength() > 0 ?
                    <Tabs className="product-tab library" 
                    tabBarGutter={16}
                    activeKey={materialTab}
                    size="large"
                    onChange={changeMaterialTab}>
                        {(FileConstants.isMSProvider && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) ?
                        <TabPane  tab={MANAGED_CUSTOMER_USERNAME+ `'s Materials (${getMaterialsCount('customer_selected')}${searchValue != '' ? ` of ${getMaterials('customer_selected').length} materials` : ``})`} key={"customer_selected"} style={{padding: "10px"}}>
                        {getMaterials('customer_selected').length > 0 ? 
                            <Row className="product-tab-content-scrollable" type="flex" style={{ justifyContent: "start" }}>
                                {getMaterials('customer_selected') && getMaterials('customer_selected').length > 0 ?
                                searchValue == '' ?
                                    getMaterials('customer_selected').map(material => {
                                        {console.log('material = ', material)}
                                        return (<MaterialCards 
                                        selectable={selectable}
                                        material={material} 
                                        selectMaterial={selectMaterial} 
                                        searchValue={searchValue} 
                                        selectMaterialCards={selectMaterialCards}
                                        selectedMaterialIDs={selectedMaterialIDs}
                                        setSelectedMaterialIDs={setSelectedMaterialIDs}
                                        deleteMaterial={deleteMaterial}
                                        managed_customer={true}
                                        />)
                                    })
                                :
                                getSearchedMaterials(getMaterials('customer_selected')).length > 0 ?
                                getSearchedMaterials(getMaterials('customer_selected')).map(material => {
                                    return (<MaterialCards 
                                        selectable={selectable}
                                        material={material} 
                                        selectMaterial={selectMaterial} 
                                        searchValue={searchValue} 
                                        selectMaterialCards={selectMaterialCards}
                                        selectedMaterialIDs={selectedMaterialIDs}
                                        setSelectedMaterialIDs={setSelectedMaterialIDs}
                                        deleteMaterial={deleteMaterial}
                                        managed_customer={true}
                                    />)
                                })
                                : <EmptyDiv/>
                            : ''}
                            </Row>
                            :
                            <div className="empty-section" style={{marginTop:"10%", position:"relative",textAlign:'-webkit-center'}}>
                              <img id="empty-section-image" style={{width:"10%"}}  src = "../../../../img/Materials.svg"/>
                              <p className="empty-section-text manrope f-14 black-55">
                                Nothing to show
                            </p>
                              <p style={{marginTop:"-1%"}} className="empty-section-text manrope f-12 grey-6b">
                              There are no materials for {MANAGED_CUSTOMER_USERNAME}. To get started create a new material.
                              </p>
                            </div>
                            }
                        </TabPane> : '' }
                        <TabPane  tab={`Your Materials (${getMaterialsCount('owned')}${searchValue != '' ? ` of ${getMaterials('owned').length} materials` : ``})`} key={"owned"} style={{padding: "10px"}}>
                        {getMaterials('owned').length > 0 ? 
                            <Row className="product-tab-content-scrollable" type="flex" style={{ justifyContent: "start" }}>
                                {/* <Col lg={6} sm={24} md={8} offset={0}>
                                    <Card onClick={() => props.history.push('/material-request')} className="card-shadow-product" bordered={false} style={{ marginRight: "10px", marginBottom: "10px",height:"358px",backgroundColor:"#FAFAFA",cursor:"pointer" }}>
                                        <div>
                                            <img className="create-button-box" src={require("../../../../assets/images/create_material.svg")} />
                                            <div className="create-button-text manrope f-14 black-00">Create / Upload New Material</div>
                                        </div>
                                    </Card>
                                </Col> */}

                            {getMaterials('owned') && getMaterials('owned').length > 0 ?
                                searchValue == '' ?
                                    getMaterials('owned').map(material => {
                                        {console.log('material = ', material)}
                                        return (<MaterialCards 
                                        selectable={selectable}
                                        material={material} 
                                        selectMaterial={selectMaterial} 
                                        searchValue={searchValue} 
                                        selectMaterialCards={selectMaterialCards}
                                        selectedMaterialIDs={selectedMaterialIDs}
                                        setSelectedMaterialIDs={setSelectedMaterialIDs}
                                        deleteMaterial={deleteMaterial}
                                        managed_customer={false}
                                        />)
                                    })
                                : 
                                getSearchedMaterials(getMaterials('owned')).length > 0 ?
                                getSearchedMaterials(getMaterials('owned')).map(material => {
                                    return (<MaterialCards 
                                        selectable={selectable}
                                        material={material} 
                                        selectMaterial={selectMaterial} 
                                        searchValue={searchValue} 
                                        selectMaterialCards={selectMaterialCards}
                                        selectedMaterialIDs={selectedMaterialIDs}
                                        setSelectedMaterialIDs={setSelectedMaterialIDs}
                                        deleteMaterial={deleteMaterial}
                                        managed_customer={false}
                                    />)
                                }) : <EmptyDiv/>
                            : ''}
                            </Row>
                            :
                        <div className="empty-section" style={{marginTop:"10%", position:"relative",textAlign:'-webkit-center'}}>
                          <img id="empty-section-image" style={{width:"10%"}}  src = "../../../../img/Materials.svg"/>
                          <p className="empty-section-text manrope f-14 black-55">
                            Nothing to show
                        </p>
                          <p style={{marginTop:"-1%"}} className="empty-section-text manrope f-12 grey-6b">
                            There are no materials of your own. To get started create a new material.
                          </p>
                          <Button onClick={() => props.history.push('/material-request')}  className="outline-blk-btn square width-fit-content font-14 pd-10-18" ><PlusOutlined/>New Material</Button>
                        </div>
                        }
                        </TabPane>
                        
                        <TabPane  tab={`Shared Materials (${getMaterialsCount('shared')}${searchValue != '' ? ` of ${getMaterials('shared').length} materials` : ``})`} key={"shared"} style={{padding: "10px"}}>
                        {getMaterials('shared').length > 0 ? 
                            <Row className="product-tab-content-scrollable" type="flex" style={{ justifyContent: "start" }}>
                                {getMaterials('shared') && getMaterials('shared').length > 0 ? 
                                searchValue == '' ?
                                getMaterials('shared').map((material,index) => (
                                    <MaterialCards 
                                    selectable={selectable}
                                    material={material} 
                                    selectMaterial={selectMaterial} 
                                    searchValue={searchValue} 
                                    selectMaterialCards={selectMaterialCards}
                                    selectedMaterialIDs={selectedMaterialIDs}
                                    setSelectedMaterialIDs={setSelectedMaterialIDs}
                                    deleteMaterial={deleteMaterial}
                                    managed_customer={false}/>
                                )) :
                                getSearchedMaterials(getMaterials('shared')).length > 0 ?
                                getSearchedMaterials(getMaterials('shared')).map(material => {
                                    return (<MaterialCards 
                                        selectable={selectable}
                                        material={material} 
                                        selectMaterial={selectMaterial} 
                                        searchValue={searchValue} 
                                        selectMaterialCards={selectMaterialCards}
                                        selectedMaterialIDs={selectedMaterialIDs}
                                        setSelectedMaterialIDs={setSelectedMaterialIDs}
                                        deleteMaterial={deleteMaterial}
                                        managed_customer={false}
                                    />)
                                }) : <EmptyDiv/>
                                : ''}
                            </Row> 
                            :
                            <div className="empty-section" style={{marginTop:"10%", position:"relative",textAlign:'-webkit-center'}}>
                              <img id="empty-section-image" style={{width:"10%"}}  src = "../../../../img/Materials.svg"/>
                              <p className="empty-section-text manrope f-14 black-55">
                                Nothing to show
                            </p>
                              <p style={{marginTop:"-1%"}} className="empty-section-text manrope f-12 grey-6b">
                                There are no materials that have been shared with you.
                              </p>
                            </div>
                            }
                        </TabPane>
                    </Tabs>
                    :
                    <div className="empty-section" style={{marginTop:"10%", position:"relative",textAlign:"center",textAlign:'-webkit-center'}}>
                        <img id="empty-section-image" style={{width:"10%"}}  src = "../../../../img/Materials.svg"/>
                        <p className="empty-section-text manrope f-14 black-55">
                            Nothing to show
                        </p>
                        <p style={{marginTop:"-1%"}} className="empty-section-text manrope f-12 grey-6b">
                        There are no materials. To get started create a new material.
                        </p>
                        <Button onClick={() => props.history.push('/material-request')}  className="outline-blk-btn square width-fit-content font-14 pd-10-18" >Create New <PlusOutlined/></Button>
                    </div>
                    }
                </Col>

                {selectable &&
                    <div className='fixed-row-2'>
                        <Row className='row-margins-2'>
                            <Col span={18} className='justify-in-end'>
                                <Button onClick={onChangeSelectable} className="modal-okay-gray square font-14 mb-20">
                                    Cancel
                                </Button>
                                <Tooltip
                                    title={
                                        selectable && selectedMaterialIDs.length == 0 ? (
                                            <span className="manrope f-12 white">
                                                Share entire library or select materials
                                            </span>
                                        ) : (
                                            ""
                                        )
                                    }>
                                    <Button style={{ marginLeft: '5px', marginBottom: 20 }} onClick={() => setSharingModal(true)} className="modal-okay square font-14">
                                        Share {(selectable && selectedMaterialIDs.length !== 0) ? `(${selectedMaterialIDs.length}) ${selectedMaterialIDs.length == 1 ? 'Material' : 'Materials'}` : `Material Library`}
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    </div>
                }
               
            </div>}

            <MaterialDetailModal
            materialModal={materialModal}
            closeMaterialModal={closeMaterialModal}
            selectedMaterial={selectedMaterial}
            editable={editable}
            makeEditable={makeEditable}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
            statusMessage={statusMessage}
            onFinishForm={onFinishForm}
            saveButtonLoader={saveButtonLoader}
            setMaterialValue={setMaterialValue}
            setMaterialSearchValue={setMaterialSearchValue}
            addMaterialOption={addMaterialOption}
            categoryStyle={categoryStyle}
            materialsData={materialsData}
            materialValue={materialValue}
            errorMessage={errorMessage}
            />
        
            <SuccessModal
            visible={saveSuccess}
            onCancel={() => props.setSaveSuccess(false)}
            heading={"Material Data Updated Successfully!"}
            text={"You were successfully able to update material data."}
            footer={[
                <div className="justify-in-center">
                    <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => window.location.reload() }>
                        Okay
                    </Button>
                </div>
            ]}/>
             <Modal
                className="silo-success-modal"
                width={800}
                centered={true}
                visible={deleteModal}
                closable={false}
                footer={[
                    <span className="justify-in-center">
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-14" onClick={() => setDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button key="back" style={{textAlign:"center"}} disabled={deleteLoader} className="modal-okay square font-14" onClick={() => onDelete()}>
                            {deleteLoader? <span> Deleting <LoadingOutlined spin /></span>:<span>Yes, Delete Material </span>}
                        </Button>
                    </span>
                ]}
                > 
                <div style={{margin:"30px auto 0px auto",textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                    <h2 className="manrope f-24 black-55 w-900">Are you sure you want to delete this material?</h2>
                    {COMPANY_ID == companyID ?
                        <p className="manrope f-14 black-55">This material will be deleted for all the users of your company. Do you want to proceed?</p>
                    : ''}
                </div>           
            </Modal>
            <BatchSharingModal
                entityType='material'
                modalVisible={sharingModal}
                setSharingModal={setSharingModal}
                getEntityIds={getSelectedIds}
            />
            {/* <Modal
            className="modal-share"
            visible={sharingModal}
            onCancel={() => setSharingModal(false)}
            footer={[
                <div className="justify-in-end">
                    <Button disabled={sharingLoader} className="modal-okay square font-12" onClick={() => document.getElementById("scene-share-button").click()}>
                        Share {sharingLoader? <LoadingOutlined spin />:""}
                    </Button>
                </div>
            ]}>
            <div style={{padding:"16px 6px 0 14px"}}>
                <h2 className="manrope f-18 black-33 w-600">Share Your Material(s)</h2>
                <Form onFinish={handleShare} style={{marginBottom:"20px"}}>
                    <Form.Item name="emails"  className="manrope f-12" colon={false} rules={[{ type: 'array' }, { required: true, message: "Please enter an email address" },{ validator: Utilities.validateEmailsList },]} style={{marginBottom:"30px"}}>
                        <EmailSelect mode="tags" className="email-ph share-input-ph manrope f-12" placeholder="Enter Email Address of people to share material with"/>
                    </Form.Item>
                    <Button id="scene-share-button" type="primary" htmlType="submit" style={{"display": "none"}}>
                        Submit
                    </Button>
                </Form>
                <h4 className="manrope f-14 black-33"><b>Download Options</b></h4>
                <Radio.Group defaultValue="download_and_embed" onChange={e => setSelectedPermission(e.target.value)} className="manrope f-16 grey-99">
                    <Radio className="manrope f-12 black-55" value="download_and_embed" style={{marginBottom:"8px"}}>Download and Embed</Radio>
                    <br />
                    <Radio className="manrope f-12 black-55" value="embed" style={{marginBottom:"15px"}}>Embed Only</Radio>
                </Radio.Group>
            </div>
        </Modal> */}
        <SuccessModal
            visible={shareSuccess}
            onCancel={() => setShareSuccess(false)}
            heading={"Material(s) Shared Successfully!"}
            text={""}
            footer={[
                <div className="justify-in-center">
                    <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => window.location.reload() }>
                        Okay
                    </Button>
                </div>
            ]}/>
        </CustomerMainLayout>
      );

}

export default CustomerMaterials;
  