import React, { useContext, useState, useRef } from "react";
import { Form, Input, message, Modal, Select, Row, Col, Button, Collapse, Tooltip, Empty } from "antd";
import axios from "axios";
import CollaborateContext from "../../ContextFiles/CollaborateContext";
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';
import ENVIRONMENT from '../../../../environments'
import { LoadingOutlined, EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import CollaborateConstants from './Constants';

const { Option } = Select
const { Panel } = Collapse

const CollaborateAccess = (props) => {
    const { collaborate_access_modal, collaborators, collaboration_id, setCollaborateAccessModal,
        getCollaborators, scene_id, display_name, isCollaborationOwner, product_id, setCollaborators,
        customGroups, setCustomGroups } = useContext(props.context_for == "collaborate_qa" ? CollaborateQAContext : CollaborateContext);
    const [accessUpdateLoader, setAccessUpdateLoader] = useState(false)
    const [updatingAccessEmails, setUpdatingAccessEmails] = useState([])
    const formRef = { useRef };

    const handleAccessChange = (email, access_level = "edit") => {
        setAccessUpdateLoader(true);
        // Keep track of email address whose access is being updated
        if (!updatingAccessEmails.includes(email)) {
            let emails = updatingAccessEmails;
            emails.push(email);
            console.log('emails =', emails)
            setUpdatingAccessEmails(emails);
        }

        let payload = {
            username: localStorage.getItem('username'),
            email: email,
            access_level: access_level,
            display_name: display_name // pass display name to show in email
        };
        if (props.context_for == "collaborate_qa") {
            payload['action'] = 'update_product';
            payload['product_id'] = product_id
        } else {
            payload['collaboration_id'] = collaboration_id;
            payload['action'] = 'update';
            payload['scene_id'] = scene_id
        }

        if (access_level == "restricted") {
            payload['turn_off_email'] = true
        }

        console.log('Payload', payload)
        axios.post(ENVIRONMENT.COLLABORATE_ACCESS_CONTROLLER, payload)
            .then(res => {
                setAccessUpdateLoader(false);
                updateAccessValueInList(email, access_level)
                // Remove the email address when it's API completes
                let updated_emails = updatingAccessEmails.filter(item => item !== email);
                setUpdatingAccessEmails(updated_emails);
                message.success('Access Updated Successfully!');
            })
    }

    const updateAccessValueInList = (email, access_level) => {
        let collabs = collaborators
        collabs.map(item => {
            if(item.email == email){
                item['access_level'] = access_level
            }
        })

        setCollaborators(collabs)
    }

    const updateGroupAccessValue = (group_id, access_level) => {
        let groups = customGroups
        groups.map(item => {
            if(group_id && item.group_id == group_id){
                item['access_level'] = access_level
            }
        })
        setCustomGroups(groups)
    }

    const handleShareInvite = (values) => {
        let email = values['email'];
        let payload = {
            username: localStorage.getItem('username'),
            emails: [email],
            access_level: values["access_level"] || "edit",
            invitation: true,
            display_name: display_name // pass display name to show in email
        };
        if (props.context_for == "collaborate_qa") {
            payload['action'] = 'insert_product';
            payload['product_id'] = product_id
        } else {
            payload['collaboration_id'] = collaboration_id;
            payload['action'] = 'insert';
            payload['scene_id'] = scene_id
        }
        setAccessUpdateLoader(true);
        console.log('Payload', payload)
        axios.post(ENVIRONMENT.COLLABORATE_ACCESS_CONTROLLER, payload)
            .then(res => {
                setAccessUpdateLoader(false)
                if (res && res.data.statusCode == 200) {
                    message.success('Invite sent to user successfully!');
                    getCollaborators();
                    setCollaborateAccessModal(false);
                }
                else {
                    if (res && res.data.statusCode == 400)
                        message.error(res.data.message);
                }
            })
    }

    function handleGroupAccessChange(group_id, access_level = 'edit') {
        setAccessUpdateLoader(true);
        // Keep track of group_id whose access is being updated
        let records = updatingAccessEmails;
        records[group_id] = true
        setUpdatingAccessEmails(records);

        let payload = {
            username: localStorage.getItem('username'),
            group_id: group_id,
            access_level: access_level,
            display_name: display_name // pass display name to show in email
        };

        if (props.context_for == "collaborate_qa") {
            payload['action'] = 'update_product';
            payload['product_id'] = product_id
        } else {
            payload['collaboration_id'] = collaboration_id;
            payload['action'] = 'update';
            payload['scene_id'] = scene_id
        }

        if (access_level == "restricted") {
            payload['turn_off_email'] = true
        }

        console.log('Payload', payload)
        axios.post(ENVIRONMENT.COLLABORATE_ACCESS_CONTROLLER, payload)
        .then(res => {
            setAccessUpdateLoader(false);
            updateGroupAccessValue(group_id, access_level)
            // Remove the group_id address when it's API completes
            let updated_emails = updatingAccessEmails.filter(item => item !== group_id);
            setUpdatingAccessEmails(updated_emails);
            message.success('Access Updated Successfully!');
        })
    }

    return (
        <Modal
            className="collaborate-access-modal"
            width={600}
            destroyOnClose={true}
            visible={collaborate_access_modal}
            closable={true}
            onCancel={() => setCollaborateAccessModal(false)}
            title={<span className="manrope f-16">Collaborators</span>}>
            <div>
                <span className="manrope f-14 mt-10">People who can view</span>
                <Collapse accordion={true} defaultActiveKey={['1']} >
                    <Panel header="Individuals" key="1">
                        <div className="collaborators-list custom-scroll">
                            {collaborators.map((item, index) => {
                                return (
                                    <div key={index} className="collaborators-list-item">
                                            <div className="collaborator-email-username">
                                                <span className="manrope f-14 w-700" style={{ display: 'block' }}>{item.display}</span>
                                                <span className="manrope f-12 w-400" style={{ display: 'block' }}>{item.email}</span>
                                            </div>
                                        <div span={8} className="display-flex j-e">
                                            {isCollaborationOwner ?
                                            // Allow collaboration owner to make co-owners

                                            (["owner"].includes(item.access_level)) ?
                                                <span className="manrope f-14 w-500 line-height-20 pd-r-13 display-flex a-c j-c">{CollaborateConstants.access_levels[item.access_level]}</span>
                                                :
                                                <span className="manrope f-14 w-500 line-height-20">
                                                    {updatingAccessEmails.includes(item.email) && accessUpdateLoader ? <LoadingOutlined /> : ''}
                                                    <Select
                                                        suffixIcon={<EditOutlined className={updatingAccessEmails.includes(item.email) && accessUpdateLoader ? "" : 'collaborators-access'} />}
                                                        className="collaborators-access"
                                                        dropdownMatchSelectWidth={false}
                                                        defaultValue={item.access_level == "restricted" ? "Invite" : item.access_level}
                                                        disabled={updatingAccessEmails.includes(item.email) && accessUpdateLoader ? <LoadingOutlined /> : ''}
                                                        onChange={(e) => handleAccessChange(item.email, e)}
                                                    >
                                                        <Option style={{fontSize: 12, fontStyle: 'Manrope'}} value='edit'>Editor</Option>
                                                        <Option style={{fontSize: 12, fontStyle: 'Manrope'}} value='co-owner'>Co-Owner</Option>
                                                        {item.access_level != 'restricted' ? <Option style={{fontSize: 12, fontStyle: 'Manrope'}} value='restricted'>Restricted</Option>: ''}
                                                    </Select>
                                                </span>
                                            :
                                            (CollaborateConstants.edit_access_levels.includes(item.access_level) ?
                                                <span className="manrope f-14 w-500 line-height-20 pd-r-13 display-flex a-c j-c">{CollaborateConstants.access_levels[item.access_level]}</span>
                                                :
                                                <span className="manrope f-14 w-500 line-height-20">
                                                    <Button ghost
                                                        className='action-icon-btn blue manrope f-14 blue hover-change'
                                                        disabled={updatingAccessEmails.includes(item.email) && accessUpdateLoader ? <LoadingOutlined /> : ''}
                                                        onClick={() => handleAccessChange(item.email)}>
                                                        {updatingAccessEmails.includes(item.email) && accessUpdateLoader ? <LoadingOutlined /> : ''}&nbsp;Invite</Button>
                                                </span>
                                            )
                                            }
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Panel>
                    <Panel header={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>
                        Groups 
                        <Tooltip  title={<span className="manrope f-12 white">Use groups to share items with multiple users at once.</span>}> <InfoCircleOutlined /> </ Tooltip></span>
                        <a onClick={(e) => {window.location.href = '/account-details?tab=group'; e.stopPropagation()}}> Manage Groups</a>
                        </div>} key="2">
                        <div className="collaborators-list custom-scroll mt-10" >
                            {customGroups.length > 0 ?
                                customGroups.map((item, index) => {
                                    return (
                                        <div key={index} className="collaborators-list-item">
                                            <div className="collaborator-email-username">
                                                <span className="manrope f-14 w-500" style={{ display: 'block' }}><img src={'/img/group_icon.svg'} /> {item.group_name}</span>
                                            </div>
                                            <div>
                                            {isCollaborationOwner ?
                                                // if it is owner, allow inviting groups as editor and co-owners
                                                <span className="manrope f-14 w-500 line-height-20">
                                                    {updatingAccessEmails[item.group_id] ? <LoadingOutlined /> : ''}
                                                    <Select
                                                        suffixIcon={<EditOutlined className={updatingAccessEmails.[item.group_id] && accessUpdateLoader ? "" : 'collaborators-access'} />}
                                                        className="collaborators-access"
                                                        dropdownMatchSelectWidth={false}
                                                        defaultValue={item.access_level == "restricted" ? "Invite" : item.access_level}
                                                        disabled={updatingAccessEmails[item.group_id] ? true : false}
                                                        onChange={(e) => handleGroupAccessChange(item.group_id, e)}
                                                    >
                                                        <Option style={{fontSize: 12, fontStyle: 'Manrope'}} value='edit'>Editor</Option>
                                                        <Option style={{fontSize: 12, fontStyle: 'Manrope'}} value='co-owner'>Co-Owner</Option>
                                                        {item.access_level != 'restricted' ? <Option style={{fontSize: 12, fontStyle: 'Manrope'}} value='restricted'>Restricted</Option>: ''}
                                                    </Select>
                                                </span>
                                            :
                                            // Else Case
                                            // User is not the owner, only show invite button which invites as editor.
                                            (CollaborateConstants.edit_access_levels.includes(item.access_level) ?
                                                <span className="manrope f-14 w-500 line-height-20 pd-r-13 pd-t-10 pd-b-10 display-flex a-c j-c">{CollaborateConstants.access_levels[item.access_level]}</span>
                                                :
                                                <span className="manrope f-14 w-500 line-height-20">
                                                    <Button ghost
                                                        className='action-icon-btn blue manrope f-14 blue hover-change'
                                                        disabled={updatingAccessEmails[item.group_id] ? true : false}
                                                        onClick={() => handleGroupAccessChange(item.group_id)}>
                                                        {updatingAccessEmails[item.group_id] ? <LoadingOutlined /> : ''}&nbsp;Invite</Button>
                                                </span>
                                            )
                                            }   


                                            </div>
                                        </div>
                                    );
                                })
                            : 
                            <Empty
                                style={{ marginTop: 20, marginBottom: 20 }}
                                description={"No groups created yet."}
                                className="manrope f-14"
                            />
                            }
                        </div>
                    </Panel>

                </Collapse>
                <div className="mt-20">
                    <p className="manrope f-14 mt-10 mb-5">Invite</p>
                    <Form
                        ref={formRef}
                        onFinish={handleShareInvite}
                        initialValues={{
                            access_level: 'edit'
                        }}>
                        <Row gutter={8}>
                            <Col span={isCollaborationOwner ? 15: 20}>
                                <Form.Item
                                    name="email"
                                    rules={[{ type: 'email', message: 'Please enter a valid email.' },
                                    { required: true, message: 'Please enter an email.' },
                                    { pattern: "^[^\\s]+(\\s+[^\\s]+)*$", message: " Spaces are not allowed, whitespace found at the end." },
                                    ]}>
                                    <Input
                                        className='collab-access-form-fields'
                                        placeholder="Type email here" />
                                </Form.Item>
                            </Col>
                            {isCollaborationOwner ?
                            <Col span={5}>
                                <Form.Item
                                    name="access_level"
                                >
                                    <Select 
                                        className="collab-access-form-fields manrope f-12 mb-15" 
                                        options={CollaborateConstants.new_collaboration_access_values}
                                    >
                                    </Select>
                                </Form.Item> 
                            </Col>: ''}


                            <Col span={4}>
                                <Button htmlType="submit" disabled={accessUpdateLoader} ref={formRef} className="modal-okay square font-12" >
                                    Invite {accessUpdateLoader && updatingAccessEmails.length == 0 ? <LoadingOutlined /> : ''}
                                </Button>
                            </Col>

                        </Row>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

export default CollaborateAccess;
