import React from 'react';
import 'antd/dist/antd.css';
import ArtistMainLayout from '../ArtistMainLayout';
import { getProductRender } from "../../../../redux/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ProductStaticGuidelinesCard from '../ProductStaticGuidelines/ProductStaticGuidelinesCard';
import QuickRenders from '../QuickRendersComponent';
import {  DownloadOutlined, DownOutlined, EyeOutlined, InfoCircleOutlined, LoadingOutlined, TrophyFilled } from '@ant-design/icons';
import { Row, Col, Input, Button, Upload, Form, message,Collapse, Card,Image,Modal,List,Typography, TreeSelect, Tooltip } from 'antd';
import axios from 'axios';
import * as Constants from '../../CustomerComponents/Constants'
import ENVIRONMENT from '../../../../environments'
import * as JSZip from 'jszip';
import AWS from 'aws-sdk';
import ProductDetailsComponent from '../../ProductDetailsComponent';
import NotAuthorized from '../NotAuthorized'
import Checkbox from 'antd/lib/checkbox/Checkbox';
import {  MATERIAL_GET, PRODUCT_COMPONENT_GET, PRODUCT_COMPONENT_GET_BATCH, PRODUCT_GUIDELINES, BUCKET_NAME, LOW_POLY_MAX, PRODUCT_QA_COMB_URI, IMAGE_DIMENSIONAL_URI, QA_IMG_URI } from '../../../../environments/env';
import DottedLoader from '../../DottedLoader';
import $ from 'jquery';
import PreprocessingImages from '../PreprocessingImages/PreprocessingImages'
import ModelOBJViewer from '../../CustomerComponents/OBJViewer/OBJViewer';
import FileConstants from '../../../../FileConstants';
import WarningModal from '../../WarningModal/WarningModal';
import ProductStaticGuidelines from '../ProductStaticGuidelines/ProductStaticGuidelines';
import VariationBaseModelDetails from '../VariationBaseModelDetails';
import * as Utilities from '../../Utilities';
import { max } from 'rxjs/operators';
const { Dragger } = Upload;
const { Panel } = Collapse;

const scans_base_url = ENVIRONMENT.SCANS_BASE_URL;
const retry_count = 5;

const availableModelLabels = {
  "high_res": "High Poly",
  "ar": "Low Poly",
  "high_res_and_ar": "High Poly and Low Poly Bundles"
}
const Validation_threshold = 0.9;

const allRequiredAssets = ['High Poly Geometry',
    'Low Poly Geometry',
    'Vray Materials',
    'PBR Materials',
];

const allPBRRequiredAssets = ['High Poly Geometry',
    'Low Poly Geometry',
    'PBR Materials',
];

const highRequiredAssets = ['High Poly Geometry',
  'Vray Materials',
];

const highPBRRequiredAssets = ['High Poly Geometry',
  'PBR Materials'
]

const lowRequiredAssets = ['Low Poly Geometry',
'PBR Materials',
];

// This probabaly should be defined in some constants class? as its defined at more than one places
const treeSelectStyle = {
  maxHeight: 400,
  overflow: 'auto',
  fontFamily: 'Manrope',
  fontSize: 12,
  color: "#555555"
}

let folderStructure = {
  'vray_exist' : false,
  'high_exist' : false,
  'low_exist' : false,
  'pbr_exist' : false,
  'high_pbr_exist' : false,
  'max_high_id' : false,
  'max_low_id' : false,
  'id' : 0
}

function getModelLabel(val){
  if (availableModelLabels[val] != undefined){
      return availableModelLabels[val];
  } else {
      return val;
  }
}

class ArtistProductGuidelines extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      product_id: 0,
      modelFileList: [],
      upload_error: "",
      rejection_details: "",
      reference_files: [],
      time1: "",
      time2: "",
      time3: "",
      time4: "",
      time5: "",
      required_assets: [],
      is_configurable: false,
      request_submitted: false,
      input_fields_rules: {
        'time1': { required: true, message: 'This field is mandatory' },
        'time2': { required: true, message: 'This field is mandatory' },
        'time3': { required: true, message: 'This field is mandatory' },
        'time4': { required: true, message: 'This field is mandatory' },
        'time5': { required: true, message: 'This field is mandatory' },
        'fix_time': { required: true, message: 'This field is mandatory' },
      },
      input_fields_disabled: {
        'time1': false,
        'time2': false,
        'time3': false,
        'time4': false,
        'time5': false,
        'fix_time': false
      },
      product_guidelines: {
        modeling_required: '',
        reference_urls: [],
        product_photos: {
        },
        product_manuals: [],
        raw_files: [],
        model_files: [],
        materials: [],
        model_type: '',
        need_to_model: '',
        components: [
            {
                componentName: '',
                componentImages: []
            }
        ],
        guidelines: "",
        scans: [],
        material_files: [],
        model_files: [],
        color_extracted_images: {},
        admin_text_guidelines: ""
      },
      status: 2,
      dimension_validation: 'Invalid',
      open_input_model: false,
      success_modal: false,
      reupload_flag: false,
      inside_interval: false,
      file_info: '',
      confirmation_modal: false,
      rejections_data: [],
      req_btn_loader: false,
      call_times: false,
      validation_report: {},
      quick_renders: false,
      loading_state: true,
      automatically_fixed_high: [],
      automatically_fixed_low: [],
      successfully_validated_high: [],
      successfully_validated_low: [],
      invalid_data_high: [],
      invalid_data_low: [],
      warning_data_high: [],
      success_val_length: 0,
      invalid_val_length: 0,
      auto_val_length: 0,
      product_valid: true,
      validation_generated: false,
      version_valid: true,
      model_height: '',
      model_width: '',
      model_depth: '',
      rejected_upload_modal: false,
      rig_file: {},
      uploading: false,
      hasError: false,
      upload_failed_message: '',
      comp_mat_arr: [],
      comp_arr: [],
      image_visible: [],
      selected_materials: [],
      nominable_materials: [],
      pre_nominated_materials: [],
      nominated_materials: [],
      download_link: false,
      action_not_allowed_modal: false,
      approved_group_products: [],
      materialOutputType: '',
      platform: 'aws',
      artist_modelling_requirements : {},
      base_platform : 'aws',
      base_platform_loader : true,
      feedback_images: [],
      is_ar_variation: false,
    }
  }

  triggerUpload = () => {
    $('#upload-product').trigger('click');
    let button = document.getElementById('upload-product');
    button.disabled = false;
  }

  getProductQAComparisonImages = () => {
    // this function will get you the images that the customer has added feedback on
    const payload = {
      "required_fields": ['id', 'combination_image', 'preview_image', 'platform',
        'reference_image', 'state', 'annotation_count', 'last_modified', 'last_modified_by'],
      "filter_string": "(product_id__exact=" + this.props.match.params.id + ")",
      "order_by": "id desc"
    }
    axios.post(ENVIRONMENT.COLLABORATE_QA_IMAGERY, payload)
      .then(res => {
        console.log(res.data);
        let image_list = [];

        res.data.map((img) => {
          let low_url = ENVIRONMENT.getBaseURL(img.platform) + PRODUCT_QA_COMB_URI + this.props.match.params.id + '/' + img.combination_image;
          let url = ENVIRONMENT.getBaseURL(img.platform) + PRODUCT_QA_COMB_URI + this.props.match.params.id + '/' + img.combination_image;

          let type = "comb_image";
          if (img.preview_image && !img.combination_image && !img.reference_image) {
            type = "low_res_preview";
            url = ENVIRONMENT.getBaseURL(img.platform) + QA_IMG_URI + img.preview_image;
            low_url = url.replace('/perspective_renders/', '/low_res_perspective_renders/').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(img.platform));
            if (img.preview_image.includes("_Dimensions")) {
              url = ENVIRONMENT.getBaseURL(img.platform) + IMAGE_DIMENSIONAL_URI + img.preview_image;
              low_url = url.replace('/dimensional_renders/', '/low_res_dimensional_renders/').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(img.platform));
            }
          } else if (img.reference_image && !img.preview_image && !img.combination_image) {
            type = "reference_image";
            url = ENVIRONMENT.getBaseURL(img.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(img.reference_image);
            low_url = ENVIRONMENT.getBaseURL(img.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(img.reference_image);
          }
          if (img.state && (Object.entries(img.state).length !== 0 || img.state.length > 0)
            && img.state.data && img.state.data.objects && img.state.data.objects.length > 0) {
            image_list.push({
              "id": img.id,
              "url": url,
              "low_url": low_url,
              "type": type,
              "main_type": type,
              "status": "rendered",
              "state": img.state,
              "annotation_count": img.annotation_count
            });
          }
        })

        this.setState({
          feedback_images: image_list
        })
      });
  }

  setRequiredAsset = (requiredAssets) => {

    let input_fields_rules = {
      'time1': { required: true, message: 'This field is mandatory' },
      'time2': { required: true, message: 'This field is mandatory' },
      'time3': { required: this.state.is_configurable ? false : true, message: 'This field is mandatory' },
      'time4': { required: true, message: 'This field is mandatory' },
      'time5': { required: true, message: 'This field is mandatory' },
      'fix_time': { required: true, message: 'This field is mandatory' },
    };
    let input_fields_disabled = {
      'time1': false,
      'time2': false,
      'time3': false,
      'time4': false,
      'time5': false,
      'fix_time': false
    };

    if (!requiredAssets.includes('High Poly Geometry')) {
      input_fields_rules['time1'] = {};
      input_fields_disabled['time1'] = true;
      input_fields_rules['time3'] = {};
      input_fields_disabled['time3'] = true;
    }
    if (!requiredAssets.includes('Low Poly Geometry')) {
      input_fields_rules['time2'] = {};
      input_fields_disabled['time2'] = true;
      input_fields_rules['time4'] = {};
      input_fields_disabled['time4'] = true;
    }
    if (!requiredAssets.includes('Animations')) {
      input_fields_rules['time5'] = {};
      input_fields_disabled['time5'] = true;
    }

    this.setState({
      required_assets: requiredAssets,
      input_fields_rules: input_fields_rules,
      input_fields_disabled: input_fields_disabled
    });
  }

  disableFields = () => {
    if ((this.state.rejections_data != undefined && this.state.rejections_data.length != 0) || (typeof (this.state.rejections_data) == "object" && Object.keys(this.state.rejections_data).length != 0)) {
      let input_fields_rules = {
        'time1': { required: false, message: 'This field is mandatory' },
        'time2': { required: false, message: 'This field is mandatory' },
        'time3': { required: false, message: 'This field is mandatory' },
        'time4': { required: false, message: 'This field is mandatory' },
        'time5': { required: false, message: 'This field is mandatory' },
        'fix_time': { required: true, message: 'This field is mandatory' },
      };
      let input_fields_disabled = {
        'time1': true,
        'time2': true,
        'time3': true,
        'time4': true,
        'time5': true,
        'fix_time': false
      };
      this.setState({
        input_fields_disabled: input_fields_disabled,
        input_fields_rules: input_fields_rules,
      });
    }
  }

  checkFolderStructure = (file) => {
    let return_check = true;
    let parent = this;

    let new_zip = new JSZip();
    return new_zip.loadAsync(file)
    .then((zip) => {
      for(let key in zip['files']){
        console.log(key)
        if (key.includes('/High') && !key.includes("_MACOSX/High")) {
          parent.setState({
            upload_error: "Incorrect folder structure! Correct your folder structure and reupload file.",
          });
          return_check = false;
        } else if (key.includes('high/')){
          parent.setState({
            upload_error: "Incorrect naming structure! Correct your folder naming and reupload file.",
          });
          return_check = false;
        } else if (key.includes('High/')){
          folderStructure['high_exist'] = true;
          let max_id = key.split('/')[1];
          if (max_id === (parent.props.match.params.id + '.max')) {
            folderStructure['max_high_id'] = true;
          }
        }

        if ( folderStructure['substance_painter_file'] != undefined && key.includes('.spp') && ( key.includes('HighPBRTextures') || key.includes('PBRTextures') || key.includes('VrayTextures') ) ) {
          folderStructure['substance_painter_file'] = true;
        }

        if (key.includes('VrayTextures')){
          folderStructure['vray_exist'] = true;
        }

        if (key.includes('HighPBRTextures')){
          folderStructure['high_pbr_exist'] = true;
        }

        if (key.includes('/Low') && !key.includes("_MACOSX/Low")) {
          parent.setState({
            upload_error: "Incorrect folder structure! Correct your folder structure and reupload file.",
          });
          return_check = false;

        } else if (key.includes('low/')){
          parent.setState({
            upload_error: "Incorrect naming structure! Correct your folder naming and reupload file.",
          });
          return_check = false;
        } else if (key.includes('Low/')){
          folderStructure['low_exist'] = true;
          let max_id = key.split('/')[1];
          if (max_id == (parent.props.match.params.id + '.max')) {
            folderStructure['max_low_id'] = true;
          }
        }
        if (key.includes('PBRTextures')){
          folderStructure['pbr_exist'] = true;
        }

        if ((key.includes('Low/') && key.includes('PBRTextures/')) || (key.includes('High/') && key.includes('VrayTextures/'))) {
          parent.setState({
            upload_error: "Folder hierarchy is not correct. Correct your folder structure and reupload file.",
          });
          return_check = false;

        }
      }

      folderStructure['id'] = parent.props.match.params.id

      let upload_error_message = ""
      upload_error_message =  Utilities.checkMaxBundleFolderStructure(this.state.product_guidelines.need_to_model,folderStructure )
      if (upload_error_message != ""){
        return_check = false
        parent.setState({
          upload_error : upload_error_message
        });
      }

      if (!return_check) {
        console.log('returning false')
        return false;
      } else {
        console.log('returning true')
        return true;
      }
    });
  }

  changeVisibility = (visible) => {
    console.log(visible);
  }

  toggleVisible = (component) => {
    let arr = this.state.image_visible;
    let flag = false;
    if (arr[component]) {
      flag = false;
    } else {
      flag = false;
    }

    arr[component] = flag;

    this.setState({
      image_visible: arr
    });
  }

  // Value update callback for nominate materials select view
  setNominatedMaterials = (selectedMaterials) => {
    this.setState({
      nominated_materials: selectedMaterials
    })
  }

  // Set required material output types for current product request
  setMaterialOutputType = (materialType) => {
    this.setState({
      materialOutputType: materialType
    });
  }

  setIsArVariration = (value) => {
    this.setState({
      is_ar_variation: value
    })
  }

  // Find the missing required materials needed to make material variation
  // for example to identify if a material is vray only or pbr only
  findMissingMaterialTypeFromSelection = (currentModelMaterialType)=> {
    let allMaterialTypes = FileConstants.MATERIAL_TYPES;
    let allMissingMtls = ''
    for (let materialType in allMaterialTypes) {
      if (!currentModelMaterialType.includes(allMaterialTypes[materialType])) {
        if (!allMissingMtls) {
          allMissingMtls = FileConstants.MATERIAL_TYPE_LABELS[allMaterialTypes[materialType]] + " Materials"
        }
        else {
          allMissingMtls += ' and ' + FileConstants.MATERIAL_TYPE_LABELS[allMaterialTypes[materialType]] + " Materials"
        }
      }
    }
    return allMissingMtls
  }

  fetchGroupItems = (product_id, group_id) => {
    let payload = {
      group_ids: [group_id]
    }
    let group_products = []
    axios.post(ENVIRONMENT.GROUP_IDS_GET_BATCH, payload)
    .then(res => {
      if(res.data){
        res.data.map((item) => {
          if(item.product_id != product_id && parseInt(item.model_status) == 5){
            group_products.push(item)
          }
        })
        this.setState({
          approved_group_products: group_products
        })
      }
    })
  }

  componentDidMount() {
    this.setState({ product_id: this.props.match.params.id });
    this.getProductQAComparisonImages();
    axios.post(ENVIRONMENT.RENDER_PRODUCT, {
      product_id: this.props.match.params.id,
    })
      .then(res => {
        this.setState({
          rejection_details: res.data["rejection_details"],
          reference_files: res.data["reference_files"],
          time1: res.data["time1"],
          time2: res.data["time2"],
          time3: res.data["time3"],
          time4: res.data["time4"],
          time5: res.data["time5"],
        })
        console.log(res.data)
        axios.post(ENVIRONMENT.GET_PRODUCT_LIGHTING_RIG, { product_id:this.props.match.params.id })
            .then(res => {
              console.log(res.data)
                this.setState({
                    rig_file: res.data
                });
            }).catch (err => {
              console.log(err)
            })
      });
      axios.post(ENVIRONMENT.GET_PRODUCT_REJECTIONS, { product_id:this.props.match.params.id })
      .then(res => {
          this.setState({
              rejections_data: res.data
          }, () => {
            if (this.state.rejections_data != undefined && this.state.rejections_data.length != 0 || typeof (this.state.rejections_data) == "object" && Object.keys(this.state.rejections_data).length != 0) {
              this.disableFields();
            }
          });
      });

      axios.post(ENVIRONMENT.FETCH_PRODUCT, {
        product_id: this.props.match.params.id,
      })
      .then(res => {
          console.log(res.data)
          if (res.data.variation_type == "ar_conversion") {
            this.setIsArVariration(true)
          }
          let low_poly_url = ENVIRONMENT.getBaseURL(res.data['platform']) + ENVIRONMENT.LOW_POLY_MAX_URI +this.props.match.params.id+'.zip'
            console.log(low_poly_url);
            axios.head(low_poly_url,{})
            .then(res=> {
            console.log(res)
            this.setState({
                download_link: true
            });
            })
            .catch(err => {
            console.log(err)
            })

          if(res.data.group_id){
            // fetch other grouped items
            this.fetchGroupItems(this.props.match.params.id, res.data.group_id);
          }
          let is_configurable = res.data['is_configurable'];
          this.setState({
            is_configurable: is_configurable,
            platform: res.data['platform']
          });
          if (res.data['artist_modelling_requirements']){
            let artist_modelling_requirements = JSON.parse(res.data['artist_modelling_requirements'])
            this.setState({
              is_configurable: is_configurable,
              artist_modelling_requirements : artist_modelling_requirements['artist_modelling_requirements']
            }, () => {
              if ( this.state.artist_modelling_requirements != {} && this.state.artist_modelling_requirements['substance_painter_file'] == true) {
                folderStructure['substance_painter_file'] = false
              }
            });
          }
          let payload = {
            product_id: this.props.match.params.id
          };
          axios.post(ENVIRONMENT.PRODUCT_MATERIAL_GET_BATCH,payload)
          .then(res => {
              let response = res.data;
              if (response) {
                  response = response.body;
                  this.setState({
                    selected_materials: response
                  });
                  console.log('Materials',response)

              }
          });
          this.setState({ product_guidelines: res.data },() => {
            let quick_renders_val = false;
            let reupload_flag_val = false;
            if (this.state.product_guidelines.quick_renders == undefined  || this.state.product_guidelines.quick_renders == null) {
              quick_renders_val = false;
              reupload_flag_val = true;
            }
            else {
              if (this.state.product_guidelines.quick_renders.length == 0) {
                quick_renders_val = false;
                reupload_flag_val = true;
              } else {
                quick_renders_val = true;
                reupload_flag_val = false;
              }
            }
            if (this.state.product_guidelines.artist_model_requested) {
              reupload_flag_val = false;
            }
            this.setState({
              quick_renders: quick_renders_val,
              reupload_flag: reupload_flag_val
            });

            this.setState({
              validation_report: res.data['validation_report']
            }, () => {
              this.setValidationData();

            });
            console.log("Product Guidelines", res.data);
              let product_info = res.data;
              console.log("Validation Report", this.state.validation_report);

              let temp_arr = [];
              axios.post(PRODUCT_COMPONENT_GET_BATCH,{
                product_id: this.props.match.params.id,
              }).then(res => {

                let component_info = JSON.parse(res.data.body);
                this.setState({
                  comp_arr: component_info
                });

                console.log(component_info, 'Component Info');
                console.log(is_configurable)
                if (!is_configurable) {

                  component_info.map((component, index) => {

                    if (component.using_material_library == "1") {
                      let payload_comp_get = {
                        product_id: this.props.match.params.id,
                        component_id: component.id
                      }
                      console.log(payload_comp_get);
                      axios.post(PRODUCT_COMPONENT_GET,payload_comp_get).then(res => {

                        let material_info = JSON.parse(res.data.body);
                        console.log('Material Info', material_info);
                        let payload = {};
                        if (material_info[0]) {
                          payload = {
                            material_id: material_info[0]['material_id']
                          };
                          console.log(payload, 'Material ID');

                          axios.post(MATERIAL_GET, payload)
                          .then(res => {

                            let material = JSON.parse(res.data.body);
                            console.log(material, 'material')
                            temp_arr[component.name] = material;

                            this.setState({
                              comp_mat_arr: temp_arr
                            });
                            console.log(temp_arr, '1')
                          });
                        }
                      });
                    } else if (component.using_material_library == "0") {
                      product_info.components && product_info.components.map((component_obj) => {
                        if (component_obj.componentName == component.name) {
                          temp_arr[component.name] = component_obj;
                        }
                      });
                      this.setState({
                        comp_mat_arr: temp_arr
                      }, () => {
                        console.log(this.state.comp_mat_arr,'0')
                      });
                    }
                  });
                }
              });

          });
          let model_use_cases = res.data.need_to_model;
          let user_model_type = res.data.model_type;

          if (user_model_type == "" && model_use_cases == "high_res") {
            this.setMaterialOutputType(FileConstants.MATERIAL_OUTPUT_FOR_MODEL["high_res"])
            this.setRequiredAsset(FileConstants.highRequiredAssets);
          }
          if (user_model_type == "" && model_use_cases == "high_pbr") {
            this.setMaterialOutputType(FileConstants.MATERIAL_OUTPUT_FOR_MODEL["high_pbr"])
            this.setRequiredAsset(FileConstants.highPBRRequiredAssets);
          }
          if (user_model_type == "" && model_use_cases == "ar"){
            this.setMaterialOutputType(FileConstants.MATERIAL_OUTPUT_FOR_MODEL["ar"])
            this.setRequiredAsset(FileConstants.lowRequiredAssets);
          }
          if (user_model_type == "" && model_use_cases == "high_res_and_ar"){
            this.setMaterialOutputType(FileConstants.MATERIAL_OUTPUT_FOR_MODEL["high_res_and_ar"])
            this.setRequiredAsset(FileConstants.allRequiredAssets);
          }
          if (user_model_type == "" && model_use_cases == "high_pbr_and_ar"){
            this.setMaterialOutputType(FileConstants.MATERIAL_OUTPUT_FOR_MODEL["high_pbr_and_ar"])
            this.setRequiredAsset(FileConstants.allPBRRequiredAssets);
          }
          if (user_model_type == "high_res" && model_use_cases == "ar"){
            this.setMaterialOutputType(FileConstants.MATERIAL_OUTPUT_FOR_MODEL["high_res_and_ar"])
            this.setRequiredAsset(FileConstants.allRequiredAssets);
          }
          if (user_model_type == "high_pbr" && model_use_cases == "ar"){
            this.setMaterialOutputType(FileConstants.MATERIAL_OUTPUT_FOR_MODEL["high_pbr_and_ar"])
            this.setRequiredAsset(FileConstants.allPBRRequiredAssets);
          }
          if (user_model_type == "ar" && model_use_cases == "high_res"){
            this.setMaterialOutputType(FileConstants.MATERIAL_OUTPUT_FOR_MODEL["high_res_and_ar"])
            this.setRequiredAsset(FileConstants.allRequiredAssets);
          }
          if (user_model_type == "ar" && model_use_cases == "high_pbr"){
            this.setMaterialOutputType(FileConstants.MATERIAL_OUTPUT_FOR_MODEL["high_pbr_and_ar"])
            this.setRequiredAsset(FileConstants.allPBRRequiredAssets);
          }
          if (user_model_type == "ar" && model_use_cases == ""){
            this.setMaterialOutputType(FileConstants.MATERIAL_OUTPUT_FOR_MODEL["ar"])
            this.setRequiredAsset(FileConstants.lowRequiredAssets);

          }
          if (user_model_type == "high_res" && model_use_cases == ""){
            this.setMaterialOutputType(FileConstants.MATERIAL_OUTPUT_FOR_MODEL["high_res"])
            this.setRequiredAsset(FileConstants.highRequiredAssets);
          }
          if (user_model_type == "high_pbr" && model_use_cases == ""){
            this.setMaterialOutputType(FileConstants.MATERIAL_OUTPUT_FOR_MODEL["high_pbr"])
            this.setRequiredAsset(FileConstants.highRequiredAssets);
          }
          if (this.state.product_guidelines.scans && this.state.product_guidelines.scans.length > 0) {
            this.setMaterialOutputType(FileConstants.MATERIAL_OUTPUT_FOR_MODEL["ar"])
            this.setRequiredAsset(FileConstants.lowRequiredAssets);
          }
          this.disableFields();

          let product_id = null;
            if(res.data.immediate_parent_variant)
                product_id = this.state.product_guidelines.immediate_parent_variant;
            else if(res.data.variant_of)
                product_id = this.state.product_guidelines.variant_of;
            
            if(product_id != null){
            axios.post(ENVIRONMENT.FETCH_PRODUCT, {product_id})
                .then(res => {
                    this.setState({
                        base_platform: res.data.platform,
                        base_platform_loader: false
                    },() => {
    
                    });
                })
            }

      });

      // Load product material nomination data
      axios.post(ENVIRONMENT.PRODUCT_MATERIAL_EXPORT_INFO_GET_BATCH, {
        product_id: this.props.match.params.id,
      }).then(res => {
        console.log("product material export data: ", res.data.body)
        let productMaterialExportInfo = res.data.body;
        let preSelectedTreeData = [];
        let treeData = [];
        for (let i = 0; i < (productMaterialExportInfo).length; i++) {
          let treeItem = {
            key: productMaterialExportInfo[i]['material_name'],
            title: productMaterialExportInfo[i]['material_name'],
            value: productMaterialExportInfo[i]['material_name'],
            children: []
          }
          if(!productMaterialExportInfo[i]['artist_selected'] && !productMaterialExportInfo[i]['admin_selected']) {
            treeData.push(treeItem);
          }
          else {
            treeItem['disabled'] = true;
            treeItem['selectable'] = true;
            preSelectedTreeData.push(treeItem);
          }
        }

        this.setState({
          nominable_materials: treeData,
          pre_nominated_materials: preSelectedTreeData
        });
      });

      this.props.getProduct({ product_id: this.props.match.params.id, debug: 1 });
      window.setInterval(this.onSetTimeIntervalPassed, 1000, this);
      window.setInterval(this.onTwoMinuteInterval, 60*3000, this);


  }

  componentDidUpdate(prevProps) {
    if (prevProps!= this.props) {
      this.setDimensionsTable();
    }
  }

  onSetTimeIntervalPassed = () => {
    let uploadStatus = this.validateFileUploads(this.state.modelFileList);
    if (uploadStatus == 'error') {
      if (this.state.file_info) {

          if (this.state.file_info.file.error.code.includes('Credentials')) {
            this.setState({
              upload_failed_message: this.state.file_info.file.error.code.concat('. Your session has expired. Please reload the page.')
            });
          }  else if (this.state.file_info.file.error.code.includes('Network')) {
            this.setState({
              upload_failed_message: this.state.file_info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.')
            });
          } else {
            this.setState({
              upload_failed_message: this.state.file_info.file.error.code.concat('   ' + this.state.file_info.file.error.message)
            });
          }


      } else {

          this.setState({
            upload_failed_message: 'Error occured in uploading bundle, please re-upload your model bundle.'
          });


      }
    }
    else if (uploadStatus == 'done' && this.state.inside_interval == false && this.props.productReducer.product.model_status != -4) {
      this.setState({
        inside_interval: true
      }, () => {
        this.handleChange(this.state.file_info);
      });
    }
    if ((this.state.status == 6 || this.state.status == 8 || this.state.status == 11)) {
      this.setState({
        status: 4,
        loading_state: true
      });
      axios.post(ENVIRONMENT.FETCH_PRODUCT, {
        product_id: this.props.match.params.id,
      })
      .then(res => {
          this.setState({ product_guidelines: res.data },() => {
            let quick_renders_val = false;
            let reupload_flag_val = false;
            if (this.state.product_guidelines.quick_renders == undefined  || this.state.product_guidelines.quick_renders == null) {
              quick_renders_val = false;
              reupload_flag_val = true;
            }
            else {
              if (this.state.product_guidelines.quick_renders.length == 0) {
                quick_renders_val = false;
                reupload_flag_val = true;
              } else {
                quick_renders_val = true;
                reupload_flag_val = false;
              }
            }
            if (this.state.product_guidelines.artist_model_requested) {
              reupload_flag_val = false;
            }
            this.setState({
              quick_renders: quick_renders_val,
              reupload_flag: reupload_flag_val
            }, () => {
              this.setState({
                loading_state: false
              });
            });
            this.setState({
              validation_report: res.data['validation_report']
            },() => {
              this.setValidationData();

            });
          });
      });
      this.props.getProduct({ product_id: this.props.match.params.id, debug: 1 });
      this.forceUpdate();
    }
  }

  onTwoMinuteInterval = () => {
    if (this.props.productReducer.product.model_status in [6, 11]) {
      this.setState({
        loading_state: true
      });
      axios.post(ENVIRONMENT.FETCH_PRODUCT, {
        product_id: this.props.match.params.id,
      })
      .then(res => {
          this.setState({ product_guidelines: res.data,
          upload_error: "",
          upload_failed_message: "",
          call_times: false,
          modelFileList: [],
          inside_interval: false
        },() => {
            let quick_renders_val = false;
            let reupload_flag_val = false;
            if (this.state.product_guidelines.quick_renders == undefined  || this.state.product_guidelines.quick_renders == null) {
              quick_renders_val = false;
              reupload_flag_val = true;
            }
            else {
              if (this.state.product_guidelines.quick_renders.length == 0) {
                quick_renders_val = false;
                reupload_flag_val = true;
              } else {
                quick_renders_val = true;
                reupload_flag_val = false;
              }
            }
            if (this.state.product_guidelines.artist_model_requested) {
              reupload_flag_val = false;
            }
            this.setState({
              quick_renders: quick_renders_val,
              reupload_flag: reupload_flag_val
            }, () => {
              this.setState({
                loading_state: false
              });
            });
            this.setState({
              validation_report: res.data['validation_report']
            }, () => {
              this.setValidationData();
            });
          });
      });
      this.props.getProduct({ product_id: this.props.match.params.id,debug: 1 });
      this.forceUpdate();
    }
  }

  setValidationData = () => {

    let quick_renders_val = this.state.quick_renders;
    let reupload_flag_val = this.state.reupload_flag;
    if (this.state.product_guidelines.quick_renders == undefined  || this.state.product_guidelines.quick_renders == null) {
      quick_renders_val = false;
      reupload_flag_val = true;
    }
    else {
      if (this.state.product_guidelines.quick_renders.length == 0) {
        quick_renders_val = false;
        reupload_flag_val = true;
      }
    }
    if (this.state.product_guidelines.artist_model_requested) {
      reupload_flag_val = false;
    }
    this.setState({
      reupload_flag: reupload_flag_val,
      quick_renders: quick_renders_val
    });

    let automatically_fixed_high = [];
    let automatically_fixed_low = []
    let successfully_validated_high = [];
    let successfully_validated_low = [];
    let invalid_data_high = [];
    let invalid_data_low = [];
    let warning_data_high = [];
    let success_val_length = 0;
    let invalid_val_length = 0;
    let auto_val_length = 0;

    if (this.state.validation_report != undefined) {
      if (this.state.validation_report['High'] != undefined && Object.keys(this.state.validation_report['High']).length != 0) {
        if (this.state.validation_report['High']['hierarchy']) {
          if (!this.state.validation_report['High']['hierarchy'].valid && this.state.validation_report['High']['hierarchy'].fixed) {
            automatically_fixed_high.push('Heirarchy');
          }

          if (this.state.validation_report['High']['hierarchy'].valid && !this.state.validation_report['High']['hierarchy'].fixed) {
            successfully_validated_high.push('Heirarchy');
          }

          if (!this.state.validation_report['High']['hierarchy'].valid && !this.state.validation_report['High']['hierarchy'].fixed) {
            invalid_data_high.push(<span>Heirarchy (Refer to Section 6 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
          }
        }

        if (this.state.validation_report['High']['pivot']) {
          if (!this.state.validation_report['High']['pivot'].valid && this.state.validation_report['High']['pivot'].fixed){
            automatically_fixed_high.push('Pivot');
          }

          if (this.state.validation_report['High']['pivot'].valid && !this.state.validation_report['High']['pivot'].valid.fixed){
            successfully_validated_high.push('Pivot');
          }

          if (!this.state.validation_report['High']['pivot'].valid && !this.state.validation_report['High']['pivot'].fixed){
            invalid_data_high.push(<span>Pivot (Refer to Section 6 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
          }
        }

        if (this.state.validation_report['High']['transform']) {
          if (!this.state.validation_report['High']['transform']['position'].valid && this.state.validation_report['High']['transform']['position'].fixed) {
            automatically_fixed_high.push('Transform (Position)');
          }
          if (!this.state.validation_report['High']['transform']['rotation'].valid && this.state.validation_report['High']['transform']['rotation'].fixed) {
            automatically_fixed_high.push('Transform (Rotation)');
          }
          if (!this.state.validation_report['High']['transform']['scale'].valid && this.state.validation_report['High']['transform']['scale'].fixed) {
            automatically_fixed_high.push('Transform (Scale)');
          }

          if (this.state.validation_report['High']['transform']['position'].valid && !this.state.validation_report['High']['transform']['position'].fixed) {
            successfully_validated_high.push('Transform (Position)');
          }
          if (this.state.validation_report['High']['transform']['rotation'].valid && !this.state.validation_report['High']['transform']['rotation'].fixed) {
            successfully_validated_high.push('Transform (Rotation)');
          }
          if (this.state.validation_report['High']['transform']['scale'].valid && !this.state.validation_report['High']['transform']['scale'].fixed) {
            successfully_validated_high.push('Transform (Scale)');
          }

          if (!this.state.validation_report['High']['transform']['position'].valid && !this.state.validation_report['High']['transform']['position'].fixed) {
            invalid_data_high.push(<span>Transform (Position) (Refer to Section 8 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
          }
          if (!this.state.validation_report['High']['transform']['rotation'].valid && !this.state.validation_report['High']['transform']['rotation'].fixed) {
            invalid_data_high.push(<span>Transform (Rotation) (Refer to Section 8 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
          }
          if (!this.state.validation_report['High']['transform']['scale'].valid && !this.state.validation_report['High']['transform']['scale'].fixed) {
            invalid_data_high.push(<span>Transform (Scale) (Refer to Section 8 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
          }

        }

        if (this.state.validation_report['High'].vertex_count) {
          successfully_validated_high.push(`Vertex Count is ${this.state.validation_report['High'].vertex_count}`);
        }

        if (!this.state.is_configurable) {
          if (this.state.validation_report['High']['vray_materials'] != undefined) {
            this.state.validation_report['High']['vray_materials'].map((material) => {
              if (!material.valid && !this.state.validation_report['High']['product_valid']) {
                invalid_data_high.push(<span>{material.name} is not a valid VRay Material. (Refer to Section 7 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
              else if (!material.valid && this.state.validation_report['High']['product_valid']) {
                warning_data_high.push(<span>{material.name} is not following the standards for Vray Material. (Refer to Section 7 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            })
          }

          if (this.state.validation_report['High']['materials'] != undefined) {
            this.state.validation_report['High']['materials'].map((material) => {
              if (!material.valid) {
                invalid_data_high.push(<span>{material.name} is not a valid {material.class}. (Refer to Section 7 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            })
          }

          if (this.state.validation_report['High']['vray_textures'] != undefined) {
            this.state.validation_report['High']['vray_textures'].map((texture) => {
              if (!texture.found) {
                invalid_data_high.push(<span>{texture.name} was not found in VRay Textures. (Refer to Section 7 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            })
          }
          if (this.state.validation_report['High']['pbr_textures'] != undefined) {
            this.state.validation_report['High']['pbr_textures'].map((texture) => {
              if (!texture.found) {
                invalid_data_high.push(<span>{texture.name} was not found in High PBR Textures. (Refer to Section 7 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            })
          }
        }
        else if (this.state.is_configurable) {
          if (this.state.validation_report['High']['configurable'] != undefined) {
            if (this.state.validation_report['High']['configurable'].valid) {
              successfully_validated_high.push(`Configuration`);
            } else {
              if (this.state.validation_report['High']['configurable'].missing_components) {
                this.state.validation_report['High']['configurable'].missing_components.map(comp => {
                  invalid_data_high.push(`Mesh does not exist for ${comp.name}`);
                })
              }
            }
          }
        }

      }

      if (this.state.validation_report['Low'] != undefined && Object.keys(this.state.validation_report['Low']).length != 0) {
        if (this.state.validation_report['Low']['hierarchy']) {

          if (!this.state.validation_report['Low']['hierarchy'].valid && this.state.validation_report['Low']['hierarchy'].fixed) {
            automatically_fixed_low.push('Heirarchy');
          }

          if (this.state.validation_report['Low']['hierarchy'].valid && !this.state.validation_report['Low']['hierarchy'].fixed) {
            successfully_validated_low.push('Heirarchy');
          }

          if (!this.state.validation_report['Low']['hierarchy'].valid && !this.state.validation_report['Low']['hierarchy'].fixed) {
            invalid_data_low.push(<span>Heirarchy (Refer to Section 6 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
          }
        }

        if (this.state.validation_report['Low']['pivot']) {
          if (!this.state.validation_report['Low']['pivot'].valid && this.state.validation_report['Low']['pivot'].fixed){
            automatically_fixed_low.push('Pivot');
          }

          if (this.state.validation_report['Low']['pivot'].valid && !this.state.validation_report['Low']['pivot'].valid.fixed){
            successfully_validated_low.push('Pivot');
          }

          if (!this.state.validation_report['Low']['pivot'].valid && !this.state.validation_report['Low']['pivot'].fixed){
            invalid_data_low.push(<span>Pivot (Refer to Section 6 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
          }
        }

        if (this.state.validation_report['Low']['transform']) {
          if (!this.state.validation_report['Low']['transform']['position'].valid && this.state.validation_report['Low']['transform']['position'].fixed) {
            automatically_fixed_low.push('Transform (Position)');
          }
          if (!this.state.validation_report['Low']['transform']['rotation'].valid && this.state.validation_report['Low']['transform']['rotation'].fixed) {
            automatically_fixed_low.push('Transform (Rotation)');
          }
          if (!this.state.validation_report['Low']['transform']['scale'].valid && this.state.validation_report['Low']['transform']['scale'].fixed) {
            automatically_fixed_low.push('Transform (Scale)');
          }

          if (this.state.validation_report['Low']['transform']['position'].valid && !this.state.validation_report['Low']['transform']['position'].fixed) {
            successfully_validated_low.push('Transform (Position)');
          }
          if (this.state.validation_report['Low']['transform']['rotation'].valid && !this.state.validation_report['Low']['transform']['rotation'].fixed) {
            successfully_validated_low.push('Transform (Rotation)');
          }
          if (this.state.validation_report['Low']['transform']['scale'].valid && !this.state.validation_report['Low']['transform']['scale'].fixed) {
            successfully_validated_low.push('Transform (Scale)');
          }

          if (!this.state.validation_report['Low']['transform']['position'].valid && !this.state.validation_report['Low']['transform']['position'].fixed) {
            invalid_data_low.push(<span>Transform (Position) (Refer to Section 8 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
          }
          if (!this.state.validation_report['Low']['transform']['rotation'].valid && !this.state.validation_report['Low']['transform']['rotation'].fixed) {
            invalid_data_low.push(<span>Transform (Rotation) (Refer to Section 8 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
          }
          if (!this.state.validation_report['Low']['transform']['scale'].valid && !this.state.validation_report['Low']['transform']['scale'].fixed) {
            invalid_data_low.push(<span>Transform (Scale) (Refer to Section 8 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
          }
        }

        if (this.state.validation_report['Low'].vertex_count) {
          if (this.state.validation_report['Low'].vertex_count > 800000) {
            invalid_data_low.push(`Vertex Count is ${this.state.validation_report['Low'].vertex_count}`);
          } else if (this.state.validation_report['Low'].vertex_count > 30000) {
            automatically_fixed_low.push(`Warning! Vertex Count is ${this.state.validation_report['Low'].vertex_count}`);
          }
          else if (this.state.validation_report['Low'].vertex_count <= 30000) {
            successfully_validated_low.push(`Vertex Count is ${this.state.validation_report['Low'].vertex_count}`);
          }
        }

        if (!this.state.is_configurable) {
          if (this.state.validation_report['Low']['materials'] != undefined) {
            this.state.validation_report['Low']['materials'].map((material) => {
              if (!material.valid) {
                invalid_data_low.push(<span>{material.name} of class, {material.class}, is not a valid material. (Refer to Section 7 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            })
          }

          if (this.state.validation_report['Low']['pbr_textures'] != undefined) {
            this.state.validation_report['Low']['pbr_textures'].map((texture) => {
              if (!texture.found) {
                invalid_data_low.push(<span>{texture.name} was not found. (Refer to Section 7 of <a href={PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            })
          }
        }
        else if (this.state.is_configurable) {
          if (this.state.validation_report['Low']['configurable'] != undefined) {
            if (this.state.validation_report['Low']['configurable'].valid) {
              successfully_validated_low.push(`Configuration`);
            } else {
              if (this.state.validation_report['Low']['configurable'].missing_components) {
                this.state.validation_report['Low']['configurable'].missing_components.map(comp => {
                  invalid_data_low.push(`Mesh does not exist for ${comp.name}`);
                })
              }
            }
          }
        }

      }
    }
    success_val_length = successfully_validated_high.length + successfully_validated_low.length;
    auto_val_length = automatically_fixed_high.length + automatically_fixed_low.length;
    invalid_val_length = invalid_data_high.length + invalid_data_low.length;
    if (invalid_val_length > 0) {
      this.setState({
        reupload_flag: true
      });
    } else if (invalid_val_length == 0) {
      this.setState({
        reupload_flag: false
      });
    }

    if (this.state.validation_report != undefined  && (success_val_length != 0 || auto_val_length != 0 || invalid_val_length != 0)) {
      let not_empty = false;
      if (this.state.validation_report['High'] != undefined && Object.keys(this.state.validation_report['High']).length != 0) {
        if (!this.state.validation_report['High']['product_valid']) {
          this.setState({
            quick_renders: false,
            reupload_flag: true,
            product_valid: false
          });
        }
        not_empty = true;
      }
      if (this.state.validation_report['Low'] != undefined && Object.keys(this.state.validation_report['Low']).length != 0) {
        if (!this.state.validation_report['Low']['product_valid']) {
          this.setState({
            quick_renders: false,
            reupload_flag: true,
            product_valid: false
          });
        }
        not_empty = true;
      }

      if (not_empty) {
        this.setState({
          validation_generated: true
        });
      }
    } else {
      console.log("report could not be generated")
      this.setState({
        validation_generated: false,
        quick_renders: false,
        reupload_flag: true
      });
      if(this.state.validation_report != undefined){
        if(this.state.validation_report['version_valid'] == false){
          this.setState({
            version_valid : false
          })
        }
      }
    }

    this.setState({
      automatically_fixed_high: automatically_fixed_high,
      automatically_fixed_low: automatically_fixed_low,
      successfully_validated_high: successfully_validated_high,
      successfully_validated_low: successfully_validated_low,
      invalid_data_high: invalid_data_high,
      invalid_data_low: invalid_data_low,
      warning_data_high: warning_data_high,
      success_val_length: success_val_length,
      invalid_val_length: invalid_val_length,
      auto_val_length: auto_val_length
    })
  }

  setDimensionsTable = () => {
    if (this.props.productReducer.product.model_info != undefined )
      {
        let validation = 'Valid';
        if (this.props.productReducer.product.height != undefined) {
          let model_height = this.props.productReducer.product.height;
          let required_height = null;
          if(this.props.productReducer.product.model_info.low){
              // when model_info contains low poly info
              required_height = this.props.productReducer.product.model_info.low.height;
          }
          else if(this.props.productReducer.product.model_info.high){
              // when model_info contains high poly info
              required_height = this.props.productReducer.product.model_info.high.height;
          }
          else{
              // for backward compatibility.
              required_height = this.props.productReducer.product.model_info.height;
          }
          let height_factor = (model_height < required_height) ? (model_height/required_height) : (required_height/model_height);

          if(height_factor < Validation_threshold){
              validation = 'Invalid'
          }
          else {
            let width_diff = this.props.productReducer.product.width / this.props.productReducer.product.depth;
            let model_info_depth = null;
            let model_info_width = null;
            if(this.props.productReducer.product.model_info.low){
                // when model_info contains low poly info
                model_info_depth = this.props.productReducer.product.model_info.low.depth;
                model_info_width = this.props.productReducer.product.model_info.low.width;
            }
            else if(this.props.productReducer.product.model_info.high){
                // when model_info contains high poly info
                model_info_depth = this.props.productReducer.product.model_info.high.depth;
                model_info_width = this.props.productReducer.product.model_info.high.width;
            }
            else{
                // for backward compatibility.
                model_info_depth = this.props.productReducer.product.model_info.depth;
                model_info_width = this.props.productReducer.product.model_info.width;
            }

            let depth_diff = model_info_width / model_info_depth;
            let required_width = null;
            let required_depth = null;
            if ((width_diff >= 1 && depth_diff >= 1) || (width_diff < 1 && depth_diff < 1) || (width_diff == 1 && depth_diff == 1))
            {
                required_width = this.props.productReducer.product.width;
                required_depth = this.props.productReducer.product.depth;
            }
            else
            {
                required_width = this.props.productReducer.product.depth;
                required_depth = this.props.productReducer.product.width;
            }

            let model_width = this.props.productReducer.product.width;
            let model_depth = this.props.productReducer.product.depth;

            let width_factor = (model_width < required_width) ? (model_width/required_width) : (required_width/model_width);
            let depth_factor = (model_depth < required_depth) ? (model_depth/required_depth) : (required_depth/model_depth);

            if((width_factor < Validation_threshold) || (depth_factor < Validation_threshold)){
                validation = 'Invalid'
            }
          }

          let model_h = "N/A", model_d = "N/A", model_w = "N/A";
          if(this.props.productReducer.product.model_info){
              // If model_info has low poly info
              if(this.props.productReducer.product.model_info.low){
                  model_h = this.props.productReducer.product.model_info.low.height || "N/A";
                  model_d = this.props.productReducer.product.model_info.low.depth || "N/A";
                  model_w = this.props.productReducer.product.model_info.low.width || "N/A";
              }
              else if(this.props.productReducer.product.model_info.high){
                  // if model_info has high poly info
                  model_h = this.props.productReducer.product.model_info.high.height || "N/A";
                  model_d = this.props.productReducer.product.model_info.high.depth || "N/A";
                  model_w = this.props.productReducer.product.model_info.high.width || "N/A";
              }
              else {
                  // for backward compatibility, where model_info only contains model_dimenions and vertex count
                  model_h = this.props.productReducer.product.model_info.height || "N/A";
                  model_d = this.props.productReducer.product.model_info.depth || "N/A";
                  model_w = this.props.productReducer.product.model_info.width || "N/A";
              }
          }
            this.setState({
              dimension_validation: validation,
              model_height: model_h,
              model_width: model_w,
              model_depth: model_d
            }, () => {
              this.setState({
                loading_state: false
              });
            });
        }
      } else if (this.props.productReducer.product.model_status) {
        this.setState({
          loading_state: false
        });
      }
  }

  inputBundleFiles = e => {
    if (Array.isArray(e)) {
      return e;
    }
    var raw_files = {}
    raw_files['input_bundle'] = e.fileList;
    return e && raw_files;
  };

  handleRetry = (type_of_file) => {
    let fileList = [];
    if (type_of_file == 'zip_file') {
        fileList = [...this.state.modelFileList];
        $('#upload-product').trigger('click');
        let button = document.getElementById('upload-product');
        button.disabled = true;
    }

    fileList.map((file,index) => {
      this.setState({
        uploading: true
      });
        if (file.status == 'error') {
        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: {
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });

        cognito_credentials.refresh(() => {
        AWS.config.update({
            region: 'us-west-2',
            credentials: cognito_credentials
        });
        const S3 = new AWS.S3({
            httpOptions: {
            timeout: 240000
            }
        });

        const objParams = {
            Bucket: BUCKET_NAME,
            Key: "test_files" + "/" + file.originFileObj.uid + "/" + file.originFileObj.name,
            ACL: 'public-read',
            Body: file.originFileObj,
            ContentType: file.originFileObj.type,
            CacheControl: 'no-cache'
            };
            S3.upload(objParams)
            .on("httpUploadProgress", ({ loaded, total }) => {
              this.setState({
                uploading: true
              });
                file.status = 'uploading';
                let percent = Math.round((loaded / total) * 100);
                file.percent = percent;
                console.log("Retry Percentage: ",file.percent);
            })
            .send((err, data) => {
                if (err) {

                    file.status = 'error';
                    this.setState({
                      upload_failed_message: err.code.concat('.  ' + err.message)
                    });
                    if (err.code.includes('Credentials')) {
                        this.setState({
                          upload_failed_message: err.code.concat('. Your credentials have expired. Please refresh the page.')
                        });
                    } else if (err.code.includes('Network')) {
                        this.setState({
                          upload_failed_message: err.code.concat('. We are unable to upload due to an issue with your internet connection.')
                        });
                    }
                    this.setState({
                      uploading: false,
                      hasError: true
                    });
                } else {
                    console.log("RETRY SEND FINISHED",data.response,file);
                    file.status = 'done';
                    file.error = '';
                    this.setState({
                      upload_failed_message: '',
                      uploading: false,
                      hasError: false
                    });
                    let new_file_list = [];
                    new_file_list.push(file);
                    if (type_of_file == 'zip_file') {
                      this.setState({
                        modelFileList: new_file_list,
                        new_file_list: new_file_list
                      });
                    }
                }

            })
        });
    }
    });
}

  validateFileUploads = (files) => {
    let status_done = false;
    if ((files != undefined) && typeof (files) == "object" && Object.keys(files).length != 0) {
      for(var file of files) {
            if (file['status'] == 'uploading') {
                return 'uploading';
            }
            else if (file['status'] == 'error') {
                return 'error';
            }
            else if (file['status'] == 'done') {
                status_done = true;
            }
        }
    }
    if (status_done) {
        return 'done'
    }
    return 'not_started';
}

  handleReUploadChange = info => {
    if (this.state.upload_error == "" && this.state.upload_failed_message == "") {
      this.setState({
        file_info: info
      }, () => {
        this.handleChange(info);
      });
    }
  }

  handleRejectedUploadChange = info => {
    if (this.state.upload_error == "" && this.state.upload_failed_message == "") {
      this.setState({
        file_info: info
      }, ()=> {
        let fileList = info.fileList;
        if (this.state.modelFileList && this.state.modelFileList.length > 0) {
          fileList = this.state.modelFileList;
        }
        if (fileList != undefined) {
          fileList = fileList.slice(-1);
          if (this.state.inside_interval == false) {
            this.setState({
              file_info: info
            });
          }
          this.setState({modelFileList: fileList});
        }
      });
    }
  }

  handleSubmit = (values) => {
    if(this.state.upload_error == "" && this.state.upload_failed_message == "") {
      this.setState({
        upload_error: '',
        upload_failed_message: '',
        request_submitted: true
      });
      var product_id = this.props.match.params.id;
      var body = {};
      var archive_name = product_id + '.zip';
      body['product_id'] = product_id;
      body['model_type'] = 'low_poly_max';
      body['model_archive'] = archive_name;
      body["artist_approved"] = true;
      body['artist_username'] = localStorage.getItem('username');
      if ( this.state.is_ar_variation) {
        body['is_ar_variation'] = true;
      }
      if (this.state.product_guidelines.artist_model_requested != undefined && this.state.product_guidelines.artist_model_requested == true) {
        body["scanning_app_model"] = true;
      }

      axios.post(ENVIRONMENT.MODEL_CREATE, { body })
      .then(res => {
        if(res.data.error && res.data.error == 'submission_not_allowed'){
          this.setState({
            open_input_model: false,
            action_not_allowed_modal: true
          })
        }
        else{
        if (this.state.rejections_data == undefined && this.state.rejections_data.length == 0 || typeof (this.state.rejections_data) == "object" && Object.keys(this.state.rejections_data).length == 0) {
          axios.post(ENVIRONMENT.ARTIST_HOURS, {
            product_id: product_id,
            artist_username: localStorage.getItem("username"),
            time1: values['time1'],
            time2: values['time2'],
            time3: values['time3'],
            time4: values['time4'],
          }).then(res => {
            message.info('Model is uploaded successfully and is being processed by automation.');
            this.setState({
              open_input_model: false,
              modelFileList: [],
              success_modal: true,
              request_submitted: false
            });
            this.forceUpdate();
          });
        }
        else {
          axios.post(ENVIRONMENT.ARTIST_PRODUCT_FIX, {
            product_id: product_id,
            artist_username: localStorage.getItem("username"),
            fix_time: values['fix_time'],
            rejection_message: this.state.rejection_details,
          }).then(res => {
            message.info('Model is uploaded successfully and is being processed by automation.')
            this.setState({
              open_input_model: false,
              modelFileList: [],
              success_modal: true,
              request_submitted: false
            });;
          })
        }
      }
    });

      let nominate_materials_payload = [];
      for (let i = 0; i < this.state.nominated_materials.length; i++) {
        const element = this.state.nominated_materials[i];
        let payloadItem = {
          product_id: product_id,
          material_name: element,
          artist_selected: true
        }

        nominate_materials_payload.push(payloadItem);
      }

      console.log(nominate_materials_payload)
      axios.post(ENVIRONMENT.PRODUCT_MATERIAL_EXPORT_INFO_UPDATE_BATCH, nominate_materials_payload).then(res => {
        console.log("product material export info update batch result", res.status);
      });

    }
};

  handleChange = info => {
    let fileList = info.fileList;
    if (this.state.new_file_list && this.state.new_file_list.length > 0) {
      fileList = this.state.new_file_list;
    }
    if (fileList != undefined && fileList.length > 0) {
      fileList = fileList.slice(-1);
      if (this.state.inside_interval == false) {
        this.setState({
          file_info: info
        });
      }
      if (this.state.new_file_list && this.state.new_file_list.length > 0) {
        fileList = this.state.new_file_list;
      } else {
        if (info.file.status === "error") {
          this.setState({
            upload_failed_message: info.file.error.code.concat('   ' + info.file.error.message)
          });

          if (info.file.error.code.includes('Credentials')) {
            this.setState({
              upload_failed_message: info.file.error.code.concat('. Your session has expired. Please reload the page.')
            });
          }  else if (info.file.error.code.includes('Network')) {
            this.setState({
              upload_failed_message: info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.')
            });
          }

        } else if (info.file.status === "done") {
          this.setState({
            upload_failed_message: ""
          });
        }
      }
      this.setState({modelFileList: fileList},() => {
        if (this.state.upload_error == "" && this.state.upload_failed_message == "" && this.state.inside_interval && this.state.call_times == false) {
          this.setState({
            inside_interval: false,
            call_times: true
          });
          var product_id = this.props.match.params.id;
          var archive_name = fileList[0].name;
          if (this.state.product_guidelines.model_retries >= 0 && this.state.product_guidelines.model_retries <= retry_count){
            if (archive_name == product_id + '.zip') {
              let body = {};
              archive_name = product_id + '.zip';
              body['product_id'] = product_id;
              body['model_type'] = 'low_poly_max';
              body['model_archive'] = archive_name;
              body["artist_approved"] = false;
              body['artist_username'] = localStorage.getItem('username');

              if ( this.state.is_ar_variation) {
                body['is_ar_variation'] = true;
              }
              
              if (this.state.product_guidelines.artist_model_requested != undefined && this.state.product_guidelines.artist_model_requested == true) {
                body["scanning_app_model"] = true;
              }
              console.log('Uploading...',body);
              axios.post(ENVIRONMENT.MODEL_CREATE, { body })
                .then(res => {
                console.log(res);
                if(res.data.error && res.data.error == 'submission_not_allowed'){
                  this.setState({
                    action_not_allowed_modal: true
                  })
                }
                else{
                  this.setState({
                    upload_error: '',
                    upload_failed_message: '',
                    reupload_flag: false,
                    inside_interval: false,
                    modelFileList: []
                  });

                  if (this.props.productReducer.product.model_status == 2) {
                    this.setState({
                      status: 6
                    });
                  } else if (this.props.productReducer.product.model_status == 8) {
                    this.setState({
                      status: 8
                    });
                  }

                  if (res.status != 200) {
                    this.setState({
                      call_times: false,
                    });
                  }
                  this.forceUpdate();
                }
                })
                .catch((error) => {
                  message.error('Failed to process request for model update');
                });
            }
            else {
              message.error('Invalid File Uploaded. Names must be ' + product_id + '.zip');
            }
          }
          else {
            message.error('Retry count exceeded!');
          }
        } else {
          console.log('Failed');
        }
      });
    }
  };

  handleRejectedSubmit = (values) => {

    console.log(values);

    if(this.state.upload_error == "" && this.state.upload_failed_message == "") {
      this.setState({
        upload_error: '',
        upload_failed_message: '',
        request_submitted: true
      });
      var product_id = this.props.match.params.id;
      var body = {};
      var archive_name = product_id + '.zip';
      body['product_id'] = product_id;
      body['model_type'] = 'low_poly_max';
      body['model_archive'] = archive_name;
      body["artist_approved"] = false;
      body["rejected_case"] = true;
      body["artist_username"] = localStorage.getItem('username');
      if ( this.state.is_ar_variation) {
        body['is_ar_variation'] = true;
      }
      body["platform"] = this.state.platform
      if (this.state.product_guidelines.artist_model_requested != undefined && this.state.product_guidelines.artist_model_requested == true) {
        body["scanning_app_model"] = true;
        body["rejected_case"] = false;
      }
      console.log(body)
      axios.post(ENVIRONMENT.MODEL_CREATE, { body })
      .then(res => {
        if(res.data.error && res.data.error == 'submission_not_allowed'){
          this.setState({
            action_not_allowed_modal: true,
            rejected_upload_modal: false
          })
        }
          else{
          axios.post(ENVIRONMENT.ARTIST_PRODUCT_FIX, {
            product_id: product_id,
            artist_username: localStorage.getItem("username"),
            fix_time: values['fix_time'],
            rejection_message: this.state.rejection_details,
          }).then(res => {
            // Remove the lighting renders for this product
            axios.post(ENVIRONMENT.REMOVE_PRODUCT_RENDERS, {product_id: product_id})
            .then(res=>{
              message.info('Model is uploaded successfully and is being processed by automation.')
              this.setState({
                rejected_upload_modal: false,
                modelFileList: [],
                success_modal: true,
              });;
            })
          })
        }
      }

      );
    }
  };

  reuploadModel = () => {
    var body = {};
    body['product_id'] = this.props.match.params.id;
    body['model_status'] = '2';
    body['username'] = localStorage.getItem('username');
    body['model_delete_request'] = true;
    this.setState({
      req_btn_loader: true
    });

    axios.post(ENVIRONMENT.UPDATE_PRODUCT, body)
        .then(res => {
            this.setState({
              upload_error: '',
              upload_failed_message: '',
              reupload_flag: false,
              inside_interval: false,
              modelFileList: [],
              confirmation_modal: false,
              req_btn_loader: false
            });
            if (this.props.productReducer.product.model_status == 8 || this.props.productReducer.product.model_status == -4) {
              this.setState({
                status: 8,
                call_times: false
              });
            }
            this.forceUpdate();

        });
  }

  changeReuploadStatus = () => {
    this.setState({
      status: 2,
      confirmation_modal: false
    });
  }

  render() {
    let product_text_guidelines = '';
    if (this.state.product_guidelines.guidelines != undefined) {
      product_text_guidelines = this.state.product_guidelines.guidelines.split('\n').map((item, i) => {
        if (item != ""){
          return <li style={{margin: 2}}><div className="category-artist medium align-left" key={i}>{item}</div></li>;
        }
      });
    }

    let admin_text_guidelines = "";
    if(this.state.product_guidelines.admin_text_guidelines != undefined && this.state.product_guidelines.admin_text_guidelines != ""){
      admin_text_guidelines = <li style={{margin: 2}}><div className="category-artist medium align-left">{this.state.product_guidelines.admin_text_guidelines}</div></li>
    }

    let dimensions_text = "";
    if(this.state.product_guidelines.dimensions_text != undefined && this.state.product_guidelines.dimensions_text != "")
      dimensions_text = <li style={{margin: 2}}><div className="category-artist medium align-left">{this.state.product_guidelines.dimensions_text}</div></li>

    let category_attachment = []
    if (this.state.product_guidelines.category_attachment && this.state.product_guidelines.category_attachment.length > 0) {
      this.state.product_guidelines.category_attachment.map((image,index) => {
            let file_url = ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(image.uid + '/' + image.name);
                category_attachment.push(<Col>
                <Card className="product-artist-card">
                {image.name.toLowerCase().includes('jpg') || image.name.toLowerCase().includes('jpeg') || image.name.toLowerCase().includes('png') || image.name.toLowerCase().includes('svg') ?
                <Image
                className="product-photo-artist" src={file_url}
                />
                  :
                <a href={file_url} target='blank'>
                  <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                  <img
                  className="product-photo-artist" src={'/img/file-icon.png'}
                  />
                  </Tooltip>
                </a>    }
                </Card>
            </Col>)
        })
    }

    let rejections_data = '';
    if (this.state.rejections_data != undefined) {
      rejections_data = this.state.rejections_data.map((ele, index) => (
        !ele.customer_rejection ?
        <div style={{margin: '24px 16px'}}>
          <div className='artist-upload-txt small align-text'>Date <span style={{color:"#333333"}}>{ele.qa_time.split(' ')[0] || '-'}</span></div>
          {ele.feedback_link ? <div className='flagged-txt gray'>Feedback <a className='flagged-txt' href={ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.PRODUCT_FEEDBACK_URI +  this.props.match.params.id + "/"  + ele.feedback_link + '.pdf'} target='_blank' style={{color:"#276DD7"}}>{ele.feedback_link + '.pdf'}</a></div> : ''}
            {ele.feedback_link ? <span>
              <div className="flagged-txt gray">
                Feedback Images
              </div>
              <div className='justify-in-start wrap'>
                {this.state.feedback_images && this.state.feedback_images.map((file, index) => (
                  <Image className="image-setting-feedback" src={file.url} />
                ))}
              </div>
            </span> : ''}
          <div className="flagged-txt gray">
            Comment
          </div>
          <div className="flagged-txt black" style={{marginBottom: 30}}>
            <ul>
              {ele.rejection_details.split('\n').map((item, index) => {
                return <li>{item}</li>
              })}
            </ul>
          </div>

          {(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?

            <span>
            <div className="flagged-txt gray">
              Uploads
            </div>
            {ele.reference_files && ele.reference_files.map((file,index) => (
                <a className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}>
                  {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".svg")) ?
                  <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}/> :
                  (file.name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> :
                  <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}><img className="upload-image-settings" src={'/img/file-icon.png'}/></Tooltip> }
                  <div className="flagged-txt upload">{file.name}</div>
                </a>
              ))}
              {(this.state.rejections_data.length > 1 && index != (this.state.rejections_data.length - 1)) ? <hr style={{borderTop: "1px solid #999999"}}/> : ''}
          </span>: ''}
        </div> : ''
      ));
    }

    let modal_rejections_data = '';
    if (this.state.rejections_data[0] != undefined) {
      modal_rejections_data = <div style={{margin: '24px 16px'}}>
          <div className='artist-upload-txt small align-text'>Date <span style={{color:"#333333"}}>{this.state.rejections_data[0].qa_date || '-'}</span></div>
          {this.state.rejections_data[0].feedback_link ? <div className='flagged-txt gray'>Feedback <a className='flagged-txt' href={ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.PRODUCT_FEEDBACK_URI +  this.props.match.params.id + "/"  + this.state.rejections_data[0].feedback_link + '.pdf'} target='_blank' style={{color:"#276DD7"}}>{this.state.rejections_data[0].feedback_link + '.pdf'}</a></div> : ''}

          <div className="flagged-txt gray small">
            Comment
          </div>
          <div className="flagged-txt black small" style={{marginBottom: 16}}>
            <ul>
              {this.state.rejections_data[0].rejection_details.split('\n').map((item, index) => {
                return <li>{item}</li>
              })}
            </ul>
          </div>

          {(this.state.rejections_data[0].reference_files != [] && this.state.rejections_data[0].reference_files != undefined) && (typeof (this.state.rejections_data[0].reference_files) == "object" && Object.keys(this.state.rejections_data[0].reference_files).length != 0) ?

            <span>
            <div className="flagged-txt gray small">
              Uploads
            </div>
            {this.state.rejections_data[0].reference_files && this.state.rejections_data[0].reference_files.map((file,index) => (
                <a className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}>
                  {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".svg")) ?
                  <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}/> :
                  (file.name.includes(".tiff") ) ? <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}><img className="upload-image-settings" src={'/img/tiff_icon.png'}/></Tooltip> :
                  <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}><img className="upload-image-settings" src={'/img/file-icon.png'}/></Tooltip>}
                  <div className="flagged-txt upload small">{file.name}</div>
                </a>
              ))}
          </span>: ''}
        </div>
    }

    return (
      this.state.loading_state && !this.state.action_not_allowed_modal ? <DottedLoader/> :
      <ArtistMainLayout selected='1'>
        {this.props.productReducer.product.assigned_artist == localStorage.getItem('username') ?
        <React.Fragment>
        <Row style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginTop: 48}}>
          <Col>
            <span className="artist-product-heading">{this.state.product_guidelines.product_name}</span>
          </Col>
          <Col>
            <div className="note-bg-artist">
              {this.state.product_guidelines.model_retries < retry_count ?
              <span>
                <span className="note-text">
                  Attempt {this.state.product_guidelines.model_retries}/{retry_count}.&nbsp;
                </span>
                <span className="note-text">
                  You have a total of {retry_count - this.state.product_guidelines.model_retries} attempts to get this model right.
                </span>
              </span>: (this.state.product_guidelines.model_retries == retry_count ?
              <span>
                <span className="note-text red">
                  Attempt {retry_count}/{retry_count}.&nbsp;
                </span>
                <span className="note-text red">
                  You can no longer upload another Model. Contact Admin.
                </span>
              </span> :
                <span className="note-text">
                You have a total of {retry_count - this.state.product_guidelines.model_retries} attempts to get this model right.
                </span>) }
            </div>
          </Col>
        </Row>
        <Row style={{marginTop: 22,marginBottom: 24}}>
          {(this.state.product_guidelines.parent_category || this.state.product_guidelines.category) &&
          <Col style={{display:"flex",alignItems:"center",marginRight:'40px'}}>
            <span className="category-artist" style={{marginRight:16}}>Category</span>
            <div className ="note-bg-artist gray">
              <span className="note-text gray">
                {this.state.product_guidelines.parent_category &&  this.state.product_guidelines.category ?
                <span>
                  {this.state.product_guidelines.parent_category + ' / ' + this.state.product_guidelines.category}
                </span> :
                <span> {!this.state.product_guidelines.parent_category ?
                  this.state.product_guidelines.category
                : !this.state.product_guidelines.category ? this.state.product_guidelines.parent_category : ''}
                </span>}

              </span>

            </div>
          </Col>}
          <Col style={{display:"flex",alignItems:"center"}}>
            <span className="category-artist" style={{marginRight:16}}>ID</span>
            <div className ="note-bg-artist gray">
              <span className="note-text gray">{this.props.match.params.id}</span>
            </div>
          </Col>
        </Row>
        <VariationBaseModelDetails
        approved_group_products={this.state.approved_group_products}
        required_assets={this.state.required_assets}
        artist_modelling_requirements={this.state.artist_modelling_requirements}
        requiredMaterials={this.state.materialOutputType}
        product_id={this.state.product_id}
        productDetails={this.state.product_guidelines}
        category_attachment={category_attachment}
        selectedMaterials={this.state.selected_materials}
        changeVisibility={this.changeVisibility}
        findMissingMaterialTypeFromSelection = {this.findMissingMaterialTypeFromSelection}
        basePlatform = {this.state.base_platform}
        basePlatformLoader = {this.state.base_platform_loader}
        />

       { (this.props.productReducer.product.model_status == 2 || this.props.productReducer.product.model_status in [6, 11] || this.state.status in [6, 11]) ?
       <div style={{marginBottom: this.props.productReducer.product.model_status != 2 ? 8 : 40,display:"block"}}>
        <div className="dragger-upload-div" style={{padding:"20px",cursor:"pointer"}} onClick={this.triggerUpload}>
          <Dragger style={{display: 'inline-block', width: "100%"}}
              className="upload-dragger-artist"
              id="upload-product"
              openFileDialogOnClick={false}
              // openFileDialogOnClick={!(this.validateFileUploads(this.state.modelFileList) == 'error')}
              multiple={false}
              progress= {{
                strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
                }}
              disabled={this.state.product_guidelines.model_retries >= retry_count || this.state.status in [6, 11] || this.props.productReducer.product.model_status in [6, 11]}
              onRemove={file => {
                let button = document.getElementById('upload-product');
                button.disabled = true;
                this.setState({
                  upload_error: '',
                  modelFileList: [],
                  upload_failed_message: ''
                });
              }}
              beforeUpload={file => {
                if(file.name != this.props.match.params.id + '.zip'){
                  this.setState({
                    upload_error: "Invalid archive name, archive must be named " + this.props.match.params.id + '.zip'
                  });
                  return false;
                }
                else{
                  return this.checkFolderStructure(file);
                }
              }}

              {...(Constants.getProductModelUploadProp(this.state.platform))}
              onChange = {this.handleChange}
              listType="text"
              fileList = {this.state.modelFileList}>
                {this.validateFileUploads(this.state.modelFileList) == "uploading"  ?
                  <span>
                    <p className="artist-upload-txt large">Upload in Progress... </p>
                  </span> :
                  this.validateFileUploads(this.state.modelFileList) == "done" ?
                  <span>
                    <div className="justify-in-center">
                      <img height="25px" width="25px" src="/img/success.png" style={{marginRight: 5}}></img>
                      <p className="artist-upload-txt large" style={{color:"#25D6A4"}}>Upload Successful</p>
                    </div>
                  </span> :
                  this.validateFileUploads(this.state.modelFileList) == "error" ?
                  <span>
                    <div className="justify-in-center">
                      <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                      <p className="artist-upload-txt large" style={{color:"#D93025"}}>Upload Error</p>
                      {(this.validateFileUploads(this.state.modelFileList) == 'error') && (<Button
                            onClick={() => this.handleRetry('zip_file')}
                            disabled={this.state.modelFileList.length === 0}
                            style={{position: "unset",marginLeft: 8}}
                            loading={this.state.uploading}
                            className="retry-btn" ghost
                            >
                            {this.state.uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                        </Button>)}
                    </div>
                  </span> :
                  this.props.productReducer.product.model_status == 2 ?
                  <span>
                    <p className="ant-upload-drag-icon">
                    <img src="/img/exclude.png"></img>
                    </p>
                    <p className="artist-upload-txt">Drop your product model’s <span style={{color:"#333333"}}>Archive</span> folder here, or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                    <p className="artist-upload-txt small">
                      Only .zip files are supported
                    </p>
                  </span>: ((this.props.productReducer.product.model_status in [6, 11] || this.state.status in [6, 11]) ?
                  <span>
                  <p className="ant-upload-drag-icon">
                  <LoadingOutlined className="loading-bg"/>
                  </p>
                  <p className="artist-upload-txt">Please wait. We are running some validation checks on your upload.</p>
                </span> : (this.state.status == 3) ?
                  <span>
                  <p className="ant-upload-drag-icon">
                  <LoadingOutlined className="loading-bg"/>
                  </p>
                  <p className="artist-upload-txt">Thanks for waiting. Your model validation is almost complete. You will be directed to validation summary page shortly.</p>
                </span>: "")}
            </Dragger>
          </div>
          {this.state.upload_error == "" ? "" :
          <div style={{marginBottom: 8}}>
            <span className="category-artist red">{this.state.upload_error}</span>
          </div>}
          {this.state.upload_failed_message == "" ? "" :
          <div style={{marginBottom: 8}}>
            <span className="category-artist red">{this.state.upload_failed_message}</span>
          </div>}
        </div>: ""}



        {this.props.productReducer.product.model_status == 8 ?
        <span>
          {!this.state.reupload_flag && (this.state.quick_renders || this.state.product_guidelines.artist_model_requested == true) ?
          <span>
            <Row style={{marginBottom: 32}}>
              <Col span={24}>
                <div style={{background: "rgba(39, 109, 215, 0.06)",borderRadius: "4px",padding:"10px 16px",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <span className="scene-note flag">
                      Uploaded model is ready to be submitted. Review to re-upload or Submit.
                    </span>
                    <span style={{display:"flex"}}>
                      <Button className="modal-okay-orange" style={{marginRight:"16px"}} disabled={this.state.product_guidelines.model_retries >= retry_count} onClick={() => { this.setState({confirmation_modal: true})}}>
                        <span className="modal-okay-text">Delete and Re-Upload Your File</span>
                      </Button>
                      <Button className="modal-okay" onClick={() => {this.setState({open_input_model: true})}}>
                        <span className="modal-okay-text">Submit Your Model</span>
                      </Button>
                    </span>
                </div>
              </Col>
            </Row>
            {this.state.product_guidelines.artist_model_requested == false ?
            <Row style={{margin: "32px 0"}}>
              <Col span={12}>
                  <QuickRenders {...this.props} compareView={true} embed={false}/>
              </Col>
              <Col span={12}>
                  <ProductStaticGuidelinesCard {...this.props} rejectionDetails={this.state.rejections_data} adminScrollCheck={false} adminCheck={true} compareView={true} embed={false}/>
              </Col>
            </Row>:""}
          </span>: ""}
          <Row style={{marginBottom: 32,alignItems:"flex-start",marginTop: 32 }}>
          </Row>
          {!this.state.validation_generated && this.state.reupload_flag ? <Row style={{marginBottom: 32,alignItems:"flex-start"}}>
            <Col span={12} style={{paddingRight: "5%"}}>
              <div style={{background: " #FEF6F6",borderRadius: "4px",padding:"16px",display:"flex",justifyContent:"flex-start",alignItems:"center",marginBottom: 16}}>

                {this.state.version_valid ?
                  <span className="scene-note flag red">
                      The system was unable to generate validation summary for your model. Contact Admin.
                  </span>
                :
                  <span className="scene-note flag red">
                      Version of max file not supported. Please re-upload the asset made with MAX 2020.
                  </span>
                }
              </div>
            </Col>
          </Row> : ''}
          <Row style={{marginBottom: 32,alignItems:"flex-start"}}>

          {(this.state.validation_report  && this.state.validation_generated) || this.state.reupload_flag ?
            <Col span={12} style={{paddingRight: "5%"}}>
              {this.state.validation_report ?
              ((this.state.success_val_length == 0 && this.state.auto_val_length == 0 && this.state.invalid_val_length == 0) ? '' :
              <div style={{marginBottom: 32}}>
                {!this.state.quick_renders || this.state.invalid_val_length > 0 ?
                <div className="validation-heading" style={{marginBottom: 16}}>
                  <img src="/img/alert-triangle.png" style={{marginRight: 10}}/><span>Model Validation Summary</span>
                </div> :
                <div className="validation-heading" style={{marginBottom: 16}}>
                 <Checkbox className={this.state.auto_val_length > 0 ? "yellow-checkbox" : "green-checkbox"} checked={true} style={{marginRight: 10}}></Checkbox> <span>Model Validation Summary</span>
               </div>}
               {!this.state.product_valid ?
                <div style={{background: " #FEF6F6",borderRadius: "4px",padding:"16px",display:"flex",justifyContent:"flex-start",alignItems:"center",marginBottom: 16}}>
                    <span className="scene-note flag red">
                    The product bundle you uploaded is invalid. Please re-upload the correct bundle.
                    </span>
                </div>
                : (!this.state.quick_renders && this.state.invalid_val_length == 0 && this.state.product_guidelines.artist_model_requested == false) ?
                <div style={{background: " #FEF6F6",borderRadius: "4px",padding:"16px",display:"flex",justifyContent:"flex-start",alignItems:"center",marginBottom: 16}}>
                    <span className="scene-note flag red">
                    The system was unable to generate renders. Please re-upload your bundle.
                    </span>
                </div> : ''
                }
              {this.state.invalid_val_length == 0 ? '' :
                <Collapse
                  bordered={false}
                  defaultActiveKey={['1']}
                  expandIconPosition={'right'}
                  expandIcon={({ isActive }) => <div className="draw-full-circle red" style={{padding: "7px 11px",top: 7}}><span className="artist-upload-txt small white">{this.state.invalid_val_length}</span></div>}
                  className="collapse-validation">
                  <Panel header={<span className="modal-text red">What Went Wrong?</span>} key="1" className="site-collapse-custom-panel">
                    <Collapse defaultActiveKey={['1']} accordion className="collapse-validation-collapse" ghost expandIconPosition={'right'}>
                      {this.state.invalid_data_high.length == 0 ? '' :
                      <Panel key='1' className="site-collapse-custom-panel" header={<span className='width-height-label'>High Poly</span>}>
                        <List
                        bordered={false}
                        dataSource={this.state.invalid_data_high}
                        className="list-validation"
                        renderItem={item => (
                          <List.Item className="list-item-validation">
                            <Typography.Text className="mark-style" mark style={{marginRight: 10}}><img style={{backgroundColor: "#fafafa"}} src="/img/alert-triangle.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                          </List.Item>
                        )}
                      />
                      </Panel>}
                      {this.state.invalid_data_low.length == 0 ? '' :
                      <Panel  key='2' className="site-collapse-custom-panel" header={<span className='width-height-label'>Low Poly</span>}>
                        <List
                        bordered={false}
                        dataSource={this.state.invalid_data_low}
                        className="list-validation"
                        renderItem={item => (
                          <List.Item className="list-item-validation">
                            <Typography.Text className="mark-style" mark style={{marginRight: 10}}><img style={{backgroundColor: "#fafafa"}} src="/img/alert-triangle.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                          </List.Item>
                        )}
                      />
                      </Panel>}
                    </Collapse>
                  </Panel>
                </Collapse>}
                {this.state.warning_data_high == 0 ? '' :
                <Collapse
                  bordered={false}
                  defaultActiveKey={['1']}
                  expandIconPosition={'right'}
                  expandIcon={({ isActive }) => <div className="draw-full-circle orange" style={{padding: "7px 11px",top: 7}}><span className="artist-upload-txt small white">{this.state.warning_data_high.length}</span></div>}
                  className="collapse-validation">
                  <Panel header={<span className="modal-text orange">Warnings</span>} key="1" className="site-collapse-custom-panel">
                    <Collapse defaultActiveKey={['1']} accordion className="collapse-validation-collapse" ghost expandIconPosition={'right'}>
                      <Panel key='1' className="site-collapse-custom-panel" header={<span className='width-height-label'>High Poly</span>}>
                        <List
                        bordered={false}
                        dataSource={this.state.warning_data_high}
                        className="list-validation"
                        renderItem={item => (
                          <List.Item className="list-item-validation">
                            <Typography.Text className="mark-style" mark style={{marginRight: 10}}><img style={{backgroundColor: "#fafafa"}} src="/img/alert-warning.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                          </List.Item>
                        )}
                      />
                      </Panel>
                    </Collapse>
                  </Panel>
                </Collapse>}
                {this.state.auto_val_length == 0 ? '' :
                  <Collapse
                  bordered={false}
                  defaultActiveKey={this.state.invalid_val_length == 0  ? ['1']: ['0']}
                  expandIconPosition={'right'}
                  expandIcon={({ isActive }) => <div className="draw-full-circle yellow" style={{padding: "7px 11px",top: 7}}><span className="artist-upload-txt small white">{this.state.auto_val_length}</span></div>}
                  className="collapse-validation">
                  <Panel header={<span className="modal-text yellow">Automatically Fixed</span>} key="1" className="site-collapse-custom-panel">
                    <Collapse defaultActiveKey={['1']} accordion className="collapse-validation-collapse" ghost expandIconPosition={'right'}>
                      {this.state.automatically_fixed_high.length == 0 ? '' :
                      <Panel key='1' className="site-collapse-custom-panel" header={<span className='width-height-label'>High Poly</span>}>
                        <List
                        bordered={false}
                        dataSource={this.state.automatically_fixed_high}
                        className="list-validation"
                        renderItem={item => (
                          <List.Item className="list-item-validation">
                            <Typography.Text className="mark-style" mark style={{marginRight: 10}}><img style={{backgroundColor: "#fafafa"}} src="/img/alert-circle.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                          </List.Item>
                        )}/>
                      </Panel>}
                      {this.state.automatically_fixed_low.length == 0 ? '' :
                      <Panel key='2' className="site-collapse-custom-panel" header={<span className='width-height-label'>Low Poly</span>}>
                        <List
                          bordered={false}
                          dataSource={this.state.automatically_fixed_low}
                          className="list-validation"
                          renderItem={item => (
                            <List.Item className="list-item-validation">
                              <Typography.Text className="mark-style" mark style={{marginRight: 10}}><img style={{backgroundColor: "#fafafa"}} src="/img/alert-circle.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                            </List.Item>
                          )}/>
                      </Panel>}
                    </Collapse>
                  </Panel>
                </Collapse>}

                {this.state.success_val_length == 0 ? '' :
                <Collapse
                bordered={false}
                defaultActiveKey={this.state.invalid_val_length == 0 && this.state.auto_val_length == 0 ? ['1']: ['0']}
                expandIconPosition={'right'}
                expandIcon={({ isActive }) => <div className="draw-full-circle" style={{padding: "7px 11px",top: 7}}><span className="artist-upload-txt small white">{this.state.success_val_length}</span></div>}
                className="collapse-validation">
                <Panel header={<span className="modal-text green">Successfully Validated</span>} key="1" className="site-collapse-custom-panel">
                  <Collapse defaultActiveKey={['1']} accordion className="collapse-validation-collapse" ghost expandIconPosition={'right'}>
                  {this.state.successfully_validated_high.length == 0 ? '' :
                    <Panel key='1' className="site-collapse-custom-panel" header={<span className='width-height-label'>High Poly</span>}>
                      <List
                      bordered={false}
                      dataSource={this.state.successfully_validated_high}
                      className="list-validation"
                      renderItem={item => (
                        <List.Item className="list-item-validation">
                          <Typography.Text mark style={{marginRight: 10}}><Checkbox className="green-checkbox" checked={true}/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                        </List.Item>
                      )}/>
                    </Panel>}
                    {this.state.successfully_validated_low.length == 0 ? '' :
                    <Panel key='2' className="site-collapse-custom-panel" header={<span className='width-height-label'>Low Poly</span>}>
                      <List
                        bordered={false}
                        dataSource={this.state.successfully_validated_low}
                        className="list-validation"
                        renderItem={item => (
                          <List.Item className="list-item-validation">
                            <Typography.Text mark style={{marginRight: 10}}><Checkbox className="green-checkbox" checked={true}/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                          </List.Item>
                        )}/>
                    </Panel>}
                  </Collapse>
                </Panel>
                </Collapse>}
                </div>) : ''}
                {this.state.reupload_flag ?
                <span>
                <div className="dragger-upload-div" style={{maxWidth:"100%",padding:"20px"}}>
                <Dragger style={{display: 'inline-block', width: "100%"}}
                    className="upload-dragger-artist"
                    multiple={false}
                    progress= {{
                      strokeColor: {
                      '0%': '#108ee9',
                      '100%': '#87d068',
                      },
                      strokeWidth: 3,
                      format: percent => `${parseFloat(percent.toFixed(2))}%`,
                      }}
                      openFileDialogOnClick={!(this.validateFileUploads(this.state.modelFileList) == 'error')}

                    disabled={!this.state.reupload_flag || this.state.product_guidelines.model_retries >= retry_count || this.state.status in [6, 11] || this.props.productReducer.product.model_status in [6, 1]}
                    onRemove={file => {

                      this.setState({
                        upload_error: '',
                        upload_failed_message: '',
                        modelFileList: [],
                      });
                    }}
                    beforeUpload={file => {
                      if(file.name != this.props.match.params.id + '.zip'){
                        this.setState({
                          upload_error: "Invalid archive name, archive must be named " + this.props.match.params.id + '.zip'
                        });
                        return false;
                      }
                      else{
                        return this.checkFolderStructure(file);
                      }
                    }}
                    {...(Constants.getProductModelUploadProp(this.state.platform))}
                    onChange = {this.handleReUploadChange}
                    listType="text"
                    fileList = {this.state.modelFileList}>
                      {this.validateFileUploads(this.state.modelFileList) == "uploading"  ?
                      <span>
                        <p className="artist-upload-txt large">Upload in Progress... </p>
                      </span> :
                      this.validateFileUploads(this.state.modelFileList) == "done" ? (<span>
                        <div className="justify-in-center">
                          <img height="25px" width="25px" src="/img/success.png" style={{marginRight: 5}}></img>
                          <p className="artist-upload-txt large" style={{color:"#25D6A4"}}>Upload Successful</p>
                        </div>
                      </span>):
                      this.validateFileUploads(this.state.modelFileList) == "error" ?
                        <span>
                          <div className="justify-in-center">
                            <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                            <p className="artist-upload-txt large" style={{color:"#D93025"}}>Upload Error</p>
                            {(this.validateFileUploads(this.state.modelFileList) == 'error') && (<Button
                                  onClick={() => this.handleRetry('zip_file')}
                                  style={{position: "unset",marginLeft: 8}}
                                  disabled={this.state.modelFileList.length === 0}
                                  loading={this.state.uploading}
                                  className="retry-btn" ghost
                                  >
                                  {this.state.uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                              </Button>)}
                          </div>
                        </span> :
                      (<span>
                        <p className="ant-upload-drag-icon">
                        <img src="/img/exclude.png"></img>
                        </p>
                        <p className="artist-upload-txt">Drop your product model’s <span style={{color:"#333333"}}>Archive</span> folder here, or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                        <p className="artist-upload-txt small">
                          Only .zip files are supported
                        </p>
                        <Button
                        disabled={!this.state.reupload_flag || this.state.product_guidelines.model_retries >= retry_count}
                        className="modal-okay-orange square" style={{marginTop: 8}}>
                          <div className="modal-okay-text">Re-Upload Your File</div>
                        </Button>
                      </span>) }
                  </Dragger>
                </div>
                {this.state.upload_error == "" ? "" :
                <div style={{marginBottom: 8}}>
                  <span className="category-artist red">{this.state.upload_error}</span>
                </div>}
                {this.state.upload_failed_message == "" ? "" :
                <div style={{marginBottom: 8}}>
                  <span className="category-artist red">{this.state.upload_failed_message}</span>
                </div>}
                </span>:''}
            </Col>  : !this.state.validation_generated ?
            <Col span={12} style={{paddingRight: "5%"}}>
              <div style={{background: " #FEF6F6",borderRadius: "4px",padding:"16px",display:"flex",justifyContent:"flex-start",alignItems:"center",marginBottom: 16}}>
                <span className="scene-note flag red">
                    The system was unable to generate validation summary for your model. Contact Admin.
                </span>
              </div>
            </Col>
             : ''}

            <Col span={12}>
              <span className="product-txt-heading">Dimension Validation</span>
              <div style={{display: 'inline',background: '#FEF6F6', padding: 8, marginLeft: 10, borderRadius: 3}}>
                <span className="note-text" style={{color: this.state.dimension_validation=='Invalid'?'red':'green'}}>{this.state.dimension_validation}</span>
              </div>
              {this.props.productReducer.product.width && this.props.productReducer.product.height && this.props.productReducer.product.depth ?
              <table className="stats-table dimension-table" style={{maxWidth:"80%",marginTop: 12}}>
                  <tr>
                      <th></th>
                      <th>Desired Dimensions</th>
                      <th>3D Model Dimensions</th>
                  </tr>
                  <tr>
                    <td style={{fontSize: 16}}>Height<span style={{paddingLeft: 6, fontSize: 14, color: '#777777'}}>(Inches)</span></td>
                      <td>{(this.props.productReducer.product.height != undefined) ? this.props.productReducer.product.height : "N/A"}</td>
                      <td>{this.state.model_height}</td>
                  </tr>
                  <tr>
                  <td style={{fontSize: 16}}>Width<span style={{paddingLeft: 6, fontSize: 14, color: '#777777'}}>(Inches)</span></td>
                      <td>{(this.props.productReducer.product.width != undefined) ? this.props.productReducer.product.width : "N/A"}</td>
                      <td>{this.state.model_width}</td>
                  </tr>
                  <tr>
                  <td style={{fontSize: 16}}>Depth<span style={{paddingLeft: 6, fontSize: 14, color: '#777777'}}>(Inches)</span></td>
                      <td>{(this.props.productReducer.product.depth != undefined) ? this.props.productReducer.product.depth : "N/A"}</td>
                      <td>{this.state.model_depth}</td>
                  </tr>
              </table>
              :
              ''
              }
            </Col>
          </Row>
        </span>
        : ""}
       { this.props.productReducer.product.model_status in [6, 11] || this.state.status in [6, 11] ? <div style={{display:"flex",justifyContent:"flex-start",marginBottom: 40}}>
            <div style={{background: "rgba(39, 109, 215, 0.06)",borderRadius: "4px",padding:"10px 16px"}}>
                <span className="scene-note flag">
                    Note: <div style={{color:"#333333",marginTop: 8}}>Validating your model might take some time. You can leave the site. We will notify you via email once validation is complete. Review your model in the “Artist Review” section.</div>
                </span>
            </div>
        </div> : ""}

       { this.state.status in [6, 11] || this.props.productReducer.product.model_status == 2 || this.props.productReducer.product.model_status in [6, 11] ?
       <Row>
          <Col style={{display:"flex",alignItems:"center",marginBottom:'10px'}}>
            <div className="note-text black" style={{marginRight: 10}}>
            Status:
            </div>
            <div className="note-bg-artist red">
              <span className="note-text red">In Progress</span>
            </div>
          </Col>
        </Row>: ""}

     {this.props.productReducer.product.model_status == -4 ? <span>

      <Row style={{margin: "32px 0"}}>
        <Col span={12}>
            <ProductDetailsComponent  storePage={0} {...this.props} embed={false} artist_check={true}  compareView={true} adminScrollCheck={false} adminCheck={true}/>
        </Col>
        <Col span={12}>
            <ProductStaticGuidelinesCard {...this.props} compareView={true} embed={false}/>
        </Col>
      </Row>
      {this.state.product_guidelines.model_retries < retry_count ? <Row style={{marginBottom: 32}}>
        <Col span={24}>
          <div style={{background: "rgba(39, 109, 215, 0.06)",borderRadius: "4px",padding:"10px 16px",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <span className="scene-note flag">
                Do you want to resubmit your model?
              </span>
              <span style={{display:"flex"}}>
                <Button className="modal-okay-orange" style={{marginRight:"16px"}} disabled={this.state.product_guidelines.model_retries >= retry_count} onClick={() => { this.setState({rejected_upload_modal: true})}}>
                  <span className="modal-okay-text">Re-Upload Your File</span>
                </Button>
              </span>
          </div>
        </Col>
      </Row>: ''}

        <Row>
          <Col style={{display:"flex",alignItems:"center",marginBottom:'32px'}}>
            <div className="note-text black" style={{marginRight: 10}}>
            Status:
            </div>
            <div className="note-bg-artist red">
              <span className="note-text red">Rejected</span>
            </div>
          </Col>
        </Row>
        </span>: ""}

        <Row>
          {rejections_data && ((this.props.productReducer.product.model_status) == 2 || (this.props.productReducer.product.model_status) == -4) ?
            <Col span={11} style={{marginRight: 30, marginBottom: 16}}>
            <div className='reject-heading' style={{display: 'flex'}}>
              Rejection Criteria
            </div>
              <div className="flagged-bg border custom">
              {rejections_data}
              {this.state.download_link ? <div className="note-text blue" style={{marginBottom: 24}}><a href={ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.LOW_POLY_MAX_URI +this.props.match.params.id+'.zip' }>Download Model</a></div>: ''}
              </div>
            </Col>
            : ''}

          {product_text_guidelines != '' ||  dimensions_text != '' || admin_text_guidelines != '' ?
            <Col span={11} style={{marginBottom: 16}}>
              <div className='category-artist medium' style={{display: 'flex', fontSize: '20px', fontWeight: 600}}>
                Initial Comments and Guidelines
              </div>
              <div className="note-bg-artist gray" style={{border: '#b1b1cd', borderStyle: 'dashed'}} >
                <ul style={{paddingLeft: 10, paddingTop: 10, paddingBottom: 40}}>
                  {product_text_guidelines}
                  {dimensions_text}
                  {admin_text_guidelines}
                </ul>
              </div>
            </Col>: ''}
        </Row>


      <ProductStaticGuidelines product_id={this.props.match.params.id} artist_page={true}/>

        <Modal
        visible={this.state.open_input_model}
        className="model-time-modal"
        maskClosable={false}
        onCancel={()=> { this.setState({open_input_model: false})}}>
          <div style={{padding: "32px 32px 2px 32px"}}>
            <div className="model-time-h" style={{marginBottom: 30}}>Enter Modelling Time (In Hours)</div>
            <Form className="artist-form" onFinish={this.handleSubmit}>
              <Form.Item name="time1" className="artist-upload-txt align-text" label={"High Poly"} colon={false}
              rules={[this.state.input_fields_rules['time1']]}>
                <Input
                  type="number"
                  min="0.01" max="30"
                  step="0.01"
                  defaultValue={this.state.time1}
                  placeholder="Please enter time (in hours) it took to make high poly."
                  disabled={this.state.input_fields_disabled['time1']}
                />
              </Form.Item>
              <Form.Item name="time2" className="artist-upload-txt align-text" rules={[this.state.input_fields_rules['time2']]} label={"Low Poly"} colon={false}>
                <Input
                  type="number"
                  min="0.01" max="30"
                  step="0.01"
                  defaultValue={this.state.time2}
                  placeholder="Please enter time (in hours) it took to make low poly."
                  disabled={this.state.input_fields_disabled['time2']}
                />
              </Form.Item>
              <Form.Item name="time3" className="artist-upload-txt align-text"
              rules={[this.state.input_fields_rules['time3']]}
              label={this.state.required_assets == FileConstants.allPBRRequiredAssets || this.state.required_assets == FileConstants.highPBRRequiredAssets ?  "High Poly PBR Materials" : "Vray Textures" } colon={false}>
                <Input
                  type="number"
                  min="0.01" max="30"
                  step="0.01"
                  defaultValue={this.state.time3}
                  placeholder={this.state.required_assets == FileConstants.allPBRRequiredAssets || this.state.required_assets == FileConstants.highPBRRequiredAssets ?  "Please enter time (in hours) it took to make high poly pbr materials.": "Please enter time (in hours) it took to make vray materials."}
                  disabled={this.state.is_configurable || (!this.state.is_configurable && this.state.input_fields_disabled['time3'])}
                />
              </Form.Item>
              <Form.Item name="time4" className="artist-upload-txt align-text" rules={[this.state.input_fields_rules['time4']]} label={this.state.required_assets == FileConstants.allPBRRequiredAssets || this.state.required_assets == FileConstants.highPBRRequiredAssets ? "Low Poly PBR Materials" : " PBR Materials"} colon={false}>
                <Input
                  type="number"
                  min="0.01" max="30"
                  step="0.01"
                  defaultValue={this.state.time4}
                  placeholder={"Please enter time (in hours) it took to make pbr materials."}
                  disabled={this.state.input_fields_disabled['time4']}
                />
              </Form.Item>

              {((this.state.rejections_data != undefined && this.state.rejections_data.length != 0) || this.state.nominable_materials.length == 0) ? '' :
              // Empty div for enclosing the material nomination div so that conditional rendering check
              // is not required to be added for each item
              <div>
              <div className="model-time-h" style={{marginBottom: 30, marginTop: 30}}>Material Nomination</div>
              <Form.Item name="material nomination" className="artist-upload-txt align-text" label={"Select Materials that can be reused for other Products."} colon={false}>
                <TreeSelect className="manrope f-14 black-55"
                    showArrow
                    style={{ width: '100%',height:"auto" }}
                    dropdownStyle={treeSelectStyle}
                    placeholder="Select your product model materials"
                    allowClear
                    multiple
                    treeDefaultExpandAll
                    treeData={this.state.nominable_materials}
                    defaultValue={this.state.pre_nominated_materials}
                    onChange={this.setNominatedMaterials}
                    notFoundContent={<span><h5>No Result Found</h5></span>}
                >
                </TreeSelect>
              </Form.Item>
              </div>
              }

              
              {(this.state.rejections_data != undefined && this.state.rejections_data.length != 0) ?
              <Form.Item name="fix_time" className="artist-upload-txt align-text" rules={[this.state.input_fields_rules['fix_time']]} label={"Enter Time It Took To Fix The Issue(s)"} colon={false}>
                <Input
                  type="number"
                  min="0.01" max="30"
                  step="0.01"
                  placeholder="Please enter time (in hours) it took to fix the issue(s)."
                  disabled={this.state.input_fields_disabled['fix_time']}
                />
              </Form.Item>: ''}
              <Form.Item className="artist-form-button">
                <Button disabled={(this.state.request_submitted === true)} className="modal-okay square" htmlType="submit">
                  <div className="modal-okay-text">Submit {this.state.request_submitted === true?<LoadingOutlined spin/>:""}</div>
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <Modal
            className="silo-success-modal"
            centered={true}
            maskClosable={false}
            closable={false}
            width={800}
            onCancel={() => this.setState({success_modal: false})}
            visible={this.state.success_modal}
            bodyStyle={{padding: 0}}
            footer={[
                <Button className="modal-okay square" htmlType="submit" onClick={() => {
                    window.location.href = "/artist_products/";
                }}>
                    <div className="modal-okay-text">Okay</div>
                </Button>
            ]}>
                <div style={{padding:"40px"}}>
                    <div style={{textAlign: "center"}}>
                        <img style={{width: 85,height:85}} src={require("../../../../assets/images/success-icon.png")}></img>
                    </div>
                    <div style={{textAlign: "center",margin: "20px 0"}}>
                        <h2 className="modal-heading">Model Submitted Successfully!</h2>
                        <p className="modal-text">You will be notified once the model is reviewed by the admin and the customer.</p>
                    </div>
                </div>
        </Modal>
        <Modal
        className="modal-lifestyle-request"
        centered={true}
        maskClosable={false}
        width={800}
        onCancel={() => this.setState({confirmation_modal: false})}
        visible={this.state.confirmation_modal}
        bodyStyle={{padding: 0}}
        footer={[
          <div className="justify-in-center">
            <Button className="modal-okay-gray square font-14" disabled={this.state.req_btn_loader} htmlType="submit" onClick={() => this.setState({confirmation_modal: false})}>
                Cancel
            </Button>
            <Button className="modal-okay-orange square" disabled={this.state.req_btn_loader} htmlType="submit" onClick={this.reuploadModel}>
              <div className="modal-okay-text">{(this.props.productReducer.product.model_status == -4) ? <span>Re-upload Model</span> : <span>Delete and Re-upload Model</span>} {this.state.req_btn_loader ? <LoadingOutlined/> : ''}</div>
            </Button>
            </div>
        ]}>
            <div style={{padding:"40px"}}>
                <div style={{textAlign: "center",margin: "20px 0"}}>
                    <h2 className="modal-heading">{(this.props.productReducer.product.model_status == -4) ? <span>Are you sure you want to re-upload your model?</span> : <span>Are you sure you want to delete and re-upload your model?</span>}</h2>
                    <p className="artist-upload-txt">You will be redirected to model upload page.</p>
                </div>
            </div>
        </Modal>
        <Modal
        visible={this.state.rejected_upload_modal}
        className="model-time-modal"
        maskClosable={false}
        onCancel={()=> { this.setState({rejected_upload_modal: false})}}>
          <div style={{padding: "32px 32px 2px 32px"}}>
            <div className="model-time-h" style={{marginBottom: 30}}>Upload Model And Enter Modelling Time (In Hours)</div>
            <Form className="artist-form" onFinish={this.handleRejectedSubmit}>
              <div className="dragger-upload-div" style={{maxWidth:"100%",padding:"20px",cursor:"pointer"}} onClick={this.triggerUpload}>
              <Dragger style={{display: 'inline-block', width: "100%"}}
                  className="upload-dragger-artist"
                  id="upload-product"
                  openFileDialogOnClick={false}
                  // openFileDialogOnClick={!(this.validateFileUploads(this.state.modelFileList) == 'error')}
                  multiple={false}
                  progress= {{
                    strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                    },
                    strokeWidth: 3,
                    format: percent => `${parseFloat(percent.toFixed(2))}%`,
                    }}
                  disabled={this.props.productReducer.product.model_status != -4}
                  onRemove={file => {
                    this.setState({
                      modelFileList: [],
                      upload_error: '',
                      upload_failed_message: ''
                    });
                  }}
                  beforeUpload={file => {
                    if(file.name != this.props.match.params.id + '.zip'){
                      this.setState({
                        upload_error: "Invalid archive name, archive must be named " + this.props.match.params.id + '.zip'
                      });
                      return false;
                    }
                    else{
                      return this.checkFolderStructure(file);
                    }
                  }}
                  {...(Constants.getProductModelUploadProp(this.state.platform))}
                  onChange = {this.handleRejectedUploadChange}
                  listType="text"
                  fileList = {this.state.modelFileList}>
                    {this.validateFileUploads(this.state.modelFileList) == "uploading"  ?
                    <span>
                      <p className="artist-upload-txt large">Upload in Progress... </p>
                    </span> :
                    this.validateFileUploads(this.state.modelFileList) == "done" ? (<span>
                      <div className="justify-in-center">
                        <img height="25px" width="25px" src="/img/success.png" style={{marginRight: 5}}></img>
                        <p className="artist-upload-txt large" style={{color:"#25D6A4"}}>Upload Successful</p>
                      </div>
                    </span>):
                    this.validateFileUploads(this.state.modelFileList) == "error" ?
                    <span>
                      <div className="justify-in-center">
                        <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                        <p className="artist-upload-txt large" style={{color:"#D93025"}}>Upload Error</p>
                        {(this.validateFileUploads(this.state.modelFileList) == 'error') && (<Button
                              onClick={() => this.handleRetry('zip_file')}
                              style={{position: "unset",marginLeft: 8}}
                              disabled={this.state.modelFileList.length === 0}
                              loading={this.state.uploading}
                              className="retry-btn" ghost
                              >
                              {this.state.uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                          </Button>)}
                      </div>
                    </span> :
                    (<span>
                      <p className="ant-upload-drag-icon">
                      <img src="/img/exclude.png"></img>
                      </p>
                      <p className="artist-upload-txt">Drop your product model’s <span style={{color:"#333333"}}>Archive</span> folder here, or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                      <p className="artist-upload-txt small">
                        Only .zip files are supported
                      </p>
                    </span>) }
                </Dragger>
              </div>
              {this.state.upload_error == "" ? "" :
              <div style={{marginBottom: 8}}>
                <span className="category-artist red">{this.state.upload_error}</span>
              </div>}
              {this.state.upload_failed_message == "" ? "" :
                <div style={{marginBottom: 8}}>
                  <span className="category-artist red">{this.state.upload_failed_message}</span>
                </div>}
              {(this.state.rejections_data != undefined && this.state.rejections_data.length != 0) ?
              <Row style={{marginTop: 10,marginBottom: 10}}>
                <Col span={24}>
                  <div className="flagged-bg border custom">
                  {modal_rejections_data}
                  </div>
                </Col>
              </Row> : ''}
              {(this.state.rejections_data != undefined && this.state.rejections_data.length != 0) ?
              <Form.Item name="fix_time" className="artist-upload-txt align-text" rules={[this.state.input_fields_rules['fix_time']]} label={"Enter Time It Took To Fix The Issue(s)"} colon={false}>
                <Input
                  type="number"
                  min="0.01" max="30"
                  step="0.01"
                  placeholder="Please enter time (in hours) it took to fix the issue(s)."
                  disabled={this.state.input_fields_disabled['fix_time']}
                />
              </Form.Item>: ''}
              <Form.Item className="artist-form-button">
                <Button disabled={(this.state.request_submitted === true || this.validateFileUploads(this.state.modelFileList) != 'done')} className="modal-okay square" htmlType="submit">
                  <div className="modal-okay-text">Submit {this.state.request_submitted === true?<LoadingOutlined spin/>:""}</div>
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>

        <WarningModal
          visible={this.state.action_not_allowed_modal}
          onCancel={() => window.location.href = '/artist_products'}
          heading={"Model Submission Not Allowed"}
          text={"This model is no longer assigned to you so you cannot submit it."}
          footer={[
            <div className="justify-in-end">
              <Button key="back" style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => window.location.href = '/artist_products'}>
                Go to Homepage
              </Button>
            </div>
          ]} />
        </React.Fragment>
        :
        <NotAuthorized model_type="product"></NotAuthorized>
      }
      </ArtistMainLayout>
    );
  }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    }
});


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtistProductGuidelines)
)