import React from "react";
import { ClockCircleOutlined, InfoCircleOutlined, RetweetOutlined } from "@ant-design/icons";

const ProjectConstants = new Object();

ProjectConstants.status_data = {
    in_progress: {
      icon: <ClockCircleOutlined style={{ color: "grey" }} />,
      title: "All3D is creating",
    },
    in_review: {
      icon: <RetweetOutlined style={{ color: "grey" }} />,
      title: "Someone is reviewing",
    },
    needs_review: {
      icon: <RetweetOutlined style={{ color: "grey" }} />,
      title: "Ready for review",
    }
  };

  export default ProjectConstants;