import React from 'react';
import { Modal, message, Button, InputNumber, Input, Row, Col, Radio, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants'
import _ from "lodash" // Import the entire lodash library
import AWS from "aws-sdk";
import * as Utilities from '../../Utilities';
import axios from 'axios';
import SuccessModal from '../../SuccessModal/SuccessModal';

const radioStyleInline = {
    display: 'inline',
    height: '20px',
    lineHeight: '120%'
};

const CUSTOMER_USERNAME = localStorage.getItem('username')

class GenerateHighResModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            scene_id: props.selectedRender?.scene_id,
            renders: props.renders,
            selected_render: props.selectedRender,
            selected_image: '',
            isLoading: false,
            name: '',
            username: '',
            reportSuccess: false,
            image_visible: false,
            config_modal: false,
            dpi: 300,
            image_format: 'jpg',
            resolution_status: 1,
            resolution_value: '1024x640',
            imageResArr: [],
            aspect_ratio_selected: 1.6,
            orientation: 'Landscape',
            image_width: 1,
            image_height: 1,
            image_width_res: 1,
            image_height_res: 1,
            success_res: false,
            camera_index: 1,
            cameras: [],
            display_width: 1,
            display_height: 1,
            request_image_loader: false,
            design: '',
            config_exists: -1,
            config_data: {},
            confirmation_modal: false,
            selected_render: {},
            scene_name_edit: '',
            button_scene_loader: false,
            editable: false,
            generated_by_flag: false,
            render_names_being_edited: {},
            editable_render_name_id: '',
            render_name_button_loader: false,
            request_status: '',
            card_status: 0,
            current_package: '',
            subscription_plan: '',
            available_tokens: 0,
            used_tokens: 0,
            high_res_price: 0,
            requested_render_categories: ['4k'],
            card_save_failure: false,
            card_save_drawer: false,
            scene_payload: '',
            payment_request_id: -1,
            request_type: 'scene_lifestyle_image',
            payment_failure_message: '',
            payment_failure_modal: false,
            card_save_flow_triggered: false,
            subscription_modal: false,
            subscription_loader: false,
            scene_template_modal: false,
            space_categories: [],
            send_to_store_loader: false,
            bundle_found: false,
            render_generation: true,
            store_render_choices: [],
            platform: '',
            is_subscription_active: false,
            clipping_value: 0,
            error_message: '',
            loading_config_details: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props != prevProps) {
            if (this.props.modalVisible) {
                this.setState({
                    selected_render: this.props.selectedRender,
                    config_modal: this.props.modalVisible,
                    scene_id: this.props.selectedRender?.scene_id,
                    platform: this.props.selectedRender.platform,
                    selected_image: this.props.selectedRender.filename
                }, () => { this.getSceneInfo(this.props.selectedRender?.scene_id); })
            }
            else {
                this.setState({
                    config_modal: false,
                })
            }
        }
    }

    getSceneInfo = (scene_id) => {
        let payload = {
            "order_by": "id desc",
            "filter_string": `(id__exact=${scene_id})`,
            "required_fields": [
                "id",
                "name",
                "design",
                "final_renders"
            ]
        }

        axios.post(ENVIRONMENT.GET_SCENE_BATCH, payload)
            .then(res => {
                if (res && res.data['design']) {
                    this.setState({
                        design: res.data['design'],
                        renders: res.data['final_renders']
                    }, () => this.openConfigModal())

                    let design = res.data['design']
                    let final_renders = res.data['final_renders']
                    if (design) {
                        var design_obj = design;
                        design_obj['cameras'].forEach((cameraObj) => {
                            if (cameraObj.camera_type == "still") {
                                let cameraToks = cameraObj['camera_name'].split("_");
                                if (cameraToks[1] != undefined) {
                                    let cameraIndex = parseInt(cameraToks[1]);
                                    if (cameraIndex >= this.state.camera_index) {
                                        this.setState({
                                            camera_index: cameraIndex + 1
                                        });
                                    }
                                }
                                let camera_render = null;

                                let camera_render_file_name = cameraObj['camera_name'] + "." + cameraObj['image_format'];
                                if (final_renders.includes(camera_render_file_name)) {
                                    camera_render = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.state.scene_id + '/' + cameraObj['camera_name'] + "." + cameraObj['image_format'];
                                }
                                else {
                                    camera_render = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.state.scene_id + '/' + cameraObj['camera_name'] + "_preview.png";
                                }
                                let clipping_value = 0;
                                if (cameraObj.clipping_value != undefined) {
                                    clipping_value = cameraObj.clipping_value
                                }
                                this.addCamera(cameraObj['camera_name'], '', cameraObj.image_width, cameraObj.image_height, clipping_value, camera_render);
                            }
                        });
                    }
                    if (final_renders && final_renders.length > 0) {
                        this.setState({
                            store_render_choices: final_renders.filter(x => x.status == 'rendered' && x.filename != '360Camera.jpg')
                        })
                    }
                }
            })
    }


    loadUserRequestStatus = (request_category = null, request_type = null, confirm_request = null) => {
        let payload = {
            "username": localStorage.getItem('username'),
            "action": "check",
            "request_type": this.state.request_type,
            "categories": ['4k']
        }
        if (request_category != null && request_type != null) {
            payload.categories = request_category;
            payload.request_type = request_type;
        }



        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
            .then(res => {

                this.setState({
                    request_status: res.data['request_allowed'],
                    card_status: res.data['card_status'],
                    current_package: res.data['subscription_plan'],
                    available_tokens: res.data['available_lifestyle_tokens'],
                    used_tokens: res.data['used_lifestyle_tokens'],
                    high_res_price: res.data['price'],
                    is_subscription_active: res.data['is_subscription_active']
                })
            });
    }

    uploadCustomCameraImageOnAWS = (upload_path, blobData) => {
        console.log('Uploading on S3...')

        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: {
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });
        cognito_credentials.refresh(
            () => {
                AWS.config.update({
                    region: 'us-west-2',
                    credentials: cognito_credentials
                });
                const S3 = new AWS.S3();
                const objParams = {
                    Bucket: ENVIRONMENT.BUCKET_NAME,
                    Key: upload_path,
                    ACL: 'public-read',
                    Body: blobData,
                    ContentType: "image/png",
                    CacheControl: 'no-cache'
                };

                S3.upload(objParams, function (err, data) {
                    if (!err) {
                        console.log("Render preview uploaded successfully");
                    }
                    else {
                        console.log("Could not upload render preview");
                    }
                });
            });
    }

    uploadCustomCameraImageOnGoogle = (upload_path, blobData) => {
        console.log('Uploading on Google...')
        let key = upload_path
        axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
            'action': 'get_asigned_urls',
            'file_uri': key
        })
            .then(res => {
                let signedUrl = res.data;
                const xhr = new XMLHttpRequest();
                xhr.open('PUT', signedUrl, true);

                xhr.onload = function () {
                    console.log(xhr)
                    if (xhr.status === 200) {
                        console.log(xhr);
                        console.log("SEND FINISHED", xhr.response);
                        axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                            'action': 'make_file_public',
                            'file_uri': key
                        })
                    }
                };

                xhr.send(blobData);
            });
    }

    placeCustomCamera = (image_format, image_width, image_height, dpi, clipping_value, camera_name) => {
        let img_data = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.state.scene_id + '/' + camera_name;
        let camera_index = this.state.camera_index;
        var camera_name = "camera_" + camera_index;

        this.addCamera(camera_name, img_data, image_width, image_height, clipping_value);
        this.setState({
            camera_index: camera_index + 1,
        });

        let buf = Buffer.from(img_data.replace(/^data:image\/\w+;base64,/, ""), 'base64')
        let camera_render_upload_path = "scene_renders/" + this.state.scene_id + '/' + camera_name + "_preview.png";
        console.log("*****", this.state.platform);
        if (this.state.platform == 'aws') {
            this.uploadCustomCameraImageOnAWS(camera_render_upload_path, buf);
        } else {
            this.uploadCustomCameraImageOnGoogle(camera_render_upload_path, buf);
        }
        return camera_name;
    }

    returnPrice = (resolution, request_name, type = 'price') => {
        let width = resolution.split('x')[0]
        let height = resolution.split('x')[1]
        let category = '[]';
        // if (this.state.prices.length > 0) {
        if (Utilities.nearestThousand(height) == 4000 || Utilities.nearestThousand(width) == 4000 || height >= 4000 || width >= 4000) {
            category = "4k"
        } else if (Utilities.nearestThousand(height) == 3000 || Utilities.nearestThousand(width) == 3000) {
            category = "2k"
        } else if (Utilities.nearestThousand(height) == 2000 || Utilities.nearestThousand(width) == 2000) {
            category = "2k"
        } else if ((Utilities.nearestThousand(height) == 1000 || Utilities.nearestThousand(width) == 1000) && (height >= 1000 || width >= 1000)) {
            category = "1k"
        }
        // console.log(prices)
        // if (prices.length > 0 && prices[0]) {
        if (type == 'price') {
            // return parseInt(prices[0].price);
        } else {
            return category;
        }

        // }
        // }
    }

    openConfigModal = () => {

        if (this.state.selected_render && this.state.selected_render.filename) {
            this.setState({
                loading_config_details: true
            })
            let temp_arr = [];
            let image_format = 'jpg'
            let image_dpi = 300;
            let image_width = 1;
            let image_height = 1;
            let clipping_value = 0;
            let render_name = this.state.selected_render.filename.split(".")[0];
            this.state.renders.map(x => {
                let filename = x.filename.split(".")[0];
                temp_arr[filename] = false;
                if (filename == render_name) {
                    if (x.revision_of != undefined) {
                        this.setState({
                            config_exists: 0,
                        })
                    }
                    else {
                        if (x.filename.includes(".png")) {
                            image_format = 'png';
                        } else if (x.filename.includes(".jpg")) {
                            image_format = 'jpg';
                        } else if (x.filename.includes(".tiff")) {
                            image_format = 'tiff'
                        }
                        if (x.generated_by_admin) {
                            this.setState({
                                generated_by_flag: true
                            });
                        } else {
                            this.setState({
                                generated_by_flag: false
                            });
                        }
                        image_dpi = x.image_dpi;
                        if (x.image_width > x.image_height) {
                            image_width = parseInt(x.image_width * (4096 / x.image_width));
                            image_height = parseInt(x.image_height * (image_width / x.image_width));
                        } else {
                            image_height = parseInt(x.image_height * (4096 / x.image_height));
                            image_width = parseInt(x.image_width * (image_height / x.image_height));
                        }
                        let original_width = parseInt(x.image_width);
                        let original_height = parseInt(x.image_height);

                        this.setState({
                            resolution_value: (image_width.toString() + 'x' + image_height.toString())
                        })
                        this.orientationSettings(image_width, image_height, original_width, original_height);
                        if (x.clipping_value != undefined) {
                            clipping_value = x.clipping_value
                            this.setState({
                                clipping_value: clipping_value
                            })
                        }
                        axios.post(ENVIRONMENT.SCENE_CONFIG_GET_BY_CAMERA, {
                            "scene_id": this.state.scene_id,
                            "camera_name": render_name
                        })
                            .then(res => {
                                this.setState({
                                    config_data: res.data,
                                    loading_config_details: false
                                });
                                if (res.data.scene_config_id) {
                                    this.setState({
                                        config_exists: 1,
                                    });
                                } else {
                                    this.setState({
                                        config_exists: 0,
                                    })
                                }
                            })
                            .catch((error) => {
                                this.setState({
                                    config_exists: 0,
                                })
                            });
                    }
                }
            });

            this.setState({
                dpi: image_dpi,
                image_format: image_format
            }, () => {
                this.setState({
                    image_visible: temp_arr,
                    config_modal: true
                });
            });
            this.loadUserRequestStatus(['4k'], this.state.request_type, null)
        }
    }


    setResolutions(width, height, type) {
        // calculate high, medium, low resolutions;
        let imgResolutions = [];
        if (type == 'Portrait') {
            let res4k = Math.round(4096 * (width / height));
            let res2k = Math.round(2048 * (width / height));
            imgResolutions.push(res4k + 'x4096'); // high
            imgResolutions.push(res2k + 'x2048'); // medium

        }
        else {
            let res4k = Math.round(4096 * (height / width));
            let res2k = Math.round(2048 * (height / width));
            imgResolutions.push('4096x' + res4k); // high
            imgResolutions.push('2048x' + res2k); // medium
        }

        return imgResolutions;
    }

    orientationSettings = (width, height, original_width, original_height) => {
        let type = '';
        let ratio = parseFloat((width / height).toFixed(2));
        let resForOrientation = width;
        if (ratio == 0.56 || ratio == 0.63 || ratio == 0.52) {
            resForOrientation = height;
        }

        if (ratio == 1.60 || ratio == 1.78 || ratio == 1.91) {
            type = 'Landscape';
        }
        else if (ratio == 0.56 || ratio == 0.63 || ratio == 0.52) {
            type = 'Portrait';
        }
        else if (ratio == 1) {
            type = 'Square';
        }
        else {
            type = 'Custom';
        }

        if (resForOrientation == '4096') {
            this.setState({
                resolution_status: 1
            });
        } else if (resForOrientation == '2048') {
            this.setState({
                resolution_status: 1
            });
        } else {
            this.setState({
                resolution_status: 1
            }, () => {
                if (type == 'Portrait') {
                    this.onImageHeightChange(parseFloat(resForOrientation));
                }
                else if (type == 'Square' || type == 'Landscape') {
                    this.onImageWidthChange(parseFloat(resForOrientation));
                }
            });
        }

        this.setState({
            aspect_ratio_selected: ratio,
            orientation: type,
            image_width: width,
            image_height: height,
            image_width_res: width,
            image_height_res: height,
            display_width: original_width,
            display_height: original_height,
            imageResArr: this.setResolutions(width, height, type)
        });
    }

    onChangeDPI = (e) => {
        this.setState({
            dpi: e.target.value
        });
    }

    onImageFormatChange = (e) => {
        this.setState({
            image_format: e.target.value
        });
    }

    processImageRequest = (payload) => {

        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
            .then((response) => {
                if (response.data.status != undefined && response.data.status == 1) {
                    this.setState({
                        success_res: true,
                        config_modal: false,
                        request_image_loader: false
                    });
                }
                else {
                    this.setPaymentFailureMessage(response.data.error_message);
                    this.setPaymentFailureModal(true);
                    this.setState({
                        request_image_loader: false
                    })
                }
            })
            .catch((error) => {
                message.error('Error in submitting request!');
                this.setState({
                    config_modal: false,
                    request_image_loader: false
                });
            });

    }

    handleUpdatingPlacement = (new_cameras, scene_placement_state, scene_config_id) => {

        let payload = {
            scene_configuration_id: scene_config_id,
            cameras: new_cameras,
            placement_info: scene_placement_state
        }


        axios.post(ENVIRONMENT.SCENE_CONFIG_UPDATE, payload)
            .then((response) => {
                console.log("Success response", response);
                message.success('Updating scene configuration data...');
            })
            .catch((error) => {
            });

    }

    requestImage = () => {
        this.setState({
            request_image_loader: true
        });

        let image_format = this.state.image_format;
        let image_width = this.state.image_width;
        let image_height = this.state.image_height;
        let dpi = this.state.dpi;
        let final_renders = this.state.renders;
        let clipping_value = this.state.clipping_value;
        let camera_mode = FileConstants.SCENE_CREATOR_CAMERA_MODES.ThreeD;
        let design_obj = this.state.design;
        let scene_data = design_obj;
        if (scene_data['complaint_id']) {
            delete scene_data['complaint_id'];
        }
        let cameras_info = scene_data['cameras'];

        if (this.state.orientation == 'Custom') {
            image_width = parseFloat(this.state.image_width_res);
            image_height = parseFloat(this.state.image_height_res);
        }
        else if (this.state.resolution_status == 4 && this.state.orientation != 'Custom') {
            image_width = parseFloat(this.state.image_width);
            image_height = parseFloat(this.state.image_height);
        }
        else if (this.state.resolution_status != 4 && this.state.orientation != 'Custom') {
            image_width = parseFloat(this.state.resolution_value.split('x')[0]);
            image_height = parseFloat(this.state.resolution_value.substring(this.state.resolution_value.indexOf('x') + 1));
        }

        let camera_name = this.placeCustomCamera(image_format, image_width, image_height, parseInt(dpi), clipping_value);
        let selected_camera_name = this.state.selected_image.split(".")[0];

        let configuration_id = _.cloneDeep(this.state.config_data.scene_config_id);
        let asset_info = _.cloneDeep(this.state.config_data.placement_info.assets);
        let light_info = _.cloneDeep(this.state.config_data.placement_info.lights);
        let sun_info = _.cloneDeep(this.state.config_data.placement_info.sun);
        let space_configuration = _.cloneDeep(this.state.config_data.placement_info.space_configuration);
        let scene_placement_state = {
            assets: asset_info,
            sun: sun_info,
            lights: light_info,
            space_configuration: space_configuration
        }
        let placement_cameras = _.cloneDeep(this.state.config_data.placement_info.cameras);
        let new_cameras = _.cloneDeep(this.state.config_data.cameras);
        new_cameras.push(camera_name);
        let new_selected_camera = {};
        for (let camera of placement_cameras) {
            if (camera.camera_name == selected_camera_name) {
                if (camera.clipping_value != undefined) {
                    clipping_value = camera.clipping_value
                }
                if (camera.camera_mode != undefined) {
                    camera_mode = camera.camera_mode;
                }
                new_selected_camera = {
                    camera_name: camera_name,
                    camera_fov: _.cloneDeep(camera.camera_fov),
                    camera_position: _.cloneDeep(camera.camera_position),
                    camera_target: _.cloneDeep(camera.camera_target),
                    camera_type: _.cloneDeep(camera.camera_type),
                    camera_mode: camera_mode,
                    image_dpi: dpi,
                    image_format: image_format,
                    image_height: Math.round(image_height),
                    image_width: Math.round(image_width),
                    scene_state_index: 0,
                    scene_config_id: configuration_id,
                    clipping_value: clipping_value
                }
            }
        }
        placement_cameras.push(new_selected_camera);
        scene_placement_state['cameras'] = placement_cameras;
        this.handleUpdatingPlacement(new_cameras, scene_placement_state, configuration_id);
        cameras_info.push(new_selected_camera);
        console.log('cameras_info = ', cameras_info)
        for (let camera of cameras_info) {
            if (camera.camera_name == new_selected_camera.camera_name) {
                camera['selected'] = true;
            } else {
                camera['selected'] = false;
            }
        }

        scene_data["cameras"] = cameras_info



        scene_data["username"] = CUSTOMER_USERNAME;
        final_renders.push({
            filename: camera_name + '.' + image_format,
            image_width: image_width,
            image_height: image_height,
            type: "still",
            captured_by: CUSTOMER_USERNAME,
            is_msprovider: FileConstants.isMSProvider,
            clipping_value: clipping_value,
            camera_mode: camera_mode,
            image_dpi: dpi,
            time_updated: Date.now(),
            preview_render: this.state.selected_image,
            status: 'pending'
        });



        scene_data['generate_360'] = 0;
        scene_data['generate_still'] = 1;

        let payload = {
            "scene_id": this.state.scene_id,
            "username": CUSTOMER_USERNAME,
            "design": scene_data,
            "final_renders": final_renders,
            "genertate_renders": 1,
        };

        let requestPayload = payload;
        requestPayload['username'] = localStorage.getItem('username');
        let gateway_payload = {
            "username": localStorage.getItem('username'),
            "request_type": this.state.request_type,
            "action": "perform",
            "categories": this.state.requested_render_categories,
            "request_payload": requestPayload,
            "request_id": Date.now().toString()
        }


        this.setState({
            scene_payload: gateway_payload
        });

        if (this.state.request_status == 'allowed' || this.state.request_status == 'always_allowed') {
            this.processImageRequest(gateway_payload)
        }
        else if (this.state.request_status == 'update_subscription') {
            this.setState({
                subscription_modal: true,
                config_modal: false,
                request_image_loader: false
            })
        }
        else if (this.state.request_status == 'payment_required') {
            if (this.state.card_status == 1) {
                this.processImageRequest(gateway_payload)
            }
            if (this.state.card_status == 0) {

                window.addEventListener('card_saved', (e) => {
                    let action = e.detail.action;
                    this.setState({
                        card_save_flow_triggered: false
                    });
                    if (action == 'scene_lifestyle_image') {
                        this.setState({
                            card_status: 1
                        }, () => {
                            this.requestImage();
                        })

                    }

                }, false);


                let child_window = window.open('/save-card?action=scene_lifestyle_image', '', "width=600,height=600");
                let refreshInterval = setInterval(() => {
                    if (child_window.closed) {
                        this.setState({
                            card_save_flow_triggered: false,
                        });
                        clearInterval(refreshInterval);
                    }
                }, 500);
                this.setState({
                    card_save_flow_triggered: true
                });
            }

        }
        // Else here for payment required

    }

    goToSceneCreator = () => {
        this.setState({
            request_image_loader: true
        }, () => {
            window.location.href = "/scene_builder/" + this.state.scene_id;
        });
    }

    cancelSuccessModal = () => {
        this.setState({
            success_res: false
        }, () => {
            window.location.reload();
        });
    }

    onResolutionChange = (e) => {
        this.setState({
            resolution_status: e.target.value
        });
        if (e.target.value == 1) {
            this.setState({
                resolution_value: this.state.imageResArr[0]
            },
                () => {
                    let this_resolution = []
                    this_resolution[0] = this.returnPrice(this.state.resolution_value, this.state.request_type, 'category')
                    this.loadUserRequestStatus(this_resolution, this.state.request_type, null)
                    this.setState({
                        requested_render_categories: this_resolution
                    })
                }
            );
        }
        else if (e.target.value == 2) {
            this.setState({
                resolution_value: this.state.imageResArr[1]
            },
                () => {
                    let this_resolution = []
                    this_resolution[0] = this.returnPrice(this.state.resolution_value, this.state.request_type, 'category')
                    this.loadUserRequestStatus(this_resolution, this.state.request_type, null)
                    this.setState({
                        requested_render_categories: this_resolution
                    })
                }
            );
        }
        else if (e.target.value == 4) {
            this.setState({
                image_width: parseInt(this.state.imageResArr[0].split('x')[0]),
                image_height: parseInt(this.state.imageResArr[0].split('x')[1])
            },
                () => {
                    let this_resolution = []
                    this_resolution[0] = this.returnPrice(this.state.image_width + "x" + this.state.image_height, this.state.request_type, 'category')
                    this.loadUserRequestStatus(this_resolution, this.state.request_type, null)
                    this.setState({
                        requested_render_categories: this_resolution
                    })
                });
        }
    }

    onImageWidthChange = (value) => {
        let widthR = 1;
        let heightR = 1;
        let aspect_ratio = 1;
        if (this.state.aspect_ratio_selected == 1.6) {
            widthR = 16;
            heightR = 10;
        } else if (this.state.aspect_ratio_selected == 1.78) {
            widthR = 16;
            heightR = 9;
        } else if (this.state.aspect_ratio_selected == 1.91) {
            widthR = 1.91;
            heightR = 1;
        } else if (this.state.aspect_ratio_selected == 0.56) {
            widthR = 9;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.63) {
            widthR = 10;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.52) {
            widthR = 1;
            heightR = 1.91;
        }
        else if (this.state.aspect_ratio_selected == 1) {
            widthR = 1;
            heightR = 1;
        }
        aspect_ratio = widthR / heightR;
        if (typeof value != 'number') {
            value = 1
        }
        this.setState({
            image_width: value,
            image_height: value / aspect_ratio
        },
            () => {
                let this_resolution = []
                this_resolution[0] = this.returnPrice(this.state.image_width + "x" + this.state.image_height, this.state.request_type, 'category')
                if (this_resolution != "[]") {
                    this.loadUserRequestStatus(this_resolution, this.state.request_type, null)
                    this.setState({
                        requested_render_categories: this_resolution
                    })
                }
            }
        );
    }

    onImageHeightChange = (value) => {
        let widthR = 1;
        let heightR = 1;
        let aspect_ratio = 1;
        if (this.state.aspect_ratio_selected == 1.6) {
            widthR = 16;
            heightR = 10;
        } else if (this.state.aspect_ratio_selected == 1.78) {
            widthR = 16;
            heightR = 9;
        } else if (this.state.aspect_ratio_selected == 1.91) {
            widthR = 1.91;
            heightR = 1;
        } else if (this.state.aspect_ratio_selected == 0.56) {
            widthR = 9;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.63) {
            widthR = 10;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.52) {
            widthR = 1;
            heightR = 1.91;
        }
        else if (this.state.aspect_ratio_selected == 1) {
            widthR = 1;
            heightR = 1;
        }
        aspect_ratio = widthR / heightR;
        if (typeof value != 'number') {
            value = 1
        }
        let recalculated_width = value * aspect_ratio
        this.setState({
            image_width: recalculated_width,
            image_height: value,
        },
            () => {
                let this_resolution = []
                this_resolution[0] = this.returnPrice(this.state.image_width + "x" + this.state.image_height, this.state.request_type, 'category')
                if (this_resolution != "[]") {
                    this.loadUserRequestStatus(this_resolution, this.state.request_type, null)
                    this.setState({
                        requested_render_categories: this_resolution
                    })
                }
            });
    }

    addCamera = (camera_name, img_data, width, height, clipping_value, camera_render = '') => {
        const { cameras } = this.state;
        let resolution = Math.round(width) + 'x' + Math.round(height);
        let camera_obj = {
            name: camera_name,
            thumbnail: img_data,
            clipping_value: clipping_value,
            camera_render: camera_render,
            resolution: resolution
        };
        return this.setState({
            cameras: cameras.concat(camera_obj),
        });
    }

    closeModal = () => {
        this.props.setModalVisible(false)
        this.setState({
            selected_render: {},
            final_renders: [],
            design: null,
            scene_id: null,
            platform: null,
            selected_image: null,
            config_exists: -1,
            config_data: {}
        });
    }

    render() {

        return (
            <div key={this.props.modalVisible}>
                <Modal
                    maskClosable={false}
                    className="aspect-ratio-modal"
                    visible={this.state.config_modal}
                    onCancel={() => { this.closeModal() }}

                    destroyOnClose={true}
                    centered={true}
                    width={900}
                    footer={[
                        <div className={this.state.request_status == "payment_required" ? "justify-space-between" : "justify-in-end"}>
                            {this.state.request_status == "payment_required" ?
                                <div className="manrope f-16 black-55">
                                    Price: <span className="manrope f-16 w-700" style={{ color: "#25D6A4" }}>${this.state.high_res_price}</span>
                                </div> : ''}
                            <div className="justify-in-end">
                                <Button disabled={this.state.request_image_loader} className="modal-okay-gray square font-14" onClick={() => { this.closeModal() }}>
                                    Cancel
                                </Button>
                                <Button disabled={this.state.request_image_loader || this.state.config_exists == -1 || this.state.card_save_flow_triggered} className="modal-okay square font-14" htmlType="submit" onClick={this.state.config_exists == 1 ? this.requestImage : this.goToSceneCreator}>
                                    {this.state.config_exists == 1 ? (this.state.card_status == 1 && (this.state.request_status == "allowed" || this.state.request_status == "always_allowed" || this.state.request_status == "update_subscription") ? "Confirm" :
                                        this.state.card_status == 0 && this.state.request_status == "payment_required" ? "Confirm & Enter Payment Details" : "Confirm") : this.state.config_exists == 0 ? 'Go to Scene Creator' : 'Confirm'}
                                    <>{(this.state.request_image_loader) ? <LoadingOutlined spin /> : ""}</>
                                </Button>
                            </div>
                        </div>
                    ]}
                >
                    <div style={{ padding: "40px" }}>
                        <h2 className="snapshot-modal-heading">Snapshot Configuration</h2>
                        <Row type="flex" className={this.state.config_exists == 1 ? "" : "justify-in-center"}>
                            <Col span={12}>
                                <img src={this.state.selected_render.request_link} className="snapshot-modal-img no-border">
                                </img>
                            </Col>
                            {this.state.config_exists == 1 ?
                                <Col span={12} style={{ paddingLeft: 20 }}>
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <h5 className="modal-text small" style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center' }}><span>Orientation and Resolution of Original Image</span><Tooltip placement="right" title={'These are the orientation and resolution of your original image. We will maintain the original orientation with the new resolution.'}><img style={{ marginLeft: 10 }} src="/img/info-btn.png" /></Tooltip></h5>
                                    </div>
                                    <Row gutter={12}>
                                        <Col>
                                            <div className="note-bg-artist res"><span className="width-height-label greyish-black s-modal">{this.state.orientation}</span></div>
                                        </Col>

                                        <Col style={{ display: "contents" }}>
                                            <div className="note-bg-artist res"><span className="width-height-label greyish-black s-modal">{Math.round(this.state.display_width) + ' x ' + Math.round(this.state.display_height)}</span></div>
                                        </Col>
                                    </Row>
                                    <h5 className="modal-text small" style={{ display: this.state.orientation == 'Custom' ? "none" : "block", fontSize: "16px", marginTop: 32 }}>Resolution</h5>
                                    <Row style={{ display: this.state.orientation == 'Custom' ? "none" : "flex" }}>
                                        <Col>
                                            <Radio.Group style={{ marginBottom: 8 }} className="res-radio-settings" onChange={this.onResolutionChange} defaultValue={1} value={this.state.resolution_status}>
                                                <Radio style={{ radioStyleInline, marginBottom: 24 }} value={2}><span style={{ color: this.state.resolution_status == 2 ? "#555555" : "#777777" }} className="radio-btn-change scene-res" >2K ({this.state.imageResArr[1]})</span></Radio>
                                                <Radio style={{ radioStyleInline, marginBottom: 24 }} value={1}><span style={{ color: this.state.resolution_status == 1 ? "#555555" : "#777777" }} className="radio-btn-change scene-res" >4K ({this.state.imageResArr[0]})</span></Radio>
                                                <Radio style={{ radioStyleInline, marginBottom: 24 }} value={4}>
                                                    <span className={`radio-btn-change scene-res ${this.state.resolution_status == 4 ? `custom-res-message` : ``}`} style={{ color: this.state.resolution_status == 4 ? "#555555" : "#777777" }}> Add Custom Resolution
                                                        {
                                                            this.state.resolution_status == 4 ?
                                                                <span style={{ display: "flex", marginTop: 9 }}>
                                                                    <div className="custom-input-box" style={{ marginRight: 8 }}>
                                                                        <span>Width</span>
                                                                        <div className="custom-input-contain">
                                                                            <InputNumber onChange={this.onImageWidthChange} value={this.state.image_width} min={1} max={8096} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="custom-input-box">
                                                                        <span>Height</span>
                                                                        <div className="custom-input-contain">
                                                                            <InputNumber onChange={this.onImageHeightChange} value={this.state.image_height} min={1} max={8096} />
                                                                        </div>
                                                                    </div>
                                                                </span> : ""
                                                        }
                                                    </span>
                                                </Radio>
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                    <h2 style={{ display: this.state.orientation == 'Custom' ? "block" : "none", fontSize: 16, marginTop: 32 }} className="modal-text bold">Enter Resolution</h2>
                                    <Row style={{ display: this.state.orientation == 'Custom' ? "block" : "none" }} >
                                        <Col className="custom-res-row">
                                            <div className="custom-aspects s-modal" style={{ marginRight: 20 }}>
                                                <span className="width-height-label s-modal">Width</span>
                                                <InputNumber className="width-height-label s-modal" style={{ width: 100, marginLeft: 10 }} autoFocus={false} value={this.state.image_width_res} min={1} max={8096} onChange={this.onChangeWidthResolution} />
                                            </div>
                                            <div className="custom-aspects s-modal" style={{ marginRight: 20 }}>
                                                <span className="width-height-label s-modal">Height</span>
                                                <InputNumber className="width-height-label s-modal" style={{ width: 100, marginLeft: 10 }} autoFocus={false} value={this.state.image_height_res} min={1} max={8096} onChange={this.onChangeHeightResolution} />
                                            </div>
                                            <div className="custom-aspects s-modal">
                                                <span className="width-height-label s-modal">Unit</span>
                                                <Input className="width-height-label s-modal" style={{ width: 68, marginLeft: 10 }} value={'Pixels'} readOnly autoFocus={false} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ display: this.state.orientation == 'Custom' ? "block" : "none", marginBottom: 32 }} >
                                        <Col className="custom-res-row">
                                            <div className="note-bg">
                                                <span className="width-height-label s-modal" style={{ color: "#262626" }}>Note:&nbsp;</span><span className="width-height-label s-modal" style={{ color: "#595959" }}>The width and height you add here will be the resolution in pixels for your image.</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={[32, 32]} className="justify-in-start" style={{ alignItems: "flex-start" }}>
                                        <Col>
                                            <h5 className="modal-text small" style={{ fontSize: "16px" }}>Select DPI</h5>
                                            <Radio.Group
                                                defaultValue={300}
                                                value={this.state.dpi}
                                                onChange={this.onChangeDPI}
                                                buttonStyle="solid"
                                                className="dpi-radio">
                                                <Radio.Button value={300} className="dpi-settings">300 DPI</Radio.Button>
                                                <Radio.Button value={400} className="dpi-settings">400 DPI</Radio.Button>
                                                <Radio.Button value={600} className="dpi-settings">600 DPI</Radio.Button>
                                            </Radio.Group>
                                        </Col>

                                        <Col>
                                            <h5 className="modal-text small" style={{ fontSize: "16px" }}>Image Format</h5>
                                            <Radio.Group value={this.state.image_format} defaultValue={'jpg'} onChange={this.onImageFormatChange} className="modal-text bold">
                                                <Radio style={radioStyleInline} value={'jpg'}><span style={{ color: this.state.image_format == 'jpg' ? "#555555" : "#777777" }} className="radio-btn-change scene-res">JPG</span></Radio>
                                                <Radio style={radioStyleInline} value={'png'}><span span style={{ color: this.state.image_format == 'png' ? "#555555" : "#777777" }} className="radio-btn-change scene-res">PNG</span></Radio>
                                                <Radio style={radioStyleInline} value={'tiff'}><span span style={{ color: this.state.image_format == 'tiff' ? "#555555" : "#777777" }} className="radio-btn-change scene-res">TIFF</span></Radio>
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                </Col> :
                                this.state.config_exists == 0 && !this.state.generated_by_flag ?
                                    <Col span={12} style={{ paddingLeft: 20 }}>
                                        <div className="note-bg config">
                                            <div className="width-height-label black" style={{ marginBottom: 8 }}>Oops! We can not process this request</div>
                                            <div className="width-height-label s-modal gray">This image belongs to an older scene. Unfortunately, we can not generate a new version with updated settings for this image. Please go to the Scene Creator to capture a new image and request a high resolution render.</div>
                                        </div>
                                    </Col> : this.state.config_exists == 0 && this.state.generated_by_flag ?
                                        <Col span={12} style={{ paddingLeft: 20 }}>
                                            <div className="note-bg config">
                                                <div className="width-height-label black" style={{ marginBottom: 8 }}>Oops! We can not process this request</div>
                                                <div className="width-height-label s-modal gray">
                                                    Unfortunately, we can not generate a high resolution render for this image.
                                                    Some key information was lost while updating this image for you which prevents us from generating a new high resolution render.</div>
                                            </div>
                                        </Col>
                                        :
                                        <Col span={12} style={{ paddingLeft: 20, fontSize: 32 }} className="justify-in-center"><LoadingOutlined /></Col>}
                        </Row>
                    </div>
                </Modal>

                <Modal
                    className="modal-lifestyle-request"
                    centered={true}
                    maskClosable={false}
                    width={800}
                    onCancel={() => this.props.setConfirmHighResModal(false)}
                    visible={this.props.confirmHighResModal}
                    bodyStyle={{ padding: 0 }}
                    footer={[
                        <div className="justify-in-center">
                            <Button className="modal-okay-gray square font-14" htmlType="submit" onClick={() => this.props.setConfirmHighResModal(false)}>
                                Cancel
                            </Button>
                            <Button className="modal-okay square font-14" htmlType="submit" onClick={() => {this.props.setModalVisible(true); this.props.setConfirmHighResModal(false)}}>
                                Yes, I am Sure
                            </Button>
                        </div>
                    ]}>
                    <div style={{ padding: "40px" }}>
                        <img src='/img/alert-triangle.svg' style={{ width: "64px", height: "64px", margin: "auto" }} className="justify-in-center" alt="Alert" />

                        <div style={{ textAlign: "center", margin: "20px 0" }}>
                            <h2 className="modal-heading">This complaint hasn't been resolved yet! </h2>
                            <p className="artist-upload-txt" style={{ textTransform: "unset" }}>Are you sure you still want to generate high resolution image for this render?</p>
                        </div>
                    </div>
                </Modal>

                <SuccessModal
                    onCancel={this.cancelSuccessModal}
                    visible={this.state.success_res}
                    heading={"Image Requested Successfully!"}
                    text={"You have successfully requested a high resolution image. This image will be added to your Scene Library as soon as it is rendered."}
                    footer={[
                        <div className="justify-in-center">
                            <Button className="modal-okay square font-14" key="submit" onClick={() => {
                                this.setState({ reportSuccess: false });
                                window.location.reload();
                            }}>
                                Okay
                            </Button>
                        </div>
                    ]}
                />
            </div>

        )
    }
}
export default GenerateHighResModal
