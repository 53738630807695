import React, { useState, useEffect } from "react";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const FavouriteIcon = ({
  obj,
  handleMarkFav,
  handleUnmarkFav,
  favouriteImages,
}) => {
  const [isFav, setIsFav] = useState(obj?.isFavourite);
  useEffect(() => {
    console.log("object icon:", favouriteImages);

    if (favouriteImages.length === 0) {
      return;
    }

    setIsFav(favouriteImages.includes(obj.src));
  }, [favouriteImages]);

  const handleFavorite = () => {
    if (isFav) {
      handleUnmarkFav(obj);
    } else {
      handleMarkFav(obj);
    }
    setIsFav(!isFav);
  };

  return isFav ? (
    <Tooltip title={<span className="manrope f-12 white">Unfavorite</span>}>
      <div
        className="pointer-event-auto img-fav-icon-modal white"
        onClick={(e) => handleFavorite()}
      >
        <HeartFilled style={{ fontSize: "24px" }} />
      </div>
    </Tooltip>
  ) : (
    <Tooltip title={<span className="manrope f-12 white">Favorite</span>}>
      <div
        className="pointer-event-auto img-fav-icon-modal white"
        onClick={(e) => handleFavorite()}
      >
        <HeartOutlined style={{ fontSize: "24px" }} />
      </div>
    </Tooltip>
  );
};

export default FavouriteIcon;
