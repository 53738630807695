import { Button, Carousel, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import FileConstants from '../../../FileConstants';
import ENVIRONMENT from '../../../environments';
import { getBaseURL } from '../../../environments/env';
import DottedLoader from '../DottedLoader';

const MODEL_VIEWER_KEY = 'JKQ76HB9AB4HLNH8FK4';

const ProductDetailsComponentResponsive = (props) => {
    const [QAImages, setQAImages] = useState([]);
    const [show2DImages, setShow2DImages] = useState(true);
    const [displayLoader, setDisplayLoader] = useState(true);

    useEffect(() => {
        setQAImages(props?.productReducer?.product['2d']);
    }, [props]);

    const { match: { params } } = props;

    const getGlbUrl = () => {
        const downloadLinks = props.productReducer.product.download_links || [];
        const glbLink = downloadLinks.find((link) => link.display_name === 'GLB');
        if (glbLink) {
            return glbLink.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, getBaseURL(props.productReducer.product.platform));
        }
        return '';
    };

    const getMetadata = () => {
        if (props.productReducer.product["metadata"] && !Array.isArray(props.productReducer.product.metadata)) {
            return JSON.parse(props.productReducer.product['metadata']);
        }
        return null;
    };

    const getUSDZUrl = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name == 'USDZ');
        if (link) {
            let shortened_link = '';
            if(props.productReducer.product['platform'] == 'aws'){
                shortened_link = window.location.origin + '/usdz/' + params.id;
            }else{
                shortened_link = window.location.origin + '/usdz/g/' + params.id;
            }
            return shortened_link;
        } else {
            return ''
        }
    }

    const isARSupported = async () => {
        if (navigator.userAgent.includes("Android")) {
            return navigator.xr?.isSessionSupported('immersive-ar').then((supported) => supported) ?? false;
        } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            // For iOS, generally assume AR is supported if it’s iOS 12+ for AR Quick Look support
            return parseInt(navigator.appVersion.match(/OS (\d+)_/)[1], 10) >= 12;
        }
        return false;
    };

    const getAndroidZUrl = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name == 'GLB');

        if(isARSupported()) {
            return `intent://arvr.google.com/scene-viewer/1.0?file=${getGlbUrl()}&mode=ar_only#Intent;scheme=https;package=com.google.android.googlequicksearchbox;end;`;
        }
        else if (link) {
            let shortened_link = window.location.origin + '/android/' + params.id;
            return shortened_link.replace(ENVIRONMENT.DEFAULT_BASE_URL, getBaseURL(props.productReducer.product['platform']))
        } else {
            return ''
        }
    }

    const sendPostMessageToIframe = () => {
        const viewer = document.getElementById('model-viewer-3d-mob')?.contentWindow;
        if (viewer) {
            const message = {
                key: MODEL_VIEWER_KEY,
                glbURL: getGlbUrl(),
                metadata: getMetadata(),
                debugMode: false
            };
            console.log("Sending postMessage", message, viewer);
            viewer.postMessage(message, "*");
        }
        document.getElementById('model-inspector-frame-mob').style.display = 'block';
    };

    const openAr = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/iPhone|iPad|iPod/i.test(userAgent)) {
            window.location.href = getUSDZUrl();
        }
        else if (/android/i.test(userAgent)) {
            window.location.href = getAndroidZUrl();
        }
        else {
            window.location.href = getAndroidZUrl();
        }
    };

    useEffect(() => {
        if (!show2DImages)
            sendPostMessageToIframe();
        else
            document.getElementById('model-inspector-frame-mob').style.display = 'none';
        
    }, [show2DImages]);

    return (
        <React.Fragment>
            {displayLoader ? <DottedLoader display_inline={true} custom_class={false} /> : ""}
            {show2DImages && 
                <div className='product-details-carousel'>
                    <Carousel autoplaySpeed={10000} effect="fade" style={{ height: '100%', alignContent: 'center' }} draggable>
                        {QAImages && QAImages.map((src, index) => (
                            <div key={index} style={{ position: 'relative' }}>
                                <img
                                    src={src}
                                    alt={`Slide ${index + 1}`}
                                    style={{ width: '100%', height: 'auto' }}
                                    onLoad={() => setDisplayLoader(false)}
                                    onError={() => setDisplayLoader(false)}
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>
            }
                    
        
            <div
                id='model-inspector-frame-mob'
                className='model-inspector-viewer'
                style={{ width: "100vw", height: "100%" }}
            >
                <iframe
                    id='model-viewer-3d-mob'
                    className='model-inspector-frame'
                    src={FileConstants.ModelInspector + params.id}
                />
            </div>
            <div className='button-container'>
                <div className='view-ar-btn'>
                    <Button className="add-model-button font-75-em" onClick={openAr}>
                        View AR
                    </Button>
                </div>
                <div className='view-3d-btn'>
                    <Button className="add-model-button font-75-em" onClick={() => setShow2DImages(!show2DImages)}>
                        {show2DImages ? "View 3D" : "View Images"}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ProductDetailsComponentResponsive;
