import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import jsPDF from 'jspdf';
import AWS from "aws-sdk";
import ENVIRONMENT from '../../../../environments';
import _ from "lodash";
import { isEqual } from 'lodash-es';
import CollaborateNavBar from './CollaborateNavBar';
import CanvasEditor from '../../../../CanvasEditor';
import CollaborateCanvas from './CollaborateCanvas';
import CollaborateContext from '../../ContextFiles/CollaborateContext';
import './CollaborateTool.scss';
import CanvasLoader from './CanvasLoader';
import CollaborateAccess from './CollaborateAccess';
import CollaborationVersionHistory from './CollaborationVersionHistory';
import SceneCollaboratorLoader from './SceneCollaboratorLoader';
import CommentHistory from './CommentHistory';
import FileConstants from '../../../../FileConstants';
import { message } from 'antd';
import CollaborateRenderOverride from './CollaborateRenderOverride';
import CollaborationModals from '../CollaborationModals/CollaborationModals';
import CollaborateConstants from './Constants';
import CollaborateEmailSender from './CollaborateEmailSender';
import MspWarningModal from '../MspWarningModal/MspWarningModal';
import * as Utilities from '../../Utilities';
import { BUCKET_NAME } from '../../../../environments/env';
import { getBaseURL, BASE_URI } from '../../../../environments/env';

const COMPANY_ID = localStorage.getItem("company_id");
const MANAGED_COMPANY_ID = localStorage.getItem("managed_company_id");
const CUSTOMER_USERNAME = localStorage.getItem("username");
const IS_MSPROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;

const CollaborateTool = (props) => {
    const { match: { params } } = props;
    const url = new URL(window.location);
    const oldVersion = new URLSearchParams(url.search).get("version");

    const [sceneID, setSceneID] = useState(params.id);
    const [imageURL, setImageURL] = useState('');
    const [originalImageUrl, setOriginalImageUrl] = useState('');
    const [imageResolution, setImageResolution] = useState({});
    const [renderName, setRenderName] = useState('');
    const [orgRenderName, setOrgRenderName] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [canvasEditor, setCanvasEditor] = useState(null);
    const [initialLoader, setInitialLoader] = useState(true);
    const [refreshLoader, setRefreshLoader] = useState(false);
    const [objectColor, setObjectColor] = useState("#EE4B2B");
    const [objectThickness, setObjectThickness] = useState("thin");
    const [annotateModal, setAnnotateModal] = useState(false);
    const [selectedAction, setSelectedAction] = useState("select");
    const [tagUserList, setTagUserList] = useState([]);
    const [saveExitLoader, setSaveExitLoader] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [oldJson, setOldJson] = useState({});
    const [annotateLoader, setAnnotateLoader] = useState(false);
    const [secondSelectedOption, setSecondSelectedOption] = useState("");
    const [collaborateEntityID, setCollaborateEntityID] = useState(null);
    const [annotationDictionary, setAnnotationDictionary] = useState([]);
    const [canvasLoadFailure, setCanvasLoadFailure] = useState(false);
    const [userAccessLevel, setUserAccessLevel] = useState('');
    const [collaborateAccessModal, setCollaborateAccessModal] = useState(false);
    const [collaborators, setCollaborators] = useState([]);
    const [platform, setPlatform] = useState("aws");
    const [designExists, setDesignExist] = useState(false)
    const [versionHistoryVisible, setVersionHistoryVisible] = useState(false);
    const [historyOfRenders, setHistoryOfRenders] = useState([]);
    const [sceneCreatorLoader, setSceneCreatorLoader] = useState(false);
    const [sceneLoadFailed, setSceneLoadFailed] = useState(false);
    const [commentHistoryDrawer, setCommentHistoryDrawer] = useState(false);
    const [commentList, setCommentList] = useState([]);
    const [selectedCommentOption, setSelectedCommentOption] = useState("all_comments");
    const [showResolvedComments, setShowResolvedComments] = useState(false);
    const [displayedCommentList, setDisplayedCommentList] = useState([]);
    const [selectedComment, setSelectedComment] = useState(null);
    const [lastModified, setLastModified] = useState(null);
    const [lastModifiedBy, setLastModifiedBy] = useState(null);
    const [taggedAnnotations, setTaggedAnnotations] = useState([]);
    const [allAnnotations, setAllAnnotations] = useState([]);
    const [dataAdded, setDataAdded] = useState(false);
    const [activeCollaboratorList, setActiveCollaboratorList] = useState([]);
    const [initialJSON, setInitialJSON] = useState({});
    const [collabRenderOverrideModal, setCollabRenderOverrideModal] = useState(false);
    const [overrideRender, setOverrideRender] = useState([]);
    const [renderOverrideLoader, setRenderOverrideLoader] = useState(false);
    const [resolveConfirmModal, setResolveConfirmModal] = useState(false)
    const [isCollaborationOwner, setIsCollaborationOwner] = useState(false)
    const [isCollaborationResolved, setIsCollaborationResolved] = useState(false)
    const [collabSendEmailModal, setCollabSendEmailModal] = useState(false);
    const [collabEmailLoader, setCollabEmailLoader] = useState(false);
    const [userAnnotationIDs, setUserAnnotationIDs] = useState([]);
    const [annotationsData, setAnnotationsData] = useState([]);
    const [isMspUserWarningModal, setIsMspUserWarningModal] = useState(false);
    const [isUserIncorrect, setIsUserIncorrect] = useState(false);
    const [annotationDisplayToggle, setAnnotationDisplayToggle] = useState(false);
    const [canvasJson, setCanvasJson] = useState([]);
    const [imageFormat, setImageFormat] = useState('');
    const [currentVersion, setCurrentVersion] = useState(null);
    const [isOldVersionOpened, setIsOldVersionOpened] = useState();
    const [collaborationSignedUrl, setCollaborationSignedUrl] = useState('');
    const [versionName, setVersionName] = useState('');
    const [isVersionNameUpdate, setIsVersionNameUpdate] = useState(false);
    const [commentHistoryLoader, setCommentHistoryLoader] = useState(true);
    const [collabToolLoad, setCollabToolLoad] = useState(true);
    const [projectId, setProjectId] = useState(null)
    const [customGroups, setCustomGroups] = useState([])
    const [usersAndCustomGroupsList, setUsersAndCustomGroupsList] = useState([])
    const [productID, setProductID] = useState(null)

    useEffect(() => {
        let projectIdParam = new URLSearchParams(window.location.search).get("project_id");

        if(projectIdParam != undefined) {
            setProjectId(projectIdParam)
        }

        if (oldVersion) {
            setIsOldVersionOpened(true);
            setCurrentVersion(oldVersion);
            setUserAccessLevel("view");
        }
    }, [])

    useEffect(() => {
        if (oldVersion && sceneID && renderName && historyOfRenders.length && !isVersionNameUpdate) {
            const current = historyOfRenders.find((version) => version.version_number == oldVersion);
            const url = ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + sceneID + '/' + current.old_camera_name.replace("tiff", "jpg")
            const original_url = ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + sceneID + '/' + current.old_camera_name
            if (collabToolLoad == false) {
                setOldJson(current.old_state);
                setImageURL(url);
                setOriginalImageUrl(original_url)
                setImageFormat(Utilities.getImageFormat(current.old_camera_name));
                setVersionName(current.old_name);
                getImageResolutionFromURL(url)
                .then(resolution => {
                    setImageResolution(resolution);
                })
                .catch(error => {
                    console.error('Error:', error.message);
                });
                let canvas_editor = new CanvasEditor(url, setLoadingState, 'canvas-editor');
                setCanvasEditor(canvas_editor);
            }
           
        }
    }, [historyOfRenders, sceneID, renderName, collabToolLoad])


    useEffect(() => {
        getCollaborateEntity();
        getSharedUsers();
        getCommentHistory();
        fetchActiveCollaboraters();
    }, []);

    useEffect(() => {
        setActiveCollaboraters();
    }, [userAccessLevel]);

    useEffect(() => {
        if(isCollaborationResolved)
            setUserAccessLevel("view") // collaboration is resolved, set access to view
    }, [isCollaborationResolved, userAccessLevel])

    useEffect(() => {
        if (canvasEditor != null) {
            if (userAnnotationIDs.length > 0) {
                canvasEditor.setUserAnnotations(userAnnotationIDs);
            }
        }
    }, [canvasEditor, userAnnotationIDs])

    useEffect(() => {
        if (userAccessLevel != '') {
            if (!["owner", "co-owner"].includes(userAccessLevel)) {
                filterUserAnnotations(annotationsData)
            }
        }
    }, [userAccessLevel, annotationsData])
    
    useEffect(() => {
        // This effect will run when the component mounts
        // refresh everything after every 10 seconds
        const INTERVAL_ID = setInterval(() => {
            // This function will be called repeatedly at the specified interval
            if (CollaborateConstants.edit_access_levels.includes(userAccessLevel)) {
                refreshEverything();
            }
        }, 10000); // Interval is set to 10000 milliseconds (10 seconds)

        // cleanup function to clear the interval when the component unmounts
        return () => {
            clearInterval(INTERVAL_ID);
        };
    }, [userAccessLevel, lastModified, lastModifiedBy, initialLoader]); // dependency

    useEffect(() => {
        // This effect will run when the component mounts
        // autosave everything after 1 minute
        const INTERVAL_ID = setInterval(() => {
            // This function will be called repeatedly at the specified interval
            if (CollaborateConstants.edit_access_levels.includes(userAccessLevel)) {
                autoSaveData();
            }
        }, 60000); // 60000 milliseconds (1 min)

        // cleanup function to clear the interval when the component unmounts
        return () => {
            clearInterval(INTERVAL_ID);
        };
    }, [userAccessLevel, canvasEditor, initialJSON, selectedAction]); // dependency

    useEffect(() => {
        // This effect will run when the component mounts
        // Fetch & set active collaborators after every 1 minute
        const INTERVAL_ID = setInterval(() => {
            // This function will be called repeatedly at the specified interval
            if (CollaborateConstants.edit_access_levels.includes(userAccessLevel)) {
                setActiveCollaboraters();
                fetchActiveCollaboraters();
            }
        }, 60000); // 60000 milliseconds (1 min)

        // cleanup function to clear the interval when the component unmounts
        return () => {
            clearInterval(INTERVAL_ID);
        };
    }, [userAccessLevel]);

    useEffect(() => {
        if (FileConstants.isMSProvider && collaborators.length) {
            const currentUser  = localStorage.getItem('managed_customer_username');
            const allowedAccessLevels = ["owner", "co-owner", "edit"];
            if (currentUser == null )  {
                setIsUserIncorrect(false);
                setIsMspUserWarningModal(true);
            } else if (!isUserAuthorized(currentUser, allowedAccessLevels)) {
                setIsUserIncorrect(true);
                setIsMspUserWarningModal(true);
            }
        }
    },[collaborators]);

    useEffect(() => {
        combineUsersAndGroups()
      }, [customGroups, tagUserList])

    const combineUsersAndGroups = () => {
        let usersAndGroups = []
        console.log('tag_user_list = ', tagUserList)
        if (tagUserList.length > 0){
          usersAndGroups = [{
            id: 'all',
            display: 'all',
            is_msprovider: false,
            access_level: 'viewer'}].concat(tagUserList)
            
        }
        
        customGroups.filter(item => item.access_level).map(item => {
            // if check to avoid duplicate groups
            if(!usersAndCustomGroupsList.map(item => item.display).includes(item.group_name)){
                usersAndGroups.push({
                    id: item.group_name,
                    display: item.group_name,
                    access_level: item.access_level
                })
            }
        })
        setUsersAndCustomGroupsList(usersAndGroups)
    }

    const isUserAuthorized = (userName,allowedAccessLevels) => {
        return collaborators?.some((user) => {
            return user.username === userName && allowedAccessLevels.includes(user.access_level);
        })
    };

    useEffect(() => {
        if (isCollaborationResolved && allAnnotations.length && canvasEditor) {
            annotationsData.map((annotation) => {
                const group = canvasEditor.getGroupByAnnotationId(annotation.annotation_id)
                if (group?.is_resolved == false) {
                    canvasEditor.setGroupResolvedState(annotation.annotation_id)
                }
            })
            canvasEditor.changeHotspotColor(allAnnotations)
            saveCollaboration();
        }
    }, [annotationsData,isCollaborationResolved,allAnnotations]);

    const autoSaveData = () => {
        let json_equality = checkIfJSONIsEqual(); // check for json equality
        // check if canvas is modified and call auto save only then
        let is_canvas_modified = canvasEditor.isCanvasModified;
        let new_state_value = canvasEditor.convertToJSON();

        // json before color reset
        canvasEditor.changeHotspotColor(allAnnotations);
        let state_value = canvasEditor.convertToJSON();

        // json after color reset
        canvasEditor.changeHotspotColor(taggedAnnotations, "blue");
        if (!json_equality && selectedAction != "annotate" && is_canvas_modified) {
            let collab_payload = {
                'collaboration_id': params.id,
                'state': state_value,
                'last_modified_by': localStorage.getItem('username'),
                'action': 'update_state'
            }
            axios.post(ENVIRONMENT.COLLABORATION_ENTITY_UPDATE, collab_payload)
                .then(res => {
                    setInitialJSON(new_state_value);
                });
        }
    }

    const checkIfJSONIsEqual = () => {
        let old_json = {};
        if (Object.entries(initialJSON).length !== 0 && canvasEditor) {
            old_json = initialJSON;
        }
        let new_state_value = canvasEditor.convertToJSON();
        return isEqual(old_json, new_state_value);
    }


    const fetchActiveCollaboraters = () => {
        let payload = {
            'collaboration_id': params.id,
            'action': 'get_active_users'
        }
        axios.post(ENVIRONMENT.ACTIVE_COLLABORATORS, payload)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    let updated_collaborators = res.data.filter(user => user.customer_username != CUSTOMER_USERNAME);
                    setActiveCollaboratorList(updated_collaborators);
                }
            });
    }

    const setActiveCollaboraters = () => {
        if (CollaborateConstants.edit_access_levels.includes(userAccessLevel)) {
            let payload = {
                'collaboration_id': params.id,
                'customer_username': localStorage.getItem('username'),
                'action': 'set_active_users'
            }
            axios.post(ENVIRONMENT.ACTIVE_COLLABORATORS, payload)
                .then(res => {
                });
        }
    }



    const refreshEverything = () => {
        let payload = {
            'collaboration_id': params.id
        }
        axios.post(ENVIRONMENT.GET_COLLABORATE_ENTITY, payload)
            .then(res => {
                if (res.data) {
                    let lastModifiedNew = res.data.last_modified;
                    let lastModifiedByNew = res.data.last_modified_by;
                    if ((lastModifiedByNew != lastModifiedBy || lastModifiedNew != lastModified) && !initialLoader && (CUSTOMER_USERNAME != lastModifiedByNew)) {
                        resetStates(lastModifiedNew, lastModifiedByNew);
                    }
                }
            });
    }

    const resetStates = (last_modified, last_modified_by) => {
        setRefreshLoader(true);
        setOldJson({});
        setAnnotationDictionary([]);
        setImageURL('');
        setImageResolution({});
        setRenderName('');
        setDisplayName('');
        setSelectedCommentOption("all_comments");
        setSelectedAction("select");
        setTagUserList([]);
        setSecondSelectedOption("");
        setCollaborators([]);
        setHistoryOfRenders([]);
        setCommentList([]);
        setDisplayedCommentList([]);
        setSelectedComment(null);
        setLastModified(last_modified);
        setLastModifiedBy(last_modified_by);
        let message_string = 'Please wait. Loading changes made by ' + last_modified_by;
        message.loading(message_string, 5);
        getCollaborateEntity(true);
        getSharedUsers();
        getCommentHistory();
    }


    useEffect(() => {
        updateCommentList();
    }, [selectedCommentOption, showResolvedComments, commentList]);

    useEffect(() => {
        //restore old data
        if (Object.entries(oldJson).length !== 0 && canvasEditor && userAccessLevel != "") {
            canvasEditor.repopulateSavedProgress(oldJson["data"]);
            canvasEditor.setAccessLevel(userAccessLevel);
            setDataAdded(true);
        }
    }, [oldJson, canvasEditor, userAccessLevel]);

    useEffect(() => {
        if (dataAdded && Object.entries(oldJson).length !== 0 && canvasEditor && taggedAnnotations.length > 0) {
            canvasEditor.changeHotspotColor(taggedAnnotations, "blue");
            setDataAdded(false);
        }
    }, [taggedAnnotations, oldJson, canvasEditor, dataAdded]);


    const checkOtherUserCommentExist = () => {
        let result = false
        if (commentList.length) {
            commentList.map((comment) => {
                if (comment.created_by != CUSTOMER_USERNAME) {
                    result = true;
                }
            })
        }

        return result;
    }

    const updateCommentList = () => {
        let comment_list = [];
        if (selectedCommentOption == "all_comments" && showResolvedComments) {
            comment_list = commentList.filter(comment => comment.is_resolved == true);
        } else if (selectedCommentOption == "my_comments" && showResolvedComments) {
            comment_list = commentList.filter(comment => comment.created_by == CUSTOMER_USERNAME && comment.is_resolved == true);
        } else if (selectedCommentOption == "mentions" && showResolvedComments) {
            comment_list = commentList.filter(item => item.tagged_users.includes(`${CUSTOMER_USERNAME}`) && item.is_resolved == true);
        } else if (selectedCommentOption == "all_comments" && !showResolvedComments) {
            comment_list = commentList.filter(comment => comment.is_resolved == false);
        } else if (selectedCommentOption == "my_comments" && !showResolvedComments) {
            comment_list = commentList.filter(comment => comment.created_by == CUSTOMER_USERNAME && comment.is_resolved == false);
        } else if (selectedCommentOption == "mentions" && !showResolvedComments) {
            comment_list = commentList.filter(item => item.tagged_users.includes(`${CUSTOMER_USERNAME}`) && item.is_resolved == false);
        }
        setDisplayedCommentList(comment_list);
    }


    const getCommentHistory = () => {
        setCommentHistoryLoader(true);
        let oldVersionComments = oldVersion ? `&&version_number__exact=${oldVersion}` : '&&version_number__isnull=true';
        let payload = {
            "required_fields": ["comment_id", "annotation_id", "message", "sequence_no", "version_number",
                "created_by", "created_on", "attachment", "tagged_users", "is_resolved"],
            "filter_string": `(collaboration_id__exact=${params.id}&&is_hidden__exact=false${oldVersionComments})`,
            "order_by": "created_on desc"
        }

        axios.post(ENVIRONMENT.COMMENT_GET_BATCH, payload)
            .then(res => {
                if (res && res.data && res.data.length > 0) {
                    setCommentList(res.data);
                    openCommentHistory("block");
                    let tagged_user_annotation = [...new Set(res.data.filter(item => item.tagged_users.includes(CUSTOMER_USERNAME))
                        .map(item => item.annotation_id))];
                    setTaggedAnnotations(tagged_user_annotation);
                    let all_annotations = [...new Set(res.data.map(item => item.annotation_id))];
                    setAllAnnotations(all_annotations);
                }
                setCommentHistoryLoader(false);
            });
    }

    useEffect(() => {
        if (canvasEditor && commentList) {
            canvasEditor.setAllComments(commentList);
        }
    }, [canvasEditor, commentList]);

    useEffect(() => {
        if (dataAdded && Object.entries(oldJson).length !== 0 && canvasEditor && commentHistoryLoader == false) {
            canvasEditor.changeObjectVisibility(allAnnotations);
        }
    }, [canvasEditor, allAnnotations, dataAdded, commentHistoryLoader]);

    const getSharedUsers = () => {
        let payload = {
            'action': 'get',
            'collaboration_id': params.id
        };
        let company_id = COMPANY_ID;
        if (IS_MSPROVIDER && MANAGED_COMPANY_ID) {
            company_id = MANAGED_COMPANY_ID;
        }
        if (company_id) {
            payload['company_id'] = company_id;
        }
        axios.post(ENVIRONMENT.COLLABORATE_ACCESS_CONTROLLER, payload)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    let shared_customers = res.data.filter(item => item.group_id === null).map(item => {
                        return {
                            id: item.username,
                            username: item.username,
                            display: item.is_msprovider && (!item.display_name.includes('(ALL3D)')) ? item.display_name + ' (ALL3D)' : item.display_name,
                            email: item.email,
                            access_level: item.access_level
                        }
                    });
                    setCollaborators(shared_customers)

                    //  get company custom groups
                    let payload = {
                        'action': 'list_groups'
                    }
                    if(localStorage.getItem('company_id'))
                        payload['company_id'] = localStorage.getItem('company_id')
                    else
                        payload['created_by'] = localStorage.getItem('username')

                    axios.post(ENVIRONMENT.CUSTOM_GROUP_CONTROLLER, payload).then(res2 => {
                        if (res2?.data) {
                            Utilities.addAccessValueOfCustomGroups(res2.data, res.data, setCustomGroups)
                        }
                    })


                    console.log('Shared Users', shared_customers)
                    setTagUserList(shared_customers);
                    let user_access = res.data.filter(item => item.username == localStorage.getItem('username')).map(item => item.access_level);
                    console.log('Access Level', user_access);
                    if (oldVersion) {
                        return;
                    }
                    if (isCollaborationResolved){
                        setUserAccessLevel('view')
                    }
                    else if (user_access && user_access.length > 0) {
                        setUserAccessLevel(user_access[0]);
                    } else if (props && props.match && props.match.path.includes("admin-collaborate-tool")) {
                        setUserAccessLevel('view');
                    }
                    else {
                        setUserAccessLevel('restricted');
                    }
                } else if (!isOldVersionOpened) {
                    setUserAccessLevel('restricted');
                }

            });
    }

    const getCollaborateEntity = (refresh = false) => {
        if (refresh) {
            setRefreshLoader(true);
        } else {
            setInitialLoader(true);
        }
        let payload = {
            'collaboration_id': params.id
        }
        let collaboration_id = params.id;
        axios.post(ENVIRONMENT.GET_COLLABORATE_ENTITY, payload)
            .then(res => {
                console.log('Collaboration details: ', res)
                if (res.data) {
                    let render_name = res.data.camera_name.replace('tiff', 'jpg');
                    let scene_id = res.data.scene_id;
                    let product_id = res.data.product_id
                    let platform = res.data.platform;
                    let display_name = res.data.display_name;
                    let created_by = res.data.created_by;
                    let render_path = res.data.render_path
                    setSceneID(scene_id);
                    setProductID(product_id)
                    setRenderName(render_name);
                    setPlatform(platform);
                    setOrgRenderName(res.data.camera_name);
                    setImageFormat(Utilities.getImageFormat(res.data.camera_name));
                    
                    setLastModified(res.data.last_modified);
                    setLastModifiedBy(res.data.last_modified_by);
                    setDisplayName(display_name);
                    let base_url = null
                    let url = null
                    let original_url = null
                    if(scene_id){

                        base_url = ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI
                        url = base_url + scene_id + '/' + render_name;
                        original_url = base_url + scene_id + '/' + res.data.camera_name; // incase it was a tiff image, we need the actual url
                    }
                    else if(render_path){
                        base_url = ENVIRONMENT.getBaseURL(platform)
                        original_url = base_url + render_path
                        url = base_url + render_path.replace('tiff', 'jpg')
                    }
                    
                    
                    setCollaborateEntityID(collaboration_id);
                    if (!oldVersion) {
                        setOriginalImageUrl(original_url)
                        setImageURL(url);
                        getImageResolutionFromURL(url)
                        .then(resolution => {
                            console.log('Image resolution:', resolution.width, 'x', resolution.height);
                            setImageResolution(resolution);
                        })
                        .catch(error => {
                            console.error('Error:', error.message);
                        });
                        refreshCanvas(refresh);
                        let canvas_editor = new CanvasEditor(url, setLoadingState, 'canvas-editor');
                        setCanvasEditor(canvas_editor);
                        setOldJson(res.data.state);
                    }
                    let design_exist_state = res.data.design != null ? true : false;
                    setDesignExist(design_exist_state);
                    setIsCollaborationResolved(res.data.is_resolved)
                    if(localStorage.getItem('username') == res.data.created_by)
                        setIsCollaborationOwner(true)

                    setCollabToolLoad(false);
                }
            })
            .catch(err => {
                setInitialLoader(true);
                setCanvasLoadFailure(true);
            });
        getAnnotations(collaboration_id);
        fetchCollaborationRendersHistory(collaboration_id);
    }

    const getImageResolutionFromURL = (imageURL) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const resolution = {
                    width: img.width,
                    height: img.height,
                    aspectRatio: getAspectRatio(img.width, img.height)
                };
                resolve(resolution);
            };
            img.onerror = () => {
                reject(new Error('Failed to load the image.'));
            };
    
            img.src = imageURL;
        });
    }

    const getAspectRatio = (width, height) => {
        const gcd = (a, b) => {
            if (b === 0) {
                return a;
            } else {
                return gcd(b, a % b);
            }
        };
    
        const aspectRatioGCD = gcd(width, height);
        const aspectRatio = `${width / aspectRatioGCD}:${height / aspectRatioGCD}`;
        return aspectRatio;
    };

    const refreshCanvas = (refresh) => {
        if (refresh) {
            // clear and dispose old canvas
            // remove old canvas classes
            canvasEditor.clearFabricCanvas();
            setCanvasEditor(null);
            $('#canvas-editor').hide();
            $('#canvas-editor').siblings('.upper-canvas').remove();
            $('#canvas-editor').parent('.canvas-container').before($('#canvas-editor'));
            $('.canvas-container').remove();
            $('#canvas-editor').show();
        }
    }

    const getAnnotations = (collaboration_entity_id) => {
        let annotate_payload = {
            'collaboration_id': collaboration_entity_id
        }
        axios.post(ENVIRONMENT.ANNOTATION_GET_BATCH, annotate_payload)
            .then(res => {
                if (res && res.data) {
                    res.data.map((annotation) => {
                        getComments(annotation.annotation_id, annotation);
                    })
                    setAnnotationsData(res.data)
                }
            });
    }

    const filterUserAnnotations = (annotations) => {
        const userAnnotations = []
        annotations.map ((annotation) => {
            if (annotation.created_by != CUSTOMER_USERNAME) {
                userAnnotations.push(annotation.annotation_id)
            }
        })
        setUserAnnotationIDs(userAnnotations)
    }

    const getComments = (annotation_id, annotation_detail) => {
        let oldVersionComments = oldVersion ? `&&version_number__exact=${oldVersion}` : '&&version_number__isnull=true';
        let annotate_payload = {
            "required_fields": ["comment_id", "annotation_id", "message",
                "created_by", "created_on", "attachment", "tagged_users"],
            "filter_string": `(annotation_id__exact=${annotation_id}&&is_hidden__exact=false${oldVersionComments})`,
            "order_by": "created_on asc"
        }
        axios.post(ENVIRONMENT.COMMENT_GET_BATCH, annotate_payload)
            .then(res => {
                if (res && res.data && res.data.length > 0) {
                    createAnnotationDict(annotation_detail, res.data);
                }
            })
    }

    const createAnnotationDict = (annotation_detail, comments) => {
        let annotation_dict = annotation_detail;
        annotation_dict.comments = comments;
        setAnnotationDictionary(prev_annotation_dict => [...prev_annotation_dict, annotation_dict]);
    }


    const saveCollaboration = (onSaveSuccess = null) => {
        let new_state_value = canvasEditor.convertToJSON();
        setInitialJSON(new_state_value);
        canvasEditor.changeHotspotColor(allAnnotations);
        let state_value = canvasEditor.convertToJSON()
        canvasEditor.changeHotspotColor(taggedAnnotations, "blue");
        let collab_payload = {
            'collaboration_id': collaborateEntityID,
            'state': state_value,
            'last_modified_by': localStorage.getItem('username'),
            'action': 'update_state'
        }
        axios.post(ENVIRONMENT.COLLABORATION_ENTITY_UPDATE, collab_payload)
            .then(res => {
                if (onSaveSuccess != null) {
                    onSaveSuccess();
                }
            })
    }

    // Functions for CollaborateNavbar.jsx
    const saveAndExit = () => {
        let redirection_path = new URLSearchParams(window.location.search).get("origin");
        setSaveExitLoader(true);
        saveCollaboration(() => {
            setSaveExitLoader(false);
            if (redirection_path) {
                window.location.href = redirection_path;
            } else if(sceneID) {
                window.location.href = '/scene_renders/' + sceneID;
            }
            else{
                window.location.href = '/products/' + productID;
            }
        })
    }

    // Functions for CollaborateNavbar.jsx
    const saveCollaborationData = () => {
        setSaveLoader(true);
        saveCollaboration(() => {
            setSaveLoader(false);
            message.success("Collaboration Saved");
        });
    }


    const createCollaborationDesign = (onSaveSuccess, onFail) => {
        let payload = {
            'collaboration_id': collaborateEntityID,
            'scene_id': sceneID,
            'camera_name': renderName
        }
        axios.post(ENVIRONMENT.CREATE_COLLABORATE_DESIGN, payload)
            .then(res => {
                if (res.data && res.data.body.collaboration_design_created) {
                    onSaveSuccess();
                }
                else if (onFail) {
                    onFail();
                }
            })
            .catch(err => {
                if (onFail) {
                    onFail()
                }
            });
    }

    const fetchCollaborationRendersHistory = (collaborationID) => {
        let payload = {
            'required_fields': ['collaboration_id', 'old_camera_name',
                'old_state', 'old_last_modified', 'old_created_by',
                'version_number', 'old_name'],
            'filter_string': `(collaboration_id__exact=${collaborationID})`,
            'order_by': 'version_number desc'
        }
        axios.post(ENVIRONMENT.GET_BATCH_COLLABORATE_ENTITY, payload)
            .then(res => {
                if (res.data) {
                    setHistoryOfRenders(res.data);
                    if (!oldVersion) {
                        const maxVersion = res.data.length + 1;
                        setCurrentVersion(maxVersion);
                    }
                }
            })
            .catch(err => {
            });
    }

    const onSceneCreatorLoadFail = () => {
        setSceneLoadFailed(true)
        setSceneCreatorLoader(false)
    }

    const goToSceneCreator = () => {
        setSceneCreatorLoader(true)
        try {
            saveCollaboration(() => {
                if (!designExists) {
                    createCollaborationDesign(() => {
                        window.location.href = projectId ? 
                        `/scene_collaborator/${collaborateEntityID}?project_id=${projectId}` : 
                        `/scene_collaborator/${collaborateEntityID}`
                    }, onSceneCreatorLoadFail)
                }
                else {
                    window.location.href = projectId ? 
                        `/scene_collaborator/${collaborateEntityID}?project_id=${projectId}` : 
                        `/scene_collaborator/${collaborateEntityID}`
                }
            })
        }
        catch (err) {
            onSceneCreatorLoadFail()
        }

    }

    const exitTool = () => {
        const currentUrl = new URL(window.location);
        const previousUrl = new URL(document.referrer);
        const previousUrlParams = new URLSearchParams(previousUrl.search);
        const previousUrlHaveVersion = previousUrlParams.has("version");
        previousUrl.searchParams.delete("version");
        if (oldVersion) {
            window.location.href = "/collaborate-tool/" + params.id;
        } 
        if (projectId) {
            window.location.href = "/projects/" + projectId
        }
        else {
            // check if previous url is version url then move to collaborations
            if (currentUrl.toString() == previousUrl.toString() && previousUrlHaveVersion)
            {
                window.location.href = "/collaborations"
            }
            // if page opened in new tab and previous url is not version url
            else if (window.history.length === 1 && !previousUrlHaveVersion) {
                window.location.href = document.referrer;
            }
            else{
                window.history.back();
            }
        }
    }
     
    // Functions for CanvasToolbar.jsx file
    const selectAction = (main_action, secondary_action = "") => {
        setSecondSelectedOption(secondary_action);
        setSelectedAction(main_action);
        canvasEditor.enableAction(main_action, secondary_action, objectColor);
    }

    const extraOption = (main_action, secondary_action) => {
        canvasEditor.enableAction(selectedAction, main_action, objectColor, secondary_action);
        setObjectThickness(secondary_action)
    }

    const changeColor = (e) => {
        setObjectColor(e.target.value);
        setSecondSelectedOption("");
        setSelectedAction("color");
        canvasEditor.enableAction("color", "", e.target.value);
    }

    const onColorClick = () => {
        setSecondSelectedOption("");
        setSelectedAction("color");
        canvasEditor.enableAction("color", "", objectColor);
    }

    // Functions for Annotation.jsx file
    const toggleAnnotateDisplay = (display_value) => {
        let annotate_modal = document.getElementById("annotate-modal");
        if (annotate_modal) {
            annotate_modal.style.display = display_value;
            setTimeout(() => {
                let comment_container = document.querySelector('.comment-container');
                if (comment_container) {
                    comment_container.scrollTop = comment_container.scrollHeight;
                }
            }, 1000);
        }
    }

    useEffect(() => {
        if (canvasEditor) {
            setInitialJSON(canvasEditor.convertToJSON());
        }

    }, [initialLoader, refreshLoader])

    const setLoadingState = () => {
        setInitialLoader(false);
        setRefreshLoader(false);
    }

    const closeVersionHistoryDrawer = () => {
        setVersionHistoryVisible(false);
    }

    const closeCommentHistoryDrawer = () => {
        toggleCommentDrawer("none");
    }

    const handleChangeCommentOption = (value) => {
        setSelectedCommentOption(value);
    }

    const toggleResolveComments = (e) => {
        setShowResolvedComments(e.target.checked);
    }

    const toggleCommentDrawer = (display_value) => {
        let element = document.getElementById('comment_drawer');
        if (element) {
            element.style.display = display_value;
        }
    }

    const openCommentHistory = () => {
        toggleCommentDrawer("block");
    }

    const openComment = (comment) => {
        setSelectedComment(comment);
    }

    const handleOverrideRenderChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setOverrideRender(fileList);
    };

    const handleRenderOverride = () => {
        console.log(JSON.stringify(overrideRender))
        setRenderOverrideLoader(true);
        const isOtherUserCommentExist = checkOtherUserCommentExist();

        let payload = {
            'collaboration_id': collaborateEntityID,
            'collaboration_render': orgRenderName,
            'username': CUSTOMER_USERNAME,
            'display_name': displayName,
            'new_render': overrideRender,
        }

        if (!isOtherUserCommentExist) {
            payload['render_override'] = false;
        }

        axios.post(ENVIRONMENT.COLLABORATION_RENDER_OVERRIDE, payload)
            .then(res => {
                if (res.data) {
                        message.success("Render Updated Successfully");
                        setRenderOverrideLoader(false);
                        window.location.reload();
                }
            })
            .catch(err => {
            });
    }

    const sendEmailNotification = (emailContent, taggedUsers) => {
        setCollabEmailLoader(true);
        let payload = {
            'collaboration_id': collaborateEntityID,
            'collaboration_render': renderName,
            'username': CUSTOMER_USERNAME,
            'tagged_users': taggedUsers,
            'email_content': emailContent
        }

        axios.post(ENVIRONMENT.SEND_COLLABORATION_NOTIFICATION, payload)
        .then(res => {
            setCollabEmailLoader(false);
            setCollabSendEmailModal(false);
        });
    }

    const convertToPDF = () => {
        let loader = message.loading('Preparing to download..', 0)
        const canvasData = canvasEditor.convertToJSON();
        canvasEditor.convertRenderFeedbackToPDF(canvasData, params.id, true, loader);
    }
    
    const handleWarningModalClose = () => {
        setIsMspUserWarningModal(false);
    }

    const updtateVersionNameInHistory = (versionName,versionNumber) => {
        setIsVersionNameUpdate(true);
        let historyOfRendersCopy = [...historyOfRenders];
        if (versionNumber == currentVersion) {
            setVersionName(versionName);
        }
        historyOfRendersCopy.map((version) => {
            if(version.version_number == versionNumber){
                version.old_name = versionName;
            }
        });
        setHistoryOfRenders(historyOfRendersCopy);
    }


    return (
        <>
            <CollaborateContext.Provider
                value={{
                    scene_id: sceneID,
                    product_id: productID,
                    url: imageURL,
                    original_img_url: originalImageUrl,
                    render_name: renderName,
                    display_name: displayName,
                    canvas_editor: canvasEditor,
                    annotate_modal: annotateModal,
                    setAnnotateModal: setAnnotateModal,
                    collaboration_id: collaborateEntityID,
                    setSelectedAction: setSelectedAction,
                    selected_action: selectedAction,
                    tag_user_list: tagUserList,
                    saveAndExit: saveAndExit,
                    save_loader: saveLoader,
                    scene_creator_loader: sceneCreatorLoader,
                    exitTool: exitTool,
                    annotation_dict: annotationDictionary,
                    setAnnotationDictionary: setAnnotationDictionary,
                    secondary_selected_action: secondSelectedOption,
                    selectAction: selectAction,
                    extraOption: extraOption,
                    changeColor: changeColor,
                    objectColor: objectColor,
                    objectThickness: objectThickness,
                    goToSceneCreator: goToSceneCreator,
                    toggleAnnotateDisplay: toggleAnnotateDisplay,
                    initialLoader: initialLoader,
                    canvasLoadFailure: canvasLoadFailure,
                    access_level: userAccessLevel,
                    collaborate_access_modal: collaborateAccessModal,
                    setCollaborateAccessModal: setCollaborateAccessModal,
                    collaborators: collaborators,
                    getCollaborators: getSharedUsers,
                    platform: platform,
                    onColorClick: onColorClick,
                    annotateLoader: annotateLoader,
                    setAnnotateLoader: setAnnotateLoader,
                    versionHistoryVisible: versionHistoryVisible,
                    setVersionHistoryVisible: setVersionHistoryVisible,
                    closeVersionHistoryDrawer: closeVersionHistoryDrawer,
                    historyOfRenders: historyOfRenders,
                    setCollaborators: setCollaborators,
                    sceneLoadFailed: sceneLoadFailed,
                    commentHistoryDrawer: commentHistoryDrawer,
                    setCommentHistoryDrawer: setCommentHistoryDrawer,
                    closeCommentHistoryDrawer: closeCommentHistoryDrawer,
                    commentList: commentList,
                    handleChangeCommentOption: handleChangeCommentOption,
                    toggleResolveComments: toggleResolveComments,
                    selectedCommentOption: selectedCommentOption,
                    displayedCommentList: displayedCommentList,
                    getCommentHistory: getCommentHistory,
                    openCommentHistory: openCommentHistory,
                    openComment: openComment,
                    selectedComment: selectedComment,
                    saveCollaborationData: saveCollaborationData,
                    saveExitLoader: saveExitLoader,
                    refreshLoader: refreshLoader,
                    taggedAnnotations: taggedAnnotations,
                    allAnnotations: allAnnotations,
                    activeCollaboratorList: activeCollaboratorList,
                    setInitialJSON: setInitialJSON,
                    setSelectedComment: setSelectedComment,
                    product_id: -1,
                    collabRenderOverrideModal: collabRenderOverrideModal,
                    setCollabRenderOverrideModal: setCollabRenderOverrideModal,
                    overrideRender: overrideRender,
                    handleOverrideRenderChange: handleOverrideRenderChange,
                    handleRenderOverride: handleRenderOverride,
                    renderOverrideLoader: renderOverrideLoader,
                    setResolveConfirmModal: setResolveConfirmModal,
                    isCollaborationOwner: isCollaborationOwner,
                    isCollaborationResolved: isCollaborationResolved,
                    resolution: imageResolution,
                    collabSendEmailModal: collabSendEmailModal,
                    setCollabSendEmailModal: setCollabSendEmailModal,
                    collabEmailLoader: collabEmailLoader,
                    sendEmailNotification: sendEmailNotification,
                    getAspectRatio: getAspectRatio,
                    setAnnotationDisplayToggle: setAnnotationDisplayToggle,
                    annotationDisplayToggle: annotationDisplayToggle,
                    canvasJson: canvasJson,
                    setCanvasJson: setCanvasJson,
                    imageFormat: imageFormat,
                    currentVersion: currentVersion,
                    convertToPDF: convertToPDF,
                    isOldVersion: !!oldVersion,
                    versionName: versionName,
                    setVersionName: setVersionName,
                    setDisplayName: setDisplayName,
                    updtateVersionNameInHistory: updtateVersionNameInHistory,
                    customGroups: customGroups,
                    setCustomGroups: setCustomGroups,
                    usersAndCustomGroupsList: usersAndCustomGroupsList
                }}>
                <CollaborateNavBar />
                <CollaborateCanvas />
                <CanvasLoader />
                <CollaborateAccess />
                <CollaborationVersionHistory />
                <CommentHistory context_for={"collaborate_tool"} />
                <SceneCollaboratorLoader />
                <CollaborateRenderOverride />
                <CollaborationModals 
                    resolveConfirmModal={resolveConfirmModal}
                    setResolveConfirmModal={setResolveConfirmModal}
                    selectedCollab={{id: collaborateEntityID, type: 'scene'}}
                    setSelectedCollab={() => {}} // pass empty function
                />
                <CollaborateEmailSender />
            </CollaborateContext.Provider>
            <MspWarningModal
                visible = {isMspUserWarningModal}
                handleModalClose = {handleWarningModalClose}
                isUserIncorrect = {isUserIncorrect}
            />
        </>
    );
}


export default CollaborateTool;