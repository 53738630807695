import React, { useState, useEffect, useContext } from 'react';
import ENVIRONMENT from '../../../../environments';
import * as Constants from "../../CustomerComponents/Constants";
import { Tooltip, Row, Card, Col, Upload } from 'antd';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';
import CollaborateQACanvas from './CollaborateQACanvas';
import CollaborateCanvasToolbar from './CollaborateCanvasToolbar';
import * as Styles from "../../../../Styles";
import ImageMagnify from '../ImageMagnify/ImageMagnify';
import Annotation from '../CollaborateTool/Annotation';

const progress_bar = Styles.progress_bar;

const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem("company_id");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");

const ImageListType = (props) => {
    const { productQAImages, collaborateMode, selectedProductImage } = useContext(CollaborateQAContext);

    return (productQAImages && productQAImages.filter(image => image.main_type == props.type).length > 0 ?
        <>
            <div className='manrope f-14 black-26 w-100'>{props.name}</div>
            {productQAImages.filter(image => image.main_type == props.type).map((image, index) => (
                <Card id={image.id}
                    className={`product-qa-img-grid-card display-flex ${selectedProductImage == image.url ? `selected` : ``}`}
                    key={index} onClick={() => { props.selectImage(image.url) }}>
                        <div className='manrope f-12 white product-comparison-img-tag left'>Preview</div>
                        <div className='manrope f-12 white product-comparison-img-tag right'>Reference</div>
                    <img className='product-img-grid-img' src={image.low_url} />
                    {image.annotation_count > 0 ?
                        <Tooltip title={<span className='manrope f-12 white'>This image has {image.annotation_count} annotation(s).</span>}>
                            <div className='annotation-circle justify-in-center'>
                                <p className='manrope f-10 white mb-0'>{image.annotation_count}</p>
                            </div>
                        </Tooltip> : ''}
                </Card>
            ))}
        </> : "")
}

const ImageListTypePreviews = (props) => {
    const { productQAImages, collaborateMode, selectedProductImage } = useContext(CollaborateQAContext);

    return (productQAImages && productQAImages.filter(image => image.main_type == props.type).length > 0 ?
        <>
            <div className='manrope f-14 black-26 w-100'>{props.name}</div>
            {productQAImages.filter(image => image.main_type == props.type).map((image, index) => (
                <Card id={image.id}
                    className={`product-img-grid-card qa-preview display-flex ${selectedProductImage == image.url ? `selected` : ``}`}
                    key={index} onClick={() => { props.selectImage(image.url) }}>
                    <img className='product-img-grid-img' src={image.low_url} />
                    {collaborateMode && image.annotation_count > 0 ?
                        <Tooltip title={<span className='manrope f-12 white'>This image has {image.annotation_count} annotation(s).</span>}>
                            <div className='annotation-circle justify-in-center'>
                                <p className='manrope f-10 white mb-0'>{image.annotation_count}</p>
                            </div>
                        </Tooltip> : ''}
                </Card>
            ))}
        </> : "")
}


const ProductCollaborateQAContainer = () => {
    const { canvas_editor, productQAImages, selectedProductImage, refImagePhotoList, setRefImagePhotoList, setErrorMessage,
        setSelectedProductImage, canvasJson, setCanvasJson, productAssetData, getAnnotations, toggleAnnotateDisplay,
        collaborateMode, repopulateSavedProgress, canvasLoader, imageUploadLoader, setDataAutoSaved, setCanvasLoader,
        initialLoader, projectID,
        setSelectComparisonImageModal, access_level, closeCommentHistoryDrawer, setAnnotationDisplayToggle,
        selectedCollaborationID, setSelectedCollaborationID, setInitialJSON, hideProductBanner, setHideProductBanner,
        autoSaveLoader, autoSaveData, displayLoader, setDisplayLoader, repopulated, save_loader,
    annotateLoader, refreshLoader } = useContext(CollaborateQAContext);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const selectImage = (url) => {
        console.log("Selecting image.. ");
        let current_canvas_data = canvas_editor.convertToJSON();
        const dict = { ...canvasJson };
        let new_image_id = getQAID(url); // new image
        let old_image_id = getQAID(selectedProductImage); // previously selected image
        if (new_image_id != selectedCollaborationID) { // do all of this if the same image is not selected
            dict[old_image_id] = current_canvas_data;
            setCanvasJson(dict);
            setInitialJSON(current_canvas_data);

            // setDataAutoSaved(false); // so we know its not autosaved here
            if (autoSaveLoader || displayLoader || save_loader || annotateLoader || refreshLoader || canvasLoader) {

            } else {
                if (collaborateMode) {
                    autoSaveData();
                }
            }
            
            setDisplayLoader(true); // loader before image is loaded
            setCanvasLoader(true); // loader to before canvas is repopulated
            setSelectedProductImage(url); // setting new image url
            display_loader_and_set_image(url); // set image loader to false here after image has loaded
            toggleAnnotateDisplay("none"); // comments box should close on new image
            closeCommentHistoryDrawer(); // comment drawer (list) should close on new image
            setSelectedCollaborationID(new_image_id);
            setAnnotationDisplayToggle(true);
            getAnnotations(new_image_id);
            repopulateSavedProgress(new_image_id, url);
        }

    }

    const display_loader_and_set_image = (image_src) => {
        image_src = image_src.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(productAssetData['platform']))
        const img = new Image();
        img.onload = function () {
            setDisplayLoader(false);
            setSelectedProductImage(image_src);
            forceUpdate();
        }
        img.src = image_src;
    }

    const getQAID = (url) => {
        let ids = productQAImages.filter(item => item.url == url);
        if (ids.length > 0) {
            return ids[0].id;
        }
    }

    const getType = (url) => {
        let types = productQAImages.filter(item => item.url == url);
        if (types.length > 0) {
            return types[0].type;
        }
    }


    const getPlatform = () => {
        let product = productAssetData;
        if (product && product.hasOwnProperty('platform') && product.platform !== null) {
            return product.platform;
        } else {
            return null;
        }
    }

    const handleChangeProductPhotos = (info) => {
        let fileList = [...info.fileList];
        setRefImagePhotoList(fileList);
        setSelectComparisonImageModal(true);
        if (info.file.status === "done") {
            setErrorMessage('');
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            } else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
        }
    };

    const hideBanner = () => {
        setHideProductBanner(true);
    }


    return (<Row className='product-qa-img-tool-container'>
        <Col md={6} lg={6} xl={4} className={`image-list-qa ${initialLoader || access_level == "restricted" ? `blur-canvas` : ``}`}>
            {imageUploadLoader && <LoadingOutlined className='manrope f-32 justify-in-center' />}
            <div className={`display-flex j-s a-s wrap sa-s gg-16 custom-scroll product-grid-list ${(["co-owner", "owner"].includes(access_level) || (projectID && access_level == "edit")) ? `qa` : `qa-editor`} w-100`}>
                <ImageListTypePreviews
                    collaborateMode={collaborateMode}
                    productQAImages={productQAImages}
                    type={"low_res_preview"}
                    name={"Previews Only"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                />
                <ImageListType
                    collaborateMode={collaborateMode}
                    productQAImages={productQAImages}
                    type={"comb_image"}
                    name={"Comparison With References"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                />
                <ImageListTypePreviews
                    collaborateMode={collaborateMode}
                    productQAImages={productQAImages}
                    type={"reference_image"}
                    name={"Reference Images"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                />
            </div>
            {collaborateMode ?
                <Upload {...(Constants.getUploadProps(getPlatform()))}
                    multiple={true}
                    fileList={refImagePhotoList}
                    listType="text"
                    onDrop={e => {
                        console.log(e)
                        console.log('file dropped')
                    }}
                    onRemove={file => {
                        const index = refImagePhotoList.indexOf(file);
                        const newFileList = refImagePhotoList.slice();
                        newFileList.splice(index, 1);
                        setRefImagePhotoList(newFileList);

                        return true;
                    }}
                    onChange={handleChangeProductPhotos}
                    openFileDialogOnClick={true}
                    accept=".jpg,.jpeg,.png,.tiff"
                    className='product-qa-ref-images'
                    progress={progress_bar}>
                    <div className="justify-in-center" style={{ textAlign: "center" }}>
                        <img src={'/img/collaborate_qa/FileAdd.svg'} className='mr-4' /><p className="manrope f-14 blue m-0">Add Reference Images</p>
                    </div>
                </Upload> : ''}
        </Col>

        <Col md={18} lg={18} xl={20} className='w-100'>
            {hideProductBanner == false && (getType(selectedProductImage) == 'comb_image' || getType(selectedProductImage) == 'low_res_preview') &&
                <div className={`product-banner w-100  ${initialLoader || access_level == "restricted" ? `blur-canvas` : ``}`}>
                    <div className='manrope f-12 black-33 justify-space-between lh-14'>
                        {getType(selectedProductImage) == 'comb_image' ?
                            <span>These are preview images compared side by side with the references. Use these to approve the model or add feedback and invite others to collaborate. </span>
                            : <span>These are preview images only. Use these to approve the model or add feedback and invite others to collaborate.</span>}
                        <CloseOutlined onClick={hideBanner} />
                    </div>
                </div>}
            <CollaborateQACanvas />

            {collaborateMode ? <CollaborateCanvasToolbar /> : ''}
            {collaborateMode ? <Annotation context_for={"collaborate_qa"} /> : ''}
            {collaborateMode ? "" : <ImageMagnify
                className="product-qa-image"
                product_qa_viewer={true}
                image={selectedProductImage} />}
            {displayLoader || (canvasLoader && collaborateMode) ? <LoadingOutlined className='image-loader-qa' /> : ""}
        </Col>
    </Row>)

}

export default ProductCollaborateQAContainer;