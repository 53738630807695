import React, { useEffect, useState, useRef,useContext } from 'react';
import { ArrowRightOutlined, DownloadOutlined, PaperClipOutlined, LoadingOutlined, DownOutlined, InfoCircleOutlined, CheckCircleOutlined, ShareAltOutlined, CarOutlined, ShoppingCartOutlined, PrinterOutlined, InfoOutlined, FlagOutlined, ArrowLeftOutlined, EditOutlined, FilterOutlined } from '@ant-design/icons';
import { Dropdown, Row, Col, Button, message, Card, Modal, Tabs, Select, Checkbox, Radio, Form, Tooltip, Image, Input, Upload, Typography, Menu, InputNumber, Empty, Switch } from 'antd';
import TwoDImagesContext from '../../ContextFiles/TwoDImagesContext';
import "./TwoDImages.scss";

const ResolutionRadioButtons = () => {
    const { resolution,setResolution,onChangeResolution } = useContext(TwoDImagesContext);

    return (
        <Row>
            <Col span={24}>
                <div className="resolutions-tab manrope f-14 black-55 gutter-row justify-in-end">
                    <span className="resolutions-tab-span">
                        Show:
                    </span>
                    <Radio.Group onChange={onChangeResolution} value={resolution}
                    >
                        <Radio value='all'>
                            All
                        </Radio>
                        <Radio value='1k'>
                            1K
                        </Radio>
                        <Radio value='2k'>
                            2K
                        </Radio>
                        <Radio value='4k'>
                            4K
                        </Radio>
                        <Radio value='custom'>
                            Custom
                        </Radio>
                    </Radio.Group>
                </div>
            </Col>
        </Row>

    );
};

export default ResolutionRadioButtons;
