import "./EntityUpdate.scss";
import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  Select,
  message,
  TreeSelect,
  Button,
  Modal,
  Upload,
  Form
} from "antd";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import _ from "lodash";
import * as Styles from "../../../../Styles";
import FileConstants from "../../../../FileConstants";
import ActivityTimeline from "./ActivityTimeline";
import * as Constants from "../../CustomerComponents/Constants";

const { Option } = Select;

const categoryStyle = Styles.categoryStyle;

const PRODUCT_FILE_TYPES = {
  "MAX": ".zip",
  "GLB": '.glb',
  "USDZ": '.usdz',
  "FBX": '.fbx',
}

const ProductActionForm = () => {
  const [form] = Form.useForm();
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [productData, setProductData] = useState({});
  const [productPlatform, setProductPlatform] = useState("");
  const [hasError, setHasError] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [prevCategory, setPrevCategory] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  const [placementType, setPlacementType] = useState("");
  const [updateVisible, setUpdateVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [historyLoader, setHistoryLoader] = useState(false);
  const [itemHistoryModal, setItemHistoryModal] = useState(false);
  const [itemHistory, setItemHistory] = useState([]);
  const [isClosing, setIsClosing] = useState(false);

  const [productReplaceModal, setProductReplaceModal] = useState(false);
  const [replaceFile, setReplaceFile] = useState('');
  const [overideProductModel, setOverideProductModel] = useState([]);

  const replaceValueWithKey = (data) => {
    return data.map((item) => {
      const updatedItem = {
        ...item,
        value: item.key || item.category_id, // Use key or category_id as value
      };

      if (updatedItem.children && updatedItem.children.length > 0) {
        updatedItem.children = replaceValueWithKey(updatedItem.children);
      }

      return updatedItem;
    });
  };

  useEffect(() => {
    if (categoryTitle !== prevCategory || currentStatus !== selectedStatus) {
      setUpdateVisible(true);
    } else {
      setUpdateVisible(false);
    }
  }, [categoryValue, selectedStatus]);

  useEffect(() => {
    const payload = {
      output: "tree",
      disable_parents: "true",
    };
    axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload).then((res) => {
      let category = replaceValueWithKey(res.data);
      console.log(category);
      setCategoriesData(category);
    });
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (hasError && e.target.value) {
      setHasError(false);
    }
  };

  const ResetData = () => {
    const placementType = productData.placement_type || "Floor";
    setPlacementType(placementType);
    setCategoryValue(productData.category);
    setCategoryTitle(productData.category);
    setPrevCategory(productData.category);
    setCurrentStatus(productData.model_status);
    setSelectedStatus(productData.model_status);
  };

  const handleSubmit = () => {
    setUpdateVisible(false);
    setCategoryTitle("");
    setPrevCategory("");
    console.log("Input Value:", inputValue);
    if (!inputValue) {
      setHasError(true);
      message.error("Product ID cannot be empty.");
      return;
    }
    let payload = {
      product_id: `${inputValue}`,
    };
    setIsLoading(true);
    setIsDataLoaded(false);
    axios
      .post(ENVIRONMENT.FETCH_PRODUCT, payload)
      .then((res) => {
        if (!_.isEmpty(res.data)) {
          const data = res.data;
          const placementType = data.placement_type || "Floor";
          setPlacementType(placementType);
          setCategoryValue(data.category);
          setCategoryTitle(data.category);
          setPrevCategory(data.category);
          setCurrentStatus(data.model_status);
          setSelectedStatus(data.model_status);
          setProductPlatform(data.platform);
          setProductData({ ...data, placement_type: placementType });
          message.info("Product data fetched successfully");
          setIsDataLoaded(true);
        } else {
          message.error("Error in fetching product data");
        }
        setIsLoading(false);
      })
      .catch(() => {
        message.error("Error in fetching product data");
        setIsLoading(false);
      });

    setHistoryLoader(true);
    axios
      .post(ENVIRONMENT.GET_BATCH_ITEM_HISTORY, {
        entity_id: inputValue,
        entity_type: "Product",
      })
      .then((res) => {
        setItemHistory(res.data);
        setHistoryLoader(false);
      });
  };

  const onChangeCategoryValue = (value, label) => {
    const findCategoryById = (categories, id) => {
      for (const category of categories) {
        if (category.key === id) {
          return category;
        }
        if (category.children.length) {
          const found = findCategoryById(category.children, id);
          if (found) {
            return found;
          }
        }
      }
      return null;
    };

    const selectedCategory = findCategoryById(categoriesData, value);
    if (selectedCategory) {
      setCategoryValue(value);
      setCategoryTitle(selectedCategory.title);
      setPlacementType(selectedCategory.placement_type || "Floor");
    }
  };

  const handleUpdate = (action, regenerate_job = false) => {
    setUpdateLoader(true);
    let payload = {
      action: action,
      product_id: inputValue,
      placement_type: placementType,
      platform: productPlatform
    };

    if (action == 'update'){
      if (categoryTitle !== prevCategory) {
        payload.category = categoryTitle;
      }
  
      if (currentStatus !== selectedStatus) {
        payload.model_status = selectedStatus;
      }
    } else if (action == 'replace'){
      if(overideProductModel.length > 0){
        payload.replace_file = overideProductModel;
        payload.file_type = replaceFile;
      }
    }

    if (regenerate_job) {
      payload.regenerate_job = true;
    }

    console.log(payload);
    axios
      .post(ENVIRONMENT.PRODUCT_MODEL_ACTIONS, payload)
      .then((res) => {
        if(action == 'update'){
          if (regenerate_job) {
            message.info("Category updated successfully and Job Regenrated");
          } else {
            message.info("Category updated successfully");
          }
          setModalVisible(false);
        }
        else if(action == 'replace'){
          if (regenerate_job) {
            message.info("File Replaced successfully and Job Regenrated");
          } else {
            message.info("File Replaced successfully");
          }
          setProductReplaceModal(false);
          setOverideProductModel([]);
        } 
        setUpdateLoader(false);
      })
      .catch(() => {
        message.error("Error in updating category");
      });
  };

  const changeModelStatus = (value) => {
    setSelectedStatus(value);
  };

  const handleModalClose = () => {
    setIsClosing(true); // Trigger fade-out animation
    setTimeout(() => {
      setProductReplaceModal(false);
      form.resetFields();
      setOverideProductModel([]);
      setModalVisible(false); // Close modal after animation completes
      setIsClosing(false);    // Reset closing state
    }, 400); // Duration should match your fade-out animation
  };

  const openReplaceModal = (type) => {
    setReplaceFile(type);
    setProductReplaceModal(true);
  }

  const handleFileChange = info => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setOverideProductModel(fileList);
  };

  const onUpdate = (regenerate) => {
    form.validateFields().then((values) => {
        handleUpdate('replace', regenerate);
    }).catch((info) => {
        console.log('Validation Failed:', info);
    });
};

  const statusOptions = [3, 4, 5].filter((status) => status !== currentStatus);

  return (
    <div>
      <div style={{ padding: "20px" }}>
        <Row gutter={16}>
          <Col span={8}>
            <div style={{ marginBottom: "16px" }}>
              <label>ALL3D ID</label>
              <div className="input-container">
                <Input
                  placeholder="Please enter All3D product ID"
                  type="number"
                  value={inputValue}
                  onChange={handleInputChange}
                  className={`custom-input ${hasError ? "input-error" : ""}`}
                  onPressEnter={handleSubmit}
                  suffix={
                    <div className="suffix-container" onClick={handleSubmit}>
                      {isLoading ? (
                        <LoadingOutlined className="custom-icon" />
                      ) : (
                        <ArrowRightOutlined className="custom-icon" />
                      )}
                    </div>
                  }
                />
              </div>
            </div>
            {isDataLoaded ? (
              <>
                <div style={{ marginBottom: "16px" }}>
                  <label>Category</label>
                  <TreeSelect
                    showSearch
                    style={{ width: "100%" }}
                    className="manrope f-14 black-55 custom-tree-select"
                    dropdownStyle={categoryStyle}
                    placeholder="Select or type in your product category"
                    treeData={categoriesData}
                    notFoundContent={
                      <span style={{ textAlign: "center" }}>
                        <h5
                          style={{ textAlign: "center" }}
                          className="manrope f-10 black-55"
                        >
                          No Category Found
                        </h5>
                      </span>
                    }
                    onChange={(value, label) =>
                      onChangeCategoryValue(value, label)
                    }
                    filterTreeNode={(inputValue, treeNode) =>
                      treeNode.title
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                    value={categoryValue}
                  ></TreeSelect>
                </div>
                <div style={{ marginBottom: "16px" }}>
                  <label>Placement Type</label>
                  <div className="manrope f-14 label-data">
                    <span>{placementType}</span>
                  </div>
                </div>

                <div style={{ marginBottom: "16px" }}>
                  <label>System State</label>
                  <Select
                    style={{ lineHeight: "220%", paddingLeft: "0px" }}
                    className="manrope f-14 label-data"
                    value={FileConstants.PRODUCT_MODEL_STATUS[selectedStatus]}
                    onChange={changeModelStatus}
                  >
                    {statusOptions.map((key) => (
                      <Select.Option key={key} value={key}>
                        {FileConstants.PRODUCT_MODEL_STATUS[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                { productData && (productData.model_status == 11 || productData.model_status == 12) ?
                    <div style={{ marginBottom: "16px"}}>
                        <label>Status</label>
                        <div className ="note-bg-artist red f-14" style={{display: "block"}}>
                        {
                            (() => {
                                if (productData.model_status == 11)
                                        return <span className="note-text red product-status" style={{fontSize: '15px'}}>Model's automation job failed on {productData.last_modified}</span>
                                if (productData.model_status == 12)
                                    return <span className="note-text red product-status" style={{fontSize: '15px'}}>Model's automation job aborted on {productData.last_modified}</span>
                            })()
                        }
                        </div>
                    </div> : ''
                }

                <div style={{ marginBottom: "16px" }}>
                  <label>Max Bundle</label>
                  <div className="manrope f-14 label-data"
                    style={{ display: "flex", alignItems: "center", overflow: "hidden", }}>
                    <div style={{ flexBasis: "70%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                      <a target="_blank" rel="noopener noreferrer" href={ENVIRONMENT.getBaseURL(productPlatform) + ENVIRONMENT.LOW_POLY_MAX_URI + inputValue + ".zip"}>
                        {ENVIRONMENT.getBaseURL(productPlatform) + ENVIRONMENT.LOW_POLY_MAX_URI + inputValue + ".zip"}
                      </a>
                    </div>
                    <div style={{ flexBasis: "30%", display: "flex", justifyContent: "end"}}>
                      <Button className="modal-okay square font-12" onClick={ () => openReplaceModal("MAX")}>
                        Replace
                      </Button>
                    </div>
                  </div>
                </div>

                <div style={{ marginBottom: "16px" }}>
                  <label>GLB</label>
                  <div className="manrope f-14 label-data"
                    style={{ display: "flex", alignItems: "center", overflow: "hidden", }}>
                    <div style={{ flexBasis: "70%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                      <a target="_blank" rel="noopener noreferrer" href={ENVIRONMENT.getBaseURL(productPlatform) + ENVIRONMENT.ASSET_GLB_URI + inputValue + ".glb"}>
                        {ENVIRONMENT.getBaseURL(productPlatform) + ENVIRONMENT.ASSET_GLB_URI + inputValue + ".glb"}
                      </a>
                    </div>
                    <div style={{ flexBasis: "30%", display: "flex", justifyContent: "end"}}>
                      <Button className="modal-okay square font-12" onClick={ () => openReplaceModal("GLB")}>
                        Replace
                      </Button>
                    </div>
                  </div>
                </div>

                <div style={{ marginBottom: "16px" }}>
                  <label>USDZ</label>
                  <div className="manrope f-14 label-data"
                    style={{ display: "flex", alignItems: "center", overflow: "hidden", }}>
                    <div style={{ flexBasis: "70%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                      <a target="_blank" rel="noopener noreferrer" href={ENVIRONMENT.getBaseURL(productPlatform) + ENVIRONMENT.USDZ_BASE_URI + inputValue + ".usdz"}>
                        {ENVIRONMENT.getBaseURL(productPlatform) + ENVIRONMENT.USDZ_BASE_URI + inputValue + ".usdz"}
                      </a>
                    </div>
                    <div style={{ flexBasis: "30%", display: "flex", justifyContent: "end"}}>
                      <Button className="modal-okay square font-12" onClick={ () => openReplaceModal("USDZ")}>
                        Replace
                      </Button>
                    </div>
                  </div>
                </div>

                <div style={{ marginBottom: "16px" }}>
                  <label>FBX</label>
                  <div className="manrope f-14 label-data"
                    style={{ display: "flex", alignItems: "center", overflow: "hidden", }}>
                    <div style={{ flexBasis: "70%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                      <a target="_blank" rel="noopener noreferrer" href={ENVIRONMENT.getBaseURL(productPlatform) + 'product_assets/fbx/' + inputValue + ".fbx"}>
                        {ENVIRONMENT.getBaseURL(productPlatform) + 'product_assets/fbx/' + inputValue + ".fbx"}
                      </a>
                    </div>
                    <div style={{ flexBasis: "30%", display: "flex", justifyContent: "end"}}>
                      <Button className="modal-okay square font-12" onClick={ () => openReplaceModal("FBX")}>
                        Replace
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </Col>
          {isDataLoaded ? (
            <Col span={8} offset={4}>
              <div style={{ marginBottom: "16px" }}>
                  <label>Product Name</label>
                  <div className="manrope f-14 label-data">
                    <span>{productData['product_name']}</span>
                  </div>
                </div>
              <div
                style={{
                  height: "100%",
                  maxHeight: "405px",
                  maxWidth: "405px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "0.1px solid",
                  marginBottom: "16px",
                }}
              >
                <img
                  src={productData.thumbnail.replace(
                    ENVIRONMENT.DEFAULT_BASE_URL,
                    ENVIRONMENT.getBaseURL(productData.platform)
                  )}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>

              {!historyLoader ? (
                <div
                  style={{
                    marginBottom: "16px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    type="link"
                    disabled={itemHistory.length == 0}
                    onClick={() => {
                      setItemHistoryModal(true);
                    }}
                    className="note-text blue item-history"
                    style={{ display: "inline", marginLeft: 20 }}
                  >
                    View Activity Timeline
                    {itemHistory.length == 0 ? "(No Records Found)" : ""}
                  </Button>
                </div>
              ) : (
                ""
              )}
            </Col>
          ) : (
            ""
          )}
        </Row>

        <Modal
          className={`modal-share-success ${isClosing ? 'fade-out' : 'fade-up-center'}`}
          width={600}
          centered={true}
          visible={modalVisible}
          closable={false}
          transitionName={null}
          maskStyle={{
            backdropFilter: 'blur(10px)', // Apply the blur effect
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Semi-transparent background
          }}
          footer={[
            <span className="justify-in-center">
              <Button
                style={{ textAlign: "center" }}
                className="modal-okay-gray square font-12"
                onClick={handleModalClose}
              >
                Cancel
              </Button>
              <Button
                style={{ textAlign: "center" }}
                className="modal-okay square font-12"
                onClick={() => handleUpdate('update', false)}
              >
                {updateLoader ? (
                  <span>
                    {" "}
                    Updating... <LoadingOutlined spin />
                  </span>
                ) : (
                  <span>Update </span>
                )}
              </Button>
              <Button
                style={{ textAlign: "center" }}
                className="modal-okay square font-12"
                onClick={() => handleUpdate('update', true)}
              >
                {updateLoader ? (
                  <span>
                    {" "}
                    Updating... <LoadingOutlined spin />
                  </span>
                ) : (
                  <span>Update and Regenerate</span>
                )}
              </Button>
            </span>,
          ]}
        >
          <div
            style={{
              margin: "30px auto 0px auto",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2 className="manrope f-16 black-00 w-600">
              You are about to change this Product's Category
            </h2>
          </div>
        </Modal>

        <ActivityTimeline
          platform={productPlatform}
          itemHistory={itemHistory}
          itemHistoryModal={itemHistoryModal}
          setItemHistoryModal={setItemHistoryModal}
        ></ActivityTimeline>

        <Modal bodyStyle={{ paddingTop: "2px", paddingBottom: "0px" }}
          className={`modal-share-success ${isClosing ? 'fade-out' : 'fade-up-center'}`}
          title={`Product ${replaceFile} File Replace`}
          visible={productReplaceModal}
          width={500}
          centered={true}
          closable={false}
          transitionName={null}
          maskStyle={{
            backdropFilter: 'blur(10px)', 
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
          footer={[
            <span className="justify-in-center">
              <Button
                style={{ textAlign: "center" }}
                className="modal-okay-gray square font-12"
                onClick={handleModalClose}
              >
                Cancel
              </Button>
              <Button
                style={{ textAlign: "center" }}
                className="modal-okay square font-12"
                onClick={() => onUpdate(false)}
              >
                {updateLoader ? (
                  <span>
                    {" "}
                    Updating... <LoadingOutlined spin />
                  </span>
                ) : (
                  <span>Update </span>
                )}
              </Button>
              {replaceFile == "MAX" && <Button
                style={{ textAlign: "center" }}
                className="modal-okay square font-12"
                onClick={() => onUpdate(true)}
              >
                {updateLoader ? (
                  <span>
                    {" "}
                    Updating... <LoadingOutlined spin />
                  </span>
                ) : (
                  <span>Update and Regenerate</span>
                )}
              </Button>}
            </span>
          ]}>
          <div>
            <Form form={form} className="input-form">
            <Row type="flex">
                <Col span={24} style={{padding: 12}}>
                    <div className="d-flex" style={{justifyContent:"center"}}>
                        <div style={{textAlign:"center",minWidth:"100%"}}>
                            <Form.Item name={'override_model'} label="Product Model" style={{display: 'flow'}} colon={false}
                              rules={[{ required: true, message: 'Please upload file to replace' }]}>
                                <Upload
                                    accept={PRODUCT_FILE_TYPES[replaceFile]}
                                    {...Constants.getUploadProps(productPlatform)}
                                    multiple="true"
                                    listType="text"
                                    fileList = {overideProductModel}
                                    onChange = {handleFileChange}
                                    className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-admin"
                                >
                                <div className="d-flex">
                                    <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                    <div className="ant-upload-text">
                                    <div className="up-info">Drop your Files</div>
                                    <div>Support: {PRODUCT_FILE_TYPES[replaceFile]}</div>
                                    </div>
                                </div>
                                </Upload>
                            </Form.Item>
                        </div>
                    </div>
                </Col>
                </Row>
            </Form>
            </div>
      </Modal>
      </div>

      {updateVisible ? 
      <div className="bottom-menu">
        <div className='mr-20 justify-in-end'> 
            <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-evenly'}}>
                <Button className='product-btn br-4 f-16 light-blue pd-16' onClick={ResetData}>
                    Reset
                </Button>
                <Button className='product-btn dark-blue f-16 ml-16 br-4 pd-16' onClick={() => setModalVisible(true)}>
                    Update
                </Button>
            </div>  
        </div>
      </div> : ''}
    </div>
  );
};
export default ProductActionForm;
