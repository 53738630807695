/**
 * @author Lee Stemkoski
 *
 * Usage:
 * (1) create a global variable:
 *      var keyboard = new KeyboardState();
 * (2) during main loop:
 *       keyboard.update();
 * (3) check state of keys:
 *       keyboard.down("A")    -- true for one update cycle after key is pressed
 *       keyboard.pressed("A") -- true as long as key is being pressed
 *       keyboard.up("A")      -- true for one update cycle after key is released
 *
 *  See KeyboardState.k object data below for names of keys whose state can be polled
 */

import { event } from "jquery";

// initialization
export default function KeyboardState() {
  // bind keyEvents
  document.addEventListener('keydown', KeyboardState.onKeyDown, false);
  document.addEventListener('keyup', KeyboardState.onKeyUp, false);
}

// /////////////////////////////////////////////////////////////////////////////

KeyboardState.keyStates = {
  "ArrowUp": "up",
  "ArrowDown": "down",
  "ArrowLeft": "left",
  "ArrowRight": "right",
  "Control": "control",
  "Meta": "meta",
};

KeyboardState.status = {};

KeyboardState.keyName = function(key) {
  let keyVal = key;
  if (KeyboardState.keyStates[keyVal] ) {
    keyVal = KeyboardState.keyStates[keyVal];
  }
  return keyVal;
};

KeyboardState.unPress = function() {
  for (const key in KeyboardState.status) {
      KeyboardState.status[key].pressed = false;
      KeyboardState.status[key].down = false;
      KeyboardState.status[key].up = false;
  }
};

KeyboardState.onKeyUp = function(event) {
  event.preventDefault();
  const key = KeyboardState.keyName(event.key);
  console.log("Key released", key)
  if (KeyboardState.status[key]) {
    KeyboardState.status[key].pressed = false;
  }
}

KeyboardState.onKeyDown = function(event) {
  if (event.target.tagName.toLowerCase() != "input") {
    event.preventDefault();
    const key = KeyboardState.keyName(event.key);
    console.log("Key captured", key)
    if (event.metaKey) {
      KeyboardState.status[key] = { down: true, pressed: true, up: false, updatedPreviously: false };
    }
    if (event.altKey || event.shiftKey) {
      KeyboardState.unPress(); 
    }
    else if ( !KeyboardState.status[key] ) {
      KeyboardState.status[key] = { down: false, pressed: true, up: false, updatedPreviously: false };
    }
  }
};

KeyboardState.prototype.update = function() {
  for (const key in KeyboardState.status) {
    // insure that every keypress has "down" status exactly once
    if ( !KeyboardState.status[key].updatedPreviously ) {
      KeyboardState.status[key].down = true;
      //KeyboardState.status[key].pressed = true;
      KeyboardState.status[key].updatedPreviously = true;
    } else // updated previously
    {
      KeyboardState.status[key].down = false;
    }

    // key has been flagged as "up" since last update
    if ( KeyboardState.status[key].up ) {
      delete KeyboardState.status[key];
      continue; // move on to next key
    }

    if ( !KeyboardState.status[key].pressed ) // key released
    {
      KeyboardState.status[key].up = true;
    }
  }
};

KeyboardState.prototype.down = function(keyName) {
  return (KeyboardState.status[keyName] && KeyboardState.status[keyName].down);
};

KeyboardState.prototype.pressed = function(keyName) {
  return (KeyboardState.status[keyName] && KeyboardState.status[keyName].pressed);
};

KeyboardState.prototype.up = function(keyName) {
  return (KeyboardState.status[keyName] && KeyboardState.status[keyName].up);
};

KeyboardState.prototype.debug = function() {
  let list = 'Keys active: ';
  for (const arg in KeyboardState.status) {
    list += ' ' + arg;
  }
  console.log(list);
};

KeyboardState.prototype.unPress = function() {
  KeyboardState.unPress();
};

// /////////////////////////////////////////////////////////////////////////////
