import React, { useContext } from 'react';
import { Modal, Tabs, Row, Col } from 'antd';
import LifeStyleImagesCarousel from "../SceneRendersModal/LifeStyleImageCarousel";
import SceneImagePopupContext from '../../ContextFiles/SceneImagePopupContext';
const { TabPane } = Tabs;

const SceneRendersModal = (props) => {
  const { isVisible, handleOk, handleCancel } = useContext(SceneImagePopupContext);
  return (
    <Modal
      className='scene-render-lifestyleimage-viewer-modal'
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={'90%'}
      footer={null}>
      <Row>
        <Col span={24}>
          <Tabs
            className='items-table'
            tabBarGutter={32}
            defaultActiveKey='images'
            size='large'>
            <TabPane
              tab={<span className={`manrope f-14 asset-tab-heading`}>Lifestyle Images</span>}
              key='images'
              className='pd-8'>
              <LifeStyleImagesCarousel />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Modal>
  );
};

export default SceneRendersModal;
