import React, { useContext } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Tooltip, Row, Col, Button, Card, Menu, Collapse } from 'antd';
import { ArrowRightOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import FileConstants from '../../../../FileConstants';
import ENVIRONMENT from '../../../../environments';
import ProductInformation from './ProductInformation';
import ModelSpecifications from './ModelSpecifications';
import ARView from './ARView';

const { Panel } = Collapse;

const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem('company_id');

const ProductInfoCollapse = () => {
    const { glbSize, archiveSize, totalFileSize, getUSDZUrl, getAndroidUrl, isStorePage } = useContext(CustomerProductContext);

    return (<Collapse className="product-info-collapse mt-20" bordered={false} expandIconPosition={"right"} defaultActiveKey={['1']} style={{padding:1}}>
        <Panel header={<span className='manrope f-20 black-26'>Product Details</span>} key="1">
            <ProductInformation />
        </Panel>
        {(!isStorePage && (totalFileSize != 0 || glbSize != 0 || archiveSize != 0)) ?
            <Panel header={<span className='manrope f-20 black-26'>Model Specifications</span>} key="2">
                <ModelSpecifications />
            </Panel> : ''}
        {getUSDZUrl() != '' && getAndroidUrl() != '' &&
            <Panel header={<span className='manrope f-20 black-26'>AR</span>} key="3">
                <ARView />
            </Panel>}
    </Collapse>
    );
}

export default ProductInfoCollapse;