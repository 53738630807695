import React, { useContext, useState, useEffect } from 'react';
import { ArrowRightOutlined, LoadingOutlined, ArrowLeftOutlined, LockOutlined, CheckOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Row, Col, Button, Divider, Tooltip, Avatar, Dropdown, Menu, Popover, Input, Breadcrumb } from 'antd';
import CollaborateContext from '../../ContextFiles/CollaborateContext';
import CollaborateConstants from './Constants';
import './CollaborateTool.scss'
import * as Utilities from '../../Utilities';
import ENVIRONMENT from '../../../../environments';

const CollaborateNavBar = () => {
    const { display_name, refreshLoader, save_loader, scene_creator_loader, exitTool, goToSceneCreator,
        initialLoader, access_level, setCollaborateAccessModal, annotateLoader, convertToPDF, imageFormat,
        setVersionHistoryVisible, openCommentHistory, activeCollaboratorList, original_img_url,
        setResolveConfirmModal, isCollaborationResolved, currentVersion, isOldVersion, versionName, setVersionName, 
        collaboration_id, render_name, scene_id, setDisplayName, updtateVersionNameInHistory, product_id } = useContext(CollaborateContext);
    const activeName = isOldVersion ? versionName : display_name;

    const [showDropdown, setShowDropdown] = useState(false);
    const [isEditName, setIsEditName] = useState(false);
    const [name, setName] = useState(activeName);
    const [nameLoader, setNameLoader] = useState(false);

    const onMouseOutCommentHistory = (evt) => {
        let element = document.getElementById('comment_history');
        if (element) {
            element.setAttribute("src", "/img/collaborate_tool/comment_history.svg");
        }
    }

    const onMouseOverCommentHistory = (evt) => {
        let element = document.getElementById('comment_history')
        if (element) {
            element.setAttribute("src", "/img/collaborate_tool/comment_history_white.svg");
        }
    }

    const onMouseOverAccessButton = (evt) => {
        let element = document.getElementById('access_button')
        if (element) {
            element.setAttribute("src", "/img/collaborate_tool/share_access_white.svg");
        }
    }

    const onMouseOutAccessButton = (evt) => {
        let element = document.getElementById('access_button')
        if (element) {
            element.setAttribute("src", "/img/collaborate_tool/share_access_icon.svg");
        }
    }

    const onMouseOutVersionHistory = (evt) => {
        let element = document.getElementById('version_history');
        if (element) {
            element.setAttribute("src", "/img/collaborate_tool/version_history.svg");
        }
    }

    const onMouseOverVersionHistory = (evt) => {
        let element = document.getElementById('version_history')
        if (element) {
            element.setAttribute("src", "/img/collaborate_tool/version_history_white.svg");
        }
    }

    const onMouseOverDownloadButton = (evt) => {
        let element = document.getElementById('download_icon');
        if (element) {
            element.setAttribute("src", "/img/collaborate_tool/download-white.svg");
        }
    }

    const onMouseOutDownloadButton = (evt) => {
        let element = document.getElementById('download_icon');
        if (element) {
            element.setAttribute("src", "/img/collaborate_tool/download-icon.svg");
        }
    }

    // Function to toggle the dropdown visibility
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    // Function to generate the dropdown menu
    const dropdownMenu = (
        <Menu>
            {activeCollaboratorList.map((collaborator, index) => (
                <Menu.Item key={index} className="display-flex j-s a-c">
                    <Avatar
                        className="cursor-pointer mr-12 manrope f-16 white lh-32 circle-bg light-blue">
                        {Utilities.getInitials(collaborator.name ? collaborator.name : collaborator.customer_username)}
                    </Avatar>
                    <div className="collaborator-email-username">
                        <span className="manrope f-14 w-700" style={{ display: 'block' }}>{collaborator.customer_username}</span>
                        <span className="manrope f-12 w-400" style={{ display: 'block' }}>{collaborator.email}</span>
                    </div>
                </Menu.Item>
            ))}
        </Menu>
    );

    // Function to generate the dropdown menu
    const downloadMenu = (
        <Menu>
            <Menu.Item key={0}>
                <div className='manrope f-14 black-55' onClick={() => Utilities.downloadFile(original_img_url, display_name, imageFormat)}>Download Image</div>
            </Menu.Item>
            <Menu.Item key={1}>
                <div className='manrope f-14 black-55' onClick={convertToPDF}>Download PDF</div>
            </Menu.Item>
        </Menu>
    );

    const handleNameSubmit = () => {
        if (isOldVersion) {
            updateCollaborationHistoryName();
        } else {
            updateCollaborationName();
        }
    }

    const handleNameChange = (e) => {
        setName(e.target.value)
    }

    const handleEditCancel = () => {
        setIsEditName(false)
        setName(activeName)
    }

    const updateCollaborationName = () => {
        let payload = {
            'scene_id': `${scene_id}`,
            'render_name': {
                [render_name] : name
            }
        }
        setNameLoader(true)
        
        axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
        .then( (response) => {
            if(response.status == 200){
                setNameLoader(false);
                setDisplayName(name);
                setIsEditName(false);
            }
        });
    }

    const updateCollaborationHistoryName = () => {
        let payload = {
            'collaboration_id': collaboration_id,
            'version_number': currentVersion,
            'display_name': name,
            'action': 'update_display_name'
        }
        setNameLoader(true)
        axios.post(ENVIRONMENT.COLLABORATION_RENDER_HISTORY_UPDATE, payload)
            .then(res => {
                setVersionName(name);
                setNameLoader(false);
                setIsEditName(false);
                updtateVersionNameInHistory(name, currentVersion)
            })
    }

    const DownloadRender = (() => {
        return (
            <div >
                <Tooltip title={<span className='manrope f-12 white'>Download Options</span>} placement='bottom'>
                    <Dropdown overlay={
                        downloadMenu
                    } trigger={['click']} >
                        <div onMouseOut={onMouseOutDownloadButton} onMouseOver={onMouseOverDownloadButton}>
                            <img id="download_icon" src={'/img/collaborate_tool/download-icon.svg'}
                                className='version-history-icon ml-12'
                            />
                        </div>
                    </Dropdown>
                </Tooltip>
            </div>
        );
    });

    return (
        <div className="collaborate-navbar">
            <Row type="flex" className="w-100 display-flex j-s-b">
                <Col span={8} className='display-flex j-s a-c'>
                    <Tooltip title={<span className='manrope f-12 white'>Go back</span>} placement='bottom'>
                    <ArrowLeftOutlined className="action-icon-btn grey mr-16" onClick={exitTool} />
                    </Tooltip>
                    { display_name &&
                    <Breadcrumb className='justify-in-start'>
                        {isOldVersion && 
                        <Breadcrumb.Item>
                            <Tooltip title={<span className='manrope f-12 white'>{display_name}</span>} placement='bottom'>
                                <span onClick={exitTool} className='manrope f-16 w-400 grey-8c clamp-w-150 pointer'>
                                    {display_name.length > 20 ? display_name.substring(0, 20) + "..." : display_name}
                                </span>
                            </Tooltip>
                        </Breadcrumb.Item>}
                        <Breadcrumb.Item>
                        <Row type="flex" style={{display:"inline-block"}}>
                            <Col span={24} className='display-flex j-s a-c'>
                                {(isEditName) ?
                                <div id="name_form" className='manrope f-16 w-400 grey-8c nameEditField'>
                                    <Input className='manrope f-16' style={{width:"90%"}} value={name} onChange={handleNameChange} bordered={false} />
                                    <div className='nameEditIcons'>
                                        {nameLoader ?
                                        <LoadingOutlined className='ml-16' /> :
                                        <>
                                            <CloseOutlined className='nameIcon' onClick={handleEditCancel} />
                                            <CheckOutlined className='nameIcon' onClick={handleNameSubmit} />
                                        </>}
                                    </div>
                                </div> :
                                <div className='display-flex j-s a-c'>
                                    <Tooltip title={<span className='manrope f-12 white'>{activeName || `version ${currentVersion}`}</span>} placement='bottom'>
                                        <h3 className='manrope f-20 w-500 black-00 capitalize clamp-text w-200px mb-0'>
                                            {activeName || `version ${currentVersion}`}
                                        </h3>
                                    </Tooltip>
                                    {scene_id &&
                                    <EditOutlined className='ml-10' onClick={()=> {
                                        setIsEditName(true)
                                        setName(activeName)}} />
                                    }
                                </div>
                                }
                                </Col>
                            </Row>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    }
                </Col>

                {(CollaborateConstants.edit_access_levels.includes(access_level)) ?
                    <Col span={8} className='display-flex j-e a-c'>
                        {activeCollaboratorList.slice(0, 2).map((collaborator, index) => (
                            <Popover
                                content={<span className='manrope f-12 black-33'>{collaborator.customer_username} ({collaborator.email}) </span>}>
                                <div className='ml-6'>
                                    <Avatar
                                        key={index}
                                        className={`cursor-pointer manrope f-16 white lh-32 circle-bg light-blue`}>
                                        {Utilities.getInitials(collaborator.name ? collaborator.name : collaborator.customer_username)}
                                    </Avatar>
                                </div>
                            </Popover>
                        ))}
                        {activeCollaboratorList.length > 2 && (
                            <Dropdown overlay={dropdownMenu} trigger={['click']}>
                                <div className='ml-6'>
                                    <Avatar
                                        className="cursor-pointer manrope f-16 lh-32"
                                        onClick={toggleDropdown}>
                                        +{activeCollaboratorList.length - 2}
                                    </Avatar>
                                </div>
                            </Dropdown>
                        )}

                        <Tooltip title={<span className='manrope f-12 white'>Collaborate & Invite</span>} placement='bottom'>
                            <div onMouseOut={onMouseOutAccessButton} onMouseOver={onMouseOverAccessButton}>
                                <img id="access_button" src={'/img/collaborate_tool/share_access_icon.svg'}
                                    className='version-history-icon ml-12'
                                    onClick={() => setCollaborateAccessModal(true)} />
                            </div>
                        </Tooltip>

                        <Tooltip title={<span className='manrope f-12 white'>Comment History</span>} placement='bottom'>
                            <div onMouseOut={onMouseOutCommentHistory} onMouseOver={onMouseOverCommentHistory}>
                                <img id="comment_history" src={'/img/collaborate_tool/comment_history.svg'}
                                    className='version-history-icon ml-12'
                                    onClick={openCommentHistory} />
                            </div>
                        </Tooltip>
                        {scene_id && 
                        <Tooltip title={<span className='manrope f-12 white'>Version History</span>} placement='bottom'>
                            <div onMouseOut={onMouseOutVersionHistory} onMouseOver={onMouseOverVersionHistory}>
                                <img id="version_history" src={'/img/collaborate_tool/version_history.svg'}
                                    className='version-history-icon ml-12'
                                    onClick={() => setVersionHistoryVisible(true)} />
                            </div>
                        </Tooltip>}
                        <DownloadRender />
                        <Divider type="vertical" className='collab-nav-divider' />
                        {
                            (["owner", "co-owner"].includes(access_level) || localStorage.getItem('is_msprovider') == 'true') ?
                                <Button disabled={isCollaborationResolved || initialLoader || refreshLoader || save_loader || annotateLoader} className='basic-collab-btn green f-14' onClick={() => setResolveConfirmModal(true)}>
                                    {isCollaborationResolved ? 'Collaboration Resolved' :
                                        <span>
                                            Resolve Collaboration&nbsp;&nbsp;
                                            <CheckOutlined />
                                        </span>}
                                </Button>
                                :
                                ''
                        }
                        {scene_id ?
                        <Button disabled={save_loader || initialLoader || annotateLoader || refreshLoader} className='basic-collab-btn blue f-14 ml-12' onClick={goToSceneCreator}>
                            <span>
                                Go to scene creator&nbsp;&nbsp;
                                {!scene_creator_loader ? <ArrowRightOutlined /> : <LoadingOutlined />}
                            </span>
                        </Button>: ''}

                    </Col> : (["restricted", "view"].includes(access_level)) ? <Col span={8} className="display-flex j-e a-c">
                        {(["view"].includes(access_level)) &&
                            <Tooltip title={<span className='manrope f-12 white'>Comment History</span>} placement='bottom'>
                                <div onMouseOut={onMouseOutCommentHistory} onMouseOver={onMouseOverCommentHistory}>
                                    <img id="comment_history" src={'/img/collaborate_tool/comment_history.svg'}
                                        className='version-history-icon'
                                        onClick={openCommentHistory} />
                                </div>
                            </Tooltip>}
                        {(["view"].includes(access_level)) && scene_id &&
                            <Tooltip title={<span className='manrope f-12 white'>Version History</span>} placement='bottom'>
                                <div onMouseOut={onMouseOutVersionHistory} onMouseOver={onMouseOverVersionHistory}>
                                    <img id="version_history" src={'/img/collaborate_tool/version_history.svg'}
                                        className='version-history-icon ml-12'
                                        onClick={() => setVersionHistoryVisible(true)} />
                                </div>
                            </Tooltip>}

                        {(["view"].includes(access_level)) &&
                            <DownloadRender />
                        }
                        {
                            isCollaborationResolved ?
                            <Button disabled={true} className='basic-collab-btn green f-14 ml-12'>
                            <span>
                                Collaboration Resolved&nbsp;&nbsp;
                                <CheckOutlined />
                            </span>
                            </Button>
                            :
                            <Tooltip placement='left' title={
                                <span className='manrope f-12 white'>
                                    {
                                        isOldVersion ? "You are viewing an old version of this collaboration. Changes cant be made." : `You have ${access_level} access to this collaboration.`
                                    }
                                </span>}>
                                <LockOutlined className='access-icon ml-12' />
                            </Tooltip>
                        }
                    </Col> : ""}
            </Row>
        </div>
    );
}


export default CollaborateNavBar;
