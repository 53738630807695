import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Empty } from "antd";
import "./TwoDImages.scss";
import { Gallery } from "react-grid-gallery";
import DottedLoader from "../../DottedLoader";
import ReactPlayer from "react-player";
import {
  QA_IMG_URI,
  LOW_RES_QA_IMG_URI
} from "../../../../environments/env";


const CustomThumbnailWithLoader = ({ item, imageProps }) => {
  // You can access the original props passed to the image component via imageProps
  return (
    <div
      style={{ position: "relative", minHeight: "100px", minWidth: "100px" }}
    >
      <img
        {...imageProps} // Spread the original image props to preserve default behavior
        style={{
          ...imageProps.style,
        }} // Add custom styles
      />
    </div>
  );
};

const InfiniteScrollGallery = ({ images, loading, onImageClick }) => {
  const [displayedImages, setDisplayedImages] = useState([]);
  const pageRef = useRef(1);
  const pageSize = 20;
  const galleryRef = useRef();

  useEffect(() => {
    if (images) {
      setDisplayedImages([]);
      pageRef.current = 1;

      loadMoreImages();
    }
  }, [images]);

  const loadMoreImages = () => {
    const startIndex = (pageRef.current - 1) * pageSize;
    const newImages = images.slice(startIndex, startIndex + pageSize);

    if (newImages.length > 0) {
      const newDisplayedImages = newImages.map((image) => {
        if (image.image_type == "mp4") {
          return {
            ...image,
            src: image.mp4Preview,
          };
        }
        else if(image.image_type == "lifestyle" || image.image_type == "silo") {
          // in case of lifestyle of silo image, change image src to low res thumbnail
          // so that thumbnail is displayed in grid gallery preview
          return {
            ...image,
            src: image.thumbnail,
          };
        }
        return image;
      });
      setDisplayedImages((prev) => [...prev, ...newDisplayedImages]);
      pageRef.current += 1;
    } else {
      console.log("No more images to load.");
    }
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = galleryRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 50) {
      loadMoreImages();
    }
  };

  if (loading) return <DottedLoader />;

  if (!displayedImages.length) {
    return (
      <Row>
        <Col span={24}>
          <Empty description="No images available" />
        </Col>
      </Row>
    );
  }

  return (
    <div>
      <div
        ref={galleryRef}
        onScroll={handleScroll}
        style={{ overflowY: "auto", height: `${window.innerHeight * 0.8}px` }}
      >
        <Gallery
          onClick={(index) => {
            const { index: clickedIndex, src } = displayedImages[index];
            console.log("clickedIndex :", clickedIndex);
            onImageClick(src.replace(LOW_RES_QA_IMG_URI, QA_IMG_URI).replace("_512.", ".")); // on clicking the image, change the low res url to original image url
          }}
          rowHeight={300}
          margin={8}
          images={displayedImages}
          enableImageSelection={false}
          thumbnailImageComponent={CustomThumbnailWithLoader}
        />
      </div>
    </div>
  );
};

export default InfiniteScrollGallery;
