import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Button,
  Dropdown,
  Menu,
  Carousel,
  Tooltip,
  Image
} from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import * as Utilities from "../../Utilities.jsx";
import ENVIRONMENT from "../../../../environments";
import SpaceAssetListByType from "./SpaceAssetsListByType.jsx";
import ImageZoomAdmin from "../ImageZoomAdmin/ImageZoomAdmin.jsx";

const COMPANY_ID = localStorage.getItem("company_id");
const CUSTOMER_USERNAME = localStorage.getItem("username");
const base_uri = ENVIRONMENT.BASE_URI + "space_test_renders/";

const image_style = {
  width: "100%",
  height: "100%",
  objectFit: "contain",
  margin: "auto",
};
const image_style_in_progress = {
  width: "100%",
  height: "100%",
  objectFit: "contain",
  filter: "blur(8px)",
  margin: "auto",
};
const fullscreen_image_style = {
  width: "100vw",
  height: "100vh",
  objectFit: "contain",
};
const fullscreen_image_style_in_progress = {
  width: "100vw",
  height: "100vh",
  objectFit: "contain",
  filter: "blur(8px)",
};

const SpaceImageCarousel = ({
  renders,
  top_view,
  platform,
  selectedImageUrl,
  storePage,
  id,
  roomDetails,
  setSelectedRender,
  selectedRender
}) => {
  let carousel = React.createRef();
  const [imgAccessedFromCarousel, setImgAccessedFromCarousel] = useState("");
  const [selectedProductImage, setSelectedProductImage] = useState("");
  const [spaceImageCount, setSpaceImageCount] = useState(0);
  const [status, setStatus] = useState(null);
  const [isFullScreen,setIsFullScreen] = useState(false);

  const keyTitleMapping2DImages = {
    render: "Space Images",
    top_view: "Top View",
  };

  const getImgDisplayName = (render) => {
    if (render.display_name) {
        return render.display_name;
    }
    return `Untitled ${render.filename.split('.')[0].split('_')[1]}`;
  }

  const formattedImagesList = useMemo(() => {

    const rendersList = renders
      .map((obj) => {
        const user_type =
          obj.generated_by === "Customer"
            ? "Cust."
            : obj.generated_by
            ? obj.generated_by
            : "Artist";

        const scene_id =
          user_type == "Cust."
            ? roomDetails.customer_dummy_scene_id
            : roomDetails.dummy_scene_id;

        let url =
          ENVIRONMENT.getBaseURL(obj.platform) +
          base_uri +
          obj.uid +
          "/" +
          obj.name;
        if (obj.status == "rendered") {
          url =
            ENVIRONMENT.getBaseURL(obj.platform) +
            ENVIRONMENT.SCENE_THUMBNAIL_URI +
            scene_id +
            "/" +
            obj.filename;
        } else if (obj.status == "pending") {
          url =
            ENVIRONMENT.getBaseURL(obj.platform) +
            ENVIRONMENT.SCENE_THUMBNAIL_URI +
            scene_id +
            "/" +
            obj.preview_render.replace("tiff", "jpg");
        }

        return url ? { 
          url: url, 
          type: "render", 
          status: obj.status,
          image_width: obj.image_width,
          image_height: obj.image_height,
          captured_by: obj.captured_by,
          time_updated: obj.time_updated,
          display_name: getImgDisplayName(obj),
          filename: obj.filename
        } : null;
      })
      .filter((obj) => obj);

    const topViewList = top_view
      ? [
          {
            url:
              ENVIRONMENT.getBaseURL(platform) +
              ENVIRONMENT.SPACE_TOP_VIEW_RENDER_URI +
              top_view,
            type: "top_view",
          },
        ]
      : [];

    const combinedList2DImages = [...rendersList, ...topViewList];

    setSelectedProductImage(combinedList2DImages[0]?.url);
    setSelectedRender(combinedList2DImages[0]);
    return combinedList2DImages;
  }, [renders, top_view]);

  useEffect(() => {
    if (selectedImageUrl) {
      setSelectedProductImage(selectedImageUrl)
    };
  }, [selectedImageUrl]);


  useEffect(() => {
    setSpaceImageCount(formattedImagesList?.length);
  }, [formattedImagesList]);

  useEffect(() => {
    let image_index = formattedImagesList.findIndex(
      (img) => img.url == selectedProductImage
    );
    setStatus(formattedImagesList[image_index]?.status);
    setSelectedRender(formattedImagesList[image_index]);
    carousel.goTo(image_index, true);
  }, [selectedProductImage, imgAccessedFromCarousel, selectedRender]);

  const onChangeCarousel = (before, to) => {
    if (before == spaceImageCount - 1 && to == 0) {
      carousel.goTo(0, true);
      setImgAccessedFromCarousel(true);
    } else if (before == 0 && to == spaceImageCount - 1) {
      carousel.goTo(spaceImageCount - 1, true);
      setImgAccessedFromCarousel(true);
    }
  };

  const next = () => {
    let image_index = formattedImagesList.findIndex(
      (img) => img.url == selectedProductImage
    );

    if (image_index + 1 == spaceImageCount) {
      setSelectedProductImage(formattedImagesList[0].url);
      setSelectedRender(formattedImagesList[0]);
      carousel.goTo(0, true);
      setImgAccessedFromCarousel(true);
    } else {
      setSelectedProductImage(formattedImagesList[image_index + 1].url);
      setSelectedRender(formattedImagesList[image_index + 1]);
      carousel.goTo(image_index + 1, true);
      setImgAccessedFromCarousel(true);
    }
  };

  const prev = () => {
    let image_index = formattedImagesList.findIndex(
      (img) => img.url === selectedProductImage
    );

    //if user moves back from step from carousel then close the carousel
    if (image_index - 1 < 0) {
      carousel.goTo(spaceImageCount - 1, true);
      setSelectedProductImage(formattedImagesList[spaceImageCount - 1].url);
      setSelectedRender(formattedImagesList[spaceImageCount - 1]);
      setImgAccessedFromCarousel(true);
    } else {
      setSelectedProductImage(formattedImagesList[image_index - 1].url);
      setSelectedRender(formattedImagesList[image_index - 1]);
      carousel.goTo(image_index - 1, true);
      setImgAccessedFromCarousel(true);
    }
  };

  const getImageNumber = () => {
    if (selectedProductImage) {
      let image_index = formattedImagesList.findIndex(
        (img) => img.url === selectedProductImage
      );
      return image_index + 1;
    }
    return 1;
  };

  const downloadImageViaUrl = (url) => {
    const imageFullName = url?.split("/").pop();
    const name = imageFullName.split(".")[0];
    const extension = imageFullName.split(".").pop();

    Utilities.downloadFile(url, name, extension);
  };

  const downloadImagesByType = (type, formattedImagesList, directoryName) => {
    const imageUrlsListByType = formattedImagesList
      .filter((obj) => obj.type === type || type === "all")
      .map((obj) => obj.url);

    Utilities.saveToZip(directoryName, imageUrlsListByType, true);
  };


  const menu = (
    <Menu style={{ width: "148px" }}>
      <Menu.Item key={0}>
        <a
          onClick={() => downloadImageViaUrl(selectedProductImage)}
          className="manrope f-14 black-55"
          download
        >
          This image only
        </a>
      </Menu.Item>
      <Menu.Item key={1}>
        <div
          className="manrope f-14 black-55"
          onClick={() =>
            downloadImagesByType(
              "render",
              formattedImagesList,
              `${id}_SpaceRenders.zip`
            )
          }
        >
          Space Renders
        </div>
      </Menu.Item>
      {top_view ? (
        <Menu.Item key={2}>
          <div
            className="manrope f-14 black-55"
            onClick={() =>
              downloadImagesByType(
                "top_view",
                formattedImagesList,
                `${id}_TopView.zip`
              )
            }
          >
            Top View
          </div>
        </Menu.Item>
      ) : (
        ""
      )}
      <Menu.Item key={2}>
        <div
          className="manrope f-14 black-55"
          onClick={() => {
            downloadImagesByType(
              "all",
              formattedImagesList,
              `${id}_AllImages.zip`
            );
          }}
        >
          All Images
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Row className="h-full">
      <Col
        md={7}
        lg={7}
        xl={4}
        className="display-flex j-s a-s wrap sa-s gg-16 custom-scroll product-grid-list"
      >
        {Object.entries(keyTitleMapping2DImages).map(([key, val]) =>
          formattedImagesList.filter((obj) => obj.type === key).length > 0 ? (
            <SpaceAssetListByType
              selectedImage={selectedProductImage}
              selectImage={setSelectedProductImage}
              setImgAccessedFromCarousel={setImgAccessedFromCarousel}
              setSelectedRender={setSelectedRender}
              category={key}
              categoryTitle={val}
              list={formattedImagesList.filter((obj) => obj.type === key)}
            />
          ) : (
            <></>
          )
        )}
      </Col>
      <Col md={17} lg={17} xl={20} className="w-100 override-space-carousel h-full">
      {selectedRender && selectedRender.type == "render" && 
      <div className="resolutionText" style={{ alignItems: "baseline" }}>
          <div className="display-flex justify-start align-baseline" style={{ width: "75%" }}>
              {selectedRender.display_name &&
                  <Tooltip title={<span className="manrope f-12 white">{selectedRender.display_name}</span>}>
                      <h4 className="manrope f-16 clamp-text w-60 ml-10 mb-0">{selectedRender.display_name}</h4>
                  </Tooltip>}
                  {selectedRender.time_updated !== '' && selectedRender.captured_by ? (
                      <h5 className="manrope w-500 f-12 ml-8 grey-8c mb-0">
                          {`Created on ${new Date(selectedRender.time_updated).toDateString()} by `}
                          <span style={{ fontWeight: 'bold' }}>{selectedRender.captured_by}</span>
                      </h5>
                  ) : selectedRender.time_updated !== '' ? (
                      <h5 className="manrope w-500 f-12 ml-8 grey-8c mb-0">
                          {`Created on ${new Date(selectedRender.time_updated).toDateString()}`}
                      </h5>
                  ) : selectedRender.captured_by ? (
                      <h5 className="manrope w-500 f-12 grey-8c mb-0" style={{ marginLeft: '4px' }}>
                          by <span style={{ fontWeight: 'bold' }}>{`${selectedRender.captured_by}`}</span>
                      </h5>
                  ) : null}
          </div>
          <h4 className="manrope w-500 f-16 mr-20 mb-0">{(selectedRender.image_width && selectedRender.image_height) ? `${selectedRender.image_width}x${selectedRender.image_height}` : ''}  {selectedRender.filename ? selectedRender.filename.split('.')[1].toUpperCase() : ''}</h4>
          {storePage != 1 && status !== "pending" && (
            <Dropdown
            overlay={menu}
            trigger={["click"]}
            className="space-download-button"
          >
            <Button className="product-btn dark-blue f-14 br-4">
              Download <DownOutlined />
            </Button>
          </Dropdown>
            
          )}
      </div>}
         <Tooltip
            title={isFullScreen ? "Exit Full Screen" : "Full Screen"}
            placement="left">
              <img
                 src={
                      isFullScreen
                        ? "/img/fullScreenIcon-2.svg"
                        : "/img/fullScreenIcon.jpg"
                    }
                 alt="Fullscreen Icon"
                style={{
                      position: "absolute",
                      top: `${storePage != 1 && status !== "pending" ? '70px' : "20px"}`,
                      zIndex: "8",
                      cursor: "pointer",
                      opacity: "0.8",
                      borderRadius: "4px",
                      right: "20px",
                    }}
                    className="product-full-screen-icon"
                    onClick={(e) => {e.stopPropagation(); setIsFullScreen(!isFullScreen)}}
                    type={isFullScreen ? "fullscreen-exit" : "fullscreen"}
                  />
            </Tooltip>
        <div className="carousel-btns right-btn" onClick={next}>
          <ArrowRightOutlined className="arrow" />
        </div>
        {isFullScreen?<div className="display-image-none space-mask-none full-img" style={{background: "#FFFFFF", height: "100vh",width: "100vw", zIndex: "99"}}><Image className="display-none" src={selectedProductImage} preview={{mask: <></> , visible: isFullScreen , onVisibleChange: (value) => { if(!value) setIsFullScreen(false); }}} /></div>:<></>}
        <Carousel
          autoplay={false}
          ref={(node) => (carousel = node)}
          className="w-100 h-full bg-colored"
          beforeChange={onChangeCarousel}
        >
          {formattedImagesList.map((img, index) => {
            return (
            <div key={index} style={{width: "100%", height: "100%", display: "flex"}}>
              <div style={{width: '80%', height: "100%", margin: "auto", position: "relative"}}>
              {img.status === "pending" ? (
                <div className="thumbnail-btn-bg blur">
                  <span className="thumbnail-btn">Render in Progress...</span>
                </div>
              ) : (
                <></>
              )}  
              <ImageZoomAdmin
                custom_class={
                  img.status === "pending" ? image_style_in_progress : image_style
                }
                compare={false}
                image={img.url}
                notShowToolButtons
              />
            </div>
          </div>
            
          )})}
        </Carousel>
        <div className="carousel-btns left-btn" onClick={prev}>
          <ArrowLeftOutlined className="arrow" />
        </div>
        <div className="count-tag">
          <div className="manrope f-14 white lh-28">
            {getImageNumber()} of {spaceImageCount}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SpaceImageCarousel;
