import React, { useState, useEffect }  from 'react';
import axios from 'axios';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Input, Button, Select, Radio, Popover, Upload, TreeSelect, Tooltip, Form , Typography, Dropdown, Menu, message} from 'antd';
import 'rc-color-picker/assets/index.css';
import CustomerMainLayout from '../../CustomerMainLayout';
import * as Constants from "../../Constants";
import ENVIRONMENT from '../../../../../environments';
import loadImage from 'blueimp-load-image';
import $ from 'jquery';
import AWS from 'aws-sdk';
import SuccessModal from '../../../SuccessModal/SuccessModal';
import WarningModal from '../../../WarningModal/WarningModal';
import * as Utilities from '../../../Utilities';
import PaymentConfirmationModal from '../../../PaymentConfirmationModalSingle/PaymentConfirmationModalSingle';
import CardSaveDrawer from '../../../CardSaveDrawer/CardSaveDrawer';
import * as Styles from '../../../../../Styles';
import PaymentFailureModal from '../../../PaymentFailureModal/PaymentFailureModal';
import InAppPurchaseLimitModal from '../../InAppPurchaseLimitModal';
import ProductOutputSelection from '../../../ProductForms/ProductOutputSelection/ProductOutputSelection'
import MspWarningModal from '../../MspWarningModal/MspWarningModal';
import FileConstants from '../../../../../FileConstants'
import HelpMessageModal from '../../../HelpMessageModal/HelpMessageModal';

const categoryStyle = Styles.categoryStyle;

const progress_bar = Styles.progress_bar;

let upload_obj = {};

const UploadMaterial = (props) => {
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [materialsData, setMaterialsData] = useState([]);
    const [editableStr, setEditableStr] = useState('');

    const [materialValue, setMaterialValue] = useState('');
    const [materialSearchValue, setMaterialSearchValue] = useState('');
    const [materialFiles, setMaterialFiles] = useState([]);
    const [referenceImages, setReferenceImages] = useState([]);
    const [modelType, setModelType] = useState('plain');
    const [uploadButtonLoader, setUploadButtonLoader] = useState(false);
    const [materialsArray, setMaterialsArray] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [dim, setDim] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [dimError, setDimError] = useState('');
    const [dpi, setDpi] = useState(0);
    const [originalWidth, setOriginalWidth] = useState(0);
    const [originalHeight, setOriginalHeight] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [materialSuccess, setMaterialSuccess] = useState(false);
    const [requestID, setRequestID] = useState(-1);
    const [successConfig, setSuccessConfig] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [materialData, setMaterialData] = useState([]);
    const [initialFormValues, setInitialFormValues] = useState({});
    const [loadForm, setLoadForm] = useState(false);
    const [saveButtonLoader, setSaveButtonLoader] = useState(false);
    const [formType, setFormType] = useState(0);
    const [modelTypes, setModelTypes] = useState(['high_res']);
    const [materialRequiredOutput, setMaterialRequiredOutput] = useState(FileConstants.MATERIAL_OUTPUT_FOR_MODEL['high_res']);

    const [requestStatus, setRequestStatus] = useState(null);
    const [cardStatus, setCardStatus] = useState(null);
    const [paymentConfirmationModal, setPaymentConfirmationModal] = useState(false);
    const [materialCreatePayload,setMaterialCreatePayload] = useState(null);
    const [cardSaveDrawer, setCardSaveDrawer] = useState(false);
    const [cardSaveFailure, setCardSaveFailure] = useState(false);
    const [requestType, setRequestType] = useState('');
    const [paymentRequestID, setPaymentRequestID] = useState(-1);
    const [materialName, setMaterialName] = useState('');
    const [prices, setPrices] = useState([]);
    const [price, setPrice] = useState(-1);
    const [selectedPriceCategory, setSelectedPriceCategory] = useState('');
    const [purchaseType, setPurchaseType] = useState('')
    const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
    const [paymentFailureModal, setPaymentFailureModal] = useState(false);
    const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
    const [inAppLimitExceededModal, setInAppLimitExceededModal] = useState(false);
    const [redirectionPath, setRedirectionPath] = useState(null);
    const [isMspUserWarningModal, setIsMspUserWarningModal] = useState(false);
    const [helpMessageModal, setHelpMessageModal] = useState(false);
    const [form] = Form.useForm();
    const { match: { params } } = props;
    const { Option } = Select;

    useEffect(() => {
        let redirection_path = new URLSearchParams(window.location.search).get("origin");
        setRedirectionPath(redirection_path);
    }, []);


    useEffect(() => {
        if (props && props.match) {
            if (props.match.path.includes("create-new-material")) {
                setRequestType("new_material");
                setFormType(0);
            }
            else if (props.match.path.includes("upload-scanned-material")) {
                setFormType(1);
                setRequestType("scanned_material");
            }
        }
        let request_id = new URLSearchParams(window.location.search).get("request_id");
        console.log(request_id);
        if (request_id != null) {
            setRequestID(request_id);
        }

        let material_id = params.id;
        console.log(material_id)
        if (material_id != undefined) {
            let payload = {
                material_id: material_id
            };
            setLoadForm(true);
            console.log(payload)
            axios.post(ENVIRONMENT.MATERIAL_GET, payload )
            .then(res => {
                console.log(res)
                let material_data = JSON.parse(res.data.body);
                console.log(material_data)
                if (material_data) {
                    setReferenceImages(material_data.reference_files);
                    setMaterialFiles(material_data.material_files);
                }
                let check_if_arr_guidelines =  Array.isArray(material_data.guidelines);
                form.current.setFieldsValue({
                    material_name: material_data.name,
                    material_category: material_data.category,
                    model_type: material_data.type,
                    material_width: material_data.width && material_data.width !=0 ? material_data.width : '',
                    material_height: material_data.length && material_data.length != 0 ? material_data.length : '' ,
                    material_image: material_data.material_files,
                    reference_images: material_data.reference_files,
                    tags: material_data.tags,
                    guidelines: check_if_arr_guidelines ? "" : material_data.guidelines,
                    material_output: ''
                });
                setMaterialName(material_data.name);

                console.log(form.current.getFieldsValue())
                setInitialFormValues(form.current.getFieldsValue());
                setMaterialData(material_data);
                setMaterialRequiredOutput(material_data.material_output)
                setLoadForm(false);
            }).catch(err => {
                message.error('Error in getting material.')
            });
        }
        loadUserRequestStatus();
    }, [requestType]);

    const loadUserRequestStatus = () => {
        if(requestType != ''){
        let payload = {
          "username": localStorage.getItem('username'),
          "request_type": requestType,
          "action": "check"
        }
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
            .then(res => {
                setPurchaseType(res.data['purchase_type'])
                setPrice(res.data['price'])
                setRequestStatus(res.data['request_allowed']);
                setCardStatus(res.data['card_status']);
                getMaterialCategories();
            });
        }
      }

    const getMaterialCategories = () => {
        let payload = {
            "request_type": 'material',
        }

        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
        .then(res => {
            if (res.data) {
                setPrices(res.data);
            }
        })
    }

    useEffect(() => {
        let price_local =  prices.filter((request) => request.request_type == requestType);
        console.log(price_local);
        if (price_local && price_local[0]) {
            // setPrice(parseInt(price[0].price));
            setSelectedPriceCategory(price_local[0].category);
        }
    },[prices,requestStatus,formType]);


    useEffect(() => {
        let data = [];
        axios.post(ENVIRONMENT.ADMIN_MATERIAL_BATCH, {
        })
        .then(res => {
            let categoriesData = Utilities.getMaterialsData( res.data );
            for (let i = 1; i <= (res.data).length; i++) {
                if( res.data[i - 1].parent_id == "")
                {
                    data.push({
                        key: res.data[i - 1]['material_id'],
                        title: res.data[i - 1]['name'],
                        value: res.data[i - 1]['name'],
                        children: categoriesData[res.data[i - 1]['material_id']],
                    });
                }

            }
            setMaterialsData(data);
        });
    },[]);

    useEffect(() => {
        if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') == null)) {
            setTimeout(() => {
                setIsMspUserWarningModal(true);
            }, 5000);
        }
      },[])

    const addMaterialOption = (e) => {
        if(e.keyCode == 13){
            if(!materialsData.some(e => e.key == materialSearchValue)){
                setMaterialsData(materialsData.concat([{
                    key: materialSearchValue,
                    value: materialSearchValue,
                    title: materialSearchValue,
                    children: [],
                }]));
            }
        }
    }

    const skipUploading = () => {
        window.location.href = '/home';
    }

    // Change required output type for material based on user selection
    const changeModelOutput = (checkedValue) => {
        if (checkedValue.length == 0) {
            return
        }

        setModelTypes(checkedValue);
        let materialOutputType = FileConstants.MATERIAL_OUTPUT_FOR_MODEL[checkedValue[0]];

        if (checkedValue.find(a => a.includes('ar'))
            && checkedValue.find(a => a.includes('high_res'))) {
            materialOutputType = FileConstants.MATERIAL_OUTPUT_FOR_MODEL['high_res_and_ar'];
        }

        if (checkedValue.find(a => a.includes('high_pbr'))){
            materialOutputType = FileConstants.MATERIAL_OUTPUT_FOR_MODEL['high_pbr'];
        }

        if (checkedValue.find(a => a.includes('ar'))
            && checkedValue.find(a => a.includes('high_pbr'))) {
            materialOutputType = FileConstants.MATERIAL_OUTPUT_FOR_MODEL['high_pbr_and_ar'];
        }

        setMaterialRequiredOutput(materialOutputType);
        form.current.setFieldsValue({
            material_output: materialOutputType
        });
    }

    const handleReferenceFileChanges = (info) => {
        if (!buttonLoader && !uploadButtonLoader) {
            let fileList = [...info.fileList];
            setReferenceImages(fileList);
            setUploading(true);
            if (info.file.status === "done") {
                setErrorMessage('');
                setUploading(false);
            } else if (info.file.status === "error") {
                setErrorMessage(info.file.error.code.concat('.   ' + info.file.error.message));
                if (info.file.error.code.includes('Credentials')) {
                    setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
                }  else if (info.file.error.code.includes('Network')) {
                    setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
                }
                setUploading(false);

            }

        }
    }

    const saveProgress = () => {
        let values = form.current.getFieldsValue();
        console.log('handleSave ',values);

        if(values['material_name'] != "" && values['material_name'] != undefined){

            if (JSON.stringify(values) === JSON.stringify(initialFormValues)) {
                setUploadError("You have not made any change to your material's data. Kindly make changes to the data to Save Progress.")
            }
            else {

                let materialUploadStatus = 'not_started';
                values['material_image'] = materialFiles;
                materialUploadStatus = validateFileUploads(values['material_image']);


                let referenceFileStatus = 'not_started';
                values['reference_images'] = referenceImages;
                referenceFileStatus = validateFileUploads(values['reference_images']);

                if (referenceFileStatus == 'uploading' || materialUploadStatus == 'uploading') {
                    setUploadError('Uploads are in progress, please wait till file uploads are completed.')
                }
                else if (referenceFileStatus == 'error' || materialUploadStatus == 'error') {
                    setUploadError('Error occured in uploading bundle, please re-upload your files.')
                }
                else {
                    setSaveButtonLoader(true);
                    let image_width, image_height, image_dpi = 0;
                    if (values.material_width != "") {
                        image_width = values.material_width;
                    }
                    if (values.material_height != "") {
                        image_height = values.material_height;
                    }
                    if (values.material_height == "" || !values.material_height){
                        image_height = 0;
                    }
                    if (values.material_width == "" || !values.material_width) {
                        image_width = 0;
                    }

                    image_dpi = 0;
                    setUploadError('');
                    setDimError('');
                    let material_data = {};
                    material_data = {
                        category: values.material_category,
                        name: values.material_name,
                        type: values.model_type,
                        reference_files: referenceImages,
                        username: localStorage.getItem('username'),
                        material_files: materialFiles,
                        width: parseFloat(image_width).toFixed(2),
                        length: parseFloat(image_height).toFixed(2),
                        dpi: Number.isNaN(image_dpi) ? 0 : parseInt(image_dpi),
                        is_scanned: formType == 0 ? false : true,
                        tags: values.tags,
                        guidelines: formType == 0 ? values.guidelines  : ""
                    }

                    let material_id = params.id;
                    console.log(JSON.stringify(material_data));

                    console.log(material_id);
                    if ( material_id) {
                        console.log('Save');
                        material_data.material_id =  material_id;
                        material_data.status = 1;
                        material_data.material_output = materialRequiredOutput;
                        axios.post(ENVIRONMENT.UPDATE_MATERIAL, material_data)
                        .then(res => {
                            setSaveButtonLoader(false);
                            setUploadButtonLoader(false);
                            let cookie_name = Utilities.getCookie("save_help")
                            if (!cookie_name) {
                                setHelpMessageModal(true);
                            } else {
                                handleCancel();
                            }
                        }).catch(err => {
                            setSaveButtonLoader(false);
                        });
                    }
                    else {
                        material_data.status = 1;
                        material_data.material_output = materialRequiredOutput
                        console.log('Create')
                        axios.post(ENVIRONMENT.CREATE_MATERIAL, material_data)
                        .then(res => {
                            setSaveButtonLoader(false);
                            // setSaveSuccess(true);
                            let cookie_name = Utilities.getCookie("save_help")
                            if (!cookie_name) {
                                setHelpMessageModal(true);
                            } else {
                                handleCancel();
                            }
                            setUploadButtonLoader(false);
                        }).catch(err => {
                            setSaveButtonLoader(false);
                        });

                    }
                }
            }

        } else {
            setUploadError('You must enter at least the material name to Save the Progress.')
        }

    }

    const handleMaterialFileChange = (info) => {
        if (!buttonLoader && !uploadButtonLoader) {
            let fileList = [...info.fileList];
            fileList = fileList.slice(-1);
            setMaterialFiles(fileList);
            setUploading(true);
            if (info.file.status === "done") {
                setErrorMessage('');
                setUploading(false);
            } else if (info.file.status === "error") {
                setErrorMessage(info.file.error.code.concat('.   ' + info.file.error.message));
                if (info.file.error.code.includes('Credentials')) {
                    setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
                }  else if (info.file.error.code.includes('Network')) {
                    setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
                }
                setUploading(false);

            }
        }
    }

    const handleCancelSuccess = () => {
        setMaterialSuccess(false);
    }

    const validateFileUploads = (files) => {
        let status_done = false;
        if (files) {
            for(var file of files) {
                if (file['status'] == 'error') {
                    return 'error';
                }
                else if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                else if (file['status'] == 'done') {
                    status_done = true;
                }
            }
        }
        if (status_done) {
            return 'done'
        }
        return 'not_started';
    }

    const onFinish = (values) => {
        console.log(values);

        let material_upload_status = validateFileUploads(materialFiles);
        let reference_upload_status = validateFileUploads(referenceImages);
        if (material_upload_status == 'error' || (reference_upload_status == 'error')) {
            setUploadError('Retry failed uploads.');
            setUploadButtonLoader(false);
        } else if (material_upload_status == 'uploading' || (reference_upload_status == 'uploading')) {
            setUploadError('Please wait! Uploads are still in progress.');
            setUploadButtonLoader(false);
        }
        else if (material_upload_status == 'done') {
            let image_width, image_height, image_dpi = 0;

            if (values.material_width && values.material_height) {
                image_width = values.material_width;
                image_height = values.material_height;
            } else {
                image_width = width;
                image_height = height;
            }

            if ((((!Utilities.isNumber(width) || width == 0) || (!Utilities.isNumber(height) || height == 0)) && !values.material_width && !values.material_height) || (values.material_width && values.material_width == 0 || values.material_height && values.material_height == 0)) {
                image_width = 0;
                image_height = 0;
            }
            image_dpi = 0;

            setUploadError('');
            setDimError('');
            let material_id = params.id;
            let material_data = {};
            material_data = {
                category: values.material_category,
                name: values.material_name,
                type: values.model_type,
                reference_files: referenceImages,
                username: localStorage.getItem('username'),
                material_files: materialFiles,
                width: parseFloat(image_width).toFixed(2),
                length: parseFloat(image_height).toFixed(2),
                dpi: parseInt(image_dpi),
                is_scanned: formType == 0 ? false : true,
                guidelines: formType == 0 ? values.guidelines  : "",
                tags: values.tags,
                user_type : 'customer'
            }

            console.log(material_id);
            if (material_id) {
                material_data.material_id =  material_id;
                material_data.status = 2;
                material_data.material_output = materialRequiredOutput
                let requestPayload = material_data;
                let gateway_payload = {
                    "request_type": requestType,
                    "update": "",
                    "username": localStorage.getItem('username'),
                    "action": "perform",
                    "request_payload": requestPayload,
                    "category": selectedPriceCategory,
                    "request_id": Date.now().toString()
                }
                if (FileConstants.isMSProvider && (FileConstants.MANAGED_CUSTOMER_USERNAME != null || FileConstants.MANAGED_COMPANY_ID != null)) {
                    gateway_payload["request_payload"]['requested_for'] = FileConstants.MANAGED_CUSTOMER_USERNAME
                }
                if (requestStatus == 'allowed' || requestStatus == 'always_allowed') {
                    setUploadButtonLoader(true);
                    axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
                    .then(res => {
                        if (res.data.status!=undefined && res.data.status == 1) {
                            setButtonLoader(false);
                            setMaterialSuccess(true);
                            setUploadButtonLoader(false);
                            resetEverything();
                        }
                        else{
                            setPaymentFailureMessage(res.data.error_message);
                            setPaymentFailureModal(true);
                            setUploadButtonLoader(false);
                            setButtonLoader(false);
                        }
                    });
                }
                if (requestStatus == 'payment_required') {
                    setMaterialCreatePayload(gateway_payload);
                    setPaymentConfirmationModal(true);
                }
                if (requestStatus == 'not_allowed') {
                    setInAppLimitExceededModal(true);
                }
            }
            else {
                material_data.status = 2;
                material_data.material_output = materialRequiredOutput
                submitMaterials(material_data,false);
            }

        }

    }

    const submitMaterials = (material_data,save_form) => {

        let payload = material_data;
        payload.material_output = materialRequiredOutput
        let company_id = localStorage.getItem('company_id');
        if(company_id != undefined) {
            payload.company_id = company_id;
        }
        console.log(JSON.stringify(payload));
        let requestPayload = payload;
        let gateway_payload = {
            "request_type": requestType,
            "username": localStorage.getItem('username'),
            "action": "perform",
            "request_payload": requestPayload,
            "category": selectedPriceCategory,
            "request_id": Date.now().toString()
        }
        if (FileConstants.isMSProvider && (FileConstants.MANAGED_CUSTOMER_USERNAME != null || FileConstants.MANAGED_COMPANY_ID != null)) {
            gateway_payload["request_payload"]['requested_for'] = FileConstants.MANAGED_CUSTOMER_USERNAME
        }
        console.log(gateway_payload);
        if (requestStatus == 'allowed' || requestStatus == 'always_allowed') {
            setUploadButtonLoader(!save_form);
            axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
            .then(res => {
                if (res.data.status!=undefined && res.data.status == 1) {
                    if (requestID == -1) {
                        if (save_form) {
                            message.info("Material form saved successfully")
                        } else {
                            setMaterialSuccess(true);
                        }
                        setButtonLoader(false);
                        setSaveButtonLoader(false);
                        setUploadButtonLoader(false);
                    }
                }
                else{
                    setPaymentFailureMessage(res.data.error_message);
                    setPaymentFailureModal(true);
                    setButtonLoader(false);
                    setSaveButtonLoader(false);
                    setUploadButtonLoader(false);
                }
            });
        }
        if (requestStatus == 'payment_required') {
            setMaterialCreatePayload(gateway_payload);
            setPaymentConfirmationModal(true);
        }
        if (requestStatus == 'not_allowed') {
            setInAppLimitExceededModal(true);
        }
        // axios.post(CREATE_MATERIAL,payload)
        // .then(res => {
        //     console.log(res);

        //     if (requestID == -1) {

        //         if (save_form) {
        //             setSaveSuccess(true);
        //         } else {
        //             setMaterialSuccess(true);
        //         }
        //         setButtonLoader(false);
        //         setSaveButtonLoader(false);
        //         setUploadButtonLoader(false);

        //     } else {
        //         let material_id_arr = res.data.material_ids;
        //         let payload_data = {};
        //         let payload = [];
        //         material_id_arr.map((id) => {
        //             payload_data = {
        //                 configurable_request_id: requestID,
        //                 material_id: id
        //             }
        //             payload.push(payload_data);
        //         })
        //         console.log(payload);
        //         axios.post(ENVIRONMENT.ADD_MATERIALS_IN_REQUEST, payload)
        //             .then(res => {
        //                 console.log(res);
        //                 setButtonLoader(false);
        //                 setUploadButtonLoader(false);
        //                 setSuccessConfig(true);
        //             });
        //     }

        // });
    }

    const initiateConfirmation = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'create_new_material') {
                confirmPaymentAndSubmit();
            }

        }, false);

        if (cardStatus == 1) {
            confirmPaymentAndSubmit();
        }
        if (cardStatus == 0) {
            let child_window = window.open('/save-card?action=create_new_material', '', "width=600,height=600");
            let refreshInterval = setInterval(() => {
                if(child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);
                }
            }, 500);
            setCardSaveFlowTriggered(true);
        }
    }

    const cancelPaymentConfirmation = () => {
        setPaymentConfirmationModal(false);
        setUploadButtonLoader(false);
    }


    const confirmPaymentAndSubmit = () => {
        setUploadButtonLoader(true);
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, materialCreatePayload)
            .then(res => {
                if (res.data.status!=undefined && res.data.status == 1) {
                    if (requestID == -1) {
                        setMaterialSuccess(true);
                        setPaymentConfirmationModal(false);
                        setButtonLoader(false);
                        setSaveButtonLoader(false);
                        setUploadButtonLoader(false);
                        resetEverything();
                    }
                }
                else{
                    setPaymentFailureMessage(res.data.error_message);
                    setPaymentFailureModal(true);
                    setButtonLoader(false);
                    setSaveButtonLoader(false);
                    setUploadButtonLoader(false);
                }

            });
    }

    const saveRequestIntermedietely = (callback = null) => {
        setUploadButtonLoader(true);
        console.log(JSON.stringify(materialCreatePayload))
        let payload = {
            'request_type': requestType,
            'request_body': materialCreatePayload,
            'processed': false
        }
        if (paymentRequestID == -1) {
            axios.post(ENVIRONMENT.SAVE_REQUEST_INTERMEDIATE, payload)
                .then(res => {
                    console.log(res);
                    setPaymentConfirmationModal(false);
                    setButtonLoader(false);
                    setSaveButtonLoader(false);
                    setUploadButtonLoader(false);
                    if (callback!= null)
                    {
                        callback(res.data[0].request_id,requestType);
                    }
                });
        } else {
            setPaymentConfirmationModal(false);
            setButtonLoader(false);
            setSaveButtonLoader(false);
            setUploadButtonLoader(false);
            if (callback!= null)
            {
                callback(paymentRequestID,requestType);
            }
        }
    }

    const resetEverything = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;


        setOriginalHeight(0);
        setOriginalWidth(0);
        setModelType('plain');
        setDpi(0);
        setWidth(0);
        setHeight(0);
        form.current.resetFields();
        forceUpdate();
        setMaterialFiles([]);
        setUploadError('');
        setReferenceImages([]);
    }

    const triggerMaterialUpload = () => {
        $('#material-file-upload').trigger('click');
        let button = document.getElementById('material-file-upload');
        button.disabled = false;
    }

    const triggerRefUpload = () => {
        $('#ref-file-upload').trigger('click');
        let button = document.getElementById('ref-file-upload');
        button.disabled = false;
    }

    const onChangeModelType = (e) => {
        setModelType(e.target.value);
        console.log(e.target.value);
    }

    const updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'new_material') {
                confirmPaymentAndSubmit();
            }

        }, false);

        setPaymentFailureModal(false);
        let child_window = window.open('/save-card?action=new_material', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
            if(child_window.closed) {
            setCardSaveFlowTriggered(false);
            clearInterval(refreshInterval);
            }
        }, 500);
        setCardSaveFlowTriggered(true);
    }

    const redirectToPage = () => {
        if (formType == 0) {
            window.location.href = "/create-new-material";
        }
        if (formType == 1) {
            window.location.href = "/upload-scanned-material";
        }
    }

    const changeMaterialName = (e) => {
        setMaterialName(e.target.value);
    }

    const handleCancel = () =>{
        if(redirectionPath){
            props.history.push({
                pathname: redirectionPath
            });
        } else {
            props.history.push({
                pathname: '/home'
            });
        }
    }

    const handleWarningModalClose = () => {
        setIsMspUserWarningModal(false);
    }

    return (
        <div className="my-products">
          <CustomerMainLayout selected='16'>
            <Row  className="justify-in-center">
                <Col span={18} style={{padding: "0 5%"}} className='mt-20'>
                    {loadForm ?
                    <Row>
                        <div  className="manrope f-16 black-55" style={{marginTop: 20}}>
                        <LoadingOutlined/> &nbsp;
                            Loading saved data...
                        </div>
                    </Row>: ''}
                    {
                    (localStorage.getItem("is_msprovider") == 'true') && localStorage.getItem('managed_customer_username') &&
                    <Row gutter={[12,15]} style={{marginTop: 20}}>
                    <Col span={24}>
                        <div className="custom-alert info">
                        You are requesting this Material for customer {localStorage.getItem('managed_customer_username')}
                        <>{(localStorage.getItem('managed_company_name') != undefined) ? (' (Company: ' + localStorage.getItem('managed_company_name') + ')'):''}</>
                        </div>
                        </Col>
                    </Row>
                    }
                    <Row className="justify-in-start">
                        <Col>
                            <h3 style={{marginBottom: 0}} className='manrope f-24 black-14 w-700'>{formType == 0 ? "Create New Material" : "Upload Scanned Material"}</h3>
                        </Col>
                    </Row>

                    <Row>
                        {formType == 0 ?
                        <Col style={{marginTop: 8,marginBottom: 20}}>
                            <div className="manrope f-14 grey-77">
                            If you want to request a material (Plain or Patterned) using a photograph of your swatch, please upload it and provide the required information.
                            </div>
                            <div className="manrope f-14 blue w-400"  style={{marginTop: 8}}>
                            Make sure your material swatch is at least 300 DPI.
                            </div>
                        </Col>:
                        <Col style={{marginTop: 8,marginBottom: 30}}>
                            <div className="manrope f-14 grey-77">
                            If you already have a scanned archive of your material, please upload it and provide the required information.
                            </div>
                            <div className="manrope f-14 blue w-400"  style={{marginTop: 8}}>
                            Make sure all your material swatches inside the scanned archive are at least 300 DPI.
                            </div>
                        </Col>}
                    </Row>

                    <Form
                        scrollToFirstError
                        ref={form}
                        name="material-form"
                        onFinish={onFinish}>
                        <Row>
                            <Col span={24} className="justify-in-start"  style={{alignItems:"flex-start"}}>
                                <div className="material-field" style={{marginRight: 16}}>
                                    <Form.Item className='form-labels' colon={false} name="material_name" style={{display:'block'}} label={<span className="manrope f-14 black-55">Name</span>} rules={[{ required: true, message: 'Please enter material name!' }]}>
                                        <Input onChange={changeMaterialName} placeholder={'Enter Material Name'} className="manrope f-10 library-search adjust-height"/>
                                    </Form.Item>
                                </div>
                                <div className="material-field">
                                    <Form.Item  colon={false} name="material_category" style={{display:'block'}} label={<span className="manrope f-14 black-55">Category</span>} className="material-category-form form-labels"
                                    rules={[{ required: true, message: 'Please select a category!' }]}>
                                        <TreeSelect
                                        className="tree-select-material f-10"
                                        showSearch
                                        style={{ width: '100%', color:"#333333" }}
                                        dropdownStyle={Styles.categoryStyleF10}
                                        placeholder={'Select or type in to search'}
                                        treeData={materialsData}
                                        value={materialValue}
                                        onChange={(value) => setMaterialValue(value)}
                                        onSearch={(e) => {setMaterialSearchValue(e)}}
                                        onInputKeyDown={(e) => { addMaterialOption(e)} }
                                        notFoundContent={<span className='tree-select-material f-10'>No such material found. Press enter to add your material.</span>}>
                                        </TreeSelect>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20, marginBottom: 20}}>
                            <Col>
                                <Form.Item className='form-labels'  name="model_type" colon={false}  style={{display:'block'}} label={<span  className="manrope f-14 black-55">Select the type of material</span>} rules={[{ required: true, message: 'Please select a material type.' }]} initialValue={'plain'}>
                                    <Radio.Group onChange={onChangeModelType} className="manrope f-12 black-55" >
                                        <Radio value={'plain'} className="manrope f-12 black-55">Plain Material</Radio>
                                        <Radio value={'patterned'} className="manrope f-12 black-55">Patterned Material</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {modelType == "patterned" ?
                                <div className="note-bg-specs font-12" style={{marginTop: 24,flexDirection: 'column',alignItems:"flex-start"}}>
                                    <span>Please note that if the uploaded material type is patterned and if it is not tile-able, it will take us some time to make it tile-able and ready to be used with your configurable model.</span>
                                    <a className="manrope f-12 blue link" target="_blank" style={{marginTop: 8}} href={ENVIRONMENT.TILEABLE_MATERIAL}>What is a tile-able material?</a>
                                </div>: ''}
                            </Col>
                            <Form.Item name = "material_output">
                                <ProductOutputSelection
                                changeModelOutput={changeModelOutput}
                                modelTypes={modelTypes}
                                />
                            </Form.Item>
                        </Row>
                        <Col span={24} >
                            <div style={{marginBottom: 5,display:'block'}}>
                                <span className="manrope f-14 black-55">
                                    <span className="manrope f-14 red-ff">* </span>Attach Material Swatch&nbsp;&nbsp;<Popover className='centered-popup' placement="bottom" trigger="hover" content={<span className="manrope f-14 black-33 justify-in-center" style={{textAlign:'center',flexDirection: 'column'}}>
                                        <span className='center-popup-text'>
                                        {formType == 0 ?
                                        "Please upload one image that best represents the material swatch that is to be created." :
                                        "Please add images in your scanned archive that best represent the material swatch that is to be created."}
                                        </span>
                                        <img  src={'/img/material_guideline.png'} className="sample-image-popup contain"/>
                                    </span>}><InfoCircleOutlined/></Popover></span>

                                </div>
                                <Form.Item
                                className="upload-pd form-labels" onClick={triggerMaterialUpload}
                                    style={{display:"block"}}
                                    colon={false}
                                    label={""}
                                    name="material_image"
                                    rules={[{ required: true, message: 'Please upload material image!' }]}>
                                    <Upload
                                        id="material-file-upload"
                                        className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-adjust-form-height modal-archive-3d"
                                        accept={formType == 0 ? ".png,.jpg,.jpeg" : ".zip"}
                                        multiple={false}
                                        progress= {Styles.progress_bar}
                                        onChange = {handleMaterialFileChange}
                                        {...(Constants.getUploadProps(materialData.platform))}
                                        openFileDialogOnClick={false}
                                        onRemove={file => {
                                            let button = document.getElementById('material-file-upload');
                                            button.disabled = true;
                                            if (uploadButtonLoader || buttonLoader) {
                                                setUploadError('You cannot remove your file while upload is in progress!');
                                            } else {
                                                setMaterialFiles([]);
                                                setUploadError('');
                                                setUploading(false);
                                                setErrorMessage('');
                                            }
                                            setWidth(0);
                                            setHeight(0);
                                            setDpi(0);
                                            setOriginalWidth(0);
                                            setOriginalHeight(0);
                                        }}
                                        beforeUpload={file => {
                                            setUploadError('');
                                        }}
                                        listType="text"
                                        fileList = {materialFiles}>
                                            {validateFileUploads(materialFiles) == "uploading"  ?
                                                <div className="justify-in-center">
                                                    <p className="justify-in-center manrope f-12 black-55">Upload in Progress... </p>
                                                </div> :
                                            validateFileUploads(materialFiles) == "done" ?
                                                <span>
                                                    <div className="justify-in-center">
                                                        <img height="25px" width="25px" src="/img/success.png" style={{marginRight: 5}}></img>
                                                        <p className="manrope f-12" style={{color:"#25D6A4"}}>Upload Successful</p>
                                                    </div>
                                                </span> :
                                            validateFileUploads(materialFiles) == "error" ?
                                            <span>
                                                <div className="justify-in-center">
                                                    <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                                    <p className="manrope f-12" style={{color:"#D93025"}}>Upload Failed</p>
                                                </div>
                                                <Button
                                                    onClick={() => Constants.triggerFailedFilesUpload('material-file-upload', materialFiles, setMaterialFiles)}
                                                    disabled={materialFiles.length === 0 || uploading}
                                                    loading={uploading}
                                                    className="manrope f-12 blue retry-btn"
                                                    >
                                                    <span>{uploading ? 'Retrying...'  : 'Upload Failed. Click to Retry.'}</span>
                                                </Button>
                                            </span> :
                                            (validateFileUploads(materialFiles) == "not_started" ?
                                                <span>
                                                    {formType == 0 ?
                                                    <>
                                                        <p className="manrope f-10 grey-77" style={{textAlign:'center'}}>Drop your plain or patterned material swatch here or <span style={{color: "#276DD7"}}>Browse</span>.</p>
                                                        <p className="manrope f-10 grey-77" style={{textAlign:'center'}}>
                                                        Supported formats: PNG and JPEG only
                                                        </p>
                                                    </>:
                                                    <>
                                                        <p className="manrope f-12 grey-77" style={{textAlign:'center'}}>Drop scanned archive of your material here or <span style={{color: "#276DD7"}}>Browse</span>.</p>
                                                        <p className="manrope f-12 grey-77" style={{textAlign:'center'}}>
                                                        Supported formats: .ZIP
                                                        </p>
                                                    </>}
                                                </span>: '')}
                                    </Upload>
                                </Form.Item>


                                {validateFileUploads(materialFiles) == "error" ?
                                <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginRight: 8,marginTop: 8}}><img src="/img/error-small.png" style={{marginRight: 8}}/><span>{errorMessage}</span></div>
                            : ''}

                        </Col>

                        <Row style={{marginTop: 40}}>

                            <Col span={24} style={{marginBottom: 5,display:'block'}} >
                                <span  className="manrope f-14 black-55">Material Swatch Dimensions&nbsp;&nbsp;<Tooltip title={<span className='manrope f-12 white'> Please enter the real world dimensions (in inches) of the material swatch. This will determine the size of the material unit and how it repeats in real world. </span>}><InfoCircleOutlined/></Tooltip></span>

                            </Col>
                            <Col span={24} className="justify-space-between" style={{alignItems:"flex-start"}}>
                                <div className="material-field" style={{marginRight: 16}}>
                                    <Form.Item  className='form-labels' colon={false} style={{display:'block'}} label="" name="material_width" rules={[{ required: false, message: 'Please enter material width!' }]}>
                                        <Input placeholder={'Enter Width'} className="manrope f-10 library-search font-10 adjust-height" type="number" min="1" step="0.01"
                                        suffix={<Tooltip title={<span className="manrope f-12 white">inch</span>}><span className="manrope f-12 black-55">in</span></Tooltip>}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="material-field">
                                    <Form.Item  className='form-labels' colon={false} name="material_height" style={{display:'block'}} label="" rules={[{ required: false, message: 'Please enter material height!' }]}>
                                        <Input placeholder={'Enter Height'} type="number" min="1" className="manrope f-12 library-search font-10 adjust-height" step="0.01"
                                        suffix={<Tooltip title={<span className="manrope f-12 white">inch</span>}><span className="manrope f-12 black-55">in</span></Tooltip>}
                                        />
                                    </Form.Item>
                                </div>

                            </Col>

                        </Row>

                        <Row className='mt-40'>
                            <Col span={24} style={{marginBottom: 5,display:'block'}} >
                                <span  className="manrope f-14 black-55">Tags&nbsp;</span>
                            </Col>
                            <Col span={24}>
                                <Form.Item colon={false} name="tags" rules={[{ required: false, type: 'array', message: 'Please add tag(s).' }]}>
                                    <Select disabled={props.saveButtonLoader} className="manrope f-12 black-55 select-tags" mode="tags" placeholder="Enter tag(s)">
                                        <Option value="" className="manrope f-12 black-55 select-view"></Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Col span={24} style={formType===0? {marginTop: 24,marginBottom: 50}:{marginTop: 24,marginBottom: 115}}>
                            <div style={{marginBottom: 8,display:'block'}}>
                                <span className="manrope f-14 black-55">Attach Reference Files&nbsp;&nbsp;<Tooltip title={<span className="manrope f-12 white">
                                        You can upload any reference files including additional images.
                                    </span>}><InfoCircleOutlined/></Tooltip></span>
                                    <span className="note-w-border font-12" style={{flexDirection: 'column',alignItems:"flex-start",width: "fit-content",marginTop: 5}}>
                                     <ul style={{paddingInlineStart: 20,marginBottom: 0}}>
                                         <li>
                                             Please upload an image of the material swatch next to a ruler, so the artist can judge the real-world scale of the swatch. <Popover placement="bottom" trigger="hover" className="manrope f-12 blue link" content={<img  src={'/img/material-w-ruler.png'} className="sample-image-popup"/>}>
                                                View sample image here
                                             </Popover>
                                         </li>
                                         <li style={{marginTop: 12}}>
                                             Please upload images with the material folded for fabric swatches, so the artist can judge values such as fall-off. <Popover placement="bottom" trigger="hover" className="manrope f-12 blue link" content={<img  src={'/img/folded-materials.png'} className="sample-image-popup"/>}>
                                                View sample image here
                                             </Popover>
                                         </li>
                                     </ul>
                                    </span>
                                </div>
                            <Form.Item
                            onClick={triggerRefUpload}
                            style={{display:"block"}}
                            colon={false}
                            className="upload-pd form-labels"
                            // className={`upload-pd ${referenceImages && referenceImages.length == 0 ? `` : `picture-card-custom`}`}
                            name="reference_images"
                            label={''}
                            rules={[{ required: false, message: 'Please upload reference files!' }]}>
                                <Upload
                                className= {`upload-lg-btn ant-upload-picture-card-wrapper upload-box-adjust-form-height ${formType===0 ? '':'upload-scanned-material'} modal-archive-3d`}
                                listType="text"
                                id="ref-file-upload"
                                accept=".png,.jpg,.jpeg,.zip,.mat"
                                multiple={true}
                                progress= {Styles.progress_bar}
                                onChange = {handleReferenceFileChanges}
                                {...(Constants.getUploadProps(materialData.platform))}
                                beforeUpload={file => {
                                    setUploadError('');
                                }}
                                openFileDialogOnClick={false}
                                onRemove={file => {
                                    let button = document.getElementById('ref-file-upload');
                                    button.disabled = true;
                                    if (uploadButtonLoader || buttonLoader) {
                                        setUploadError('You cannot remove your file while upload is in progress!');
                                    } else {
                                        setReferenceImages([]);
                                        setUploadError('');
                                        setUploading(false);
                                        setErrorMessage('');
                                    }
                                }}
                                fileList = {referenceImages}>
                                    {
                                    validateFileUploads(referenceImages) == "error" ?
                                    <span>
                                        <div className="justify-in-center">
                                            <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                            <p className="manrope f-12" style={{color:"#D93025"}}>Upload Failed</p>
                                        </div>
                                        <Button
                                            onClick={() =>  Constants.triggerFailedFilesUpload('ref-file-upload', referenceImages, setReferenceImages)}
                                            disabled={referenceImages.length === 0 || uploading}
                                            loading={uploading}
                                            className="manrope f-12 blue retry-btn"
                                            >
                                            <span>{uploading ? 'Retrying...'  : 'Upload Failed. Click to Retry.'}</span>
                                        </Button>
                                    </span> :
                                    <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                                        <div style={{textAlign:"center"}}>
                                            <div className="ant-upload-text ant-upload-text-w" >
                                                <p className="manrope f-10 grey-77" style={{textAlign:'center'}}>Drop reference files here , or <span style={{color: "#276DD7"}}>Browse</span>.</p>
                                                <p className="manrope f-10 grey-77" style={{textAlign:'center'}}>
                                                Supported formats: PNG, JPEG, MAT, ZIP
                                                </p>
                                            </div>
                                        </div>
                                    </div>}
                                </Upload>
                            </Form.Item>
                            {validateFileUploads(referenceImages) == "error" ?
                            <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginRight: 8}}><img src="/img/error-small.png" style={{marginRight: 8}}/><span>{errorMessage}</span></div>
                        : ''}
                        </Col>

                        {/* </Row> */}
                        {formType== 0?
                        <Col span={24} style={{marginBottom: 100}}>
                            <div className="manrope f-14 black-55" style={{marginBottom: 8}}>Comments/Guidelines</div>
                            <Form.Item   className='form-labels' name="guidelines" label="" colon={false} rules={[{ required: false, message: 'Please enter comments/guidelines.' }]}>
                                <Input.TextArea rows={4} className="manrope f-10 grey-77 library-search"
                                    placeholder = "Enter any comments or guidelines you want us to follow"/>
                            </Form.Item>
                        </Col>: ''}
                        {uploadError == '' ? '' :
                        <Row style={{marginBottom: 10}}>
                            <Col span={24}>
                                <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginRight: 8}}><img src="/img/error-small.png" style={{marginRight: 8}}/><span>{uploadError}</span></div>
                            </Col>
                        </Row>}
                        {dimError == '' ? '' :
                        <Row style={{marginBottom: 10}}>
                            <Col span={24}>
                                <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginRight: 8}}><img src="/img/error-small.png" style={{marginRight: 8}}/><span>{dimError}</span></div>
                            </Col>
                        </Row>}
                        <div className='fixed-row'>
                        <Row className='row-margins'>
                            {
                                // requestStatus == 'payment_required'
                                // ?
                                // <Col span={8} className='text-left'>
                                //     <div className='manrope f-16'>
                                //         <span>Total Price: <span className='manrope f-16 green w-700' >${price}</span></span>
                                //     </div>
                                // </Col>
                                // :
                                // ''
                            }
                            <Col span={6} className='justify-in-start'>
                                <Button onClick={handleCancel} style={{marginBottom:'10px'}} className="modal-okay-gray square font-14">
                                    Cancel
                                </Button>
                            </Col>
                            <Col span={18} className='justify-in-end'>
                                <Button style={{marginLeft:'5px',marginBottom: 10}} disabled={saveButtonLoader || buttonLoader} className="modal-okay-invert square font-14" onClick={saveProgress}>
                                    Save Draft & Exit {saveButtonLoader ? <LoadingOutlined/> : ''}
                                </Button>
                                <Form.Item>
                                    <Button style={{marginLeft:'5px'}} disabled={buttonLoader || uploadButtonLoader || saveButtonLoader} className="modal-okay square font-14" htmlType="submit">
                                        Submit {uploadButtonLoader ? <LoadingOutlined/> : ''}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        </div>

                    </Form>
                </Col>

                {/* Need this code to develop UI later */}
                {/* <Col span={12} className={materialsArray.length != 0 ? '' : 'center-align-v'} style={{padding: materialsArray.length != 0 ? "0 5%" : '0'}}>
                    {materialsArray.length != 0 ?
                    <Row className="justify-in-start" style={{marginTop:"80px"}}>
                        <Col span={24}>
                            <h3 className='manrope f-24 black-14' id="scroll_here">Recent Uploads ({materialsArray.length})</h3>
                            {materialsArray.map((material,index) => (
                                <Row>
                                    <Col span={24} className="justify-space-between mat-list-border">
                                        <div className="justify-space-between manrope f-18 black-55 capitalize">
                                            <Tooltip title={<span className="manrope f-12 white">{material.name}</span>}><span className="clip-text">{material.name}</span></Tooltip>
                                            {material.width == 0 && material.length == 0 ?
                                            <Popover content={input_box} trigger="click" onClick={() => selectIndex(index)}>
                                                <div className="manrope f-14 blue hover-change" style={{marginLeft: 4}}>
                                                    Enter Dimensions
                                                 </div>
                                             </Popover>
                                            :
                                            <div className="manrope f-14 grey-77" style={{marginLeft: 4}}>
                                                {material.width}x{material.length} inches
                                            </div>
                                            }
                                        </div>
                                        <div className="manrope f-14 grey-77">{material.category}</div>
                                        <div className="manrope f-14 grey-77 capitalize">{material.type}</div>
                                        <Dropdown placement="top" overlay={
                                        <Menu style={{ width: "auto",fontFamily:"Avenir" }}>
                                            <Menu.Item key={0}>
                                                <a href="#"  className="select-aspect delete" onClick={() => deleteMaterial(index)}>
                                                    Delete
                                                </a>
                                            </Menu.Item>
                                        </Menu>
                                        }
                                        placement="bottomRight" trigger={["click"]}>
                                        <img src="/img/bin.png" className="delete-material" />
                                    </Dropdown>
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>:
                    <Row>
                        <Col span={24} className="center-align-h">
                            <EmptyBox/>
                            <div className="manrope f-18 black-55 j-center">No materials uploaded yet!</div>
                            <div className="manrope f-16 black-55">Once uploaded, your materials will start populating here</div>
                        </Col>
                    </Row>
                    }
                </Col> */}
                {materialsArray.length == 0 ? (requestID != -1 ?
                    <div className="sticky-banner">
                        <div className="justify-space-between">
                            <Col span={12}>
                                <div className="manrope f-14 grey-77">You have chosen to make your model configurable.  Upload material for your configurable model or you can skip this step if they are materials already present in your Material Library.</div>
                            </Col>

                            <Button className="modal-skip square font-14" onClick={skipUploading}>
                            Skip Material Uploading
                            </Button>

                        </div>
                    </div>
                 : '') :
                 ''}
            </Row>
        <PaymentFailureModal
        paymentFailureMessage={paymentFailureMessage}
        setPaymentFailureModal={setPaymentFailureModal}
        updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
        paymentFailureModal={paymentFailureModal}
        />
        <HelpMessageModal
         visible={helpMessageModal}
         closable={true}
         onCancel={handleCancel}
         heading={"Draft Saved Successfully!"}
         help_image={'/img/inprogress-screen.png'}
         text={`Your drafts are saved in 'Incomplete' section on your home dashboard.`}
         cookie_name={"save_help"}
        />
        <SuccessModal
        onCancel={handleCancelSuccess}
        visible={materialSuccess}
        heading={"Material Created Successfully!"}
        text={`Your request to ${formType == 0 ? `create new material` : `upload scanned material`} is now in progress. You will be informed via email as soon as the material is added to your library and ready to be used.`}
        footer={[
            <div className="justify-in-center">
                <Button className="modal-okay-gray square font-14" onClick={redirectToPage}>
                    {formType == 0 ? "Create New Material" : "Upload Another Material"}
                </Button>
                <Button className="modal-okay square font-14" htmlType="submit" onClick={() => window.location.href="/home"}>
                    Go To Dashboard
                </Button>
            </div>
        ]}
        />
        <SuccessModal
        visible={successConfig}
        onCancel={() => setSuccessConfig(false)}
        heading={"Configurable Model Request Submitted Successfully!"}
        text={"Your request to create a configurable product has been submitted successfully. You will be informed via email when your model is ready to be reviewed."}
        footer={[
            <div className="justify-in-center">

                <Button style={{margin:"auto"}} className="modal-okay square font-14" htmlType="submit" onClick={() => {
                    window.location.href = '/home';
                }}>
                    Okay
                </Button>
            </div>
        ]}
        />
        <SuccessModal
         onCancel={() => setSaveSuccess(false)}
         visible={saveSuccess}
         heading={"Material Saved Successfully!"}
         text={"You can come back any time by reopening this form or from the incomplete section to fill out the remaining information. "}
         footer={[
            <div className="justify-in-center">
                <Button className="modal-okay-gray square font-14" onClick={redirectToPage}>
                    {formType == 0 ? "Create New Material" : "Upload Another Material"}
                </Button>
                <Button className="modal-okay square font-14" htmlType="submit" onClick={() => window.location.href="/home"}>
                Go To Dashboard
                </Button>
            </div>
        ]}
        />
       <PaymentConfirmationModal
       name={materialName}
       visible={paymentConfirmationModal}
       purchaseType={purchaseType}
       amount={<span>${price}</span>}
       onCancel={cancelPaymentConfirmation}
       text={"Please pay the amount below to proceed. It can take 24 to 72+ hours for completion of the model based on complexity"}
       footer={[
       <span style={{display: 'flex', justifyContent:'center'}}>
           <Button disabled={uploadButtonLoader} className="modal-okay-gray font-14 square" onClick={cancelPaymentConfirmation}>
           Cancel
           </Button>
           <Button disabled={uploadButtonLoader || cardSaveFlowTriggered} className="modal-okay font-14 square" onClick={initiateConfirmation}>
           {cardStatus == 1 && requestStatus == "payment_required" ? "Confirm & Process Payment" :
               cardStatus == 0 && requestStatus == "payment_required" ?"Confirm & Enter Payment Details" : "Confirm"} {uploadButtonLoader == true ? <LoadingOutlined type="sync" spin />: ''}
           </Button>
       </span>
       ]}/>

       <CardSaveDrawer
       setCardSaveFailure={setCardSaveFailure}
       saveRequestIntermedietely={saveRequestIntermedietely}
       visible={cardSaveDrawer}
       onClose={() => setCardSaveDrawer(false)}
       />

        <WarningModal
        visible={cardSaveFailure}
        onCancel={() => setCardSaveFailure(false)}
        heading={"Unable to Save Payment Details"}
        text={"Failed to process payment details. Please try another payment method."}
        footer={[
        <div className="justify-in-end">
            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => setCardSaveFailure(false)}>
                Okay
            </Button>
        </div>
        ]}/>

        <InAppPurchaseLimitModal visible={inAppLimitExceededModal} setVisible={setInAppLimitExceededModal} />
        <MspWarningModal
              visible={isMspUserWarningModal}
              handleModalClose={handleWarningModalClose}
        />
        </CustomerMainLayout>

        </div>
      );
}

export default UploadMaterial;