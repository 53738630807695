import React, { useEffect, useState, useRef } from "react";
import "./View360.scss";

const View360 = ({ imageData }) => {
  useEffect(() => {
    setTimeout(() => {
      if (window.CI360) {
        window.CI360.init(); // Initialize the viewer
      }
    }, 10);

    return () => {
      if (window.CI360) {
        window.CI360.destroy();
      }
    };
  }, [imageData]);

  return (
    <div
      className="cloudimage-360"
      id="image-360"
      data-amount={imageData["data-amount"]}
      data-filename={imageData["data-filename"]}
      data-folder={imageData["data-folder"]}
    />
  );
};

export default View360;
