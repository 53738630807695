import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Button,
  Carousel,
  Tooltip,
  Card,
  message,
  Image,
  Switch,
} from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FullscreenOutlined,
  PlayCircleFilled,
} from "@ant-design/icons";
import ENVIRONMENT from "../../../../environments";
import ImageZoomAdmin from "../../CustomerComponents/ImageZoomAdmin";
import "./TwoDImages.scss";
import DottedLoader from "../../DottedLoader";
import View360 from "../../View360/View360";
import ReactPlayer from "react-player";

const image_style = { objectFit: "contain", width: "100%", height: "70vh" };

const convertDate = (time_updated) => {
  // Convert to number if time_updated is a string
  const timestamp =
    typeof time_updated === "string" ? Number(time_updated) : time_updated;

  // Drop the decimal part using parseInt
  const truncatedTimestamp = parseInt(timestamp, 10); // Converts to integer
  const timestampInMs = truncatedTimestamp < 1e12 ? truncatedTimestamp * 1000 : truncatedTimestamp;
  const date = new Date(timestampInMs);

  return isNaN(date.getTime()) ? null : date.toDateString();
};

const ImageListType = ({
  displayedImages,
  selectImage,
  selectedImage,
  loading,
}) => {
  return (
    <>
      {displayedImages.map((image, index) => {
        const imageUrl = image.thumbnail || image.src;
        return (
          <Card
            className={`product-img-grid-card display-flex ${
              // when comparing src, convert the thumbnail url to actual image url
              selectedImage.src === imageUrl.replace(ENVIRONMENT.LOW_RES_QA_IMG_URI, ENVIRONMENT.QA_IMG_URI).replace('_512.', '.')  ? "selected" : ""
            }`}
            onClick={() => selectImage(index)}
            key={index}
          >
            {image.image_type === "mp4" ? (
              <div className="video-thumbnail">
                <img
                  src={image.mp4Preview}
                  alt="Thumbnail"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
                <PlayCircleFilled
                  style={{
                    fontSize: "48px",
                    opacity: "50%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
            ) : (
              <img
                className="product-img-grid-img"
                src={imageUrl}
                alt={`${image.image_type}`}
                style={{ objectFit: "contain" }}
              />
            )}
          </Card>
        );
      })}
      {/* {loading && <DottedLoader />} */}
    </>
  );
};

const ImageCarousel = ({
  currentfile,
  filteredImages,
  renderCollaborationTooltip,
  renderDownload,
  renderReportButton,
  renderGenerateHighResImage,
  renderDelete,
  renderFavouriteButton,
}) => {
  const [displayedImages, setDisplayedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedImg, setSelectedImg] = useState(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [whiteBalanceSwitchSet, setWhiteBalanceSwitchSet] = useState(new Set());
  const [startingImgIndex, setStartingImgIndex] = useState(null)
  const carouselRef = useRef(null);
  const displayCount = 20;
  const [isFullScreen, setIsFullScreen] = useState(false); // State for full screen
  const debounceTimeoutRef = useRef(null); // Ref for storing timeout

  useEffect(() => {
    setDisplayedImages([]);
    if (!filteredImages) return;

    let imagesToDisplay;
    let ind = filteredImages.indexOf(currentfile)
    let lastIndexToDisplay = ind + displayCount
    if(lastIndexToDisplay >= filteredImages.length){
      lastIndexToDisplay = filteredImages.length
    }
    imagesToDisplay = filteredImages.slice(ind, lastIndexToDisplay)
    setSelectedImg(currentfile)
    setCarouselIndex(0)
    setStartingImgIndex(ind)

    setDisplayedImages(imagesToDisplay);
    setHasMore(filteredImages.length > ind);
  }, [filteredImages, currentfile]);

  const loadMoreImages = () => {
    if (loading || !hasMore) return;
    setLoading(true);

    const startIndex = startingImgIndex + displayedImages.length;
    const newImages = filteredImages.slice(
      startIndex,
      startIndex + displayCount
    );

    setDisplayedImages((prev) => [...prev, ...newImages]);

    if (newImages.length < displayCount) {
      setHasMore(false);
    }
    setLoading(false);
  };

  const selectImage = (index) => {
    const image = displayedImages[index];
    if (image) {
      setSelectedImg(image);
      setCarouselIndex(index);
      carouselRef.current.goTo(index, true);
    }
  };

  const next = () => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current); // Clear previous timeout
    }

    // Set a new timeout
    debounceTimeoutRef.current = setTimeout(() => {
      const nextIndex = (carouselIndex + 1) % displayedImages.length;
      setCarouselIndex(nextIndex);
      setSelectedImg(displayedImages[nextIndex]);
      carouselRef.current.next();
    }, 200);
  };

  const prev = () => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current); // Clear previous timeout
    }

    // Set a new timeout
    debounceTimeoutRef.current = setTimeout(() => {
      const prevIndex =
        (carouselIndex - 1 + displayedImages.length) % displayedImages.length;
      setCarouselIndex(prevIndex);
      setSelectedImg(displayedImages[prevIndex]);
      carouselRef.current.prev();
    }, 200);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    if (currentfile && carouselRef.current) {
      setCarouselIndex(0);
      carouselRef.current.goTo(0, true);
    }
  }, [currentfile, carouselRef]);

  useEffect(() => {
    const handleScroll = () => {
      const listElement = document.querySelector(".product-grid-list");
      if (listElement) {
        if (
          listElement.scrollTop + listElement.clientHeight >=
          listElement.scrollHeight - 100
        ) {
          loadMoreImages();
        }
      }
    };

    const listElement = document.querySelector(".product-grid-list");
    if (listElement) {
      listElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore, displayedImages]);

  const get360ImageProps = (image) => {
    const dataAmount = image.spin_360_frames.length;
    const fileName = image.spin_360_frames[0].replace(
      /_Frame\d+/,
      "_Frame{index}"
    );

    let baseUrl = "";
    if (image.company_id) {
      baseUrl = "company/" + image.company_id;
    } else {
      baseUrl = image.captured_by ? image.captured_by : image.customer_username;
    }

    const imageUrl =
      ENVIRONMENT.getBaseURL() +
      "product_assets/threesixtys_frames/" +
      `${baseUrl}/`;
    return {
      ...image,
      "data-amount": dataAmount,
      "data-filename": fileName,
      "data-folder": imageUrl,
    };
  };

  const getImageSrcWithWhiteBalance = (image) => {
    // Extract the base path (everything up to and including the ID)
    const baseUrl = image?.src?.substring(0, image?.src?.lastIndexOf("/"));
    // If whiteBalanceSwitch is true, return the URL with the white balance image filename
    if (
      whiteBalanceSwitchSet.has(image.index) &&
      image.white_balanced_image &&
      baseUrl
    ) {
      return `${baseUrl}/${image.white_balanced_image}`;
    }

    // Otherwise, return the original image source
    return image.src;
  };

  const getFullResolution = (selectedImg) => {
    if (selectedImg?.image_type === "mp4") {
      // Convert height to a number (in case it's passed as a string)
      const heightValue = Number(selectedImg.resolution);

      // Validate if the height is a valid number
      if (isNaN(heightValue)) {
        throw new Error("Invalid height value");
      }

      // Define the aspect ratio (16:9)
      const aspectRatio = 16 / 9;

      // Calculate the width based on the aspect ratio (height * 16/9)
      const width = Math.round(heightValue * aspectRatio);

      // Return the full resolution as a string in "width x height" format
      return `${width} x ${heightValue}`;
    }

    // Handle the case where width and height are not defined (empty or missing)
    if (!selectedImg.width || !selectedImg.height) {
      return "";
    }
    return `${selectedImg.width}x${selectedImg.height}`;
  };

  return (
    selectedImg && (
      <Row>
        <Col
          md={7}
          lg={7}
          xl={5}
          className="display-flex j-s a-s wrap sa-s gg-16 custom-scroll product-grid-list"
          style={{ overflowY: "auto", maxHeight: "70vh" }}
        >
          <ImageListType
            displayedImages={displayedImages}
            selectImage={selectImage}
            selectedImage={selectedImg}
            loading={loading}
          />
          {!hasMore && (
            <div style={{ textAlign: "center" }}>No more images to load</div>
          )}
        </Col>
        <Col
          id="carousel-column"
          md={17}
          lg={17}
          xl={19}
          className={`w-100`}
          style={{ paddingLeft: 20 }}
        >
          <div className="image-info-row">
            <div className="image-info-left">
              <div
                className="manrope f-16"
                style={{ textTransform: "uppercase", marginLeft: 1 }}
              >
                {selectedImg?.camera_display_name ? selectedImg.camera_display_name : selectedImg?.display_name ? selectedImg.display_name : ''}
              </div>
              <div className="image-info-size">
                <Tooltip
                  title={
                    <span className="manrope f-12 white">
                      {getFullResolution(selectedImg)}
                    </span>
                  }
                >
                  <div className="manrope f-12 black-33">
                    {getFullResolution(selectedImg)}
                  </div>
                </Tooltip>
                <div
                  className="manrope f-12 black-33"
                  style={{ textTransform: "uppercase", marginLeft: 1 }}
                >
                  {selectedImg?.filename?.split(".")[1] || ""}
                </div>
              </div>
              {convertDate(selectedImg.time_updated) &&
              <div className="image-info-date">
                <Tooltip
                  title={
                    <span className="manrope f-12 white">
                      {convertDate(selectedImg.time_updated)}
                    </span>
                  }
                >
                  <div
                    className="manrope f-12 grey-8c clamp-text-400"
                    style={{ marginLeft: 15 }}
                  >
                    <span>Created on {convertDate(selectedImg.time_updated)} </span> 
                  </div>
                </Tooltip>
              </div>}
                <div className="manrope f-12 grey-8c clamp-text-400" style={{ marginLeft: '-20px' }}>
                  &nbsp;
                {   selectedImg.image_type === "spin_360" ? 
                      selectedImg.spin_360_details.captured_by ? 
                      <span>
                          by <span style={{ fontWeight: 'bold' }}>{selectedImg.spin_360_details.captured_by}</span>
                      </span>
                    : ''
                    :
                    selectedImg.captured_by ? 
                        <span>
                            by <span style={{ fontWeight: 'bold' }}>{selectedImg.captured_by}</span>
                        </span>
                    : ''
                }
                </div>
            </div>

            {selectedImg.white_balanced_image && (
              <div className="lifestyle-img-auto-white-switch">
                <div className="white-balance-title">Auto White Balance</div>
                <Tooltip
                  title={
                    whiteBalanceSwitchSet.has(selectedImg?.index)
                      ? "Auto White Balanced Image: On"
                      : "Auto White Balanced Image: Off"
                  }
                >
                  <Switch
                    checked={whiteBalanceSwitchSet.has(selectedImg?.index)}
                    onClick={(check) => {
                      setWhiteBalanceSwitchSet((prevSet) => {
                        const newSet = new Set(prevSet);
                        if (check) {
                          newSet.add(selectedImg?.index);
                        } else {
                          newSet.delete(selectedImg?.index);
                        }
                        return newSet;
                      });
                    }}
                    checkedChildren={
                      <img src="/img/white_balance_icon_white.svg"></img>
                    }
                    unCheckedChildren={
                      <img src="/img/white_balance_icon.svg"></img>
                    }
                  />
                </Tooltip>
              </div>
            )}
          </div>

          <div>
            {isFullScreen ? (
              <div
                className="display-image-none space-mask-none full-img"
                style={{
                  background: "#FFFFFF",
                  height: "100vh",
                  width: "100vw",
                  zIndex: "99",
                }}
              >
                <Image
                  className="display-none"
                  src={selectedImg.src}
                  preview={{
                    mask: <></>,
                    visible: isFullScreen,
                    onVisibleChange: (value) => {
                      if (!value) setIsFullScreen(false);
                    },
                  }}
                />
              </div>
            ) : (
              <></>
            )}

            <Button
              className="carousel-btns left-btn"
              onClick={prev}
              style={{ marginLeft: 20 }}
            >
              <ArrowLeftOutlined className="arrow" />
            </Button>
            <Carousel
              autoplay={false}
              ref={carouselRef}
              className="w-100"
              initialSlide={carouselIndex}
            >
              {displayedImages.map((image, index) => (
                <Card
                  key={index}
                  className="lifestyle-carousel-card"
                  style={{ height: 100 }}
                >
                  {carouselIndex === index ? (
                    <>
                      {renderCollaborationTooltip(image)}
                      <div className="overlay-container">
                        <div> {renderFavouriteButton(image)} </div>
                        <div>
                          {image.image_type !== "spin_360" &&
                            image.image_type !== "mp4" && (
                              <FullscreenOutlined
                                className="lifestyle-image-expand-modal"
                                onClick={toggleFullScreen}
                              />
                            )}
                        </div>
                      </div>

                      {image.image_type === "spin_360" ? (
                        <div id="container-360">
                          <View360 imageData={get360ImageProps(image)} />
                        </div>
                      ) : image.image_type === "mp4" ? (
                        <ReactPlayer
                          url={image.src}
                          controls
                          width="100%"
                          height={"calc(100vh - 284px)"}
                          style={{ borderRadius: "4px", objectFit: "contain" }}
                        />
                      ) : (
                        <ImageZoomAdmin
                          custom_class={image_style}
                          compare={true}
                          image={getImageSrcWithWhiteBalance(image)}
                          notShowToolButtons={true}
                        />
                      )}
                    </>
                  ) : ''}

                  <div className="carousel-index-display">
                    <span className="manrope f-14 w-500">
                      {index + 1} of {filteredImages?.length}
                    </span>
                  </div>
                </Card>
              ))}
            </Carousel>
            <Button className="carousel-btns right-btn" onClick={next}>
              <ArrowRightOutlined className="arrow" />
            </Button>

            <div className="button-container">
              <div className="left-buttons">
                {renderReportButton(selectedImg)}
                {renderDelete(selectedImg)}
              </div>
              <div className="right-buttons">
                {selectedImg &&
                  renderDownload(
                    getImageSrcWithWhiteBalance(selectedImg),
                    selectedImg
                  )}
                {selectedImg?.image_type === "lifestyle"
                  ? renderGenerateHighResImage(selectedImg)
                  : ""}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    )
  );
};

export default ImageCarousel;
