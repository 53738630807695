import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { registerUser } from "react-cognito";
import ENVIRONMENT from "../../../../environments";
import {
  LockOutlined,
  MailOutlined,
  UserOutlined,
  IdcardOutlined,
  TeamOutlined,
  LoadingOutlined,
  EyeOutlined,
  EyeInvisibleOutlined
} from "@ant-design/icons";
import { Input, Button, Row, Col, Select, Form } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import Dashboard from "../../Dashboard";
import FileConstants from "../../../../FileConstants";

const { Option } = Select;

const companies_domain_maping = {
  "skylinefurnituremfg.com": "Skyline Furniture MFG",
  "tintash.com": "Tintash",
  "gaiadesign.com.mx": "GAIA Design",
  "estout.com": "Stout",
};

class RegisterForm extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    let email_regex = /email=([^&]+)/.exec(window.location.href); //extracted email
    let email = "";
    if (email_regex && email_regex[1]) {
      email = email_regex[1];
    }
    this.state = {
      error: "",
      username: "",
      password: "",
      password_confirm: "",
      email: email || "",
      isLoading: false,
      user_type: "individual",
      stage: 1,
      user_type_param: "",
    };
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  componentDidMount() {
    let search_url = window.location.href;
    let email_regex = /email=([^&]+)/.exec(search_url); //extracted email
    let email = "";
    if (email_regex && email_regex[1]) {
      email = email_regex[1];
    }
    console.log("Email ", email);
    let email_to_lowercase = email.toLowerCase();
    this.setState({
      email: email_to_lowercase,
    });

    let user_type_param = new URLSearchParams(window.location.search).get(
      "user_type"
    );
    this.setState({
      user_type_param: user_type_param,
    });
    if (user_type_param == "artist") {
      this.setState({
        user_type: "artist",
      });
    } else if (user_type_param == "msprovider") {
      this.setState({
        user_type: "msprovider",
      });
    } else if (user_type_param == "agency_admin") {
      this.setState({
        user_type: "agency_admin",
      });
    }
    if (!user_type_param) {
      this.setState({
        user_type_param: "customer",
      });
    }

    if (email != undefined) {
      for (let company in companies_domain_maping) {
        if (email.includes(company)) {
          this.setCompanyName(
            companies_domain_maping[company],
            user_type_param
          );
        }
      }
    }
  }

  loginUser = () => {
    this.setState({
      stage: 3,
    });
  };

  onSubmitWrapper = (values) => {
    this.setState({ error: "" });
    if (this.state.email) {
      let email_to_lowercase = this.state.email.toLowerCase();
      // if email is entered, validate if it is unique
      this.setState({ isLoading: true });
      axios
        .post(ENVIRONMENT.FETCH_USERNAME, { email: email_to_lowercase })
        .then((res) => {
          if (res.data.username == null) {
            // If no username is returned that means this email is unique
            this.onSubmit(values);
          } else {
            this.setState({ isLoading: false });
            this.setState({
              error: "A User with this email address already exist.",
            });
          }
        });
    } else {
      this.onSubmit(values);
    }
  };

  onSubmit = (values) => {
    console.log(JSON.stringify(values));
    console.log(values);
    let payload = values;
    payload.email = payload.email.toLowerCase();

    payload.user_group = this.state.user_type_param;
    if (
      this.state.user_type_param == "artist" ||
      this.state.user_type_param == "msprovider" ||
      this.state.user_type_param == "agency_admin"
    ) {
      payload.user_type = this.state.user_type;
    }
    if (this.state.user_type_param == "msprovider") {
      payload.is_msprovider = true;
    }

    console.log(payload);
    if (this.state.password === this.state.password_confirm) {
      const { store } = this.context;
      const state = store.getState();
      const userPool = state.cognito.userPool;
      const config = state.cognito.config;
      this.setState({ isLoading: true });
      registerUser(userPool, config, this.state.username, this.state.password, {
        email: this.state.email.toLowerCase(),
      }).then(
        (action) => {
          store.dispatch(action);
          this.setState({ stage: 2 });
          this.setState({ isLoading: false });
          axios.post(ENVIRONMENT.USER_GROUP, payload).then((res) => {
            console.log(res.data);
          });
        },
        (error) => this.setState({ error, isLoading: false })
      );
    } else {
      this.setState({
        error: "Passwords does not match",
      });
    }
  };

  changeUsername = (event) => {
    this.setState({ username: event.target.value });
  };

  changeSkypeUsername = (event) => {
    this.setState({ skype_username  : event.target.value });
  };

  changePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  changePasswordConfirm = (event) => {
    this.setState({ password_confirm: event.target.value });
  };

  changeEmail = (event) => {
    let email = event.target.value;
    this.setState({ email: email });
    for (let company in companies_domain_maping) {
      if (email.includes(company)) {
        this.setCompanyName(companies_domain_maping[company]);
      }
    }
  };

  setCompanyName = (name, type = null) => {
    this.formRef.current.setFieldsValue({
      company_name: name,
    });
    if (type == "artist") {
      this.formRef.current.setFieldsValue({
        user_type: "artist",
      });
    } else if (type == "msprovider") {
      this.formRef.current.setFieldsValue({
        user_type: "msprovider",
      });
    }
  };

  onSelectUserType = (value) => {
    this.setState({
      user_type: value,
    });
  };

  render = () => {
    const formDiv = {
      display: "flex",
      "justify-content": "space-around",
      "margin-top": "25px",
    };

    const formStyle = {
      "background-color": "white",
      "border-radius": "5px",
    };
    return (
      <span>
        {this.state.stage === 1 ? (
          <Row>
            <Col span={12}>
              <img
                className="branding-image"
                alt="Brand Image"
                src={require("../../../../assets/images/login-branding.jpg")}
              />
            </Col>
            <Col span={12}>
              <div className="input-form custom-frm" style={formDiv}>
                <div style={formStyle}>
                  <div style={formDiv}>
                    <div style={formStyle}>
                      <h3 className="text-center manrope w-700 text-login-logo">
                        <img 
                        src={require("../../../../assets/images/all3d-new-logo-red.svg")}
                        height="40px"
                        alt=""/>
                      </h3>
                      <p
                        className="manrope f-14 black-33"
                        style={{ textAlign: "center" }}
                      >
                        By continuing, you accept ALL3D's
                        <br />
                        <a href="https://all3d.ai/terms" target="_blank">
                          Terms of Use
                        </a>{" "}
                        and{" "}
                        <a href="https://all3d.ai/privacy" target="_blank">
                          Privacy Policy
                        </a>
                        .
                      </p>
                      <Form
                        onFinish={this.onSubmitWrapper}
                        onFinishFailed={(e) => console.log(e)}
                        className="login-form"
                        style={{ width: "300px" }}
                        ref={this.formRef}
                        initialValues={{ email: this.state.email || "" }}
                      >
                        <Form.Item
                          name="profile_name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your profile name!",
                            },
                          ]}
                        >
                          <Input
                            prefix={
                              <IdcardOutlined
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            placeholder="Profile Name / Full Name"
                          />
                        </Form.Item>
                        <Form.Item
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: "Please input your username!",
                            },
                            {
                              pattern: "^[^\\s]+(\\s+[^\\s]+)*$",
                              message:
                                " Spaces are not allowed, whitespace found at the end.",
                            },
                            {
                              pattern: "^[a-zA-Z0-9_]*$",
                              message:
                                " Username can only contain alphanumeric or underscore",
                            },
                          ]}
                        >
                          <Input
                            onChange={this.changeUsername}
                            prefix={
                              <UserOutlined
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            placeholder="Username"
                          />
                        </Form.Item>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: "Please enter a valid email.",
                            },
                            {
                              required: true,
                              message: "Please input your email!",
                            },
                            {
                              pattern: "^[^\\s]+(\\s+[^\\s]+)*$",
                              message:
                                " Spaces are not allowed, whitespace found at the end.",
                            },
                          ]}
                        >
                          <Input
                            onChange={this.changeEmail}
                            prefix={
                              <MailOutlined
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            placeholder="Work Email"
                          />
                        </Form.Item>
                        <Form.Item
                          name="company_name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your profile name!",
                            },
                          ]}
                        >
                          <Input
                            prefix={
                              <TeamOutlined
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            placeholder="Company Name"
                          />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Password!",
                            },
                            {
                              pattern:
                                "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                              message:
                                "Password should be at least 8 characters including at least one uppercase, one lower, one special character and a digit",
                            },
                          ]}
                        >
                          <Input.Password
                            className="custom-input-suffix"
                            onChange={this.changePassword}
                            prefix={
                              <LockOutlined
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            type="password"
                            placeholder="Password"
                            iconRender={visible => (
                                <span
                                  style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                  }}
                                >
                                  {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                </span>
                              )}
                          />
                        </Form.Item>
                        <Form.Item
                          name="password_confirm"
                          rules={[
                            {
                              required: true,
                              message: "Confirm your password!",
                            },
                            {
                              pattern:
                                "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                              message:
                                "Password should be at least 8 characters including at least one uppercase, one lower, one special character and a digit",
                            },
                          ]}
                        >
                          <Input.Password
                            className="custom-input-suffix"
                            onChange={this.changePasswordConfirm}
                            prefix={
                              <LockOutlined
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            type="password"
                            placeholder="Re-enter Password"
                            iconRender={visible => (
                                <span
                                  style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                  }}
                                >
                                  {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                </span>
                              )}
                          />
                        </Form.Item>
                        <Form.Item
                          style={{
                            display:
                              this.state.user_type_param == "artist" ||
                              this.state.user_type_param == "agency_admin" ||
                              this.state.user_type_param == "msprovider"
                                ? "none"
                                : "flex",
                          }}
                          name="user_type"
                          rules={[
                            {
                              required:
                                this.state.user_type_param == "artist" ||
                                this.state.user_type_param == "agency_admin" ||
                                this.state.user_type_param == "msprovider"
                                  ? false
                                  : true,
                              message: "Please select user type!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Pick one that best suits you"
                            onSelect={this.onSelectUserType}
                            value={this.state.user_type}
                          >
                            <Option value="brand">
                              Brand (Direct to Customer or a Seller)
                            </Option>
                            <Option value="retailer">Retailer</Option>
                            <Option value="marketplace">Marketplace</Option>
                            <Option value="designer">Designer</Option>
                            <Option value="realestate">SMB/Real-estate</Option>
                            <Option value="manufacturer">Manufacturer</Option>
                            <Option value="individual">Individual</Option>
                          </Select>
                        </Form.Item>
                        
                        <Form.Item
                          style={{
                            display:
                              this.state.user_type_param == "artist" ||
                              this.state.user_type_param == "agency_admin"
                                ? "flex"
                                : "none",
                          }}
                          name="skype_username"
                          rules={[
                            {
                              required:
                                this.state.user_type_param == "artist" ||
                                this.state.user_type_param == "agency_admin"
                                  ? true
                                  : false,
                              message: "Please enter your skype username!",
                            },
                          ]}
                        >
                          <Input
                            onChange={this.changeSkypeUsername}
                            prefix={
                              <UserOutlined />
                            }
                            type="text"
                            placeholder="Skype Username"
                          />
                          
                        </Form.Item>

                        <Form.Item>
                          <Button
                            type="danger"
                            htmlType="submit"
                            className="login-form-button"
                          >
                            Sign Up{" "}
                            {this.state.isLoading ? (
                              <LoadingOutlined spin />
                            ) : (
                              ""
                            )}
                          </Button>
                          Already have an account?{" "}
                          <Link to="/">
                            <a href="" className="text-danger">
                              Sign In
                            </a>
                          </Link>{" "}
                          instead
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-danger text-center"> {this.state.error}</div>
            </Col>
          </Row>
        ) : (
          <span>
            {this.state.stage === 2 ? (
              <Dashboard loginUser={this.loginUser} />
            ) : (
              <Dashboard
                registerUsername={this.state.username}
                registerPassword={this.state.password}
              />
            )}
          </span>
        )}
      </span>
    );
  };
}
RegisterForm.contextTypes = {
  store: PropTypes.object,
};

export default withRouter(RegisterForm);
