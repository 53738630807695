import React, { useState, useEffect, useCallback } from 'react';
import { getProductRender, listProducts} from "../../../redux/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ProductDetailsComponent from './ProductDetailsComponent'
import ProductDetailsComponentResponsive from './ProductDetailsComponentResponsive';


const EmbedProductDetail = (props) => {
    const { match: { params } } = props;
    useEffect(() => {
        if (params.username == undefined) {
            props.getProduct({ product_id: params.id, 'public': true });
        } else {
            props.getProduct({ product_id: params.id, username: params.id })
        }
    }, [params]);

    return(
    <>
        <div className='embed-product-web'>
            <ProductDetailsComponent  storePage={0}  {...props} compareView={false} adminCheck={false} embed={true} publicViewerUser={params.username}/>
        </div>
        <div className='embed-product-mobile'>
            <ProductDetailsComponentResponsive  storePage={0}  {...props} compareView={false} adminCheck={false} embed={true} publicViewerUser={params.username}/>
        </div>
    </>
    );

}


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    },
    listProducts: () => {
        dispatch(listProducts());
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(EmbedProductDetail))