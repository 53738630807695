import React from 'react';
import UserButton from '../UserButton';
import jwtDecode from 'jwt-decode'
import { Row, Col,Dropdown,Button,Menu,message } from 'antd';
import { Link } from "react-router-dom";
import {
    ShoppingCartOutlined,DownOutlined, CheckOutlined, WindowsFilled
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    CognitoState,
} from 'react-cognito';
import AlertModal from '../AlertModal';
import AddCustomerModal from '../CustomerComponents/AddCustomerModal/AddCustomerModal';
import axios from 'axios';
import ENVIRONMENT from '../../../environments';
import FileConstants from '../../../FileConstants';

const right_header_style = {
    "display": "flex",
    "flexDirection": "row",
    "justifyContent": "flex-end",
    "alignItems": "center",
}
let login_type = -1;
function GetMainViewContentFromGroup(user, state) {
    var groups = ['Customer'];
    const token = localStorage.getItem('modified_super_admin_token')
    if (state === CognitoState.LOGGED_IN || state === CognitoState.LOGGING_IN) {
        if(token){
            const decoded = jwtDecode(token);
            if(decoded['cognito:groups'])
              groups = decoded['cognito:groups']
          }
          else{
            if ('cognito:groups' in user['signInUserSession']['idToken']['payload']) {
                groups = user['signInUserSession']['idToken']['payload']['cognito:groups'];
            }
          }
    }
    if (groups.indexOf('Admin') != -1) {
       return 0;
    }

    if (groups.indexOf('Artist') != -1) {
        return 1;
    }

    if (groups.indexOf('Customer') != -1 || groups.indexOf('MSProvider') != -1) {
        return 2;
    }

    if (groups.indexOf("TestArtist") != -1) {
        return 3;
    }
    
    if (groups.indexOf("SuperAdmin") != -1){
      return 4
    }


    return login_type;
}


class CustomHeaderView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            show: true,
            user_type: -1,
            customers: [],
            credit_card_missing: false,
            card_status: '',
        };
    }

    componentDidMount() {

        let login_type =  GetMainViewContentFromGroup(this.props.user, this.props.state);
        this.setState({
            user_type: login_type
        });

        //importing from FileConstants was yeilding incorrect result so that's why added this check here
        if (localStorage.getItem("is_msprovider") == 'true' || login_type == 4 || localStorage.getItem('super_admin_username')) {
          let username = localStorage.getItem('username')
          if(localStorage.getItem('super_admin_username')){
            username = localStorage.getItem('super_admin_username')
          }  
          this.loadManagedServiceUsers(username);
        } 
        let sequence_ids = localStorage.getItem('sequence_ids');
        if (login_type == 2 && !sequence_ids) {
            this.loadSequenceIDs();
        }

        this.checkSuperAdminTokenExpiry()
    }

    clearLocalStorage = () => {
      localStorage.removeItem("all3d_jwt_token");
      localStorage.removeItem("modified_super_admin_token")
      localStorage.removeItem("super_admin_username");
      localStorage.removeItem("super_admin_expiry_token");
      localStorage.removeItem("company_name");
      localStorage.removeItem("shared_entities");
      localStorage.removeItem("username");
      localStorage.removeItem("company_id");
      localStorage.removeItem('sequence_ids')
    }

    checkSuperAdminTokenExpiry = () => {
      let token = localStorage.getItem('super_admin_expiry_token')
      if (!token){
        return; // No token, no need to check
      }
      const decoded = jwtDecode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Token has expired, log out the user
        console.log('logging out user from assumed role')
        this.clearLocalStorage()
        message.info("Your session has timed out. Please select the desired customer again.")
        setTimeout(() => window.location.href = '/', 2000)
      }
    };

    

    loadManagedServiceUsers = (username) => {
        axios.post(ENVIRONMENT.GET_MANAGED_SERVICE_RELATION,{
            designer_username: username
        }).then(res => {
            let customer_data_arr = {}
            if (res.data) {
                customer_data_arr = res.data.reduce((acc, customer) => {
                  if (!acc[customer.company_id]) {
                    acc[customer.company_id] = {
                      company_name: customer.company_name,
                      customers: []
                    };
                  }
                  acc[customer.company_id].customers.push(customer);
                  return acc;
                }, {});
                console.log('customers = ', customer_data_arr)

                this.setState({
                    customers: customer_data_arr
                })
            }

        })
    }

    loadSequenceIDs = () => {
        let payload = {
            'username': localStorage.getItem('username'),
            'action': 'get_sequence_ids'
        };
        let company_id = localStorage.getItem('company_id');
        if (company_id) {
            payload['company_id'] = company_id;
            payload['shared_entities'] = localStorage.getItem('shared_entities');
        }
        axios.post(ENVIRONMENT.ENTITY_ORGANIZER, payload)
        .then(res => {
            if (res.data && res.data.length > 0) {
                localStorage.setItem('sequence_ids', JSON.stringify(res.data))
            }
        });
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
        this.props.toggleMethod();
    };

    openModal = () => {
        window.location.href = "/home/?step_no=0";
    }

    viewStore = () => {
        window.location.href = '/store';
    }

    switchToDefault = () => {
        localStorage.removeItem("managed_customer_username");
        localStorage.removeItem("managed_company_id");
        localStorage.removeItem("managed_customer_email");
        localStorage.removeItem("managed_company_name");
        localStorage.removeItem("managed_shared_entites");

        message.success('User set to default!')
        window.location.reload()

    }

    handleAssign = (customer) => {
        localStorage.setItem("managed_customer_username",customer.customer_username);
        if (customer.company_id) {
            localStorage.setItem("managed_company_id",customer.company_id);
        } else {
            localStorage.removeItem("managed_company_id");
        }
        if (customer.shared_entities) {
          localStorage.setItem("managed_shared_entites",customer.shared_entities);
        } else {
          localStorage.removeItem("managed_shared_entites");
        }
        localStorage.setItem("managed_customer_email",customer.customer_email);

        if (customer.company_name) {
          localStorage.setItem("managed_company_name",customer.company_name);
        } else {
          localStorage.removeItem("managed_company_name")
        }
      
        message.success('Customer set as ' + customer.customer_username);
        window.location.reload();
    }

    assumeSuperAdminRole = () => {
      let super_admin_username = localStorage.getItem('super_admin_username')
      this.clearLocalStorage()
      localStorage.setItem('username', super_admin_username)
      window.location.href = '/'
    }

    assumeCustomerRole = (customer_username) => {
      let payload = {
        'required_user': customer_username,
        'authorization_token': localStorage.getItem('all3d_jwt_token')
      }
      axios.post(ENVIRONMENT.VERIFY_ADMIN_AND_GET_USER_DETAILS, payload)
      .then(res => {
        if(res && res.data.statusCode == 200){
          if(localStorage.getItem('super_admin_username') == undefined)
            localStorage.setItem('super_admin_username', localStorage.getItem('username'))
          localStorage.setItem('modified_super_admin_token', res.data.all3d_jwt_token)
          // localStorage.setItem('all3d_jwt_token',  res.data.all3d_jwt_token)
          localStorage.setItem('username', res.data.user_details.username)
          localStorage.setItem('company_id', res.data.user_details.company_id)
          localStorage.setItem('shared_entities', res.data.user_details.shared_entities)
          localStorage.setItem('customer_company', res.data.user_details.customer_company)

          localStorage.setItem('super_admin_expiry_token', res.data.token)
          localStorage.removeItem('sequence_ids')
          message.success('Customer set as ' + customer_username);
          window.location.href = '/home';
        }
      })
    }

    openCreditCardMissingModal = () => {
        this.setState({
            credit_card_missing: true
        });
    }

    closeCreditCardMissing = () => {
        this.setState({
            credit_card_missing: false
        });
    }

    goToAccountDetails = () => {
        window.location.href = "/account-details"
    }

    render() {
      const store_options = () => {
        return <Menu>
          <Menu.Item key="1" className='manrope f-14 w-400' onClick={() => { window.location.href = '/store?item_type=products' }}>
            <span>Product models</span>
          </Menu.Item>
          <Menu.Item key="2" className='manrope f-14 w-400' onClick={() => { window.location.href = '/store?item_type=spaces' }}>
            <span>Space models</span>
          </Menu.Item>
          <Menu.Item key="3" className='manrope f-14 w-400' onClick={() => { window.location.href = '/store?item_type=scenes' }}>
            <span>Scene templates</span>
          </Menu.Item>
          <Menu.Item key="4" className='manrope f-14 w-400' onClick={() => { window.location.href = '/store?item_type=brands' }}>
            <span>Brands</span>
          </Menu.Item>
        </Menu>
      };

      const support_options = () => {
        return <Menu>
          <Menu.Item key="1" className='manrope f-14 w-400' onClick={() => { window.location.href = 'https://all3d.ai/faqs' }}>
            <span>FAQs</span>
          </Menu.Item>
          <Menu.Item key="2" className='manrope f-14 w-400' onClick={() => { window.location.href = 'https://all3d.ai/contact-us' }}>
            <span>Contact us</span>
          </Menu.Item>
        </Menu>
      };
    
      const menu = (
        <Menu style={{ width: "230px", maxHeight: 400, overflowY: 'auto' }}>
          {(localStorage.getItem("is_msprovider") === 'true' && (localStorage.getItem('managed_customer_username') !== null || localStorage.getItem('managed_company_id') !== null)) && (
            <>
              <Menu.Item key="0" onClick={this.switchToDefault}>
                <div className="manrope f-14 black-55">Default User</div>
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          {Object.keys(this.state.customers).map((companyId, companyIndex) => (
            <Menu.SubMenu key={`company-${companyIndex}`} title={this.state.customers[companyId].company_name}>
              {this.state.customers[companyId].customers.map((customer, customerIndex) => (
                <Menu.Item key={`customer-${companyIndex}-${customerIndex}`} onClick={() => this.handleAssign(customer)}>
                  <div className="manrope f-14 black-14 justify-space-between">
                    <span>{customer.customer_username}</span>
                    <span>{localStorage.getItem('managed_customer_username') === customer.customer_username ? <CheckOutlined /> : ''}</span>
                  </div>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ))}
          {this.state.customers.length === 0 && (
            <Menu.ItemGroup title={<span className="manrope f-14 black-55">No customers assigned yet!</span>} />
          )}
        </Menu>
      );

        const superAdminCustomersMenu = (
          <Menu style={{ width: "165px" }}>
              {localStorage.getItem("super_admin_username") ?
              <>
                  <Menu.Item key={0}  onClick={this.assumeSuperAdminRole}>
                      <div className="manrope f-14 black-55">Default User</div>
                  </Menu.Item>
                  <Menu.Divider />
              </>: ''}
              {Object.keys(this.state.customers).map((companyId, companyIndex) => (
                <Menu.SubMenu key={`company-${companyIndex}`} title={this.state.customers[companyId].company_name}>
                  {this.state.customers[companyId].customers.map((customer, customerIndex) => (
                    <Menu.Item key={`customer-${companyIndex}-${customerIndex}`} onClick={() => this.assumeCustomerRole(customer.customer_username)}>
                      <div className="manrope f-14 black-14 justify-space-between">
                        <span>{customer.customer_username}</span>
                        <span>{localStorage.getItem('username') === customer.customer_username ? <CheckOutlined /> : ''}</span>
                      </div>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ))}
              {this.state.customers.length === 0 && (
                <Menu.ItemGroup title={<span className="manrope f-14 black-55">No customers assigned yet!</span>} />
              )}
          </Menu>
        )
        
        return (
          <Row type="flex" justify="space-between">
            <Col span={8} style={{ display: "flex", alignItems: "center" }}>
              <a href="/home" style={{ lineHeight: "100%" }}>
                <img
                  className="home-btn"
                  src={require("../../../assets/images/new-all3d-white.svg")}
                  height="30px"
                  width="98px"
                  alt=""
                ></img>
              </a>
            </Col>

            <Col span={16} className="text-right">
              <div style={right_header_style}>
                {this.state.payment_type == "prepaid" &&
                this.state.card_status == 0 ? (
                  <div
                    className="store-button yellow text-yellow"
                    onClick={this.openCreditCardMissingModal}
                  >
                    *Credit Card Information Missing
                  </div>
                ) : (
                  ""
                )}
                {localStorage.getItem("is_msprovider") == "true" ? (
                  <AddCustomerModal />
                ) : (
                  ""
                )}
                {localStorage.getItem("is_msprovider") == "true" ? (
                  <Dropdown overlay={menu}>
                    <Button className="store-button store-button-text text-white" ghost>
                      {FileConstants.MANAGED_CUSTOMER_USERNAME != undefined ? (FileConstants.MANAGED_CUSTOMER_USERNAME) + ((localStorage.getItem('managed_company_name') != undefined) ? (' (' + localStorage.getItem('managed_company_name') + ')'):'') : 'Select Customer'}
                      &nbsp;<DownOutlined />
                    </Button>
                  </Dropdown>
                ) : (
                  ""
                )}
                {
                  (this.state.user_type == 4 && Object.keys(this.state.customers).length > 0) || localStorage.getItem('super_admin_username') ? (
                    <Dropdown overlay={superAdminCustomersMenu}>
                    <Button className="store-button store-button-text text-white" ghost>
                      {localStorage.getItem('super_admin_username') ? (localStorage.getItem('username')) + ((localStorage.getItem('user_company_name') != undefined) ? (' (' + localStorage.getItem('user_company_name') + ')'):'') : 'Select Customer'}
                      &nbsp;<DownOutlined />
                    </Button>
                  </Dropdown>

                  ) : ""
                }
                {/* {this.state.user_type == 2 ? 
                        <div className="store-button-text text-white store-button blue" onClick={this.openModal}>
                            Take Tour
                        </div>: ''} */}
                {this.state.user_type == 3 ? (
                  ""
                ) : (
                  <Dropdown overlay={store_options} trigger={["click"]}>
                    <Button className="store-button store-button-text text-white" ghost>
                      ALL3D Store&nbsp;<DownOutlined />
                    </Button>
                  </Dropdown>
                )}

                  <Button className="store-button store-button-text text-white" ghost onClick={() => {
                    window.location.href = "https://all3d.ai/how-all3d-works";
                  }}>
                    Tutorials
                  </Button>

                  <Dropdown overlay={support_options} trigger={["click"]}>
                    <Button className="store-button store-button-text text-white" ghost>
                      Support&nbsp;<DownOutlined />
                    </Button>
                  </Dropdown>

                {/* <Notification></Notification> */}
                <UserButton user_type={this.state.user_type} />
              </div>
            </Col>
            <AlertModal
              onCancel={this.closeCreditCardMissing}
              visible={this.state.credit_card_missing}
              heading={"Credit Card Information Missing"}
              text={
                "Your credit card information seems to be missing. In order to seemlessly proceed with your purchases on ALL3D, it is recommended that you provide your card information."
              }
              footer={[
                <div className="justify-in-center">
                  <Button
                    className="modal-okay-gray square font-14"
                    onClick={this.closeCreditCardMissing}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={this.goToAccountDetails}
                    className="modal-okay square font-14"
                    htmlType="submit"
                  >
                    Provide Information
                  </Button>
                </div>,
              ]}
            />
          </Row>
        );
    }
}

CustomHeaderView.propTypes = {
    user: PropTypes.object,
    component: PropTypes.func,
    attributes: PropTypes.object,
    state: PropTypes.string,
};
  
const mapStateToProps = (state) => ({
    state: state.cognito.state,
    user: state.cognito.user,
    attributes: state.cognito.attributes,
});
  
const CustomHeader = connect(mapStateToProps, null)(CustomHeaderView);
  
export default CustomHeader;
