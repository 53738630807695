import React from "react";
import { Card } from "antd";
import "./SpaceComponents.scss";

const SpaceAssetListByType = ({
  selectedImage,
  selectImage,
  category,
  categoryTitle,
  list,
  setImgAccessedFromCarousel,
  setSelectedRender
}) => {
  return (
    <>
      <div className="manrope f-14 black-26 w-100">{categoryTitle}</div>
      {list?.map((image, index) => (
        <Card
          id={image.id}
          className={`display-flex summary-img-card ${
            selectedImage == image.url ? `selected` : ``
          }`}
          key={index}
          onClick={() => {
            selectImage(image.url);
            setImgAccessedFromCarousel(true);
            setSelectedRender(image);
          }}
        >
          <img className="space-img-grid-img" src={image.url} />
        </Card>
      ))}
    </>
  );
};

export default SpaceAssetListByType;
