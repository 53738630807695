import React, { useState } from 'react';
import ENVIRONMENT from '../../../../environments';
import './styles.css'
import {
    LoadingOutlined,
    RightOutlined,
    ShareAltOutlined,
    PlusOutlined,
    DownOutlined,
    ArrowRightOutlined,
    ArrowLeftOutlined,
    LinkOutlined,
    LeftOutlined,
    CheckCircleOutlined,
    InfoCircleOutlined,
    CloseCircleOutlined,
    InfoCircleFilled
} from '@ant-design/icons';
import {
    Row,
    Col,
    Radio,
    Collapse,
    Card,
    Image,
    Button,
    Modal,
    Form,
    Select,
    Dropdown,
    Menu,
    InputNumber,
    Input,
    TreeSelect,
    Typography, Tooltip,
    Carousel,
    Divider,
    Checkbox
} from 'antd';
import CustomerMainLayout from '../CustomerMainLayout';
import { Link } from "react-router-dom";
import ReviewProduct from './ReviewProduct';
import * as Styles from '../../../../Styles';
import ProductStaticGuidelinesCard from '../../ArtistComponents/ProductStaticGuidelines/ProductStaticGuidelinesCard'
import ProductDetailsComponent from '../../ProductDetailsComponent';
import SiloPhotographyPresetsModal from '../../SiloPhotography/SiloPhotographyPresetsModal';
import { ReactComponent as SmallEmptyBox } from '../../../../icons/small-empty-box.svg'
import ConfigurationViewer from '../../ConfigurationViewer/ConfigurationViewer';
import StyleCategory from '../../FormUtilities/StyleCategorySelect';
import ColorSelect from '../../FormUtilities/ColorSelect';
import {  SCANS_BASE_URL } from '../../../../environments/env';
import DottedLoader from '../../DottedLoader';
import SuccessModal from '../../SuccessModal/SuccessModal';
import FileConstants from '../../../../FileConstants';
import CardSaveDrawer from '../../CardSaveDrawer/CardSaveDrawer';
import WarningModal from '../../WarningModal/WarningModal';
import ModelVariationRequestModal from '../ModelVariationRequestModal/ModelVariationRequestModal';
import PaymentFailureModal from '../../PaymentFailureModal/PaymentFailureModal'
import InAppPurchaseLimitModal from '../InAppPurchaseLimitModal';
import AddItemToStore from '../../AddItemToStore';
import * as Utilites from '../../Utilities';
import { useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import axios from 'axios';
import SiloComplaints from '../SiloComplaints';
import EmailSelect from '../../FormUtilities/EmailSelect';
import ProductCustomerPage from '../ProductCustomerPage/ProductCustomerPage';
import * as Utilities from '../../Utilities';
import ProductQAPage from '../ProdcutQAPage/ProductQAPage';

const { Paragraph } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const radioStyle = Styles.radioStyle;
const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem('company_id');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const IS_MSPROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;

const categoryStyle = Styles.categoryStyle;
const accentColor = "#D93025";

const generateOption = [
    {
        selector: '[data_tut="reactour__generateImageOption"]',
        content: () => (
            <div>
                <p className='manrope f-14 black-14'>
                    Click on the Generate Button to generate imagery of your product.
                </p>
            </div>
        ),
        style: { minWidth: 400 }
    }
];


const ProductDetailsView = (props) => {

    const [showDetailedRejection, setShowDetailedRejection] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [showImage, setShowImage] = useState(false);
    const [validationState, setValidationState] = useState(-2);
    const [groupIdList, setGroupIdList] = useState([]);
    const [groupIdValue, setGroupIdValue] = useState('');
    const [groupIdSearchValue, setGroupIdSearchValue] = useState('');
    const [complaints, setComplaints] = useState([]);
    const [complaintsView, setComplaintsView] = useState(false)
    const [emailError, setEmailError] = useState('');
    const [sharingEmails, setSharingEmails] = useState([]);
    const [isApproveAccess, setIsApproveAccess] = useState(false);
    
    const [form] = Form.useForm();
    const [formRef] = Form.useForm();

    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);

    const onVisibleChange = (value) => {
        props.setOpenGenerateOption(value);
    }

    useEffect(() => {
        setGroupIdList(props.customerGroupIds)
    }, []);

    useEffect(() => {
        if (props.projectID) {
            getSharedUsers();
        } else {
            setIsApproveAccess(false);
        }

    }, [props.projectID]);


    const getSharedUsers = () => {
        if (props.projectID == null) return;

        props.projectID.map(project => {
            let payload = {
            'action': 'get',
            'project_id': project
            };
            let company_id = COMPANY_ID;
            if (IS_MSPROVIDER && MANAGED_COMPANY_ID) {
            company_id = MANAGED_COMPANY_ID;
            }
            if (company_id) {
            payload['company_id'] = company_id;
            }
            axios.post(ENVIRONMENT.PROJECT_ACCESS_CONTROLLER, payload)
            .then(res => {
                if (res.data && res.data.length > 0) {
                let user_access = res.data.filter(item => item.username == localStorage.getItem('username')).map(item => item.access_level);
        
                if (user_access && user_access == 'quality_control' || user_access == 'owner' || user_access == 'co_owner' || user_access == 'editor') {
                    setIsApproveAccess(true);
                } 
                }
            });
        })
    };
    
    useEffect(() => {
        if (props.uploadedModel && props.assignedArtist == '' && props.variantModel == false) {
            props.loadUserRequestStatus('customer_model_fix')
        }

        if (props.productReducer.product.model_status == 4 && props.validationReport && props.uploadedModel == true && props.variantModel == false) {
            if (props.validationReport && props.validationReport.High && props.validationReport.High.hidden_objects_count > 0) {
                setValidationState(0)
            }
            else if (props.validationReport && props.validationReport.High && props.validationReport.High.hidden_objects_count == 0) {
                setValidationState(1)
            }
        }

        if (props.uploadedModel == true
            && props.variantModel == false
            && props.productReducer.product.model_status == -6
            && props.validationReport != undefined
            && props.validationReport.High != undefined
            && props.validationReport.High.product_valid == false) {
            setValidationState(2)
        }
    }, [props.productReducer.product.model_status])

    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        let viewComplaintPage = query.get('complaints')
        axios.post(ENVIRONMENT.GET_COMPLAINT_BATCH, {
            entity_id: props.params.id,
            complaint_against: "Product"
        })
            .then(res => {
                console.log('COMPLAINTS AGAINST SILO = ', res)
                let complaints = []
                for (let complaint of res.data) {
                    complaints.push({
                        'id': complaint['id'],
                        'status': complaint['status'],
                        'issue': complaint['issue'],
                        'submitted_on': complaint['submitted_on'],
                        'admin_message': complaint['admin_message'],
                        'attachment': complaint['attachment']
                    })
                }
                setComplaints(complaints)
                if (viewComplaintPage) {
                    setComplaintsView(true)
                }
            }).catch(err => {
                console.log(err, ' Error in getting complaints against this product');
            })
    }, [props.params.id])
    const handleImage = (event) => {
        event.preventDefault();
        setImageUrl(event.currentTarget.getAttribute('href'));
        setShowImage(true);
    }
    const showRejectionModal = () => {
        setShowDetailedRejection(true);
    };
    const handleCancel = () => {
        setShowDetailedRejection(false);
    };

    const onClickAssignToArtist = () => {
        props.setShowArtistModal(true)
    }

    const onChangeAllowDownloading = (e) => {
        let default_sharing_value = 'embed'
        if (e.target.checked) {
            props.setSelectedPermission(e.target.value)
        }
        else {
            props.setSelectedPermission(default_sharing_value)
        }
    }

    const addAsGroup = () => {
        if (!groupIdList.some(e => e.key == groupIdSearchValue)) {
            setGroupIdList(groupIdList.concat([{
                key: groupIdSearchValue,
                value: groupIdSearchValue,
                title: groupIdSearchValue,
                children: [],
            }]));
            setGroupIdValue(groupIdSearchValue);
        }
    }
    const viewComplaintsListener = () => {
        setComplaintsView(true);
        const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?complaints=true';
        window.history.pushState({ path: newUrl }, '', newUrl);
        console.log('complaints param:::', window.location.search)
    }

    const validateEmails = (_, value) => {
        // Check if the value is an array
        if (Array.isArray(value)) {
            // Regular expression for email validation
            const emailRegex = /^\S+@\S+\.\S+$/;
            const invalidEmails = value.filter(email => !emailRegex.test(email));

            // Clear previous error message
            setEmailError('');

            if (value.length === 0) {
                return Promise.reject();
            }

            if (invalidEmails.length > 0) {
                setEmailError('Please enter valid email address');
                return Promise.reject();
            }
        }

        return Promise.resolve();
    };

    const handleClearEmails = () => {
        form.setFieldsValue({ emails: [] });
        setEmailError('');
        setSharingEmails([])
    };

    const redirectPage = () => {
        if (props.storePage == 0) {
            window.location.href = '/list-products?entity_id=' + props.params.id;
        } else {
            window.location.href = '/store'
        }
    }

    const handleShareEmailChange = (e) => {
        setSharingEmails(e)
    }

    const menu = (
        !props.isConfigurable ?
            <Menu style={{ width: "145px", fontFamily: "Avenir" }}>
                <Menu.Item key={0} onClick={ () => props.setOpenSiloPresetModal(true)}>
                    <span style={{ cursor: "pointer" }}>Silo Photography</span>
                </Menu.Item>
                <Menu.Item key={1} onClick={props.openVideoModal} disabled={props.mp4Status == "pending"}>
                    <Tooltip title={props.mp4Status == "pending" ? "We are processing your MP4 Video request at the moment. You’ll be able to request a new MP4 Video once that request is complete. " : ""}>
                        <span style={{ cursor: "pointer" }}>MP4 Video</span>
                    </Tooltip>
                </Menu.Item>
                <Menu.Item key={2} onClick={props.openDimensionalModal}>
                    <span style={{ cursor: "pointer" }}>Dimensional Image</span>
                </Menu.Item>
                <Menu.Item key={3} disabled={props.status360 == "pending"} onClick={props.openThreeSixtyModal}>
                    <Tooltip title={props.status360 == "pending" ? "We are processing your 360 Spin request at the moment. You’ll be able to request a new 360 Spin once that request is complete. " : ""}>
                        <span style={{ cursor: "pointer" }} >360 Spin</span>
                    </Tooltip>
                </Menu.Item>
                <Menu.Item key={4} onClick={props.openVariationModal}>
                    <Tooltip title={"You can make adjustements like, change the colour, size, textures,  etcetera and request a variation of this model."}>
                        <span style={{ cursor: "pointer" }}>Product Variation</span>
                    </Tooltip>
                </Menu.Item>


            </Menu>
            :
            <Menu style={{ width: "145px", fontFamily: "Avenir" }}>
                <Menu.Item key={4}>
                    <Link to={"/add-configurations/" + props.params.id}>Configurations</Link>
                </Menu.Item>
            </Menu>

    );

    let high_poly_model_size = 0;
    let high_poly_texture_size = 0;
    let low_poly_model_size = 0;
    let low_poly_texture_size = 0;
    let total_file_size = 0;
    let glb_size = 0;
    if (props.productReducer.product.model_info != undefined) {
        high_poly_model_size = props.productReducer.product.model_info.size_high_poly_model || 0;
        high_poly_texture_size = props.productReducer.product.model_info.size_high_poly_textures || 0;
        low_poly_model_size = props.productReducer.product.model_info.size_low_poly_model || 0;
        low_poly_texture_size = props.productReducer.product.model_info.size_low_poly_textures || 0;
        glb_size = props.productReducer.product.model_info.glb_size || 0;
        if (glb_size == 0) {
            props.getGlbSize();
            glb_size = props.glbSize / (1024 * 1024);
        }
        total_file_size = high_poly_model_size + high_poly_texture_size + low_poly_model_size + low_poly_texture_size + glb_size;
    }

    if (props.productReducer.product.model_info == undefined || total_file_size == 0 || total_file_size == glb_size) {
        props.getGlbSize();
        props.getArchiveSize();
    }

    let model_height = "N/A", model_depth = "N/A", model_width = "N/A";
    if (props.productReducer.product.model_info) {
        // If model_info has low poly info
        if (props.productReducer.product.model_info.low) {
            model_height = props.productReducer.product.model_info.low.height || "N/A";
            model_depth = props.productReducer.product.model_info.low.depth || "N/A";
            model_width = props.productReducer.product.model_info.low.width || "N/A";
        }
        else if (props.productReducer.product.model_info.high) {
            // if model_info has high poly info
            model_height = props.productReducer.product.model_info.high.height || "N/A";
            model_depth = props.productReducer.product.model_info.high.depth || "N/A";
            model_width = props.productReducer.product.model_info.high.width || "N/A";
        }
        else {
            // for backward compatibility, where model_info only contains model_dimenions and vertex count
            model_height = props.productReducer.product.model_info.height || "N/A";
            model_depth = props.productReducer.product.model_info.depth || "N/A";
            model_width = props.productReducer.product.model_info.width || "N/A";
        }
    } else if (model_depth == 'N/A' && model_height == 'N/A' && model_height == 'N/A' && props.productReducer.product.width && props.productReducer.product.height && props.productReducer.product.length) {
        model_depth = parseInt(props.productReducer.product.length);
        model_height = parseInt(props.productReducer.product.height);
        model_width = parseInt(props.productReducer.product.width);
    }

    let high_poly_vertex = null, low_poly_vertex = null;
    if (props.productReducer.product.model_info) {
        if (props.productReducer.product.model_info.low) {
            low_poly_vertex = props.productReducer.product.model_info.low.vertex_count;
        }
        else if (props.productReducer.product.need_to_model == 'ar') {
            // for backward compatibility
            low_poly_vertex = props.productReducer.product.model_info.vertex_count;
        }

        if (props.productReducer.product.model_info.high) {
            high_poly_vertex = props.productReducer.product.model_info.high.vertex_count;
        }
        else if (props.productReducer.product.need_to_model != 'ar') {
            // for backward compatibility
            high_poly_vertex = props.productReducer.product.model_info.vertex_count;
        }
    }
    
    let high_poly_triangles = null, low_poly_triangles = null;
    if(props.productReducer.product.metadata && !Array.isArray(props.productReducer.product.metadata)){
        let metadata = (JSON.parse(props.productReducer.product.metadata)).metadata
        high_poly_triangles = metadata?.high?.triangle_count;
        low_poly_triangles = metadata?.low?.triangle_count;
    }

    const information_not_available = (
        <Col style={{ display: 'flex', padding: '30px 0px 10px 10px', alignItems: 'center' }}>
            <SmallEmptyBox />
            <div>
                <span style={{ fontSize: 16, color: '#555555', paddingLeft: 20 }}>No Information Found</span>
            </div>
        </Col>
    )


    let rejections_data = '';
    let customer_rejection_comments = [];
    if (props.rejectionDetails) {
        props.rejectionDetails.map((ele) => {
            // customer_rejection attribute is true for saved rejection commnets
            if (!ele.customer_rejection && ele.qa_role != "admin") {
                customer_rejection_comments.push(ele);
            }
        })
    }

    if (customer_rejection_comments.length > 0) {
        let ele = customer_rejection_comments[0];
        rejections_data = (
            <div style={{ marginTop: 20 }}>
                <div className='manrope f-12 black-55 align-text' style={{ marginBottom: 8 }}>Date <span style={{ color: "#333333" }}>{Utilites.convertUtcToLocalTimezone(ele.qa_time) || '-'}</span></div>
                <div style={{ display: "flex" }}>
                    <Col span={(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ? 16 : 24}>
                        <span style={{ paddingRight: 5, overflow: 'hidden' }}>
                            <span className="manrope f-12 black-55" style={{ textAlign: 'left', display: 'block', marginBottom: 4 }}>
                                Comment
                            </span>
                            <span style={{ display: 'flex' }}>
                                <span className="manrope f-12 black-33" style={{ marginBottom: 0, width: '90%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'left' }}>
                                    {ele.rejection_details}
                                </span>
                            </span>
                        </span>
                    </Col>


                    {(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?

                        <Col span={8}>
                            <div className="category-artist" style={{ textAlign: 'left', marginBottom: 7 }}>
                                Uploads
                            </div>
                            {ele.reference_files && ele.reference_files.length > 0 ?
                                <a className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.state.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.reference_files[0]['uid'] + '/' + ele.reference_files[0]['name'])}>
                                    {(ele.reference_files[0].name.includes('.jpg') || ele.reference_files[0].name.includes(".png") || ele.reference_files[0].name.includes(".PNG") || ele.reference_files[0].name.includes(".svg")) ?
                                        <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(props.state.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.reference_files[0]['uid'] + '/' + ele.reference_files[0]['name'])} /> :
                                        (ele.reference_files[0].name.includes(".tiff")) ? <img className="upload-image-settings" src={'/img/tiff_icon.png'} /> :
                                            <img className="upload-image-settings" src={'/img/ele.reference_files[0]-icon.png'} />}
                                    <span className="category-artist" style={{ fontSize: 14, textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', width: '90%', whiteSpace: 'nowrap' }}>{ele.reference_files[0].name}</span>
                                </a>
                                : ''}
                        </Col>
                        : ''}
                </div>

            </div>
        );
    }


    let detailed_rejection_data = '';
    if (customer_rejection_comments.length > 0) {
        detailed_rejection_data = customer_rejection_comments.map((ele, index) => (
            <div style={{ marginTop: 2, maxWidth: "941px" }}>
                <span style={{ marginTop: 15, display: "block" }} className='note-bg-artist manrope f-12'>Date: <span style={{ color: "#276DD7", paddingLeft: 3, marginRight: 25 }}>{Utilites.convertUtcToLocalTimezone(ele.qa_time) || '-'}</span>  Activity By: <span style={{ marginLeft: 5, color: "#276dd7" }}>{ele.qa_person}</span></span>
                {(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?
                    <div style={{ display: "block", margin: 2, padding: 2, width: '100%' }} >
                        <div>
                            <span className="manrope f-14 black-55" style={{ textAlign: 'left', display: 'block', marginBottom: 4, width: "50%", float: 'left' }}>
                                Comments:
                            </span>
                            <span className="manrope f-14 black-55" style={{ marginBottom: 4, width: "50%", float: 'left' }}>
                                Uploads:
                            </span>
                        </div>
                        <div style={{ marginBottom: 10, width: '100%' }}>
                            <span className="manrope f-12 black-33" style={{ textAlign: "left", width: "51%", float: 'left' }}>
                                {ele.rejection_details}
                            </span>
                            <div style={{ float: 'left', width: '47%' }}>
                                <Carousel style={{ width: "100%" }} arrows={true} nextArrow={<RightOutlined />} prevArrow={<LeftOutlined />}>

                                    {ele.reference_files && ele.reference_files.map((file, index) => (

                                        <a onClick={handleImage} style={{ color: "#276DD7", lineHeight: '120%' }} className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.state.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}>
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".PNG") || file.name.includes(".svg")) ?
                                                    <img className="upload-image-settings-reject" src={ENVIRONMENT.getBaseURL(props.state.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']} /> :
                                                    (file.name.includes(".tiff")) ? <img className="upload-image-settings-reject" src={'/img/tiff_icon.png'} /> :
                                                        <img className="upload-image-settings-reject" src={'/img/file-icon.png'} />}
                                            </div>
                                            <p className="manrope f-12" style={{ textAlign: 'center', color: '#555555' }}>{file.name}</p>
                                        </a>

                                    ))}

                                </Carousel>
                            </div>

                        </div>
                    </div>
                    :
                    <div className='justify-in-start' style={{ marginTop: 4 }}>
                        <span className="manrope f-14 black-55" style={{ textAlign: 'left', width: "10%", float: 'left' }}>
                            Comments:
                        </span>
                        <span className="manrope f-12 black-33" style={{ textAlign: "left", float: 'left' }}>
                            {ele.rejection_details}
                        </span>
                    </div>
                }
            </div>
        ));
    }

    let product_photos = []
    if (props.state && props.state.product_photos != undefined) {
        Object.keys(props.state.product_photos).forEach((key) => {
            let photos_array = props.state.product_photos[key];
            if (!Array.isArray(photos_array)) {
                if (key in photos_array) {
                    photos_array = props.state.product_photos[key][key];
                }
                else {
                    photos_array = [];
                }
            }
            let product_photos_subset = (photos_array || []).map((ele, index) => {
                let file_url =  ENVIRONMENT.getBaseURL(props.state.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name);
                return <Col>
                    <Card className="reference-img-card">
                        {ele.name.toLowerCase().includes('jpg') || ele.name.toLowerCase().includes('jpeg') || ele.name.toLowerCase().includes('png') || ele.name.toLowerCase().includes('svg') ?
                            <Image
                                className="reference-img-photo" src={file_url}
                            />
                            :
                            <a href={file_url} target='blank'>
                                <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                                    <img
                                        className="reference-img-photo" src={'/img/file-icon.png'}
                                    />
                                </Tooltip>
                            </a>}
                    </Card>
                </Col>
            });
            if (key == 'product_photos_all') {
                product_photos.unshift(product_photos_subset);
            }
            else {
                product_photos.push(product_photos_subset);
            }
        });
        if (props.state && props.state.product_photos && Array.isArray(props.state.product_photos) && product_photos && product_photos[0] && product_photos[0].length == 0) {
            product_photos = []
            props.state.product_photos.map((ele) => {
                let file_url = ENVIRONMENT.getBaseURL(props.state.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name);
                product_photos.push(<Col>
                    <Card className="reference-img-card">
                        {ele.name.toLowerCase().includes('jpg') || ele.name.toLowerCase().includes('jpeg') || ele.name.toLowerCase().includes('png') || ele.name.toLowerCase().includes('svg') ?
                            <Image
                                className="reference-img-photo" src={file_url}
                            />
                            :
                            <a href={file_url} target='blank'>
                                <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                                    <img
                                        className="reference-img-photo" src={'/img/file-icon.png'}
                                    />
                                </Tooltip>
                            </a>}
                    </Card>
                </Col>)
            });
        }
    }
    let category_attachment = []
    if (props.state.category_attachment && props.state.category_attachment.length > 0) {
        props.state.category_attachment.map((image, index) => {
            let file_url = ENVIRONMENT.getBaseURL(props.state.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(image.uid + '/' + image.name);
            category_attachment.push(<Col>
                <Card className="reference-img-card">
                    {image.name.toLowerCase().includes('jpg') || image.name.toLowerCase().includes('jpeg') || image.name.toLowerCase().includes('png') || image.name.toLowerCase().includes('svg') ?
                        <Image
                            className="reference-img-photo" src={file_url}
                        />
                        :
                        <a href={file_url} target='blank'>
                            <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                                <img
                                    className="reference-img-photo" src={'/img/file-icon.png'}
                                />
                            </Tooltip>
                        </a>}
                </Card>
            </Col>)
        })
    }

    let bread_crumbs = <div>
        {(props.productReducer.product.model_status == 4) ?
            <h2 className="manrope f-14 w-600" style={{ marginBottom: "10px" }}>
                <span><Link to={'/home'} className="store-bd">Home</Link> / <Link to={'/home'} className="store-bd">Review</Link> / <span className="active-route-link-color">{props.productReducer.product.product_name}</span></span>
            </h2> : ''}
        {(props.productReducer.product.model_status == -6) ?
            <h2 className="manrope f-14 w-600" style={{ marginBottom: "10px" }}>
                <span><Link to={'/home'} className="store-bd">Home</Link> / <Link to={'/home'} className="store-bd">Incomplete</Link> / <span className="active-route-link-color">{props.productReducer.product.product_name}</span></span>
            </h2> : ''}
        {(props.productReducer.product.model_status != 4 && props.productReducer.product.model_status != 5 && props.productReducer.product.model_status != -6) ? <h2 className="manrope f-14 w-600" style={{ marginBottom: "20px" }}><Link to={'/home'} className="store-bd">Home</Link> / <Link to={'/home'} className="store-bd">In Progress</Link> / <span className="active-route-link-color">{props.productReducer.product.product_name}</span></h2> : ''}

        {(props.productReducer.product.model_status == 5) ?
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                <h2 className="manrope f-14 w-600" style={{ marginBottom: 10, display: 'flex', cursor: 'pointer' }}><span onMouseDown={redirectPage} className="store-bd">{props.storePage == 0 ? "Product Library" : "Store"}</span>
                    {complaintsView ?
                        <span style={{ color: "#9A9A9A", display: 'flex' }}>
                            <Link className='manrope clamp-text w-60 active-route-link-color' to={`/products/${props.params.id}`} onClick={() => setComplaintsView(false)}>
                                {' / ' + props.productReducer.product.product_name}</Link>{' / Complaints'}
                        </span> :
                        <span className="active-route-link-color">{' / ' + props.productReducer.product.product_name}</span>
                    }

                </h2>
            </div> : ''}
    </div>

    return (
        <>
            {props.productReducer.isLoading ? <DottedLoader /> :
            <>
            {
            (FileConstants.isMSProvider) &&
            props.productReducer.product.model_status == 4 && props.state.requested_for != ''
            &&
            <Row style={{marginTop:20}}>
                <Col span={24}>
                <div className="custom-alert info">
                You requested this Product Model for customer {props.state.requested_for}
                </div>
                </Col>
            </Row>
            }
            {props.productReducer.isLoading ? <DottedLoader style={{zIndex:"100000"}} /> : !complaintsView ?
                <React.Fragment>
                    <Row type="flex" justify="space-around" className="product-side-margins">
                        <Col span={24}>
                            {bread_crumbs}
                            {props.firstTimeUser &&
                                <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 12 }}>
                                    <div style={{ background: "rgba(39, 109, 215, 0.06)", borderRadius: "4px", padding: "10px 16px" }}>
                                        <span className="scene-note flag">
                                            <InfoCircleFilled />&nbsp;<b>Please Note:</b> <span style={{ color: "#333333", marginTop: 8 }}> The images provided below are rendered for approving or rejecting the model based on its texture and geometry. </span>
                                        </span>
                                    </div>
                                </div>}
                            <div style={{ position: 'relative' }}>
                                {(props.productReducer.product.model_status == 4) && !props.isConfigurable ?
                                    <Button className="modal-okay square font-14 comparison-btn customer-compare" onClick={() => props.setCompareView(!props.compareView)}>
                                        {props.compareView == false ?
                                            <span>Compare With Reference Images <ArrowRightOutlined /></span> :
                                            <span><ArrowLeftOutlined /> Back to Original View</span>}

                                    </Button> : ""}

                                {
                                    props.uploadedModel == true && props.productReducer.product.model_status == -6 && props.validationReport != undefined && props.validationReport.High != undefined && props.validationReport.High.product_valid == false && props.variantModel == false ?
                                        ''
                                        :
                                        (props.productReducer.product.model_status == 4) && props.compareView == true && !props.isConfigurable ?
                                            <Row>
                                                <Col span={12}>
                                                    <ProductDetailsComponent storePage={props.storePage}  {...props} complaints={complaints} viewComplaintsListener={viewComplaintsListener} dimensionCrypto={props.dimensionCrypto} cryptoMatteExists={props.cryptoMatteExists} status360={props.status360} mp4Status={props.mp4Status} adminCheck={true} compareView={true} embed={false} />
                                                </Col>
                                                <Col span={12}>
                                                    <ProductStaticGuidelinesCard rejectionDetails={props.rejectionDetails} adminCheck={true} {...props} compareView={true} embed={false} />
                                                </Col>
                                            </Row>
                                            : props.isConfigurable ?
                                                <ConfigurationViewer /> :
                                                (props.productReducer.product.model_status != 4 && props.productReducer.product.model_status != 5) && !props.isConfigurable ?
                                                    <ProductStaticGuidelinesCard {...props} rejectionDetails={props.rejectionDetails} compareView={false} embed={false} adminCheck={false} />
                                                    : !props.isConfigurable ?
                                                        <ProductDetailsComponent storePage={props.storePage}  {...props} complaints={complaints} viewComplaintsListener={viewComplaintsListener} dimensionCrypto={props.dimensionCrypto} cryptoMatteExists={props.cryptoMatteExists} compareView={false} adminCheck={false} embed={false} /> : ''
                                }
                            </div>


                            <Row type="flex" style={{ justifyContent: "space-between", marginTop: "10px", marginBottom: "12px" }}>
                                <Col span={10} className="justify-in-start">
                                    <Tooltip title={<span className="manrope f-12 white">{props.productReducer.product.product_name}</span>}>
                                        <span className="manrope f-20 black-33 clamp-text w-60 capitalize">
                                            {props.productReducer.product.product_name}
                                        </span>
                                    </Tooltip>
                                    {props.restrictedItem? '': 
                                    <span>
                                        {props.productReducer.product.model_status == 5 && props.storePage == 0 ?
                                            <Tooltip title={<span className="manrope f-12 white">Edit Product Info</span>}>
                                                <img style={{ width: 30, height: 30 }} id="edit-icon" src="/img/edit-w-hover.png" className="edit-icon-data" onClick={props.openEditForm} onMouseOut={props.onMouseOut} onMouseOver={props.onMouseOver} />
                                            </Tooltip> : ''}
                                    </span>}
                                </Col>

                                {
                                    validationState == 2 ?
                                        <React.Fragment>
                                            <Divider />
                                            <Col span={10}>
                                                <div className='validation-uploaded-model-rejection-div'>
                                                    <Card title={<div><CloseCircleOutlined /><span className='validation-uploaded-model-header-rejection'>{FileConstants.UploadValidationTitle[validationState]}</span></div>}>
                                                        <span className='manrope f-14 validation-uploaded-model-rejection'>
                                                            {FileConstants.UploadValidationMessage[validationState]}
                                                        </span>
                                                        <Divider />
                                                        <div className='justify-in-end'>
                                                            <Button className="modal-okay square font-12" onClick={() => { onClickAssignToArtist() }}>
                                                                Request a New Model
                                                            </Button>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </React.Fragment>
                                        :
                                        ''
                                }
                                <Col span={12} className='justify-in-end'>
                                    {(props.productReducer.product.model_status == 5 || props.productReducer.product.model_status == 4) && props.storePage == 0 ?
                                        <Button className="modal-okay-invert square font-14" style={{ marginRight: "16px" }} onClick={() => props.setSharingModal(true)}>
                                            Share Product
                                        </Button> : ''}


                                    {(props.productReducer.product.model_status == 5) && props.storePage == 0 && (!window.location.href.includes("msp_products") && !window.location.href.includes("product_page")) ?
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Dropdown overlay={menu} trigger={['click']} visible={props.openGenerateOption} onVisibleChange={onVisibleChange}>
                                                <Button data_tut="reactour__generateImageOption" className="modal-okay-orange square font-14">Generate&nbsp;<DownOutlined /></Button>
                                            </Dropdown>
                                        </div>
                                        : ''}

                                    {(props.productReducer.product.model_status == 4) && isApproveAccess && props.storePage == 0 && (!window.location.href.includes("msp_products") && !window.location.href.includes("product_page")) ?
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button className="modal-okay square font-14" onClick={props.openVariationModal}>Generate Product Variant</Button>
                                        </div>
                                        : ''}
                                </Col>
                            </Row>

                            {rejections_data && props.storePage == 0 ?
                                <Row style={{ marginBottom: 16, marginTop: 15 }}>
                                    <Col sm={24} lg={24} xl={12} className="note-bg-artist pd-8 red">

                                        <div className='justify-space-between'>
                                            <div className='manrope f-14 red' style={{ display: 'inline' }}>
                                                Rejection Comments
                                            </div>
                                            <Button type="link" onClick={() => { showRejectionModal() }} className='manrope f-14 blue hover-change' style={{ display: 'inline', position: 'absolute', right: 20 }}>
                                                View Details
                                            </Button>
                                        </div>

                                        {rejections_data}
                                    </Col>
                                </Row> : ""}

                            <Row type="flex" style={{ justifyContent: "space-between", marginTop: "0px", alignItems: 'flex-start' }}>
                                <Col span={14}>
                                    <div className="justify-in-start" style={{ flexWrap: 'wrap', alignItems: 'flex-start' }}>
                                        {props.state.category ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Category</div>
                                                <div className="manrope f-12 black-33 w-600">
                                                    {props.state.parent_category && FileConstants.HIDDEN_CATEGORY[props.state.parent_category] ?
                                                        props.state.parent_category
                                                        : props.state.category}
                                                </div>
                                            </Col> : ''}

                                        {props.state && props.state.style_category ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}> Style Category</div>
                                                <div className="manrope f-12 black-33 w-600">
                                                    {props.state.style_category}
                                                </div>
                                            </Col> : ''}
                                        {props.state.licensing_options && props.state.licensing_options.license ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>License</div>
                                                <div className="manrope f-12 black-33 w-600 capitalize">{props.state.licensing_options.license == 'private' ? props.state.licensing_options.license : ''}
                                                    {props.state.licensing_options.license == 'public' ? props.state.licensing_options.public_option == 'forCreativeCommon' ?
                                                        <Tooltip title={<span className='manrope f-12 white'>{props.state.licensing_options.creative_common}</span>}>
                                                            <img style={{ marginLeft: 5 }} src={FileConstants.getLicensingIcon(props.state.licensing_options.creative_common)} />
                                                        </Tooltip> :
                                                        <span>
                                                            <Tooltip title={<span className='manrope f-12 white'>Only For ALL3D Usage</span>}>
                                                                <img src={FileConstants.getLicensingIcon(props.state.licensing_options.public_option)} />
                                                            </Tooltip>
                                                            &nbsp;<Tooltip title={<span className='manrope f-12 white'>Not Downloadable</span>}><img src='/img/not_downloadable.png' /></Tooltip>
                                                        </span>
                                                        : ''}
                                                </div>
                                            </Col> : ''}

                                        {props.productReducer.product &&
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Dimensions</div>
                                                <div className="manrope f-12 black-33 w-600">
                                                    {model_width == "N/A" ? "N/A" : model_width.toFixed(1)}"W x {model_height == "N/A" ? 'N/A' : model_height.toFixed(1)}"H x {model_depth == "N/A" ? 'N/A' : model_depth.toFixed(1)}"D
                                                </div>
                                            </Col>}

                                        <Col span={6} style={{ marginBottom: "10px" }}>
                                            <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Item ID</div>
                                            <div className="manrope f-12 black-33 w-600">{props.productReducer.product.brand_id || 'N/A'}</div>
                                        </Col>
                                        
                                        <Col span={6} style={{ marginBottom: "10px" }}>
                                            <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>GTIN</div>
                                            <div className="manrope f-12 black-33 w-600">{props.gtin || 'N/A'}</div>
                                        </Col>
                                        
                                        {props.groupId ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Group ID</div>
                                                <div className="manrope f-12 black-33 w-600">{props.groupId || 'N/A'}</div>
                                            </Col> : ''
                                        }
                                        {(props.state && (props.state.color_name)) ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Color</div>
                                                <div className="manrope f-12 black-33 w-600">{props.state.color_name}</div>
                                            </Col> : ''}
                                        {(props.state && (props.state.similar_color)) ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Similar Color</div>
                                                <div className="manrope f-12 black-33 w-600">{props.state.similar_color}</div>
                                            </Col> : ''}
                                        {props.state && props.state.materials && props.state.materials.length > 0 ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ textAlign: "left", marginBottom: 4 }}>
                                                    Materials
                                                </div>
                                                <div className="justify-in-start" style={{display: 'inline-block'}}>
                                                    {props.state.materials.map((material, index) => (
                                                        <span className="manrope f-12 black-33 w-600">
                                                            {index > 0 ? `, ${material}` : `${material}`}
                                                        </span>
                                                    ))
                                                    }
                                                </div>
                                            </Col> : ''}
                                        {(props.state && (props.state.product_model_type)) ?
                                        <Col span={6} style={{ marginBottom: "10px" }}>
                                            <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Product Type</div>
                                            <div className="manrope f-12 black-33 w-600">{FileConstants.PRODUCT_MODEL_TYPE[props.state.product_model_type]}</div>
                                        </Col> : ''}
                                        {props.state && props.state.reference_urls != undefined && props.state.reference_urls.length > 0 && (props.storePage === 0 || FileConstants.ALL3D_COMPANY_IDS().includes(COMPANY_ID)) ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}> Reference URL(s)</div>
                                                {props.state.reference_urls.map((ele, index) => {
                                                    return <div className="manrope f-12 blue hover-change clamp-text w-60" style={{ textAlign: "left", marginBottom: 4 }}><a href={ele} target='_blank'>{ele}</a></div>
                                                })}
                                            </Col>
                                            : ""}

                                        {props.state && props.state.product_manuals != undefined && props.state.product_manuals.length > 0 && (props.storePage === 0 || FileConstants.ALL3D_COMPANY_IDS().includes(COMPANY_ID))  ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}> Reference Manual(s)</div>
                                                {props.state.product_manuals.map((ele, index) => {
                                                    let fileURL = ENVIRONMENT.getBaseURL(props.state.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((ele.originFileObj ? ele.originFileObj.uid : ele.uid) + '/' + ele.name);
                                                    return <div className="manrope f-12 blue hover-change clamp-text w-60" style={{ textAlign: "left", marginBottom: 4 }}><a href={fileURL} target='_blank'>{fileURL}</a></div>
                                                })}
                                            </Col>
                                            : ""}

                                        {props.state && props.state.raw_files != undefined && props.state.raw_files.length > 0 && props.storePage == 0 ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}> Raw Files(s)</div>
                                                {props.state.raw_files.map((ele, index) => {
                                                    let fileURL = ENVIRONMENT.getBaseURL(props.state.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.originFileObj.uid + '/' + ele.name);
                                                    return <div className="manrope f-12 blue hover-change clamp-text w-60" style={{ textAlign: "left", marginBottom: 4 }}><a href={fileURL} target='_blank'>{fileURL}</a></div>
                                                })}
                                            </Col>
                                            : ""}

                                        {props.state && props.state.model_files != undefined && props.state.model_files.length > 0 && (props.storePage === 0 ) && (localStorage.getItem("username") == props.productReducer.product.customer_username || props.productReducer.product.company_id == localStorage.getItem('company_id')) ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Model File</div>
                                                {props.state.model_files.map((ele, index) => {
                                                    let fileURL = ENVIRONMENT.getBaseURL(props.state.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((ele.originFileObj ? ele.originFileObj.uid : ele.uid) + '/' + ele.name);
                                                    return <div className="manrope f-12 blue hover-change clamp-text w-60" style={{ textAlign: "left", marginBottom: 4 }}><a href={fileURL} target='_blank'>{fileURL}</a></div>
                                                })}
                                            </Col>
                                            : ""}

                                        {props.state && props.state.scans != undefined && props.state.scans.length > 0 && props.storePage == 0 ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Scans Data</div>
                                                {props.state.scans.map((ele, index) => {
                                                    let fileURL = SCANS_BASE_URL + encodeURIComponent(ele);
                                                    return <div className="manrope f-12 blue hover-change clamp-text w-60" style={{ textAlign: "left", marginBottom: 4 }}><a href={fileURL} target='_blank'>{fileURL}</a></div>
                                                })}
                                            </Col>
                                            : ""}
                                        {props.state && props.state.variant_of && (props.storePage === 0 || FileConstants.ALL3D_COMPANY_IDS().includes(COMPANY_ID))  ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Model Type</div>
                                                <div className="manrope f-12 black-33 w-600">Variation</div>
                                            </Col> : ''}
                                        {props.state && props.state.segmented && props.storePage == 0 ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Segmented Model</div>
                                                <div className="manrope f-12 black-33 w-600">{props.state.segmented ? ' Yes' : ' No'}</div>
                                            </Col> : ''}
                                        {props.state && props.state.variation_type && (props.storePage === 0 || FileConstants.ALL3D_COMPANY_IDS().includes(COMPANY_ID))  ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Variation Type</div>
                                                <div className="manrope f-12 black-33 w-600">{props.state && FileConstants.variationDict(props.state.variation_type, props.productReducer.product.need_to_model)}</div>
                                            </Col> : ''}
                                        {props.state && props.state.variant_of && (localStorage.getItem("username") == props.productReducer.product.customer_username || props.productReducer.product.company_id == localStorage.getItem('company_id')) && props.storePage == 0 ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Parent Model</div>
                                                <div className="manrope f-12 blue hover-change clamp-text w-60" style={{ textAlign: "left" }}><a href={ENVIRONMENT.getBaseURL(props.baseVariantPlatform) + ENVIRONMENT.LOW_POLY_MAX_URI + props.state.variant_of + '.zip'} target='_blank'>{ENVIRONMENT.getBaseURL(props.baseVariantPlatform) + ENVIRONMENT.LOW_POLY_MAX_URI + props.state.variant_of + '.zip'}</a></div>
                                            </Col>
                                            : ""}
                                        {props.state.category_type && props.storePage == 0 ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Bedding Type</div>
                                                {FileConstants.HIDDEN_CATEGORY[props.state.category].map((cat, index) => (
                                                    <div className="manrope f-12 black-33 w-600" style={{ textAlign: "left" }}>
                                                        {cat == props.state.category_type ?
                                                            cat
                                                            : ''}
                                                    </div>
                                                ))}
                                            </Col> : FileConstants.HIDDEN_CATEGORY[props.state.category] && props.storePage == 0 ?
                                                <Col span={6} style={{ marginBottom: "10px" }}>
                                                    <div className="manrope f-14 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Bedding Type</div>
                                                    <div className="manrope f-12 black-33 w-600" style={{ textAlign: "left" }}>
                                                        White Mattress and Pillows
                                                    </div>
                                                </Col> : FileConstants.HIDDEN_CATEGORY[props.state.parent_category] && props.storePage == 0 ?
                                                    <Col span={6} style={{ marginBottom: "10px" }}>
                                                        <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Bedding Type</div>
                                                        <div className="manrope f-12 black-33 w-600" style={{ textAlign: "left" }}>
                                                            {props.state.category}
                                                        </div>
                                                    </Col> : ''}
                                        {(props.state && props.state.guidelines && (props.storePage === 0 || FileConstants.ALL3D_COMPANY_IDS().includes(COMPANY_ID)) ) ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Guidelines/ Comments</div>
                                                {props.state.guidelines.split('\n').map((item, i) => {
                                                    return <div className="manrope f-12 black-33 w-600" style={{ textAlign: "left", marginBottom: 4 }} key={i}>{item}</div>
                                                })}
                                            </Col> : ''}
                                        {(props.state && props.state.tags && props.state.tags.length > 0) ?
                                            <Col span={6} style={{ marginBottom: "10px" }}>
                                                <div className="manrope f-12 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Tags</div>
                                                <div className="justify-in-start" style={{display: 'inline-block'}}>
                                                    {props.state.tags.map((tag, index) => (
                                                        <span className="manrope f-12 black-33 w-600">
                                                            {index > 0 ? `, ${tag}` : `${tag}`}
                                                        </span>
                                                    ))
                                                    }
                                                </div>
                                            </Col> : ''}
                                    </div>
                                </Col>
                                <Col className='justify-in-end'>
                                    {(props.productReducer.product.model_status == 5) && (CUSTOMER_USERNAME == props.productReducer.product.customer_username) && props.storePage == 0 ?
                                        <Button className={`modal-okay-invert square font-14 ${props.state.is_store_item == 1 ? `pd-15` : `pd-35`}`} onClick={() => props.performStoreAction()}>
                                            {props.state.is_store_item == 1 ? 'Remove from Store' : 'Sell on Store'}
                                        </Button> : ''}
                                </Col>
                            </Row>

                            <hr />

                            {props.state && (
                                (props.state.product_photos && props.state.product_photos.length > 0)
                                || (props.state.material_files && props.state.material_files.length > 0)
                                || (props.libraryMaterial && props.libraryMaterial.length > 0)
                                || (props.state.category_attachment &&
                                    props.state.category_attachment.length > 0)) &&
                                <Row type="flex" style={{ justifyContent: "space-between", marginTop: "0px", alignItems: 'center' }}>
                                    <Col span={16}>
                                        <div className="justify-in-start" style={{ alignItems: 'flex-start' }}>
                                            {props.state.product_photos
                                                && props.state.product_photos.length != 0
                                                && props.storePage == 0 ?
                                                <div style={{ marginBottom: "10px" }}>
                                                    <div className="manrope f-14 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Reference Images</div>
                                                    <Row>
                                                        {product_photos}
                                                    </Row>
                                                </div> : ''}
                                            {props.state.material_files
                                                && props.state.material_files.length > 0 && props.storePage == 0 ?
                                                <div style={{ marginBottom: "10px" }}>
                                                    <div className="manrope f-14 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Material Images</div>
                                                    <Row>
                                                        {props.state.material_files.map(photo => (
                                                            <Col>
                                                                <Card className="reference-img-card">
                                                                    {photo.name.toLowerCase().includes('jpg') || photo.name.toLowerCase().includes('jpeg') || photo.name.toLowerCase().includes('png') || photo.name.toLowerCase().includes('svg') ?
                                                                        <Image
                                                                            className="reference-img-photo" src={ENVIRONMENT.getBaseURL(props.state.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(photo.uid + '/' + photo.name)}
                                                                        />
                                                                        :
                                                                        <a href={ENVIRONMENT.getBaseURL(props.state.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(photo.uid + '/' + photo.name)} target='blank'>
                                                                            <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                                                                                <img
                                                                                    className="reference-img-photo" src={'/img/file-icon.png'}
                                                                                />
                                                                            </Tooltip>
                                                                        </a>}
                                                                </Card>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </div> : ''}
                                            {props.libraryMaterial
                                                && props.libraryMaterial.length > 0 && props.storePage == 0 ?
                                                <div style={{ marginBottom: "10px" }}>
                                                    <div className="manrope f-14 grey-99" style={{ marginBottom: "4px", textAlign: "left" }}>Selected Materials</div>
                                                    <Row>
                                                        {props.libraryMaterial.map(mat => (
                                                            <Col>
                                                                <Card className="reference-img-card">
                                                                    {mat.renders.data && mat.renders.data.thumbnail &&
                                                                        <Image
                                                                            className="reference-img-photo"
                                                                            preview={{
                                                                                visible: props.imageVisible,
                                                                                maskClassName: 'customize-mat-icon',
                                                                                mask: (
                                                                                    <span className='manrope f-12' style={{ textAlign: 'center' }}>
                                                                                        {mat.name}
                                                                                    </span>
                                                                                ),
                                                                                onVisibleChange: props.onChangeImageVisibility
                                                                            }}
                                                                            src={ENVIRONMENT.getBaseURL(mat.platform) + ENVIRONMENT.MATERIAL_FILE_URI + mat.id + '/' + encodeURIComponent(mat.renders.data.thumbnail)}
                                                                        />}
                                                                </Card>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </div> : ''}
                                            {props.state.category_type
                                                && props.state.category_attachment
                                                && props.state.category_attachment.length > 0
                                                && props.storePage == 0 ?
                                                <div style={{ marginBottom: "10px" }}>
                                                    <div className="manrope f-14 grey-99" style={{ marginRight: 16 }}>Bedding Type</div>
                                                    <Row>
                                                        {category_attachment}
                                                    </Row>
                                                </div> : ''}
                                        </div>
                                    </Col>
                                </Row>}

                            {
                                validationState < 2 && validationState > 0 ?
                                    <Row type="flex" style={{ justifyContent: "flex-start", marginTop: "0px", marginBottom: 10 }}>
                                        <Col span={10}>
                                            <div className={validationState == 0 ? 'validation-uploaded-model-warning-div' : validationState == 1 ? 'validation-uploaded-model-success-div' : ''}>
                                                <Card title={
                                                    <div>
                                                        {validationState == 0 ? <InfoCircleOutlined /> : validationState == 1 ? <CheckCircleOutlined /> : ''}
                                                        <span className={validationState == 0 ? 'validation-uploaded-model-header-warning' : validationState == 1 ? 'validation-uploaded-model-header-success' : ''}>
                                                            {
                                                                FileConstants.UploadValidationTitle[validationState]
                                                            }
                                                        </span>
                                                    </div>
                                                }
                                                >
                                                    <span className='manrope f-14 validation-uploaded-model-success'>
                                                        {FileConstants.UploadValidationMessage[validationState]}
                                                    </span>
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    ''
                            }

                            <Row type="flex" style={{ justifyContent: "flex-start", marginTop: "0px" }}>
                                <Col span={12}>
                                    {/* File Sizes */}
                                    <Col span={24} style={{ marginBottom: 10 }}>
                                        {total_file_size > 0 && total_file_size != glb_size && props.storePage == 0 ?
                                            <React.Fragment>
                                                <div>
                                                    <span className="manrope f-14 grey-99">Total File Size</span>
                                                    <span className="manrope f-14 blue" style={{ paddingLeft: 10 }}>{props.convertByteSize(total_file_size)}</span>
                                                </div>
                                                <table className="stats-table" style={{ marginTop: 4, tableLayout: "fixed", width: "94%" }}>
                                                    <tr>
                                                        {high_poly_model_size + high_poly_texture_size > 0 ?
                                                            <td colSpan={2}>
                                                                <span className="manrope f-12 grey-77 capitalize" style={{ display: 'block', paddingTop: 7 }}>High Poly</span>
                                                            </td> : ""}
                                                        {low_poly_model_size + low_poly_texture_size > 0 ?
                                                            <td colSpan={2}>
                                                                <span className="manrope f-12 grey-77 capitalize" style={{ display: 'block', paddingTop: 7 }}>Low Poly</span>
                                                            </td> : ""}
                                                        {glb_size > 0 ?
                                                            <td>
                                                                <span className="manrope f-12 grey-77 capitalize" style={{ display: 'block', paddingTop: 7 }}>GLB</span>
                                                            </td> : ""}

                                                    </tr>
                                                    <tr>
                                                        {high_poly_model_size > 0 ?
                                                            <td colSpan={high_poly_texture_size > 0 ? 1 : 2}>
                                                                <span className="manrope f-12 grey-77 capitalize" style={{ display: 'block', paddingTop: 7 }}>Texture + Mesh</span>
                                                                <span style={{ fontSize: 12, paddingTop: 7 }}>{props.convertByteSize(high_poly_model_size)}</span>
                                                            </td>
                                                            : ""}
                                                        {high_poly_texture_size > 0 ?
                                                            <td colSpan={high_poly_model_size > 0 ? 1 : 2}>
                                                                <span className="manrope f-12 grey-77 capitalize" style={{ display: 'block', paddingTop: 7 }}>Texture file</span>
                                                                <span style={{ fontSize: 12, paddingTop: 7 }}>{props.convertByteSize(high_poly_texture_size)}</span>
                                                            </td>
                                                            : ""}
                                                        {low_poly_model_size > 0 ?
                                                            <td colSpan={low_poly_texture_size > 0 ? 1 : 2}>
                                                                <span className="manrope f-12 grey-77 capitalize" style={{ display: 'block', paddingTop: 7 }}>Texture + Mesh</span>
                                                                <span style={{ fontSize: 12, paddingTop: 7 }}>{props.convertByteSize(low_poly_model_size)}</span>
                                                            </td>
                                                            : ""}
                                                        {low_poly_texture_size > 0 ?
                                                            <td colSpan={low_poly_model_size > 0 ? 1 : 2}>
                                                                <span className="manrope f-12 grey-77 capitalize" style={{ display: 'block', paddingTop: 7 }}>Texture file</span>
                                                                <span style={{ fontSize: 12, paddingTop: 7 }}>{props.convertByteSize(low_poly_texture_size)}</span>
                                                            </td>
                                                            : ""}
                                                        {glb_size > 0 ?
                                                            <td colSpan={1}>
                                                                {/* <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>Texture file</span> */}
                                                                <span style={{ fontSize: 12, paddingTop: 7 }}>{props.convertByteSize(glb_size)}</span>
                                                            </td>
                                                            : ""}

                                                    </tr>
                                                </table>
                                            </React.Fragment>
                                            : (props.archiveSize > 0 || props.glbSize > 0) && props.storePage == 0 ?
                                                <React.Fragment style={{ marginBottom: 10 }}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <span className="manrope f-14 grey-99">Total File Size</span>
                                                        <span className="manrope f-14 blue" style={{ paddingLeft: 10 }}>{props.convertByteSize((props.archiveSize + props.glbSize) / (1024 * 1024))}</span>
                                                    </div>
                                                    <table className="stats-table" style={{ marginTop: 4 }}>
                                                        <tr>
                                                            {props.archiveSize > 0 ?
                                                                <td>
                                                                    <span className="manrope f-12 grey-77 capitalize" style={{ display: 'block', paddingTop: 7 }}>Archive Size</span>
                                                                    <span style={{ fontSize: 12, paddingTop: 7 }}>{props.convertByteSize(props.archiveSize / (1024 * 1024))}</span>
                                                                </td>
                                                                : ""}
                                                            {props.glbSize > 0 ?
                                                                <td>
                                                                    <span className="manrope f-12 grey-77 capitalize" style={{ display: 'block', paddingTop: 7 }}>GLB Size</span>
                                                                    <span style={{ fontSize: 12, paddingTop: 7 }}>{props.convertByteSize(props.glbSize / (1024 * 1024))}</span>
                                                                </td>
                                                                : ""}
                                                        </tr>
                                                    </table>
                                                </React.Fragment>
                                                : props.storePage == 0 ?
                                                    <div style={{ marginBottom: 10 }}>
                                                        <span className="manrope f-14 grey-99">Total File Size</span>
                                                        {information_not_available}
                                                    </div> : ''
                                        }
                                    </Col>

                                    {(low_poly_vertex || high_poly_vertex) && props.storePage == 0 ?
                                        <Col span={24} style={{ marginBottom: 10 }}>
                                            <span className="manrope f-14 grey-99">{low_poly_triangles||high_poly_triangles ? "Triangle Count" : "Vertex Count"}</span>
                                            <table className="stats-table" style={{ marginTop: 4 }}>
                                                <tr>
                                                    {low_poly_vertex ?
                                                        <td>
                                                            <span className="manrope f-12 grey-77 capitalize" style={{ display: 'block', color: '#777777', fontSize: 14, paddingTop: 7 }}>
                                                                {low_poly_triangles ? "Low Poly Triangle Count" : "Low Poly Vertex Count"}
                                                            </span>
                                                            <span style={{ fontSize: 12, paddingTop: 7 }}>
                                                                {low_poly_triangles ? parseInt(low_poly_triangles).toLocaleString() : parseInt(low_poly_vertex).toLocaleString()}
                                                            </span>
                                                        </td>
                                                        : ""}
                                                    {high_poly_vertex ?
                                                        <td>
                                                            <span className="manrope f-12 grey-77 capitalize" style={{ display: 'block', color: '#777777', fontSize: 14, paddingTop: 7 }}>
                                                                {high_poly_triangles ? "High Poly Triangle Count" :"High Poly Vertex Count"}
                                                            </span>
                                                            <span style={{ fontSize: 12, paddingTop: 7 }}>
                                                                {high_poly_triangles ? parseInt(high_poly_triangles).toLocaleString() : parseInt(high_poly_vertex).toLocaleString()}
                                                            </span>
                                                        </td>
                                                        : ""}
                                                </tr>
                                            </table>
                                        </Col>
                                        : ""}



                                </Col>
                            </Row>
                            <div style={{ height: "50px" }}></div>
                            <hr />
                        </Col>
                    </Row>
                    {(props.productReducer.product.model_status == "4" || props.productReducer.product.model_status == "-6" && props.showArtistModal == true) && (!window.location.href.includes("msp_products")) ?
                        <ReviewProduct
                            switchToNewView={props.switchToNewView}
                            legacyViewLoader={props.legacyViewLoader}
                            approveLoader={props.approveLoader}
                            visible={props.rejectionModalVisible}
                            setVisible={props.setRejectionModalVisible}
                            uploaded_model={props.state.uploaded_model}
                            firstTimeUser={props.firstTimeUser}
                            setNextActionPrompt={props.setNextActionPrompt}
                            assignedArtist={props.assignedArtist}
                            referenceFiles={props.productReducer.product.reference_files}
                            uploadedModel={props.uploadedModel}
                            variantModel={props.variantModel}
                            rejectLoader={props.rejectLoader}
                            setRejectLoader={props.setRejectLoader}
                            cardStatus={props.cardStatus}
                            requestStatus={props.requestStatus}
                            rejectionPrice={props.rejectionPrice}
                            setShowArtistModal={props.setShowArtistModal}
                            modelStatus={props.productReducer.product.model_status}
                            adminForm={false}
                            roomDetailModal={false}
                            roomDetail={false}
                            onApprove={props.onApprove}
                            onReject={props.onReject}
                            rejectionDetails={props.productReducer.product.rejection_details}
                            platform={props.productReducer.product.platform}>
                            
                        </ReviewProduct>
                        : ''}
                    {(props.productReducer.product.model_status == "2" || props.productReducer.product.model_status == "3") ?
                        <div className="product-status-review" style={{ background: "#FFFFFF", boxShadow: "0px -8px 25px rgba(0, 0, 0, 0.04)" }}>
                            <Row type="flex" justify="flex-start">
                                <h4></h4>
                                <div>
                                    <Button disabled={true} type="default" size="large" className="inprogress-btn">
                                        <div className="accept-btn-text red">
                                            In Progress
                                        </div>
                                    </Button>
                                </div>
                            </Row>
                        </div>
                        : ''}
                </React.Fragment>
                :
                // View complaints page for silo complaints
                <SiloComplaints
                    bread_crumbs={bread_crumbs}
                    companyId={props.companyId}
                    username={props.cognito.user.username}
                    product_id={props.params.id}
                    complaints={complaints}
                    viewComplaintsListener={viewComplaintsListener}
                    setComplaintsView={setComplaintsView}
                    platform={this.state.platform}
                />
            }

            <AddItemToStore
                type={"customer_details"}
                entity_type={"product"}
                visible={props.sellVisible}
                price={props.price}
                add_store_item_loader={props.sellLoader}
                onCancel={props.cancelSellModal}
                selected_item={props.state}
                onChangePrice={props.onChangePrice}
                addToStore={props.setStoreRequests}
            />

            <Modal
                className="modal-share-success"
                width={600}
                centered={true}
                onCancel={() => props.setRemoveFromStore(false)}
                visible={props.removeFromStore}
                closable={false}
                footer={[
                    <span className="justify-in-center">
                        <Button key="back" style={{ textAlign: "center" }} className="modal-okay-gray square font-12" onClick={() => props.setRemoveFromStore(false)}>
                            Cancel
                        </Button>
                        <Button key="back" style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={() => props.removeItemFromStore()}>
                            {props.removeLoader ? <span> Removing <LoadingOutlined spin /></span> : <span> Okay </span>}
                        </Button>
                    </span>
                ]}
            >
                <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <h2 className="manrope f-16 black-00 w-600">You're about to remove this product from store. Are you sure you want to remove?</h2>
                </div>
            </Modal>

            <Modal
                visible={props.sharingModal}
                onCancel={() => props.setSharingModal(false)}
                className="modal-share"
                footer={null}
            >
                <div style={{ padding: "16px 6px 0 14px", marginBottom: 30 }}>
                    <h2 className="manrope f-20 black-00 w-600">Share or Embed Your Product</h2>

                    <h4 className="manrope f-14 black-33">Share within ALL3D</h4>
                    <Form form={form} onFinish={values => props.handleShare(values, form)} style={{ marginBottom: "20px" }}>
                        <Form.Item name="emails" style={{ marginBottom: "30px" }} className="manrope f-12" colon={false} validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                { type: 'array', required: true, message: 'Please enter an email address' },
                                { validator: validateEmails },
                            ]}
                            validateStatus={emailError ? 'error' : ''}
                            help={emailError}
                        >  
                            <EmailSelect allowClear={true} onChange={(e) => handleShareEmailChange(e)} mode="tags" className="email-ph share-input-ph manrope f-12" placeholder="Enter Email Address of people to share product with"/>
                        </Form.Item>
                        <Button id="scene-share-button" type="primary" htmlType="submit" style={{ "display": "none" }}>
                            Submit
                        </Button>
                    </Form>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: -15 }}>
                        <Checkbox style={{ fontWeight: 400 }} value='download_and_embed' defaultChecked={false} onChange={e => onChangeAllowDownloading(e)} >Allow Downloading</Checkbox>
                        <Button key="back" disabled={props.sharingLoader || sharingEmails.length == 0} className="modal-okay square font-12" onClick={() => document.getElementById("scene-share-button").click()}>
                            Share {props.sharingLoader ? <LoadingOutlined spin /> : ""}
                        </Button>
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <h2 className="manrope f-20 black-00 w-600">Share Public Links</h2>
                        <span>

                            <h4 className="manrope f-14 black-33">URL for Public Viewer</h4>
                            <Paragraph className="modal-radio-anchor bg manrope f-12" copyable={{ text: `https://app.all3d.ai/product-details/${props.params.id}/${localStorage.getItem("username")}` }}>
                                <Link to={'/product-details/' + props.params.id + '/' + localStorage.getItem("username")} {...props} target="_blank">
                                    Public Viewer
                                </Link>
                            </Paragraph>
                            {props.productReducer.product['360'] &&
                                <div style={{ marginTop: 10 }}>
                                    <h4 className="manrope f-14 black-33">URL for Product 360</h4>
                                    <Paragraph className="modal-radio-anchor bg manrope f-12" copyable={{ text: `https://app.all3d.ai/product_public_viewer/product_360/${props.params.id}/${localStorage.getItem("username")}${props.productReducer.product.company_id && props.productReducer.product.company_id != "" ? ("?company_id=" + props.productReducer.product.company_id) : ""}` }}>
                                        <Link to={'/product_public_viewer/product_360/' + props.params.id + '/' + localStorage.getItem("username") + props.productReducer.product.company_id != "" ? ("?company_id=" + props.productReducer.product.company_id) : ""} target="_blank">
                                            Product 360
                                        </Link>
                                    </Paragraph>

                                </div>}

                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <h4 className="manrope f-14 black-33">URL for Product 3D Model</h4>
                                <Paragraph className="modal-radio-anchor bg manrope f-12" copyable={{ text: `https://app.all3d.ai/product_public_viewer/product_3d_model/${props.params.id}` }}>
                                    <Link to={'/product_public_viewer/product_3d_model/' + props.params.id} target="_blank">
                                        Product 3D Model
                                    </Link>
                                </Paragraph>

                            </div>

                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <h4 className="manrope f-14 black-33">Embed in your site</h4>
                                <Paragraph className="modal-radio-anchor bg manrope f-12" copyable={{ text: '<iframe allow-popups style="width: 700px; height: 700px;" src="' + 'http://app.all3d.ai/product-details/' + props.params.id + "/" + localStorage.getItem("username") + '"></iframe>' }}>
                                    <p className="modal-radio-anchor manrope f-12" style={{ color: "#999999" }} id="iframe-text">{'<iframe allow-popups style="width: 700px; height: 700px;" src="' + 'http://app.all3d.ai/product-details/' + props.params.id + "/" + localStorage.getItem("username") + '"></iframe>'}</p>
                                </Paragraph>
                            </div>
                        </span>
                    </div>
                </div>
            </Modal>
            <SuccessModal
                visible={props.successModal}
                heading={"Product Shared Successfully!"}
                onCancel={() => props.setSuccessModal(false)}
                footer={[
                    <div className="justify-in-center">
                        <Button key="back" style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => { props.setSuccessModal(false); handleClearEmails() }}>
                            Okay
                        </Button>
                    </div>
                ]}
            />

            <SuccessModal
                visible={props.successSave}
                onCancel={() => props.setSuccessSave(false)}
                heading={"Product Data Updated Successfully!"}
                text={"You were successfully able to update product data."}
                footer={[
                    <div className="justify-in-center">
                        <Button key="back" style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => window.location.href = "/products/" + props.params.id + `?folder_id=${props.folderParentID}`}>
                            Okay
                        </Button>
                    </div>
                ]} />

            <Modal
                visible={props.publicLink}
                className="modal-share"
                onCancel={props.handlePublicCancel}
                footer={null}
            >
                <div style={{ padding: "16px 6px 0 14px" }}>
                    <h2 className="manrope f-18 black-00 w-600">Public Link Details</h2>
                    <span>

                        <h4 className="manrope f-14 black-33">URL for Public Viewer</h4>
                        <Paragraph className="modal-radio-anchor bg manrope f-12" copyable={{ text: `https://app.all3d.ai/product-details/${props.params.id}` }}>
                            <Link to={'/product-details/' + props.params.id} {...props} target="_blank">
                                Public Viewer
                            </Link>
                        </Paragraph>

                        {props.productReducer.product['360'] &&
                            <div style={{ marginTo: 10 }}>
                                <h4 className="manrope f-14 black-33">URL for Product 360</h4>
                                <Paragraph className="modal-radio-anchor bg manrope f-12" copyable={{ text: `https://app.all3d.ai/product_public_viewer/product_360/${props.params.id}` }}>
                                    <Link to={'/product_public_viewer/product_360/' + props.params.id} target="_blank">
                                        Product 360
                                    </Link>
                                </Paragraph>

                            </div>}


                        <div style={{ marginTop: 10, marginBottom: 10 }}>
                            <h4 className="manrope f-14 black-33">URL for Product 3D Model</h4>
                            <Paragraph className="modal-radio-anchor bg manrope f-12" copyable={{ text: `https://app.all3d.ai/product_public_viewer/product_3d_model/${props.params.id}` }}>
                                <Link to={'/product_public_viewer/product_3d_model/' + props.params.id} target="_blank">
                                    Product 3D Model
                                </Link>
                            </Paragraph>

                        </div>
                    </span>
                </div>
            </Modal>

            <Modal
                closable={false}
                visible={props.replaceMP4Confirm}
                className="modal-share-success"
                width={600}
                onCancel={() => props.setReplaceMP4Confirm(false)}
                footer={[
                    <div className='justify-in-end'>
                        <Button key="back" style={{ textAlign: "center" }} className="modal-okay-black square font-12" onClick={() => props.setReplaceMP4Confirm(false)}>
                            Cancel MP4
                        </Button>
                        <Button key="back" style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={() => props.replaceMP4()}>
                            Replace Older MP4 {props.mp4GenerationLoader ? <LoadingOutlined spin /> : ""}
                        </Button>
                    </div>
                ]}>
                <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <h2 className="manrope f-16 black-00 w-600" style={{ marginBottom: 8 }}>Your Older MP4 Video Will Be Replaced</h2>
                    <div className="manrope f-14 black-55">Your previously generated MP4 video will be replaced with the new one. Are you sure you want continue with your request?</div>
                </div>
            </Modal>

            <Modal
                closable={false}
                visible={props.replace360Confirm}
                className="modal-share-success"
                width={600}
                onCancel={() => props.setReplace360Confirm(false)}
                footer={[
                    <div className='justify-in-end'>
                        <Button key="back" style={{ textAlign: "center" }} className="modal-okay-black square font-12" onClick={() => props.setReplace360Confirm(false)}>
                            Cancel 360 Spin
                        </Button>
                        <Button key="back" style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={() => props.replace360()}>
                            Replace Older 360 Spin {props.threesixtyGenerationLoader ? <LoadingOutlined spin /> : ""}
                        </Button>
                    </div>
                ]}>
                <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <h2 className="manrope f-16 black-00 w-600">Your Older 360 Spin Will Be Replaced</h2>
                    <div className="manrope f-14 black-55">Your previously generated 360 Spin will be replaced with the new one. Are you sure you want continue with your request?</div>
                </div>
            </Modal>

            {/* Modal for image showing  */}

            <Modal
                className="rejection-image"
                style={{ width: '100vw' }}
                closeIcon={
                    <img src={"/img/closeIcon.png"} alt="Close Modal Icon"
                        style={{ marginTop: 28, marginRight: 20, cursor: "pointer", borderRadius: "4px" }}

                    />
                }
                footer={false} visible={showImage} onCancel={() => { setImageUrl(''); setShowImage(false); }}>
                <a href={imageUrl} target="_blank" >
                    <img src={imageUrl} style={{ width: '100%', height: "100%" }} />
                </a>
            </Modal>

            {/* Rejection history Modal*/}
            <Modal visible={showDetailedRejection}
                closeIcon={
                    <img src={"/img/closeIcon.png"} alt="Close Modal Icon"
                        style={{ marginTop: 28, marginRight: 20, cursor: "pointer", borderRadius: "4px" }}
                        onClick={handleCancel}
                    />

                }
                onCancel={handleCancel} footer={false} width={1000}>
                <div className='manrope f-18 black-14 w-600'>
                    Rejection Comments
                </div>
                <div className="rejection-modal" style={{ height: '60vh', overflowY: 'scroll' }}>
                    <div style={{ display: 'grid' }}>
                        {detailed_rejection_data}
                    </div>

                </div>

            </Modal>


            <Modal
                visible={props.openEditModal}
                className="modal-share"
                width={788}
                maskClosable={false}
                closable={false}
                destroyOnClose={true}
                onCancel={props.cancelEdit}
                footer={[
                    <div className="justify-in-end">
                        <Button disabled={props.saveButtonLoader} className="modal-okay square font-12" onClick={() => document.getElementById("save-product-data").click()}>
                            Save&nbsp;{props.saveButtonLoader ? <LoadingOutlined /> : ''}
                        </Button>
                    </div>
                ]}
            >
                <div style={{ padding: "16px 6px 0 14px" }}>
                    <div className="justify-space-between" style={{ marginBottom: 20 }}>
                        <h2 className="manrope f-18 black-14 w-700">Edit Information</h2>
                        <h2 className="manrope f-18 grey-77" style={{ cursor: "pointer" }} onClick={props.cancelEdit}>X</h2>
                    </div>

                    <Form
                        className="product-edit-form" initialValues={{
                            product_name: props.productReducer.product.product_name,
                            brand_id: props.productReducer.product.brand_id,
                            group_id: props.groupId,
                            gtin: props.gtin,
                            category: props.productReducer.product.parent_category && FileConstants.HIDDEN_CATEGORY[props.productReducer.product.parent_category] ? props.productReducer.product.parent_category : props.productReducer.product.category,
                            style_category: props.state && props.state.style_category,
                            product_model_type: props.state && props.state.product_model_type,
                            reference_urls: props.state && props.state.reference_urls,
                            color_name: props.state && props.state.color_name,
                            similar_color: props.state && props.state.similar_color,
                            guidelines: props.state && props.state.guidelines,
                            tags: props.state && props.state.tags
                        }}
                        onFinish={props.saveProductData} layout="vertical">
                        <Row type='flex' gutter={12}>
                            <Col span={24} style={{ marginBottom: 10 }}>
                                <Form.Item colon={false} label={<span className="manrope f-14 black-55">Name</span>} name="product_name" rules={[{ required: true, message: 'Please enter product name.' }]}>
                                    <Input disabled={props.saveButtonLoader} placeholder={'Enter Product Name'} className="manrope f-12 black-55 library-search" />
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{ marginBottom: 10 }}>
                                <div className="material-field">
                                    <Form.Item label={<span className="manrope f-14 black-55">Product Model Category</span>} name="category" className="material-category-form"
                                        rules={[{ required: true, message: 'Please select a product model category.' }]}>
                                        <TreeSelect
                                            disabled={props.saveButtonLoader}
                                            className="tree-select-material f-12  item-height-35"
                                            showSearch
                                            style={{ width: '100%', color: "#333333" }}
                                            dropdownStyle={categoryStyle}
                                            placeholder={'Select or type in to search category'}
                                            treeData={props.categoriesData}
                                            value={props.categoryValue}
                                            onChange={(value) => props.setCategoryValue(value)}
                                            onSearch={(e) => { props.setCategorySearchValue(e) }}
                                            onInputKeyDown={(e) => { props.addCategoryOption(e) }}
                                            notFoundContent={<span className='tree-select-material f-12'>No such category found. Press enter to add your category.</span>}>
                                        </TreeSelect>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col span={12} style={{ marginBottom: 10 }}>
                                <div className="material-field">
                                    <Form.Item label={<span className="manrope f-14 black-55">Product Type</span>} name="product_model_type" className="material-category-form"
                                        rules={[{ required: false, message: 'Please select a product type.' }]}>
                                            <Select
                                                className={`tree-select-material f-12`}
                                                disabled={props.saveButtonLoader}
                                                value={FileConstants.PRODUCT_MODEL_TYPE[props.product_model_type]}
                                                showSearch
                                                placeholder={`Select or type in your product model type`}
                                            >
                                                {Object.keys(FileConstants.PRODUCT_MODEL_TYPE).map((key) => (
                                                    <Select.Option
                                                        key={key}
                                                        className="manrope f-10 select-view"
                                                        value={key}
                                                    >
                                                        {FileConstants.PRODUCT_MODEL_TYPE[key]}
                                                    </Select.Option>
                                                    ))}                                             
                                            </Select>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col span={12} style={{ marginBottom: 10 }}>
                                <div className="material-field">
                                    <Form.Item label={<span className="manrope f-14 black-55">Product Style Category</span>} name="style_category" className="material-category-form"
                                        rules={[{ required: false, message: 'Please select a product model style sategory.' }]}>
                                        <StyleCategory disabled={props.saveButtonLoader} className="tree-select-material f-12" />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col span={24} style={{ marginBottom: 10 }}>
                                <div className="material-field">
                                    <Form.Item label={<span className="manrope f-14 black-55">Product Group</span>} name="group_id" className="material-category-form"
                                        rules={[{ required: false, message: 'Please select product group.' }]}>
                                        <TreeSelect
                                            disabled={props.saveButtonLoader}
                                            className="tree-select-material f-12  item-height-35"
                                            showSearch
                                            style={{ width: '100%', color: "#333333" }}
                                            dropdownStyle={categoryStyle}
                                            placeholder={'Select or type in your product group name'}
                                            treeData={props.customerGroupIds}
                                            value={props.groupId}
                                            onChange={(value) => props.setGroupId(value)}
                                            onSearch={(e) => { props.setGroupIdSearchValue(e) }}
                                            onInputKeyDown={(e) => { props.addGroupIdOption(e) }}
                                            notFoundContent={<span style={{ textAlign: 'center' }}><h5 style={{ textAlign: 'center' }} className='manrope f-14 black-55'>No Group Found</h5><p className='manrope f-14 blue' style={{ textAlign: 'center', cursor: 'pointer' }} onClick={props.addGroupIdOption}><PlusOutlined />&nbsp;Add As Group</p></span>}
                                        >
                                        </TreeSelect>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col span={12} style={{ marginBottom: 10 }}>
                                <Form.Item colon={false} label={<span className="manrope f-14 black-55">Item ID</span>} name="brand_id" rules={[{ required: false, message: 'Please enter item id.' }]}>
                                    <Input disabled={props.saveButtonLoader} placeholder={'Enter Item ID'} className="manrope f-12 black-55 library-search" />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ marginBottom: 10 }}>
                                <Form.Item colon={false} label={<span className="manrope f-14 black-55">GTIN</span>} name="gtin" rules={[{ required: false, message: 'Please enter GTIN for the product.' }]}>
                                    <Input disabled={props.saveButtonLoader} placeholder={'Enter product GTIN'} className="manrope f-12 black-55 library-search" />
                                </Form.Item>
                            </Col>


                            <Col span={12} style={{ marginBottom: 10 }}>
                                <Form.Item colon={false} label={<span className="manrope f-14 black-55">Product Color</span>} name="color_name" rules={[{ required: false, message: 'Please enter product color.' }]}>
                                    <Input disabled={props.saveButtonLoader} placeholder={'Enter Product Color'} className="manrope f-12 black-55 library-search" />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ marginBottom: 10 }}>
                                <Form.Item colon={false} label={<span className="manrope f-14 black-55">Closest Color Match</span>} name="similar_color" rules={[{ required: true, message: 'Please enter the closest color match of your product.' }]}>
                                    <ColorSelect disabled={props.saveButtonLoader} className="tree-select-material f-12" />
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{ marginBottom: 10 }}>
                                <Form.Item colon={false} label={<span className="manrope f-14 black-55">Reference URL(s)</span>} name="reference_urls" rules={[{ required: false, type: 'array', message: 'Please add reference url(s).' }]}>
                                    <Select disabled={props.saveButtonLoader} className="manrope f-12 black-55 select-tags" mode="tags" placeholder="Enter Reference URL(s) that you would like us to have a look at, for your product model">
                                        <Option value="" className="manrope f-12 black-55 select-view"></Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                                <Col span={24} style={{ marginBottom: 10 }}>
                                    <Form.Item colon={false} label={<span className="manrope f-14 black-55">Tag(s)</span>} name="tags" rules={[{ required: false, type: 'array', message: 'Please add tag(s).' }]}>
                                        <Select disabled={props.saveButtonLoader} className="manrope f-12 black-55 select-tags" mode="tags" placeholder="Enter tag(s)">
                                            <Option value="" className="manrope f-12 black-55 select-view"></Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            <Col span={24}>
                                <Form.Item colon={false} label={<span className="manrope f-14 black-55">Guidelines/ Comments</span>} name="guidelines" rules={[{ required: false, message: 'Please enter guidelines/comments.' }]}>
                                    <Input disabled={props.saveButtonLoader} placeholder={'Enter guidelines/comments'} className="manrope f-12 black-55 library-search" />
                                </Form.Item>
                            </Col>
                            {props.errorMessage == "" ? '' :
                                <Col span={24} style={{ marginTop: 10 }}>
                                    <div className="justify-space-between err-bg manrope f-12 red-error" style={{ marginRight: 8 }}><img src="/img/error-small.png" style={{ marginRight: 8 }} /><span>{props.errorMessage}</span></div>
                                </Col>}
                            <Button id="save-product-data" type="primary" htmlType="submit" style={{ "display": "none" }}>
                                Submit
                            </Button>
                        </Row>
                    </Form>

                </div>
            </Modal>

            <SuccessModal
                onCancel={() => props.setSiloSuccessModal(false)}
                visible={props.siloSuccessModal}
                closable={true}
                heading={"Silo Images Requested Successfully!"}
                text={"You have successfully requested your Silo Images. You will be notified via email once they are ready."}
                footer={[
                    <div className="justify-in-center">
                        <Button className="modal-okay square font-16" htmlType="submit" onClick={() => {
                            props.setSiloSuccessModal(false);
                            props.openThreeSixtyModal();
                        }}>

                            Create 360 Spin
                        </Button>
                        <Button className="modal-okay square font-16" htmlType="submit" onClick={() => {
                            props.setSiloSuccessModal(false);
                            props.openDimensionalModal();
                        }}>
                            Create Dimensional Image
                        </Button>
                        <Button className="modal-okay square font-16" htmlType="submit" onClick={() => {
                            props.setSiloSuccessModal(false);
                            props.openVideoModal();
                        }}>
                            Request MP4 Video
                        </Button>
                    </div>
                ]}
            />
            <SuccessModal
                visible={props.dimensionalSuccessModal}
                heading={"Dimensional Image Requested Successfully!"}
                closable={true}
                text={"You have successfully requested your new dimensional image. The current image will be replaced as soon as the new one is available. We will notify you once it is ready."}
                onCancel={() => props.setDimensionalSuccessModal(false)}
                footer={[
                    <div className='justify-in-center'>
                        <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                            window.location.href = "/silo-tool/" + props.params.id;
                        }}>
                            Create Silo Images
                        </Button>

                        <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                            props.setDimensionalSuccessModal(false);
                            props.openThreeSixtyModal();
                        }}>
                            Create 360 Spin
                        </Button>
                        <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                            props.setDimensionalSuccessModal(false);
                            props.openVideoModal();
                        }}>
                            Request MP4 Video
                        </Button>
                    </div>
                ]} />

            <SuccessModal
                visible={props.mp4SuccessModal}
                onCancel={() => props.setMp4SuccessModal(false)}
                heading={"MP4 Video Requested Successfully!"}
                closable={true}
                text={"You have successfully requested your new mp4 video. The current mp4 video will be replaced as soon as the new one is available. We will notify you once it is ready."}
                footer={[
                    <div className='justify-in-center'>
                        <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                            window.location.href = "/silo-tool/" + props.params.id;
                        }}>
                            Create Silo Images
                        </Button>

                        <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                            props.setMp4SuccessModal(false);
                            props.openDimensionalModal();
                        }}>
                            Create Dimensional Image
                        </Button>
                        <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                            props.setMp4SuccessModal(false);
                            props.openThreeSixtyModal();
                        }}>
                            Create 360 Spin
                        </Button>
                    </div>
                ]}
            />
            <SuccessModal
                onCancel={() => props.setThreesixtySuccessModal(false)}
                visible={props.threesixtySuccessModal}
                heading={"360 Spin Requested Successfully!"}
                closable={true}
                text={"You have successfully requested your new 360 spin. The current 360 spin will be replaced as soon as the new one is available. We will notify you once it is ready."}
                footer={[
                    <div className='justify-in-center'>
                        <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                            window.location.href = "/silo-tool/" + props.params.id;
                        }}>
                            Create Silo Images
                        </Button>
                        <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                            props.setThreesixtySuccessModal(false);
                            props.openDimensionalModal();
                        }}>
                            Create Dimensional Image
                        </Button>
                        <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                            props.setThreesixtySuccessModal(false);
                            props.openVideoModal();
                        }}>
                            Request MP4 Video
                        </Button>
                    </div>
                ]}
            />

            <ModelVariationRequestModal
                requestType={'variation_product_model'}
                paymentRequestID={props.paymentRequestID}
                requestStatus={props.requestStatus}
                cardStatus={props.cardStatus}
                variationLoader={false}
                variationModal={props.variationModal}
                closeVariationModal={props.closeVariationModal}
                savedID={props.savedID}
                variationStep={props.variationStep}
                variationType={props.variationType}
                onChangeVariationType={props.onChangeVariationType}
                onChangeARVariationType={props.onChangeARVariationType}
                setVariationStep={props.setVariationStep}
                arVariationType={props.arVariationType}
                model_depth={model_depth}
                model_width={model_width}
                model_height={model_height}
                variationSaved={props.variationSaved}
                variationCreated={props.variationCreated}
                product_id={props.params.id}
                product_details={props.savedID == -1 ? props.state : props.product_details}
            />
            <SuccessModal
                visible={props.requestSubmitted}
                onCancel={() => props.setRequestSubmitted(false)}
                heading={"Model Variation Requested Successfully!"}
                text={"Your Product Model's Variation request has been successfully submitted. You will be notified via email once your Product Model is ready to be reviewed."}
                footer={[
                    <div className="justify-in-center">
                        <Button className="modal-okay square font-12" onClick={() => window.location.href = "/products/" + props.params.id + `?folder_id=${props.folderParentID}`}>
                            Okay
                        </Button>
                    </div>
                ]}
            />

            <SuccessModal
                visible={props.requestSaved}
                onCancel={() => props.setRequestSaved(false)}
                heading={"Information Saved Successfully!"}
                text={"You can come back any time by reopening this form or from the incomplete section to fill out the remaining information."}
                footer={[
                    <div className="justify-in-center">
                        <Button className="modal-okay square font-14" onClick={() => window.location.href = "/products/" + props.params.id + `?folder_id=${props.folderParentID}`}>
                            Okay
                        </Button>
                    </div>
                ]}
            />

            <CardSaveDrawer
                setCardSaveFailure={props.setCardSaveFailure}
                saveRequestIntermedietely={props.saveRequestIntermedietely}
                visible={props.cardSaveDrawer}
                onClose={() => props.setCardSaveDrawer(false)}
            />
            <WarningModal
                visible={props.cardSaveFailure}
                onCancel={() => props.setCardSaveFailure(false)}
                heading={"Unable to Save Payment Details"}
                text={"Failed to process payment details. Please try another payment method."}
                footer={[
                    <div className="justify-in-end">
                        <Button key="back" style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => props.setCardSaveFailure(false)}>
                            Okay
                        </Button>
                    </div>
                ]} />
            <Modal
                visible={props.dimensionalDetailsModal}
                onCancel={() => props.setDimensionalDetailsModal(false)}
                className="modal-share"
                width={788}
                footer={[
                    <div className={(props.requestStatus == "payment_required" || props.requestStatus == "not_allowed") ? "justify-space-between" : "justify-in-end"}>
                        {(props.requestStatus == "payment_required" || props.requestStatus == "not_allowed") ?
                            <div className="manrope f-14 black-55">
                                Total Price: <span className="manrope f-14 w-700" style={{ color: "#25D6A4" }}>{"$" + props.dimensionalPrice}</span>
                            </div> : ''}
                        <Button disabled={props.cardSaveFlowTriggered || props.dimensionalLoader} style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => document.getElementById("generate-dimensions-button").click()}>
                            {props.dimensionalLoader ? <>Submitting Request<LoadingOutlined style={{ marginLeft: 6 }} spin /></> :
                                <>
                                    {props.cardStatus == 1 && (props.requestStatus == "payment_required" || props.requestStatus == "not_allowed") ? "Confirm & Process Payment" :
                                        props.cardStatus == 0 && (props.requestStatus == "payment_required" || props.requestStatus == "not_allowed") ? "Confirm & Enter Payment Details" : "Confirm"}
                                </>
                            }
                        </Button>
                    </div>
                ]}>
                <div style={{ padding: "16px 16px 0 16px" }}>
                    <h2 className="manrope f-18 black-14 w-700" style={{ marginBottom: 12 }}>Dimensional Image Configuration</h2>

                    <Form onFinish={props.handleDimensionalSubmit} layout={"vertical"}>
                        <Col span={24}>
                            <Row type='flex' style={{ paddingTop: 12, paddingLeft: 12, paddingRight: 12, paddingBottom: 12 }}>
                                <Col span={24}>
                                    <h1 className="manrope f-14 black-55 w-900" style={{ marginBottom: 0, marginTop: 8 }}>Dimensions</h1>
                                    <Radio.Group onChange={(e) => props.setDimensionalImageType(e.target.value)} value={props.dimensionalImageType} style={{ marginTop: 12, marginBottom: 12, width: '80%' }}>
                                        <Radio style={radioStyle} value={'system'} style={{ marginTop: 6, marginBottom: 6 }}>
                                            <span className="manrope f-14 grey-73">Use System Generated Dimensions</span>
                                        </Radio>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Radio style={radioStyle} value={'custom'} style={{ marginTop: 6, marginBottom: 6 }}>
                                                <span className="manrope f-14 grey-73">Enter Dimensions Manually</span>
                                            </Radio>
                                        </div>
                                    </Radio.Group>
                                    {(props.dimensionalImageType == 'custom') ?
                                        <span>

                                            <div style={{ width: '80%', display: 'flex', justifyContent: "flex-end" }}>
                                                <div style={{ position: "absolute", bottom: 65 }}>
                                                    <Form.Item name="custom_dimensional_unit" initialValue="inches" colon={false}>
                                                        <Select
                                                            className="manrope black-55 tree-select-material f-14"
                                                            defaultValue={'inches'}
                                                        >
                                                            <Option className="manrope f-12 select-view" value="feet">Feet</Option>
                                                            <Option className="manrope f-12 select-view" value="inches">Inches</Option>
                                                            <Option className="manrope f-12 select-view" value="meter">Meter</Option>
                                                            <Option className="manrope f-12 select-view" value="millimeter">millimeter</Option>
                                                            <Option className="manrope f-12 select-view" value="centimeter">centimeter</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div style={{ width: "80%", display: "flex", justifyContent: "space-between", marginTop: 12 }}>

                                                <div style={{ marginRight: 6 }}>
                                                    <Form.Item name="custom_dimensional_height" colon={false} rules={[{ required: true, message: 'Input height' }]}>
                                                        <InputNumber
                                                            style={{ color: "#333333" }}
                                                            className="medium-input-box manrope f-12"
                                                            placeholder="Height"
                                                            min={0}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div style={{ marginRight: 3 }}>
                                                    <Form.Item name="custom_dimensional_width" colon={false} rules={[{ required: true, message: 'Input width' }]}>
                                                        <InputNumber
                                                            className="medium-input-box manrope f-12"
                                                            style={{ color: "#333333" }}
                                                            placeholder="Width"
                                                            min={0}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div style={{ marginLeft: 3 }}>
                                                    <Form.Item name="custom_dimensional_depth" colon={false} rules={[{ required: true, message: 'Input depth' }]}>
                                                        <InputNumber
                                                            style={{ color: "#333333" }}
                                                            className="medium-input-box manrope f-12"
                                                            min={0}
                                                            placeholder="Depth"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                        </span>
                                        : ""}
                                </Col>

                                <Col span={24}>
                                    <Form.Item style={{ marginTop: 24 }} name="custom_dimensional_dpi" label={<span className="manrope f-14 black-55 w-900">DPI</span>} initialValue={props.customerDefaultDimDPI} colon={false}>
                                        <Select
                                            className="manrope black-55 tree-select-material f-12"
                                            defaultValue={props.customerDefaultDimDPI}>
                                            <Option className="manrope f-12 select-view" value="300">300 DPI (default)</Option>
                                            <Option className="manrope f-12 select-view" value="400">400 DPI</Option>
                                            <Option className="manrope f-12 select-view" value="600">600 DPI</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item style={{ marginTop: 24 }} name="custom_dimensional_resolution" label={<span className="manrope f-14 black-55 w-900">Resolution</span>} initialValue={props.customerDefaultDimResolution} colon={false}>
                                        <Select
                                            className="manrope black-55 tree-select-material f-12"
                                            onChange={(value) => props.changeDimRes(value)}
                                            defaultValue={props.customerDefaultDimResolution}>
                                            <Option className="manrope f-12 select-view" value="1k">1K</Option>
                                            <Option className="manrope f-12 select-view" value="2k">2K (Default)</Option>
                                            <Option className="manrope f-12 select-view" value="3k">3K</Option>
                                            <Option className="manrope f-12 select-view" value="4k">4K</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Button id="generate-dimensions-button" type="primary" htmlType="submit" style={{ "display": "none" }}>
                            Submit
                        </Button>
                    </Form>
                </div>
            </Modal>
            <Modal
                visible={props.mp4DetailsModal}
                className="modal-share"
                width={788}
                onCancel={() => props.setMp4DetailsModal(false)}
                footer={[
                    <div className={(props.requestStatus == "payment_required" || props.requestStatus == "not_allowed") ? "justify-space-between" : "justify-in-end"}>
                        {(props.requestStatus == "payment_required" || props.requestStatus == "not_allowed") ?
                            <div className="manrope f-14 black-55">
                                Total Price: <span className="manrope f-14 w-700" style={{ color: "#25D6A4" }}>{"$" + props.mp4Price}</span>
                            </div> : ''}
                        <Button disabled={props.cardSaveFlowTriggered || props.mp4GenerationLoader} style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => document.getElementById("generate-mp4-button").click()}>
                            {props.mp4GenerationLoader ? <>Submitting Request<LoadingOutlined style={{ marginLeft: 6 }} spin /></> :
                                <>
                                    {props.cardStatus == 1 && (props.requestStatus == "payment_required" || props.requestStatus == "not_allowed") ? "Confirm & Process Payment" :
                                        props.cardStatus == 0 && (props.requestStatus == "payment_required" || props.requestStatus == "not_allowed") ? "Confirm & Enter Payment Details" : "Confirm"}
                                </>
                            }

                        </Button>

                    </div>
                ]}>
                <div style={{ padding: "16px 16px 0 16px" }}>
                    <h2 className="manrope f-18 black-14 w-700" style={{ marginBottom: 20 }}>MP4 Video Configuration</h2>
                    {
                        props.mp4Exists ?
                            <div style={{ padding: "10px 16px", marginTop: 0, marginBottom: 30 }} className="note-flag">
                                <span className="manrope f-14 grey-73">
                                    <span className="manrope f-14 grey-73 w-700">Note:</span>&nbsp;Please select a configuration different than before to request another MP4 Video.
                                </span>
                            </div> : ''
                    }
                    <span>
                        <Form onFinish={props.handleMp4Submit} layout="vertical">
                            <Row type='flex' gutter={12}>
                                <Col span={24}>
                                    <Form.Item name="frame_count" className="material-category-form" label={<span className="manrope f-14 black-55 w-900">Video Smoothness</span>} initialValue="72" colon={false}>
                                        <Select
                                            value={props.mp4FrameCount}
                                            onChange={props.handleMp4FrameChange}
                                            className="manrope black-55 tree-select-material f-12"
                                            defaultValue={'72'}
                                        >
                                            <Option value="72" className="manrope f-12 select-view">72 Frames</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24} style={{ marginTop: 24 }}>
                                    <Form.Item name="frame_rate" className="material-category-form" label={<span className="manrope f-14 black-55 w-900">Video Speed</span>} initialValue="10" colon={false}>
                                        <Select
                                            value={props.mp4FpsCount}
                                            onChange={props.handleFpsChange}
                                            className="manrope black-55 tree-select-material f-12"
                                            defaultValue={'10'}
                                        >
                                            <Option value="10" className="manrope f-12 select-view">10 fps (Default)</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24} style={{ marginTop: 24 }}>
                                    <Form.Item name="resolution" className="material-category-form" label={<span className="manrope f-14 black-55 w-900">Video Quality</span>} initialValue="1080" colon={false}>
                                        <Select
                                            value={props.mp4Resolution}
                                            onChange={props.handleMp4ResChange}
                                            className="manrope black-55 tree-select-material f-12"
                                            defaultValue={'1080'}>
                                            <Option value="1080" className="manrope f-12 select-view">1080p (Default)</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Button id="generate-mp4-button" type="primary" htmlType="submit" style={{ "display": "none" }}>
                                    Submit
                                </Button>
                            </Row>
                        </Form>
                    </span>
                </div>
            </Modal>


            <Modal
                visible={props.threesixtyDetailsModal}
                className="modal-share"
                width={788}
                onCancel={() => props.setThreesixtyDetailsModal(false)}
                footer={[
                    <div className={(props.requestStatus == "payment_required" || props.requestStatus == "not_allowed") ? "justify-space-between" : "justify-in-end"}>
                        {(props.requestStatus == "payment_required" || props.requestStatus == "not_allowed") ?
                            <div className="manrope f-14 black-55">
                                Total Price: <span className="manrope f-14 w-700" style={{ color: "#25D6A4" }}>{"$" + props.threeSixtyPrice}</span>
                            </div> : ''}
                        <Button disabled={props.disable360 || props.cardSaveFlowTriggered || props.threesixtyGenerationLoader} style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => document.getElementById("generate-threesixty-button").click()}>
                            {props.threesixtyGenerationLoader ? <>Submitting Request<LoadingOutlined style={{ marginLeft: 6 }} spin /></> :
                                <>
                                    {props.cardStatus == 1 && (props.requestStatus == "payment_required" || props.requestStatus == "not_allowed") ? "Confirm & Process Payment" :
                                        props.cardStatus == 0 && (props.requestStatus == "payment_required" || props.requestStatus == "not_allowed") ? "Confirm & Enter Payment Details" : "Confirm"}
                                </>}
                        </Button>
                    </div>
                ]}>
                <div style={{ padding: "16px 16px 0 16px" }}>
                    <h2 className="manrope f-18 black-14 w-700" style={{ marginBottom: 20 }}>360 Spin Configuration</h2>
                    {
                        props.exist360 && props.disable360 ?
                            <div style={{ padding: "10px 16px", marginTop: 0, marginBottom: 30 }} className="note-flag">
                                <span className="manrope f-12 grey-73">
                                    <span className="manrope f-12 grey-73 w-700">Note:</span>&nbsp;Please select a configuration different than before to request another 360 Spin.
                                </span>
                            </div> : ''
                    }

                    <Form onFinish={props.handleThreesixtySubmit} layout="vertical">
                        <Col span={24}>
                            <Form.Item name="frame_count" className="material-category-form" label={<span className="manrope f-14 black-55 w-900">Frames Count</span>} initialValue="36" colon={false}>
                                <Select
                                    value={props.frame360}
                                    onChange={props.handleFrameChange}
                                    className="manrope black-55 tree-select-material f-12"
                                    defaultValue={'36'}>
                                    <Option value="36" className="manrope f-12 select-view">36 Frames</Option>
                                    <Option value="72" className="manrope f-12 select-view">72 Frames</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginTop: 24 }}>
                            <Form.Item name="frame_resolution" className="material-category-form" label={<span className="manrope f-14 black-55 w-900">Frame Resolution</span>} initialValue="2.0" colon={false}>
                                <Select
                                    value={props.res360}
                                    className="manrope black-55 tree-select-material f-12"
                                    defaultValue={'36'}
                                    onChange={props.handleResChange}>
                                    <Option value="2.0" className="manrope f-12 select-view">2K</Option>
                                    <Option value="4.0" className="manrope f-12 select-view">4K</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Button id="generate-threesixty-button" type="primary" htmlType="submit" style={{ "display": "none" }}>
                            Submit
                        </Button>
                    </Form>

                </div>
            </Modal>
            <Modal
                className='approve-modal'
                closable={false}
                maskClosable={false}
                visible={props.nextActionPrompt}
                onCancel={props.setNextActionPrompt}
                footer={[
                    <div className='justify-in-end'>
                        <Button className='modal-okay square font-12' onClick={props.openGenerateButton}>
                            Continue
                        </Button>
                    </div>
                ]}
                title={<span className='manrope f-14 black-33 w-600'>Model Approved Successfully</span>}>
                <>
                {
                (props.state.variant_of != '' || props.state.variant_of != undefined) 
                && (props.state.variation_type == 'ar_conversion') &&
                (props.productReducer.product.need_to_model == 'ar') ? 
                <p className='manrope f-14 black-55'>   
                    We are getting the AR variant of your model ready. You'll be notified through email once its ready.
                </p> :
                <p className='manrope f-14 black-55' style={{padding: "5px 25px 5px 25px"}}>
                    Your model has been successfully approved. You can now generate additional imagery such as silo images, 360 spins and mp4 videos.
                </p>
                }
                    
                </>
            </Modal>
            <PaymentFailureModal
                paymentFailureMessage={props.paymentFailureMessage}
                setPaymentFailureModal={props.setPaymentFailureModal}
                updatePaymentDetailsOnFailure={props.updatePaymentDetailsOnFailure}
                paymentFailureModal={props.paymentFailureModal}
            />
            <InAppPurchaseLimitModal visible={props.inAppLimitExceededModal} setVisible={props.setInAppLimitExceededModal} />
            <Tour
                steps={generateOption}
                isOpen={props.openGenerateButtonTour}
                className="helper"
                rounded={5}
                accentColor={accentColor}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
                maskClassName="custom-mask"
                disableDotsNavigation={true}
                closeWithMask={false}
                lastStepNextButton={<Button type="primary" className="modal-okay square font-14" onClick={() => props.closeTour()} style={{ position: "absolute", right: 20, bottom: 20 }}>Okay</Button>}
                nextButton={<span></span>}
                prevButton={<span></span>}
                showCloseButton={false}
                showNavigation={false}
                showNavigationNumber={false}
                showNumber={false}
                onRequestClose={() => props.closeTour()}
            /> 
            </>}
        </>
    );
}

export default ProductDetailsView;