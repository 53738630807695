import React, { useContext, useEffect, useState } from 'react';
import _ from "lodash";
import * as Utilities from '../../Utilities';
import axios from 'axios';
import { Modal, Row, Col, Button, Checkbox, Form, Input, message, Upload } from 'antd';
import { CloseOutlined, LoadingOutlined, PaperClipOutlined } from '@ant-design/icons';
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import ENVIRONMENT from '../../../../environments';
import { QA_IMG_URI, getBaseURL } from '../../../../environments/env';
import * as Constants from "../../../App/CustomerComponents/Constants";
import SuccessModal from '../../SuccessModal/SuccessModal';
import ProjectActionContext from '../../ContextFiles/ProjectActionContext';

const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem("company_id");

var siloInput = '';

const FlagSiloModal = (props) => {
    const { product_id, companyId, productAssetData, imageFlagModal, setImageFlagModal,
        singleImage, setSingleImage, selectedImageForFlag, cognito_username, context_type, selectedSiloImage, silos } = useContext(props.context_type == "project" ? ProjectActionContext :
            CustomerProductContext);

    const [selectAll, setSelectAll] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [screenshotImageList, setScreenshotImageList] = useState([]);
    const [checkImageState, setCheckImageState] = useState(false);
    const [checkedImage, setCheckedImage] = useState([]);
    const [reportSuccess, setReportSuccess] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [siloArray, setSiloArray] = useState();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        if (context_type == 'project') {
            setSiloArray(silos);
        } else {
            setSiloArray(productAssetData['silo_data']);
        }
    }, []);
    const selectAllImages = () => {
        if (selectAll == false) {
            if (context_type == 'project') {
                checkedImage[selectedSiloImage.camera_name] = true;
            } else {
                (productAssetData['silo_data'] || []).map((name) => {
                    let imageLink = null
                    imageLink = getBaseURL(productAssetData['platform']) + QA_IMG_URI + product_id + '/' + (companyId == null ? cognito_username : ('company/' + companyId)) + '/' + name.camera_name + '.' + name.image_data.img_format;
                    if (name.image_status != undefined && name.image_status == 'completed' && !name.is_hidden) {
                        checkedImage[name.camera_name] = true;
                    }
                });
            }

            setCheckImageState(true);
            setCheckedImage(checkedImage);
            setSelectAll(true);
        }
        else {
            if (context_type == 'project') {
                checkedImage[selectedSiloImage.camera_name] = false;
            } else {
                (productAssetData['silo_data'] || []).map((name) => {
                    let imageLink = null
                    imageLink = getBaseURL(productAssetData['platform']) + QA_IMG_URI + product_id + '/' + (companyId == null ? cognito_username : ('company/' + companyId)) + '/' + name.camera_name + '.' + name.image_data.img_format;
                    if (name.image_status != undefined && name.image_status == 'completed' && !name.is_hidden) {
                        checkedImage[name.camera_name] = false;
                    }
                });
            }
            setCheckImageState(false);
            setCheckedImage(checkedImage);
            setSelectAll(false);
        }
    }

    const changeSingleImageState = () => {
        checkedImage[selectedImageForFlag.split('/')[6].split('.').slice(0, -1).join('.')] = true;
        setCheckedImage(checkedImage);
        setSingleImage(false);

        let flag = false;
        let countTrue = 0;
        let countFalse = 0;
        let length = 0;
        let silo_array = [];
        if (context_type == 'project') {
            silo_array = silos;
        } else {
            silo_array = productAssetData['silo_data'];
        }
        (silo_array || []).map((u) => {
            if (u.image_status != undefined && u.image_status == 'completed' && !u.is_hidden) {
                length = length + 1;
                if (checkedImage[u.camera_name]) {
                    countTrue = countTrue + 1;
                    flag = true;
                    setCheckImageState(true);
                }
                else if (checkedImage[u.camera_name] == false) {
                    countFalse = countFalse + 1;
                }
            }
        })

        if (countTrue == length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
        if (countFalse == length) {
            setSelectAll(false);
        }

        if (!flag) {
            setCheckImageState(false);
        }

        forceUpdate();
    }


    const onChangeFlagImageCheck = (name) => {

        let render_name = name;
        if (checkedImage[render_name]) {
            checkedImage[render_name] = false;
            setCheckedImage(checkedImage);
        }
        else {
            checkedImage[render_name] = true;
            setCheckedImage(checkedImage);
        }

        let flag = false;
        let countTrue = 0;
        let countFalse = 0;
        let length = 0;
        let silo_array = [];
        if (context_type == 'project') {
            silo_array = silos;
        } else {
            silo_array = productAssetData['silo_data'];
        }
        (silo_array || []).map((u, index) => {
            if (u.image_status != undefined && u.image_status == 'completed' && !u.is_hidden) {
                length = length + 1;
                if (checkedImage[u.camera_name]) {
                    countTrue = countTrue + 1;
                    flag = true;
                    setCheckImageState(true);
                }
                else if (checkedImage[u.camera_name] == false) {
                    countFalse = countFalse + 1;
                }
            }
        })

        if (countTrue == length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
        if (countFalse == length) {
            setSelectAll(false);
        }

        if (!flag) {
            setCheckImageState(false);
        }

        forceUpdate();
    }


    const handleImageReport = (values) => {
        let payload = {};
        setButtonLoader(true);
        let image_upload_status = Utilities.validateFileUploads(values["upload_image_screenshots"]);
        let image_flag = '';
        if (selectedImageForFlag) {
            let split_image = selectedImageForFlag.split('/');
            image_flag = selectedImageForFlag.split('/')[split_image.length - 1];
        }
        if (singleImage) {
            payload = {
                complaint_against: "Product",
                entity_id: product_id,
                issue: values.image_flag_reason,
                attachment: image_flag,
                reference_images: values.upload_image_screenshots,
                status: "pending",
                username: cognito_username
            }
        }
        else {
            let selected_images = [];
            let silo_array = [];
            if (context_type == 'project') {
                silo_array = silos;
            } else {
                silo_array = productAssetData['silo_data'];
            }
            (silo_array || []).map((name) => {
                if (name.image_status != undefined && name.image_status == 'completed' && !name.is_hidden) {
                    if (checkedImage[name.camera_name]) {
                        selected_images.push(name.camera_name);
                    }
                }
            });
            payload = {
                complaint_against: "Product",
                entity_id: product_id,
                issue: values.image_flag_reason,
                attachment: selected_images,
                reference_images: values.upload_image_screenshots,
                status: "pending",
                username: cognito_username
            }
        }

        if (image_upload_status == "uploading") {
            setButtonLoader(false);
            message.info('Uploads are in progress, please wait till file uploads are complete.');
        }
        else if (image_upload_status == "error") {
            setButtonLoader(false);
            message.error('Error occured in uploading file(s), please re-upload your file(s).');

        }
        else if (image_upload_status == "done" || image_upload_status == "not_started") {
            axios.post(ENVIRONMENT.FLAG_IF_UNHAPPY, payload)
                .then((res) => {
                    siloInput = '';
                    setImageFlagModal(false);
                    setSingleImage(false);
                    setButtonLoader(false);
                    setScreenshotImageList([]);
                    setCheckImageState(false);
                    setCheckedImage([]);
                    setReportSuccess(true);
                })
                .catch((error) => {
                    message.error('Error in sending your request to the admin.');
                    setButtonLoader(false);
                });
        }

    }

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const changeSiloInput = (e) => {
        siloInput = e.target.value;
        forceUpdate();
    }

    const handleImageScreenshots = info => {
        let fileList = [...info.fileList];
        setScreenshotImageList(fileList);

        setUploading(true);
        if (info.file.status === "done") {
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            } else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            message.error(`${info.file.name} file upload failed.`);
            setUploading(false);

        }
    };

    const cancelReportImageIssue = () => {
        siloInput = '';
        setImageFlagModal(false);
        setSingleImage(false);
        setScreenshotImageList([]);
        setCheckImageState(false);
        setCheckedImage([]);
    }
    return (
        <>
        <Modal
            destroyOnClose={true}
            maskClosable={false}
            visible={imageFlagModal}
            onCancel={cancelReportImageIssue}
            width={750}
            className="modal-flag">
            <div style={{ padding: "0" }}>
                <h2 className="share-modal-heading greyish-black"><span>Report an Issue</span><span style={{ color: "#999999", fontWeight: 500, cursor: "pointer" }} onClick={cancelReportImageIssue}><CloseOutlined/></span></h2>
                {context_type == 'project' ? "" :
                (singleImage ?
                    <h3 className="flag-text" style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>Selected Silo Image</span>
                        <span onClick={changeSingleImageState} style={{ color: "#276DD7", cursor: "pointer" }}>Select More Images</span>
                    </h3> :
                    <h3 className="flag-text" style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>Select the images you would like to flag</span>
                        <span onClick={selectAllImages} style={{ color: "#276DD7", cursor: "pointer" }}>{selectAll == false ? `Select All` : `Deselect All`}</span>
                    </h3>)
                }

                <Form onFinish={handleImageReport}>
                    <div className={singleImage ? "" : "request-silo-scrollable flag-height"}>
                        <Row gutter={16}>
                            {singleImage ?
                                <Col span={24} className="flag-col-margin single">
                                    <img src={selectedImageForFlag}
                                        className="flag-image-thumbnail-single product" />
                                </Col>
                                : <div style={{ display: "contents" }}>
                                    {(siloArray || []).map((u, index) => (
                                        <span>
                                            {(u.image_status != undefined && u.image_status == 'completed' && !u.is_hidden) ?
                                                <Col span={8} className="flag-col-margin">
                                                    <span className="flag-checkbox-settings" style={{ zIndex: "10" }}>
                                                        <Checkbox className="black-checkbox" onChange={() => onChangeFlagImageCheck(u.camera_name)} checked={checkedImage[u.camera_name]} />
                                                    </span>
                                                    <img src={(getBaseURL(props.context_type == 'project' ? u.platform : productAssetData['platform']) + QA_IMG_URI + product_id + '/' + (companyId == null ? cognito_username : ('company/' + companyId)) + '/' + u.camera_name + '.' + u.image_data.img_format)}
                                                        className="flag-image-thumbnail" />
                                                </Col>
                                                : ""}
                                        </span>
                                    ))}
                                </div>}
                        </Row>
                    </div>
                    <span className="flag-form" style={{ marginTop: 22, display: (checkImageState || singleImage) ? "flex" : "none" }}>
                        <Form.Item name="image_flag_reason" label="I am Flagging Because..." className="share-modal-input" style={{ display: "inline-block", width: 330, margin: 0 }} colon={false}>
                            <Input.TextArea
                                autoSize={{
                                    maxRows: 4,
                                    minRows: 4
                                }}
                                onChange={changeSiloInput} rows={4} placeholder="Describe the issue, example lighting etc." />
                        </Form.Item>
                        <Form.Item
                            className="share-modal-input"
                            style={{ display: "inline-block", width: 330, margin: 0 }}
                            name="upload_image_screenshots"
                            colon={false}
                            label="Additional Information"
                            valuePropName="image_filelist"
                            getValueFromEvent={normFile}>
                            <Upload {...Constants.upload_props}
                                onRemove={file => {
                                    message.success(`${file.name} removed successfully!`)
                                    const index = screenshotImageList.indexOf(file);
                                    const newFileList = screenshotImageList.slice();
                                    newFileList.splice(index, 1);
                                    setScreenshotImageList(newFileList);

                                    return true;
                                }}
                                accept="application/msword,.zip,.rar,image/jpeg,image/tiff,image/gif,image/svg,image/png,application/pdf"
                                progress={{
                                    strokeColor: {
                                        '0%': '#108ee9',
                                        '100%': '#87d068',
                                    },
                                    strokeWidth: 3,
                                    format: percent => `${parseFloat(percent.toFixed(2))}%`,
                                }}
                                openFileDialogOnClick={!(Utilities.validateFileUploads(screenshotImageList) == 'error')}
                                fileList={screenshotImageList}
                                onChange={handleImageScreenshots}
                                className="material-upload-box scroll-upload"
                                name="logo" multiple={true} listType="text">
                                <span>
                                    <Button className="material-photos-btn flag">
                                        <span className="material-photos-btn-txt">Attach files or screenshots here <PaperClipOutlined /></span>
                                    </Button>
                                    {(Utilities.validateFileUploads(screenshotImageList) == 'error') && (<Button
                                        onClick={() => Constants.triggerFailedFilesUpload('upload_image_screenshots', screenshotImageList, setScreenshotImageList)}
                                        disabled={screenshotImageList.length === 0}
                                        loading={uploading}
                                        className="retry-btn flag" ghost
                                    >
                                        {uploading ? <span>Retrying...</span> : 'Upload Failed. Click to Retry.'}
                                    </Button>)}
                                </span>
                            </Upload>
                        </Form.Item>
                    </span>
                    {(Utilities.validateFileUploads(screenshotImageList) == 'error') ?
                        <div className='retry-error-message'>{errorMessage}</div>
                        : ''}
                    <div style={{ padding: "10px 16px", marginBottom: 2, marginTop: singleImage ? 18 : 18 }} className="note-flag">
                        <span className="scene-note flag">
                            Note: <div style={{ color: "#333333" }}>Your issue will be reported to All3D admin. We will reach out to you via email. </div>
                        </span>
                    </div>
                    <Form.Item style={{ textAlign: "right", marginTop: 32 }}>
                        <Button className="modal-okay square" htmlType="submit" disabled={((checkImageState == false && singleImage == false) || siloInput == '')}>
                            <div className="modal-okay-text">Send {buttonLoader ? <LoadingOutlined /> : ""}</div>
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
        <SuccessModal
        visible={reportSuccess}
        heading={"Issue Reported Successfully!"}
        text={"Your issue has been reported to the admin successfully. We will get back to you as soon as possible."}
        footer={[
           <div className="justify-in-center">
               <Button style={{margin:"auto"}} className="modal-okay square font-14" htmlType="submit" onClick={() => {
                   window.location.reload();}}>
                   Okay
               </Button>
           </div>]}
        />
        </>
    )
}

export default FlagSiloModal;