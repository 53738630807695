import React, { useState, useEffect, useCallback } from 'react';
import {
    DownloadOutlined,
    DownOutlined,
    RightOutlined,
    PaperClipOutlined,
    LoadingOutlined,
    IssuesCloseOutlined,
    DownCircleOutlined,
    UpCircleOutlined
} from '@ant-design/icons';
import AWS from 'aws-sdk';
import {
    Menu,
    Dropdown,
    Card,
    Popover,
    Input,
    Form,
    Checkbox,
    Upload,
    Row, Col, Modal,
    message, Tooltip, Button
} from 'antd';
import axios from 'axios';
import ProductGoogleViewer from '../CustomerComponents/ProductGoogleViewer'
import ImageZoom from "../CustomerComponents/ImageZoom";
import ImageZoomAdmin from "../CustomerComponents/ImageZoomAdmin"
import DottedLoader from '../DottedLoader';
import ReactPlayer from 'react-player';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import * as Constants from "../../App/CustomerComponents/Constants";
import ENVIRONMENT from '../../../environments'
import { file } from 'jszip';
import ImageMagnify from '../CustomerComponents/ImageMagnify/ImageMagnify';
import { BUCKET_NAME, getBaseURL, IMAGE_DIMENSIONAL_URI } from '../../../environments/env';
import FileConstants from '../../../FileConstants';

const QA_IMG_URI = ENVIRONMENT.QA_IMG_URI
const MODEL_VIEWER_KEY = 'JKQ76HB9AB4HLNH8FK4'
const google_viewer_style = { height: "calc(100vh - 200px)", width: "100%" };
const google_viewer_style_fullscreen = { height: "100vh", width: "100%" };
const image_style = { width: '100%', height: "calc(100vh -  282px)", objectFit: "contain" };
const fullscreen_image_style = { width: "100vw", height: "100vh", objectFit: "contain" };
var siloInput = '';
var isScrolling = '';
function saveToZip(filename, urls) {
    let loader = message.loading('Preparing to download..', 0)

    const zip = new JSZip()
    urls.forEach((url) => {
        const blobPromise = fetch(url, { cache: 'no-store' }).then(r => {
            if (r.status === 200) return r.blob()
            return Promise.reject(new Error(r.statusText))
        })
        const name = url.split('/').slice(-1)[0]
        zip.file(name, blobPromise)
    })

    zip.generateAsync({ type: "blob" })
        .then((blob) => {
            saveAs(blob, filename);
            setTimeout(loader);

        })
        .catch((e) => {
            console.log(e);
        });
}

const ProductDetailsComponent = (props) => {
    const { match: { params } } = props;
    const [viewType, setViewType] = useState('2D');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [image, setImage] = useState(null);
    const [imageFlagModal, setImageFlagModal] = useState(false);
    const [singleImage, setSingleImage] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [screenshotImageList, setScreenshotImageList] = useState([]);
    const [checkImageState, setCheckImageState] = useState(false);
    const [checkedImage, setCheckedImage] = useState([]);
    const [previewImage, setPreviewImage] = useState(true);
    const [reportSuccess, setReportSuccess] = useState(false);
    const [, updateState] = React.useState();
    const [borderState, setBorderState] = useState(false);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const [aspectRatio, setAspectRatio] = useState(1);
    const [tempArray, setTempArray] = useState([]);
    const [siloZoomCheck, setSiloZoomCheck] = useState(false);
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [uploading, setUploading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [displayLoader, setDisplayLoader] = useState(false);
    const [collapseViewer, setCollapseViewer] = useState(false);
    const [paramType, setParamType] = useState(new URLSearchParams(window.location.search).get("type"));
    const [showNonARModal, setShowNonARModal] = useState(false);
    const [imageSelected, setImageSelected] = useState('')

    const handleViewChange = (e) => {
        setTimeout(() => {
            window.CI360.destroy();
            window.CI360.init();
        }, 10);
        changeViewType(e);
    }

    const changeViewType = (type) => {
        if (type == '3D'){
            let viewer = document.getElementById('model-viewer-3d').contentWindow;
            let message = {
                key : MODEL_VIEWER_KEY,
                glbURL : getGLBUrl(),
                metadata: getMetadata(),
                debugMode : props.embed ? false : true
            }
            viewer.postMessage(message, "*");
            if (document.getElementById('model-inspector-frame') != undefined){
                document.getElementById('model-inspector-frame').style.display = 'block'
            }
        }
        else{
            if (document.getElementById('model-inspector-frame') != undefined){
                document.getElementById('model-inspector-frame').style.display = 'none'
            }
        }
        setViewType(type)

    }

    const getRigName = (image_url) => {
        let rig_name = '';
        if (props.productReducer.product && props.productReducer.product.rig_association) {
            if (image_url in props.productReducer.product.rig_association) {
                rig_name = props.productReducer.product.rig_association[image_url];
                rig_name = ' - ' + rig_name;  // adding a dash before the rig name
            }
        }
        return rig_name;
    }

    const cancelReportImageIssue = () => {
        siloInput = '';
        setImageFlagModal(false);
        setSingleImage(false);
        setScreenshotImageList([]);
        setCheckImageState(false);
        setCheckedImage([]);
    }

    const getImageResolutions = () => {
        let silo_array = [];
        (props.productReducer.product['silo_data'] || []).map((item, index) => {
            silo_array[item.camera_name] = false;
            if (item.image_data.width < 1800 || item.image_data.height < 1800) {
                silo_array[item.camera_name] = true;
            }
        });
        setTempArray(silo_array);
    }

    const resize360Spin = () => {
        let element = document.getElementById('container-360');
        if (imageWidth == 0) {
            const img = new Image();
            img.onload = function () {
                let aspect_ratio = this.height / this.width;
                setAspectRatio(aspect_ratio);
                setImageWidth(this.width);
                setImageHeight(this.height);
                set360WidthStyle(element, aspect_ratio);
            }
            img.src = (props.productReducer.product['360'] && props.productReducer.product['360']["data-folder"]) + props.productReducer.product.product_id + '_Frame1.jpg';
        }
        if (imageWidth != 0) {
            set360WidthStyle(element);
        }
    }

    const set360WidthStyle = (element, aspect_ratio = null) => {
        let aspect = aspectRatio;
        if (aspect_ratio != null) {
            aspect = aspect_ratio;
        }
        if (element != null) {
            element.style.visibility = "hidden";
            if (isFullScreen || props.embed) {
                element.style.width = ((document.documentElement.clientHeight - 10) / aspect) + "px";
            }
            else {
                element.style.width = ((document.documentElement.clientHeight - 210) / aspect) + "px";
            }
            element.style.visibility = "visible";
            handleViewChange('360D');
            forceUpdate();
        }
    }

    useEffect(() => {
        resize360Spin();
    }, [viewType, document.getElementById('container-360'), isFullScreen, props.productReducer.product['360']]);

    useEffect(() => {
        getImageResolutions();
    }, [props.productReducer.product['silo_data']]);

    const validateFileUploads = (files) => {
        let status_done = false;
        if (files != undefined) {
            for (var file of files) {
                if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                else if (file['status'] == 'error') {
                    return 'error';
                }
                else if (file['status'] == 'done') {
                    status_done = true;
                }
            }
        }
        if (status_done) {
            return 'done'
        }
        return 'not_started';
    }

    const openSingleFlagImage = () => {
        setSingleImage(true);
        setSelectedImage(image || props.productReducer.product['2d'] && props.productReducer.product['2d'][0]);
        setImageFlagModal(true);
    }

    const handleImageReport = (values) => {
        let payload = {};
        setButtonLoader(true);
        let image_upload_status = validateFileUploads(values["upload_image_screenshots"]);
        let image_flag = '';
        if (selectedImage) {
            let split_image = selectedImage.split('/');
            image_flag = selectedImage.split('/')[split_image.length - 1];
        }
        if (singleImage) {
            payload = {
                complaint_against: "Product",
                entity_id: params.id,
                issue: values.image_flag_reason,
                attachment: image_flag,
                reference_images: values.upload_image_screenshots,
                status: "pending",
                username: props.cognito.user.username
            }
        }
        else {
            let selected_images = [];
            //-------------- TO BE USED LATER ------------
            // (props.productReducer.product['2d'] || []).map((name, index) => {
            //     if (checkedImage[name.split('/')[5].split('.').slice(0, -1).join('.')]) {
            //         selected_images.push(name.split('/')[5].split('.').slice(0, -1).join('.'));
            //     }
            // });
            (props.productReducer.product['silo_data'] || []).map((name, index) => {
                if (name.image_status != undefined && name.image_status == 'completed') {
                    if (checkedImage[name.camera_name]) {
                        selected_images.push(name.camera_name);
                    }
                }
            });
            payload = {
                complaint_against: "Product",
                entity_id: params.id,
                issue: values.image_flag_reason,
                attachment: selected_images,
                reference_images: values.upload_image_screenshots,
                status: "pending",
                username: props.cognito.user.username
            }
        }

        if (image_upload_status == "uploading") {
            setButtonLoader(false);
            message.info('Uploads are in progress, please wait till file uploads are complete.');
        }
        else if (image_upload_status == "error") {
            setButtonLoader(false);
            message.error('Error occured in uploading file(s), please re-upload your file(s).');

        }
        else if (image_upload_status == "done" || image_upload_status == "not_started") {
            axios.post(ENVIRONMENT.FLAG_IF_UNHAPPY, payload)
                .then((res) => {
                    siloInput = '';
                    setImageFlagModal(false);
                    setSingleImage(false);
                    setButtonLoader(false);
                    setScreenshotImageList([]);
                    setCheckImageState(false);
                    setCheckedImage([]);
                    setReportSuccess(true);
                })
                .catch((error) => {
                    message.error('Error in sending your request to the admin.');
                    setButtonLoader(false);
                });
        }

    }

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const changeSiloInput = (e) => {
        siloInput = e.target.value;
        forceUpdate();
    }

    const handleImageScreenshots = info => {
        let fileList = [...info.fileList];
        setScreenshotImageList(fileList);

        setUploading(true);
        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            } else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            message.error(`${info.file.name} file upload failed.`);
            setHasError(true);
            setUploading(false);

        }
    };
    const selectAllImages = () => {
        if (selectAll == false) {
            //TO BE USED LATER
            // (props.productReducer.product['2d'] || []).map((name, index) => {
            //     checkedImage[name.split('/')[5].split('.').slice(0, -1).join('.')] = true;
            // });
            (props.productReducer.product['silo_data'] || []).map((name, index) => {
                let imageLink = null
                if (props.embed == false) {
                    imageLink = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + params.id + '/' + (props.companyId == null ? props.cognito.user.username : ('company/' + props.companyId)) + '/' + name.camera_name + '.' + name.image_data.img_format;
                } else {
                    imageLink = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + params.id + '/' + (props.publicViewerUser == undefined ? name.camera_name : (props.publicViewerUser + '/' + name.camera_name)) + '.' + name.image_data.img_format;
                }
                if (name.image_status != undefined && name.image_status == 'completed') {
                    checkedImage[name.camera_name] = true;
                }
            });

            setCheckImageState(true);
            setCheckedImage(checkedImage);
            setSelectAll(true);
        }
        else {
            //TO BE USED LATER
            // (props.productReducer.product['2d'] || []).map((name, index) => {
            //     checkedImage[name.split('/')[5].split('.').slice(0, -1).join('.')] = false;
            // });
            (props.productReducer.product['silo_data'] || []).map((name, index) => {
                let imageLink = null
                if (props.embed == false) {
                    imageLink = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + params.id + '/' + (props.companyId == null ? props.cognito.user.username : ('company/' + props.companyId)) + '/' + name.camera_name + '.' + name.image_data.img_format;
                } else {
                    imageLink = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + params.id + '/' + (props.publicViewerUser == undefined ? name.camera_name : (props.publicViewerUser + '/' + name.camera_name)) + '.' + name.image_data.img_format;
                }
                if (name.image_status != undefined && name.image_status == 'completed') {
                    checkedImage[name.camera_name] = false;
                }
            });
            setCheckImageState(false);
            setCheckedImage(checkedImage);
            setSelectAll(false);
        }
    }

    const changeSingleImageState = () => {
        checkedImage[selectedImage.split('/')[6].split('.').slice(0, -1).join('.')] = true;
        setCheckedImage(checkedImage);
        setSingleImage(false);

        let flag = false;
        let countTrue = 0;
        let countFalse = 0;
        let length = 0;

        //------------- TO BE USED LATER -----------------
        // (props.productReducer.product['2d'] || []).map((x, index) => {
        //     length = length + 1;
        //     if (checkedImage[x.split('/')[5].split('.').slice(0, -1).join('.')]) {
        //         countTrue = countTrue + 1;
        //         flag = true;
        //         setCheckImageState(true);
        //     }
        //     else if (checkedImage[x.split('/')[5].split('.').slice(0, -1).join('.')] == false) {
        //         countFalse = countFalse + 1;
        //     }
        // });

        (props.productReducer.product['silo_data'] || []).map((u, index) => {
            if (u.image_status != undefined && u.image_status == 'completed') {
                length = length + 1;
                if (checkedImage[u.camera_name]) {
                    countTrue = countTrue + 1;
                    flag = true;
                    setCheckImageState(true);
                }
                else if (checkedImage[u.camera_name] == false) {
                    countFalse = countFalse + 1;
                }
            }
        })

        if (countTrue == length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
        if (countFalse == length) {
            setSelectAll(false);
        }

        if (!flag) {
            setCheckImageState(false);
        }

        forceUpdate();
    }

    const setFullScreen = () => {
        if (!isFullScreen) {
            message.info("Press ESC to exit full screen");
        }
        setIsFullScreen(!isFullScreen);
    }

    const onChangeFlagImageCheck = (name) => {

        let render_name = name;
        if (checkedImage[render_name]) {
            checkedImage[render_name] = false;
            setCheckedImage(checkedImage);
        }
        else {
            checkedImage[render_name] = true;
            setCheckedImage(checkedImage);
        }

        let flag = false;
        let countTrue = 0;
        let countFalse = 0;
        let length = 0;

        //--------------- TO BE USED LATER ----------------
        // (props.productReducer.product['2d'] || []).map((x, index) => {
        //     length = length + 1;
        //     if (checkedImage[x.split('/')[5].split('.').slice(0, -1).join('.')]) {
        //         countTrue = countTrue + 1;
        //         flag = true;
        //         setCheckImageState(true);
        //     }
        //     else if (checkedImage[x.split('/')[5].split('.').slice(0, -1).join('.')] == false) {
        //         countFalse = countFalse + 1;
        //     }
        // });

        (props.productReducer.product['silo_data'] || []).map((u, index) => {
            if (u.image_status != undefined && u.image_status == 'completed') {
                length = length + 1;
                if (checkedImage[u.camera_name]) {
                    countTrue = countTrue + 1;
                    flag = true;
                    setCheckImageState(true);
                }
                else if (checkedImage[u.camera_name] == false) {
                    countFalse = countFalse + 1;
                }
            }
        })

        if (countTrue == length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
        if (countFalse == length) {
            setSelectAll(false);
        }

        if (!flag) {
            setCheckImageState(false);
        }

        forceUpdate();
    }

    const display_loader_and_set_image = (image_src) => {
        image_src = image_src.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product['platform']))
        const img = new Image();
        img.onload = function () {
            setDisplayLoader(false);
            setImage(this.src);
            setSelectedImage(this.src || props.productReducer.product['2d'] && props.productReducer.product['2d'][0]);
            forceUpdate();
        }
        img.src = image_src;
    }

    const view2DImage = (image) => {
        changeViewType('2D');
        let temp_img = new Image();
        temp_img.src = require("../../../assets/images/blank.jpeg");
        // Set blank image first
        setImage(temp_img.src)
        setSelectedImage(temp_img.src)
        setDisplayLoader(true);
        forceUpdate();
        display_loader_and_set_image(image);
    }

    useEffect(() => {

        if (props.productReducer.product['2d'] && props.productReducer.product['2d'][0]) {
            setSiloZoomCheck(false);
            setImage(props.productReducer.product['2d'] && props.productReducer.product['2d'][0]);
        }
        if (props.productReducer.product['silo_data'] && props.productReducer.product['silo_data'][0]) {
            if (props.productReducer.product['silo_data'][0].image_status != undefined && props.productReducer.product['silo_data'][0].image_status == 'completed') {

                if (paramType == 'silo') {
                    let camera = new URLSearchParams(window.location.search).get("camera");
                    (props.productReducer.product['silo_data'] || []).map((item, index) => {
                        if (camera.includes(item.camera_name)) {
                            setSiloZoomCheck(props.productReducer.product['silo_data'].camera_name);
                            setImage(getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + props.productReducer.product.product_id + '/' + (props.companyId == null ? props.cognito.user.username : ('company/' + props.companyId)) + '/' + item.camera_name + '.' + item.image_data.img_format);
                        }

                    });
                } else {
                    setSiloZoomCheck(props.productReducer.product['silo_data'][0].camera_name);
                    if (props.embed == false) {
                        setImage(getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + props.productReducer.product.product_id + '/' + (props.companyId == null ? props.cognito.user.username : ('company/' + props.companyId)) + '/' + props.productReducer.product['silo_data'][0].camera_name + '.' + props.productReducer.product['silo_data'][0].image_data.img_format);
                    } else {
                        setImage(getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + props.productReducer.product.product_id + '/' + (props.publicViewerUser == undefined ? '' : (props.publicViewerUser + '/')) + props.productReducer.product['silo_data'][0].camera_name + '.' + props.productReducer.product['silo_data'][0].image_data.img_format);
                    }
                }

                setPreviewImage(false);
            }
            else if (props.productReducer.product['silo_data'][0].image_status != undefined && (props.productReducer.product['silo_data'][0].image_status == 'pending' || props.productReducer.product['silo_data'][0].image_status == 'requested')) {
                setSiloZoomCheck(false);
                if (props.embed == false) {
                    viewInprogressRender(getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + props.productReducer.product.product_id + '/' + (props.companyId == null ? props.cognito.user.username : ('company/' + props.companyId)) + '/' + props.productReducer.product['silo_data'][0].camera_name + '_preview' + '.jpg');
                } else {
                    viewInprogressRender(getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + props.productReducer.product.product_id + '/' + (props.publicViewerUser == undefined ? '' : (props.publicViewerUser + '/')) + props.productReducer.product['silo_data'][0].camera_name + '_preview' + '.jpg');
                }
            }
        }
    }, [props.productReducer.product['silo_data'], props.productReducer.product['2d'] && props.productReducer.product['2d'][0]]);

    const viewInprogressRender = (image) => {
        changeViewType('render_in_progress');
        setImage(image);
        setPreviewImage(true);
    }

    const view360Player = () => {
        resize360Spin();
        handleViewChange('360D');
        resize360Spin();
        setPreviewImage(true);
    }

    const getGLBUrl = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name == 'GLB');
        if (link) {
            return link.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, getBaseURL(props.productReducer.product['platform']))
        } else {
            return ''
        }
    }

    const getMetadata = () => {
        if (props.productReducer.product["metadata"] && !Array.isArray(props.productReducer.product.metadata)) {
            return JSON.parse(props.productReducer.product['metadata']);
        }
        return null;
    }

    const getUSDZUrl = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name == 'USDZ');
        if (link) {
            let shortened_link = '';
            if(props.productReducer.product['platform'] == 'aws'){
                shortened_link = window.location.origin + '/usdz/' + params.id;
            }else{
                shortened_link = window.location.origin + '/usdz/g/' + params.id;
            }
            return shortened_link;
        } else {
            return ''
        }
    }

    const getAndroidZUrl = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name == 'GLB');

        if (link) {
            let shortened_link = window.location.origin + '/android/' + params.id;
            return shortened_link.replace(ENVIRONMENT.DEFAULT_BASE_URL, getBaseURL(props.productReducer.product['platform']))
        } else {
            return ''
        }
    }

    const getVideoUrl = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name == 'VIDEO');
        if (link) {
            return link.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, getBaseURL(props.productReducer.product['platform']))
        } else {
            return ''
        }
    }

    const download360Frames = () => {
        let downloadData = [];
        if (props.productReducer.product != undefined) {
            if (props.productReducer.product['360'] != undefined) {
                let length360 = props.productReducer.product['360']['data-amount']
                let i = 1
                for (i = 1; i <= length360; i++) {
                    downloadData.push(props.productReducer.product['360']['data-folder'] + params.id + '_Frame' + i + '.jpg');
                }


            }

            saveToZip(params.id + '_360 Frames.zip', downloadData);


        }
    }

    const downloadImagesArchive = () => {
        let downloadData = [];
        if (props.productReducer.product != undefined) {
            if (props.productReducer.product['2d'] != undefined) {
                if (props.productReducer.product['2d'].length > 0) {
                    props.productReducer.product['2d'].map((url) => {
                        url = url.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product['platform']))
                        console.log(url)
                        downloadData.push(url);
                    });
                }
            }
            if (props.productReducer.product['silo_data'] != undefined) {
                if (props.productReducer.product['silo_data'].length > 0) {
                    props.productReducer.product['silo_data'].map((url) => {
                        if (props.embed == false) {
                            downloadData.push(getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + props.productReducer.product.product_id + '/' + (props.companyId == null ? props.cognito.user.username : ('company/' + props.companyId)) + '/' + url.camera_name + "." + url.image_data.img_format);
                        } else {
                            downloadData.push(getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + props.productReducer.product.product_id + '/' + (props.publicViewerUser == undefined ? url.camera_name : (props.publicViewerUser + '/' + url.camera_name)) + "." + url.image_data.img_format);
                        }
                    });
                }
            }
            saveToZip(params.id + '_Product Images.zip', downloadData);
        }
    }

    const downloadCryptoImagesArchive = () => {
        if (props.cryptoMatteExists) {
            let downloadData = [];
            if (props.productReducer.product != undefined) {
                if (props.productReducer.product['2d'] != undefined) {
                    if (props.productReducer.product['2d'].length > 0) {
                        props.productReducer.product['2d'].map((url) => {
                            let splitted_url = url.split('/')
                            let last_token = splitted_url[splitted_url.length - 1];
                            let image_name = last_token.split('.');
                            let image_name_w_format = image_name[0] + '_cMatte.jpg';
                            let new_url = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + image_name_w_format;

                            if (!image_name[0].includes('_Debug') && !image_name[0].includes('_Dimensions')) {
                                downloadData.push(new_url);
                            }
                            if (image_name[0].includes('_Dimensions') && props.dimensionCrypto) {
                                new_url = getBaseURL(props.productReducer.product['platform']) + IMAGE_DIMENSIONAL_URI + image_name_w_format;
                                downloadData.push(new_url);
                            }

                        });
                    }
                }
                if (props.productReducer.product['silo_data'] != undefined) {
                    if (props.productReducer.product['silo_data'].length > 0) {
                        props.productReducer.product['silo_data'].map((url) => {
                            if (props.embed == false) {
                                let url = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + props.productReducer.product.product_id + '/' + (props.companyId == null ? props.cognito.user.username : ('company/' + props.companyId)) + '/' + url.camera_name + "_cMatte.jpg"
                                downloadData.push(url);
                            } else {
                                let url = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + props.productReducer.product.product_id + '/' + (props.publicViewerUser == undefined ? url.camera_name : (props.publicViewerUser + '/' + url.camera_name)) + + "_cMatte.jpg"
                                downloadData.push(url);
                            }
                        });
                    }
                }
                saveToZip(params.id + '_Cryptomatte.zip', downloadData);

            }
        } else {
            message.error("Cryptomatte for this product aren't available.");

        }
    }

    const menu = (

        <Menu style={{ width: "190px" }}>
            <Menu.Item key={0}>
                <a className='manrope f-12' target="_blank" onClick={() => downloadImagesArchive()}> Download Images <DownloadOutlined style={{ float: "right" }} /></a>
            </Menu.Item>
            <Menu.Item key={1}>
                <a className='manrope f-12' target="_blank" onClick={() => downloadCryptoImagesArchive()}> Download Cryptomatte <DownloadOutlined style={{ float: "right" }} /></a>
            </Menu.Item>
            {props.status360 == "completed" ? <Menu.Item key={2}>
                <a className='manrope f-12' target="_blank" onClick={() => download360Frames()}> Download 360 Frames <DownloadOutlined style={{ float: "right" }} /></a>
            </Menu.Item> : ''}
            {(props.productReducer.product.download_links || []).map((ele, index) => {
                return (
                    <Menu.Item key={index}>
                        <a className='manrope f-12' target="_blank" href={ele.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, getBaseURL(props.productReducer.product['platform']))} >{ele.display_name} Download Link <DownloadOutlined style={{ float: "right" }} /></a>
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setIsFullScreen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    useEffect(() => {
        window.CI360.destroy();
        window.CI360.init();
    }, [isFullScreen]);

    useEffect(() => {
        forceUpdate();
    }, [checkedImage]);

    const leftScroll = () => {
        var elem = document.getElementById('pdp-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            elem.scrollLeft -= 273;
            var left = document.getElementById('left-button-scr');
            var right = document.getElementById('right-button-scr');
            if (left != null && elem.scrollLeft == 0) {
                left.style.display = "none";
            }

            if (right != null) {
                right.style.visibility = 'visible';
                elem.style.left = "20px";
                // forceUpdate();
            }
        }
    }

    const rightScroll = () => {
        var elem = document.getElementById('pdp-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            elem.scrollLeft += 273;
            var left = document.getElementById('left-button-scr');
            var right = document.getElementById('right-button-scr');
            elem.style.left = "94px";
            if (right != null) {
                if (left != null) {
                    left.style.display = "inline-block";
                }

                var newScrollLeft = elem.scrollLeft;
                var divWidth = elem.offsetWidth;
                var scrollwidth = elem.scrollWidth;
                if (newScrollLeft + 2 >= scrollwidth - divWidth) {
                    right.style.visibility = 'hidden';
                }
            }

        }
    }

    const hideViewer = () => {
        setCollapseViewer(true);
    }
    const showViewer = () => {
        setCollapseViewer(false);
    }

    const checkScroll = () => {
        var elem = document.getElementById('pdp-scrollable');
        var carousel_elem = document.getElementById('pdp-carousel');
        if (elem != null) {
            elem.style.scrollBehavior = "unset";

            var y1 = elem.scrollTop;
            elem.scrollTop += 1;
            var y2 = elem.scrollTop;
            elem.scrollTop -= 1;
            var y3 = elem.scrollTop;
            elem.scrollTop = y1;

            var x1 = elem.scrollLeft;
            elem.scrollLeft += 1;
            var x2 = elem.scrollLeft;
            elem.scrollLeft -= 1;
            var x3 = elem.scrollLeft;
            elem.scrollLeft = x1;
            var right = document.getElementById('right-button-scr');
            var left = document.getElementById('left-button-scr');
            let nextValue = elem.scrollLeft - 273;

            if (elem.style.left == "94px") {
                elem.style.left = "94px";
            }
            else {
                if (elem.scrollLeft == 0) {
                    elem.style.left = "20px";
                }
            }
            if (right != null && (x1 !== x2 || x2 !== x3)) {
                right.style.display = "inline-block";
                if (left != null) {
                    if (elem.style.left == "20px" && elem.scrollLeft == 0) {
                        left.style.display = "none";
                    }
                }
                if (elem.style.left == "20px" && nextValue <= 0) {// for right scroll
                    if (window.innerWidth <= 1500 && props.compareView) {
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1320 && props.compareView) {
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1200 && props.compareView) {
                        elem.style.width = "100%";
                    }

                    if (props.compareView) {
                        carousel_elem.style.width = "80%"
                    }
                }
                else { // for left scroll
                    if (window.innerWidth <= 1500 && props.compareView) {
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1320 && props.compareView) {
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1200 && props.compareView) {
                        elem.style.width = "100%";
                    }
                    if (props.compareView) {
                        carousel_elem.style.width = "80%"
                    }
                }
            }

            if (!props.compareView && props.adminScrollCheck) {
                elem.style.width = "100%";
                elem.style.left = "20px";
                if (window.innerWidth <= 1300) {
                    elem.style.width = "100%";
                }
            }

            if (right != null && left != null && !(x1 !== x2 || x2 !== x3)) {
                right.style.display = "none";
                // left.style.display = "none";
            }
            if (right != null && !(x1 !== x2 || x2 !== x3)) {
                right.style.display = "none";
                // left.style.display = "none";
            }
            // ---- NEED FOR LATER USE ----
            // console.log("H",x1 !== x2 || x2 !== x3);
            // console.log("V",y1 !== y2 || y2 !== y3);
        }
    }

    useEffect(() => {
        checkScroll();
        forceUpdate();
    }, [props.compareView, props.adminScrollCheck]);

    useEffect(() => {
        checkScroll();
        forceUpdate();
    }, []);

    React.useEffect(() => {
        checkScroll();
        window.addEventListener('resize', checkScroll);
        return () => {
            window.removeEventListener('resize', checkScroll);
        }
    });

    useEffect(() => {
        if (image != null) {
            forceUpdate();
        }
    }, [image]);

    return (
        <React.Fragment>
            {displayLoader ? <DottedLoader display_inline={true} custom_class={props.compareView ? true : false} /> : ""}
            <Card className={((isFullScreen || props.embed) ? 'product-full-screen' : "product-viewer-normal")} bodyStyle={{ padding: (isFullScreen || props.embed) ? 0 : 12, height:'100%' }}
                style={{ zIndex: (isFullScreen || props.embed) ? 5 : "unset", marginRight: (props.productReducer.product.model_status == 4) || (props.productReducer.product.model_status == 4) ? "3px" : "0px" }} id="pdp-card">
                {props.embed ? "" : (props.productReducer.product.model_status !== '2' ? <Tooltip title={(!isFullScreen ? "Full Screen" : "Exit Full Screen")} placement="left">
                    <img src={isFullScreen ? "/img/fullScreenIcon-2.svg" : "/img/fullScreenIcon.jpg"} alt="Fullscreen Icon"
                        style={{ position: "absolute", bottom: "20px", zIndex: "8", cursor: "pointer", opacity: "0.8", borderRadius: "4px", right: "20px" }}
                        className="product-full-screen-icon" onClick={() => setFullScreen()} />
                </Tooltip> : "")}

                {props.productReducer.isLoading ? <DottedLoader /> : <div className="awaiting-models">
                    {props.productReducer.product.model_status == '2' ? <h3 className="model-pending-msg">Product Awaiting Models</h3>
                        : ''}
                </div>}
                {props.embed || props.storePage != 0 || viewType == '3D' ? "" :
                    (
                        !isFullScreen
                            && (([5, 3, 4].includes(parseInt(props.productReducer.product.model_status)))
                                && (parseInt(props.productReducer.product.model_status) == 3 || props.productReducer.product.is_store_item == -1 || props.productReducer.product.customer_username == FileConstants.CUSTOMER_USERNAME || props.productCompanyId == FileConstants.COMPANY_ID)
                            ) ?
                            <div style={{ position: "absolute", zIndex: 2, top: "20px", right: props.productReducer.product.model_status == 4 && (window.location.href.includes('/inprogress-product/') || window.location.href.includes('/product-qa/')) ? "" : "20px", left: props.productReducer.product.model_status == 4 && !window.location.href.includes('admin_products') ? 20 : "" }} id="components-dropdown-demo-dropdown-button">
                                {props.productReducer.product.customer_username == FileConstants.CUSTOMER_USERNAME || props.productCompanyId == FileConstants.COMPANY_ID || FileConstants.isMSProvider  ?
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <Button className="modal-okay square font-14">Download Links <DownOutlined /></Button>
                                </Dropdown> : ""}
                            </div>
                            : "")}
                {
                    props.embed ? "" : (!isFullScreen && (props.productReducer.product.model_status == "5" && (props.complaints && props.complaints.length > 0) && (props.productReducer.product.is_store_item == -1 || props.productReducer.product.customer_username == localStorage.getItem("username"))) ?
                        <div style={{ position: "absolute", zIndex: 2, top: "25px", right: "200px" }}>
                            <Tooltip placement="top" title={'View your complaints'}>
                                <img onClick={props.viewComplaintsListener} src={"/img/view-complaints.svg"}></img>
                            </Tooltip>
                        </div> : "")
                }
                {
                    props.embed ? "" : (!isFullScreen && (props.productReducer.product.model_status == "5" && previewImage == false && (props.productReducer.product.is_store_item == -1 || props.productReducer.product.customer_username == localStorage.getItem("username"))) ?
                        <div style={{ position: "absolute", zIndex: 2, top: "20px", right: "250px" }}>
                            <Tooltip placement="left" title={`Something does not look good? Report it to the admin.`}>
                                <img className="flag-image-silo" src="/img/image_flag.png" onClick={openSingleFlagImage} />
                            </Tooltip>
                        </div> : "")
                }


                {/* 3D dimensions */}
                {
                    <div style={{display:'none'}} id ='model-inspector-frame' className={`model-inspector-viewer ${props.embed ? 'fullscreen' : ''}`}>
                        {/* <ProductGoogleViewer custom_style={(isFullScreen || props.embed) ? google_viewer_style_fullscreen : google_viewer_style} gltf={getGLBUrl()} /> */}
                        <iframe id='model-viewer-3d' className='model-inspector-frame' src={ FileConstants.ModelInspector + params.id}/>
                    </div>
                }
                {/* 360 dimensions */}
                {viewType == '360D' ?
                    props.status360 == "pending" ?
                        <div>
                            <div className="silo-status-message">
                                360 Spin in Progress
                            </div>
                        </div> :
                        <div id="container-360" className="container-360-spin" style={{ height: (isFullScreen || props.embed) ? "100vh" : "calc(100vh - 210px)" }}>
                            <div
                                className="cloudimage-360"
                                id="image-360"
                                data-folder={props.productReducer.product['360'] && props.productReducer.product['360']["data-folder"]}
                                data-filename={props.productReducer.product['360'] && props.productReducer.product['360']["data-filename"]}
                                data-amount={props.productReducer.product['360'] && props.productReducer.product['360']["data-amount"]}>

                            </div>
                        </div>
                    : ''}

                {/*  2D Flow   */}
                {viewType == '2D' &&
                    <div>
                        {(isFullScreen || props.embed) ?
                            <span>
                                <img className={(isFullScreen || props.embed) ? "full-img" : "card-img"} style={{ objectFit: "contain", width: "100%" }} src={image && image.replace('.tiff', '.jpg').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product.platform))} />
                                {props.storePage == 0 ?
                                    <div className="tag-bg large"><span className="tag-txt large">
                                        {image ? (image.includes('camera') ? 'Silo' : image && image.includes('_Debug') ? 'Debug' : image && image.includes('swatch') ? 'Swatch' : image && image.includes('mount') ? 'Blind' : image && ((image.includes(FileConstants.CUSTOMER_USERNAME) || image.includes('company'))) && image.includes('_Dimensions') ? 'DIM' : image && ((image.includes(FileConstants.CUSTOMER_USERNAME) || image.includes('company')) && image.includes('_Dimensions')) ? 'DIM' : 'Preview') : props.productReducer.product['2d'] ? props.productReducer.product['2d'].includes('camera') ? 'Silo' : props.productReducer.product['2d'].includes('_Debug') ? 'Debug' : image && image.includes('swatch') ? 'Swatch' : image && image.includes('Mount') ? 'Blind' : image && ((image.includes(FileConstants.CUSTOMER_USERNAME) || image.includes('company')) && image.includes('_Dimensions')) ? 'DIM' : "Preview" : ""}{getRigName(image)}</span>
                                    </div> : ''}
                            </span>
                            :
                            <div style={{ display: 'flex', justifyContent: "space-around" }}>
                                {props.adminCheck == true ?
                                    <div>
                                        <ImageZoomAdmin custom_class={isFullScreen ? fullscreen_image_style : image_style} image={image ? image.replace('.tiff', '.jpg').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product.platform)) : null} compare={false} />
                                    </div>
                                    : siloZoomCheck ?
                                        <span>
                                            <img className={(isFullScreen || props.embed) ? "full-img" : "card-img"} style={{ objectFit: "scale-down", width: "auto", height: "calc(100vh - 202px)" }} src={image ? image.replace('.tiff', '.jpg').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product.platform)) : null} />
                                        </span>
                                        :
                                        <span>
                                            <ImageMagnify compareView={props.compareView} style={{ cursor: "zoom-in" }} image={image ? image.replace('.tiff', '.jpg').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product.platform)) : null} className="image-zoom" />
                                        </span>}
                                {props.storePage == 0 ?
                                    <div className="tag-bg large"><span className="tag-txt large">{image ? (image.includes('camera') ? 'Silo' : (props.productReducer.product.lighting_renders && props.productReducer.product.lighting_renders.includes(image) && window.location.href.includes('admin_products') ? 'RIG' : image && image.includes('_Debug') ? 'Debug' : image && image.includes('swatch') ? 'Swatch' : image && image.includes('Mount') ? 'Blind' : image && ((image.includes(FileConstants.CUSTOMER_USERNAME) || image.includes('company')) && image.includes('_Dimensions')) ? 'DIM' : 'Preview')) : props.productReducer.product['2d'] ? props.productReducer.product['2d'].includes('camera') ? 'Silo' : (props.productReducer.product.lighting_renders && props.productReducer.product.lighting_renders.includes(image) && window.location.href.includes('admin_products') ? 'RIG' : image && image.includes('_Debug') ? 'Debug' : image && image.includes('swatch') ? 'Swatch' : image && image.includes('Mount') ? 'Blind' : 'Preview') : ''} {getRigName(image)}</span></div>
                                    : ''}
                            </div>
                        }
                    </div>}

                {viewType == 'render_in_progress' &&
                    <div>
                        <img className={(isFullScreen || props.embed) ? "full-img" : "card-img"} style={{ objectFit: "contain", width: "100%", opacity: "0.5", filter: "blur(0px)" }} src={image || props.productReducer.product['2d'] && props.productReducer.product['2d'][0]} />
                        <div className="silo-status-message">
                            Render in Progress
                        </div>
                    </div>
                }

                {viewType == 'video' ?
                    props.mp4Status == "pending" ?
                        <div>
                            <div className="silo-status-message">
                                MP4 Video in Progress
                            </div>
                        </div>
                        : <div>
                            <ReactPlayer width="100%" height={(isFullScreen || props.embed) ? "calc(100vh - 84px)" : "calc(100vh - 284px)"} url={getVideoUrl()} controls={true} loop={true} />
                        </div>
                    : ''}

                <div className='pdp-carousel-scroll' id="pdp-carousel">
                    {!collapseViewer ?
                        <Tooltip title={<span className='manrope f-12 white'>Hide image viewer</span>}>
                            <DownCircleOutlined className='left-coll-icon' onClick={hideViewer} />
                        </Tooltip> :
                        <Tooltip title={<span className='manrope f-12 white'>Show image viewer</span>}>
                            <UpCircleOutlined className='right-coll-icon' onClick={showViewer} />
                        </Tooltip>
                    }
                    {!collapseViewer &&
                        <div onClick={leftScroll} className="icon-con-left" id="left-button-scr">
                            <RightOutlined className="left-button-scr" />
                        </div>}
                    {!collapseViewer ?
                        <div id="pdp-scrollable" className={!props.compareView && props.adminScrollCheck ? "pdp-scrollable" : "pdp-scrollable"}>
                            {(props.productReducer.product['silo_data'] || []).slice(0).reverse().map((u, index) => {
                                if (u.image_status != undefined && (u.image_status == 'pending' || u.image_status == 'requested') && u.camera_name.includes("Mount")) {
                                    let imageLink = null;
                                    if (u.camera_name.includes("inside")) {
                                        imageLink = require("../../../assets/images/insideBlind.jpg")
                                    }
                                    else {
                                        imageLink = require("../../../assets/images/outsideBlind.jpg")
                                    }
                                    return <Card onClick={() => {
                                        viewInprogressRender(imageLink);
                                        setBorderState(false);
                                        setImageSelected('Silo');
                                    }} key={index} bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} className='pdp-card' style={{ border: ((image == imageLink) && (borderState == false)) ? "1px solid #333333" : "1px solid #f0f0f0" }}>
                                        <img className="product-thumbnail-img" src={imageLink} alt="Clickable Product Image" />
                                        <div className="tag-bg"><span className="tag-txt">Blind</span></div>

                                    </Card>
                                }
                                else if (u.image_status != undefined && (u.image_status == 'pending' || u.image_status == 'requested')) {
                                    let imageLink = null
                                    if (props.embed == false) {
                                        imageLink = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + params.id + '/' + (props.companyId == null ? props.cognito.user.username : ('company/' + props.companyId)) + '/' + u.camera_name + '_preview' + '.jpg';
                                    } else {
                                        imageLink = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + params.id + '/' + (props.publicViewerUser == undefined ? u.camera_name : (props.publicViewerUser + '/' + u.camera_name)) + + '_preview' + '.jpg';
                                    }
                                    return <Card onClick={() => {
                                        viewInprogressRender(imageLink);
                                        setBorderState(false);
                                        setImageSelected('Silo');
                                    }} key={index} bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} className='pdp-card' style={{ border: ((image == imageLink) && (borderState == false)) ? "1px solid #333333" : "1px solid #f0f0f0" }}>
                                        <img className="product-thumbnail-img" src={imageLink} alt="Clickable Product Image" />
                                        <div className="tag-bg"><span className="tag-txt">Silo</span></div>

                                    </Card>
                                }
                            })}
                            {(props.productReducer.product['silo_data'] || []).slice(0).reverse().map((u, index) => {

                                if (!u.camera_name.includes("swatch") && !u.camera_name.includes("Mount") && u.image_status != undefined && u.image_status == 'completed') {
                                    let imageLink = null
                                    if (props.embed == false) {
                                        imageLink = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + params.id + '/' + (props.companyId == null ? props.cognito.user.username : ('company/' + props.companyId)) + '/' + u.camera_name + '.' + u.image_data.img_format;
                                    } else {
                                        imageLink = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + params.id + '/' + (props.publicViewerUser == undefined ? u.camera_name : (props.publicViewerUser + '/' + u.camera_name)) + '.' + u.image_data.img_format;
                                    }
                                    imageLink = imageLink.replace('tiff', 'jpg');
                                    return <Card onClick={() => {
                                        view2DImage(imageLink);
                                        setPreviewImage(false);
                                        setBorderState(false);
                                        setImageSelected('Silo');
                                        setSiloZoomCheck(tempArray[u.camera_name]);
                                    }} key={index} bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} style={{ border: ((image == imageLink) && (borderState == false)) ? "1px solid #333333" : "1px solid #f0f0f0" }} className="product-thumbnail-card">
                                        <img className="product-thumbnail-img" src={imageLink.includes('/dimensional_renders/') ? imageLink.replace('/dimensional_renders/', '/low_res_dimensional_renders/') : imageLink.replace('/perspective_renders/', '/perspective_renders/')} alt="Clickable Product Image" />
                                        <div className="tag-bg"><span className="tag-txt">Silo</span></div>

                                    </Card>
                                }
                                else if (u.camera_name.includes("swatch") && u.image_status != undefined && u.image_status == 'completed') {
                                    let imageLink = null
                                    if (props.embed == false) {
                                        imageLink = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + params.id + '/' + (props.companyId == null ? props.cognito.user.username : ('company/' + props.companyId)) + '/' + u.camera_name + '.' + u.image_data.img_format;
                                    } else {

                                        imageLink = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + params.id + '/' + (props.publicViewerUser == undefined ? u.camera_name : (props.publicViewerUser + '/' + u.camera_name)) + '.' + u.image_data.img_format;
                                    }
                                    imageLink = imageLink.replace('tiff', 'jpg');
                                    return <Card onClick={() => {
                                        view2DImage(imageLink);
                                        setPreviewImage(false);
                                        setBorderState(false);
                                        setImageSelected('Silo');
                                        setSiloZoomCheck(tempArray[u.camera_name]);
                                    }} key={index} bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} style={{ border: ((image == imageLink) && (borderState == false)) ? "1px solid #333333" : "1px solid #f0f0f0" }} className="product-thumbnail-card">
                                        <img className="product-thumbnail-img" src={imageLink} alt="Clickable Product Image" />
                                        <div className="tag-bg"><span className="tag-txt">Swatch</span></div>

                                    </Card>
                                }
                                else if (u.camera_name.includes("Mount") && u.image_status != undefined && u.image_status == 'completed') {
                                    let imageLink = null
                                    if (props.embed == false) {
                                        imageLink = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + params.id + '/' + (props.companyId == null ? props.cognito.user.username : ('company/' + props.companyId)) + '/' + u.camera_name + '.' + u.image_data.img_format;
                                    } else {

                                        imageLink = getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + params.id + '/' + (props.publicViewerUser == undefined ? u.camera_name : (props.publicViewerUser + '/' + u.camera_name)) + '.' + u.image_data.img_format;
                                    }
                                    imageLink = imageLink.replace('tiff', 'jpg');
                                    return <Card onClick={() => {
                                        view2DImage(imageLink);
                                        setPreviewImage(false);
                                        setBorderState(false);
                                        setImageSelected('Silo');
                                        setSiloZoomCheck(tempArray[u.camera_name]);
                                    }} key={index} bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} style={{ border: ((image == imageLink) && (borderState == false)) ? "1px solid #333333" : "1px solid #f0f0f0" }} className="product-thumbnail-card">
                                        <img className="product-thumbnail-img" src={imageLink} alt="Clickable Product Image" />
                                        <div className="tag-bg"><span className="tag-txt">Blind</span></div>

                                    </Card>
                                }
                            })}
                            {(props.productReducer.product['2d'] || []).map((u, index) => {
                                if (props.productReducer.product['silo_data'] != undefined) {
                                    if (props.productReducer.product['silo_data'].length > 0) {
                                        if (u.includes("_Dimensions")) {
                                            // return <Card key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} style={{border: ((image == u) && (borderState == false)) ? "1px solid #333333" : "1px solid #f0f0f0",marginRight:"10px",width:"64px",height:"64px",padding:"10px"}}>
                                            //         <img onClick={() =>{
                                            //             view2DImage(u);
                                            //             setPreviewImage(true);
                                            //             setBorderState(false);
                                            //             setSiloZoomCheck(false);

                                            //         }}  style={{ cursor: "pointer", height: "40px", width:"40px",objectFit: "contain", display: "inline-block",width:"40px"}} src={u.includes('/dimensional_renders/') ? u.replace('/dimensional_renders/', '/low_res_dimensional_renders/') : u.replace('/perspective_renders/', '/low_res_perspective_renders/')} alt="Clickable Product Image"/>
                                            // </Card>
                                        }
                                    }
                                    else {

                                        return <Card key={index} onClick={() => {
                                            view2DImage(u);
                                            setPreviewImage(true);
                                            setBorderState(false);
                                            setImageSelected((u.includes(FileConstants.CUSTOMER_USERNAME) || u.includes('company')) && u.includes('_Dimensions') ? 'Dimensional Image' : 'Preview');
                                            setSiloZoomCheck(false);

                                        }} bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} className="product-thumbnail-card" style={{ border: ((image == u) && (borderState == false)) ? "1px solid #333333" : "1px solid #f0f0f0" }}>

                                            <img className="product-thumbnail-img" src={u.includes('/dimensional_renders/') ? u.replace('/dimensional_renders/', '/low_res_dimensional_renders/').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product.platform)) : u.replace('/perspective_renders/', '/low_res_perspective_renders/').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product.platform))} alt="Clickable Product Image" />
                                            {props.storePage == 0 ?
                                                <div className="tag-bg"><span className="tag-txt">{props.productReducer.product.lighting_renders && props.productReducer.product.lighting_renders.includes(u) && window.location.href.includes('admin_products') ? 'RIG' : u.includes('Debug') ? 'Debug' : (u.includes(localStorage.getItem('username')) || u.includes('company')) && u.includes('_Dimensions') ? 'DIM' : 'Preview'}</span></div>
                                                : ''}
                                        </Card>
                                    }
                                }
                                else {
                                    return <Card key={index} onClick={() => {
                                        view2DImage(u);
                                        setPreviewImage(true);
                                        setImageSelected((u.includes(FileConstants.CUSTOMER_USERNAME) || u.includes('company')) && u.includes('_Dimensions') ? 'Dimensional Image' : 'Preview');
                                        setBorderState(false);
                                        setSiloZoomCheck(false);

                                    }} bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} className="product-thumbnail-card" style={{ border: ((image == u) && (borderState == false)) ? "1px solid #333333" : "1px solid #f0f0f0" }}>
                                        <img className="product-thumbnail-img" src={u.includes('/dimensional_renders/') ? u.replace('/dimensional_renders/', '/low_res_dimensional_renders/').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product.platform)) : u.replace('/perspective_renders/', '/low_res_perspective_renders/').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product.platform))} alt="Clickable Product Image" />
                                        {props.storePage == 0 ?
                                            <div className="tag-bg"><span className="tag-txt">{props.productReducer.product.lighting_renders && props.productReducer.product.lighting_renders.includes(u) && window.location.href.includes('admin_products') ? 'RIG' : u.includes('Debug') ? 'Debug' : (u.includes(FileConstants.CUSTOMER_USERNAME) || u.includes('company')) && u.includes('_Dimensions') ? 'DIM' : 'Preview'}</span></div>
                                            : ''}
                                    </Card>
                                }

                            })}
                            {(props.productReducer.product['2d'] || []).map((u, index) => {
                                if (props.productReducer.product['silo_data'] != undefined) {
                                    if (props.productReducer.product['silo_data'].length > 0) {
                                        // if (!u.includes("_Dimensions")) {
                                        return <Card onClick={() => {
                                            view2DImage(u);
                                            setPreviewImage(true);
                                            setBorderState(false);
                                            setImageSelected((u.includes(localStorage.getItem('username')) || u.includes('company')) && u.includes('_Dimensions') ? 'Dimensional Image' : 'Preview');
                                            setSiloZoomCheck(false);
                                        }} key={index} bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} style={{ border: ((image == u) && (borderState == false)) ? "1px solid #333333" : "1px solid #f0f0f0" }} className="product-thumbnail-card">
                                            <img className="product-thumbnail-img" src={u.includes('/dimensional_renders/') ? u.replace('/dimensional_renders/', '/low_res_dimensional_renders/').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product.platform)) : u.replace('/perspective_renders/', '/low_res_perspective_renders/').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product.platform))} alt="Clickable Product Image" />
                                            {props.storePage == 0 ?
                                                <div className="tag-bg"><span className="tag-txt">{props.productReducer.product.lighting_renders && props.productReducer.product.lighting_renders.includes(u) && window.location.href.includes('admin_products') ? 'RIG' : u.includes('Debug') ? 'Debug' : (u.includes(FileConstants.CUSTOMER_USERNAME) || u.includes('company')) && u.includes('_Dimensions') ? 'DIM' : 'Preview'}</span></div>
                                                : ''}
                                        </Card>
                                        // }
                                    }
                                }
                            })}
                            {/* ------------------ TO BE USED LATER ------------------   */}
                            {/* {
            props.artist_check && artistArray.map((u,index) => {
                return <Card key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} style={{border: ((image == u) && (borderState == false)) ? "1px solid #333333" : "1px solid #f0f0f0",marginRight:"10px",width:"64px",height:"64px",padding:"10px"}}>
                        <img onClick={() => {
                            view2DImage(u);
                            setPreviewImage(true);
                            setBorderState(false);
                            }}
                             style={{ cursor: "pointer", height: "40px", width:"40px",objectFit: "contain", display: "inline-block",width:"40px"}}
                            src={u} alt="Clickable Product Image"/>
                        </Card>
            })
        } */}

                            <Tooltip title={"3D"} placement="top">
                                <Card className='pdp-card' bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} style={{ border: (viewType == '3D') ? "1px solid #333333" : "1px solid #f0f0f0", marginRight: "10px" }}>
                                    <div className="product-view-icon" onClick={() => {
                                        changeViewType('3D');
                                        setPreviewImage(true);
                                        setBorderState(true);
                                        setImageSelected('3D');
                                        if (props.productReducer && (!props.productReducer.product.need_to_model.includes("ar") && !props.productReducer.product.need_to_model.includes("pbr")) && props.productReducer.product.model_status == 4) {
                                            setShowNonARModal(true)
                                        }
                                    }}>
                                        <span style={{ background: "url(/img/3d.jpg)", objectFit: "contain", height: "40px", width: "40px" }}><b style={{ color: "white" }}></b></span>
                                    </div>
                                </Card>
                            </Tooltip>

                            {(props.status360 == "completed" || props.status360 == "pending") &&
                                <Tooltip title={"360"} placement="top">
                                    <Card className='pdp-card' bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} style={{ border: (viewType == '360D') ? "1px solid #333333" : "1px solid #f0f0f0", marginRight: "10px" }}>
                                        <div className="product-view-icon" onClick={() => {
                                            setBorderState(true);
                                            view360Player();
                                            setImageSelected('360 spin')
                                        }}>
                                            <span style={{ background: "url(/img/360-tn.jpg)", objectFit: "contain", height: "40px", width: "40px" }}><b style={{ color: "white" }}></b></span>
                                        </div>
                                    </Card>
                                </Tooltip>}

                            <Tooltip title={"AR"} placement="top">
                                <Card className='pdp-card' bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} style={{ border: (viewType == 'view_in_room') ? "1px solid #333333" : "1px solid #f0f0f0", marginRight: "10px" }}>
                                    <div className="product-view-icon" onClick={() => {
                                        changeViewType('view_in_room');
                                        setPreviewImage(true);
                                        setBorderState(true);
                                        setImageSelected('AR')
                                    }}>
                                        <span style={{ background: "url(/img/ar.jpg)", objectFit: "contain", height: "40px", width: "40px" }}><b style={{ color: "white" }}></b></span>
                                    </div>
                                </Card>
                            </Tooltip>

                            <Tooltip title={"Android"} placement="top">
                                <Card className='pdp-card' bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} style={{ border: (viewType == 'view_in_android') ? "1px solid #333333" : "1px solid #f0f0f0", marginRight: "10px" }}>
                                    <div className="product-view-icon" onClick={() => {
                                        changeViewType('view_in_android');
                                        setPreviewImage(true);
                                        setBorderState(true);
                                        setImageSelected('Android')
                                    }}>
                                        <span style={{ background: "url(/img/android.jpg)", objectFit: "contain", height: "40px", width: "40px" }}><b style={{ color: "white" }}></b></span>
                                    </div>
                                </Card>
                            </Tooltip>

                            {(getVideoUrl() != '' || props.mp4Status == "pending") &&
                                <Tooltip title={"MP4"} placement="top">
                                    <Card className='pdp-card' bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} style={{ border: (viewType == 'video') ? "1px solid #333333" : "1px solid #f0f0f0", marginRight: "10px" }}>
                                        <div className="product-view-icon" onClick={() => {
                                            changeViewType('video');
                                            setPreviewImage(true);
                                            setBorderState(true);
                                            setImageSelected('MP4 Video')
                                        }}>
                                            <span style={{ background: "url(/img/mp4.svg)", objectFit: "contain", height: "40px", width: "40px" }}><b style={{ color: "white" }}></b></span>
                                        </div>
                                    </Card>
                                </Tooltip>
                            }
                        </div> : ''}

                    {!collapseViewer &&
                        <div style={{ display: !props.compareView && props.adminScrollCheck ? "none" : "inline-block" }} onClick={rightScroll} className="icon-con" id="right-button-scr">
                            <RightOutlined className="right-button-scr" />
                        </div>
                    }

                </div>

                {viewType == 'view_in_room' &&
                    <div className="position-ar-link">
                        <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "50px", display: "flex", justifyContent: "space-around" }}>
                            <p className="product-h2">Open the following link in your Apple Device</p>
                        </div>
                        <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "20px", justifyContent: "space-around" }}>
                            <h3 className="apply-Avenir" style={{ wordBreak: 'break-word', textAlign: 'center' }}><a href={getUSDZUrl()}>{getUSDZUrl()}</a></h3>
                        </div>
                    </div>
                }

                {viewType == 'view_in_android' &&
                    <div className="position-ar-link">
                        <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "50px", display: "flex", justifyContent: "space-around" }}>
                            <p className="product-h2">Open the following link in your Android Device</p>
                        </div>
                        <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "20px", justifyContent: "space-around" }}>
                            <h3 className="apply-Avenir" style={{ wordBreak: 'break-word', textAlign: 'center' }}><a href={getAndroidZUrl()}>{getAndroidZUrl()}</a></h3>
                        </div>
                    </div>
                }

                <Modal
                    destroyOnClose={true}
                    maskClosable={false}
                    visible={imageFlagModal}
                    onCancel={cancelReportImageIssue}
                    width={750}
                    className="modal-flag">
                    <div style={{ padding: "0" }}>
                        <h2 className="share-modal-heading greyish-black"><span>Report an Issue</span><span style={{ color: "#999999", fontWeight: 500, cursor: "pointer" }} onClick={cancelReportImageIssue}>x</span></h2>
                        {singleImage ?
                            <h3 className="flag-text" style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Selected Silo Image</span>
                                <span onClick={changeSingleImageState} style={{ color: "#276DD7", cursor: "pointer" }}>Select More Images</span>
                            </h3> :
                            <h3 className="flag-text" style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Select the images you would like to flag</span>
                                <span onClick={selectAllImages} style={{ color: "#276DD7", cursor: "pointer" }}>{selectAll == false ? `Select All` : `Deselect All`}</span>
                            </h3>
                        }

                        <Form onFinish={handleImageReport}>
                            <div className={singleImage ? "" : "request-silo-scrollable flag-height"}>
                                <Row gutter={16}>
                                    {singleImage ?
                                        <Col span={24} className="flag-col-margin single">
                                            <img src={(image || props.productReducer.product['2d'] && props.productReducer.product['2d'][0])}
                                                className="flag-image-thumbnail-single product" />
                                        </Col>
                                        : <div style={{ display: "contents" }}>
                                            {/* --------- TO BE USED LATER --------- */}
                                            {/* {(props.productReducer.product['2d'] || []).map((u, index) => (
                                        <Col span={8} className="flag-col-margin">
                                            <span className="flag-checkbox-settings" style={{zIndex:"10"}}>
                                                <Checkbox className="black-checkbox" onChange={() => onChangeFlagImageCheck(u.split('/')[5].split('.').slice(0, -1).join('.'))} checked={checkedImage[u.split('/')[5].split('.').slice(0, -1).join('.')]}/>
                                            </span>
                                            <img src={(u)}
                                            className="flag-image-thumbnail"/>
                                        </Col>
                                    ))} */}

                                            {(props.productReducer.product['silo_data'] || []).map((u, index) => (
                                                <span>
                                                    {(u.image_status != undefined && u.image_status == 'completed') ?
                                                        <Col span={8} className="flag-col-margin">
                                                            <span className="flag-checkbox-settings" style={{ zIndex: "10" }}>
                                                                <Checkbox className="black-checkbox" onChange={() => onChangeFlagImageCheck(u.camera_name)} checked={checkedImage[u.camera_name]} />
                                                            </span>
                                                            {(props.embed == false) ?
                                                                <img src={(getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + params.id + '/' + (props.companyId == null ? props.cognito.user.username : ('company/' + props.companyId)) + '/' + u.camera_name + '.' + u.image_data.img_format)}
                                                                    className="flag-image-thumbnail" />
                                                                :
                                                                <img src={(getBaseURL(props.productReducer.product['platform']) + QA_IMG_URI + params.id + '/' + (props.publicViewerUser == undefined ? u.camera_name : (props.publicViewerUser + '/' + u.camera_name)) + '.' + u.image_data.img_format)}
                                                                    className="flag-image-thumbnail" />
                                                            }
                                                        </Col>
                                                        : ""}
                                                </span>
                                            ))}
                                        </div>}
                                </Row>
                            </div>
                            <span className="flag-form" style={{ marginTop: 22, display: (checkImageState || singleImage) ? "flex" : "none" }}>
                                <Form.Item name="image_flag_reason" label="I am Flagging Because..." className="share-modal-input" style={{ display: "inline-block", width: 330, margin: 0 }} colon={false}>
                                    <Input.TextArea
                                        autoSize={{
                                            maxRows: 4,
                                            minRows: 4
                                        }}
                                        //value={siloInput}
                                        onChange={changeSiloInput} rows={4} placeholder="Describe the issue, example lighting etc." />
                                </Form.Item>
                                <Form.Item
                                    className="share-modal-input"
                                    style={{ display: "inline-block", width: 330, margin: 0 }}
                                    name="upload_image_screenshots"
                                    colon={false}
                                    label="Additional Information"
                                    valuePropName="image_filelist"
                                    getValueFromEvent={normFile}>
                                    <Upload {...Constants.upload_props}
                                        onRemove={file => {
                                            message.success(`${file.name} removed successfully!`)
                                            const index = screenshotImageList.indexOf(file);
                                            const newFileList = screenshotImageList.slice();
                                            newFileList.splice(index, 1);
                                            setScreenshotImageList(newFileList);
                                            newFileList.forEach((file) => {
                                                if (file.status !== "error") {
                                                    setHasError(false)
                                                }
                                                else {
                                                    setHasError(true)
                                                }
                                            })
                                            return true;
                                        }}
                                        accept="application/msword,.zip,.rar,image/jpeg,image/tiff,image/gif,image/svg,image/png,application/pdf"
                                        progress={{
                                            strokeColor: {
                                                '0%': '#108ee9',
                                                '100%': '#87d068',
                                            },
                                            strokeWidth: 3,
                                            format: percent => `${parseFloat(percent.toFixed(2))}%`,
                                        }}
                                        openFileDialogOnClick={!(validateFileUploads(screenshotImageList) == 'error')}
                                        fileList={screenshotImageList}
                                        onChange={handleImageScreenshots}
                                        className="material-upload-box scroll-upload"
                                        name="logo" multiple={true} listType="text">
                                        <span>
                                            <Button className="material-photos-btn flag">
                                                <span className="material-photos-btn-txt">Attach files or screenshots here <PaperClipOutlined /></span>
                                            </Button>
                                            {(validateFileUploads(screenshotImageList) == 'error') && (<Button
                                                onClick={() => Constants.triggerFailedFilesUpload('upload_image_screenshots', screenshotImageList, setScreenshotImageList)}
                                                disabled={screenshotImageList.length === 0}
                                                loading={uploading}
                                                className="retry-btn flag" ghost
                                            >
                                                {uploading ? <span>Retrying...</span> : 'Upload Failed. Click to Retry.'}
                                            </Button>)}
                                        </span>
                                    </Upload>
                                </Form.Item>
                            </span>
                            {(validateFileUploads(screenshotImageList) == 'error') ?
                                <div className='retry-error-message'>{errorMessage}</div>
                                : ''}
                            <div style={{ padding: "10px 16px", marginBottom: 2, marginTop: singleImage ? 18 : 18 }} className="note-flag">
                                <span className="scene-note flag">
                                    Note: <div style={{ color: "#333333" }}>Your issue will be reported to All3D admin. We will reach out to you via email. </div>
                                </span>
                            </div>
                            <Form.Item style={{ textAlign: "right", marginTop: 32 }}>
                                <Button className="modal-okay square" htmlType="submit" disabled={((checkImageState == false && singleImage == false) || siloInput == '')}>
                                    <div className="modal-okay-text">Send {buttonLoader ? <LoadingOutlined /> : ""}</div>
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
                <Modal
                    className="modal-lifestyle-request"
                    centered={true}
                    visible={reportSuccess}
                    width={750}
                    bodyStyle={{ padding: 0 }}
                    footer={[
                        <div className="justify-in-center">
                            <Button className="modal-okay font-14" key="submit" onClick={() => {
                                setReportSuccess(false);
                                window.location.reload()
                            }}>
                                Okay
                            </Button>
                        </div>
                    ]}>
                    <div style={{ padding: "40px" }}>
                        <div style={{ textAlign: "center" }}>
                            <img style={{ width: 85, height: 85 }} src={require("../../../assets/images/success-icon.png")}></img>
                        </div>
                        <div style={{ textAlign: "center", margin: "20px 0" }}>
                            <h2 className="modal-heading">Issue Reported Successfully!</h2>
                            <p className="modal-text">Your issue has been reported to the admin successfully. We will get back to you as soon as possible.</p>
                        </div>
                    </div>
                </Modal>

                <Modal
                    centered={true}
                    width={500}
                    title="Please Note"
                    visible={showNonARModal}
                    className='non-ar-modal'
                    onCancel={() => { setShowNonARModal(false) }}
                    footer={[
                        <div className="justify-in-end">
                            <Button className="modal-okay square font-14" key="submit" onClick={() => {
                                setShowNonARModal(false);
                            }}>
                                Okay
                            </Button>
                        </div>
                    ]}
                >
                    <span className='manrope f-14'>
                        Since you did not request an AR model, this is a low resolution model which you can use to see the product geometry. It may not have the right material - please look at the Preview images for the material and approve the model based on those images.
                    </span>

                </Modal>
            </Card>


        </React.Fragment>);

}

export default ProductDetailsComponent;