import React, { useEffect, useState } from "react";
import CustomerMainLayout from "../CustomerMainLayout";
import { Col, Drawer, Form, Input, Row, Tabs, Tooltip, Radio } from "antd";
import ENVIRONMENT from '../../../../environments';
import * as Utilities from '../../Utilities'
import MaterialDetailModal from "../MaterialDetailModal/MaterialDetailModal";
import axios from "axios";
import FileConstants from '../../../../FileConstants';
import { FilterOutlined, LoadingOutlined } from "@ant-design/icons";
import ActiveModelsList from "./ActiveModelsList";
const { TabPane } = Tabs;

const ActiveModelsKeys = {
    review : "in-review",
    progress : "in-progress",
    drafts : "drafts"
}

const CUSTOMER_USERNAME = localStorage.getItem("username");
const COMPANY_ID = localStorage.getItem("company_id");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_SHARED_ENTITIES = localStorage.getItem('managed_shared_entites')
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const MANAGED_COMPANY_NAME = localStorage.getItem('managed_company_name')

const ActiveModels = (props) => {
    const [modelsTabKey, setModelsTabKey] = useState('in-review');
    const [materialModal, setMaterialModal] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [reviewDataOrg, setReviewDataOrg] = useState([]);
    const [progressDataOrg, setProgressDataOrg] = useState([]);
    const [draftsDataOrg, setDraftsDataOrg] = useState([]);
    const [reviewData, setReviewData] = useState([]);
    const [progressData, setProgressData] = useState([]);
    const [draftsData, setDraftsData] = useState([]);
    const [reviewDataLoader, setReviewDataLoader] = useState(false);
    const [progressDataLoader, setProgressDataLoader] = useState(false);
    const [draftsDataLoader, setDraftsDataLoader] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [filtersDrawer, setFiltersDrawer] = useState(false);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [filtersAppliedFlag, setFiltersAppliedFlag] = useState(false);
    const [modelTypeFilterValue, setModelTypeFilterValue] = useState('all')
    const [reviewTabCount, setReviewTabCount] = useState(0)
    const [inProgressTabCount, setInProgressTabCount] = useState(0)
    const [draftTabCount, setDraftTabCount] = useState(0)

    useEffect(() => {
        setActiveTabFromQueryParams();
        getModelsData(ActiveModelsKeys.review);
        getModelsData(ActiveModelsKeys.progress);
        getModelsData(ActiveModelsKeys.drafts);
    }, []);

    const setActiveTabFromQueryParams = () => {
        const url = new URL(window.location.href);
        let tab = url.searchParams.get("tab");

        if(Object.values(ActiveModelsKeys).includes(tab)){
            setModelsTabKey(tab)
        }
    }

    const getModelsData = (type) => {
        if (type == ActiveModelsKeys.review) {
            setReviewDataLoader(true);
        } else if (type == ActiveModelsKeys.progress) {
            setProgressDataLoader(true);
        } else if (type == ActiveModelsKeys.drafts) {
            setDraftsDataLoader(true);
        }
        let payload = {
            username : CUSTOMER_USERNAME,
            model_type: type,
        };
        if (COMPANY_ID != undefined) {
            payload.company_id = COMPANY_ID;
            payload.shared_entities = SHARED_ENTITIES;
        };

        if(MANAGED_CUSTOMER_USERNAME){
            payload['username'] = MANAGED_CUSTOMER_USERNAME
            payload['shared_entities'] = MANAGED_SHARED_ENTITIES
        }
        if(MANAGED_COMPANY_ID){
            payload['company_id'] = MANAGED_COMPANY_ID
        }

        axios.post(ENVIRONMENT.ACTIVE_MODELS_DATA, payload).then((res) => {
            let finalData = Utilities.getEntitiesDataWithKeywords(res.data);
            if (type == ActiveModelsKeys.review) {
                setReviewDataOrg(finalData);
                setReviewData(finalData);
                setReviewDataLoader(false);
                setReviewTabCount(finalData.length)
            } else if (type == ActiveModelsKeys.progress) {
                setProgressDataOrg(finalData);
                setProgressData(finalData);
                setProgressDataLoader(false);
                setInProgressTabCount(finalData.length)
            } else if (type == ActiveModelsKeys.drafts) {
                setDraftsDataOrg(finalData);
                setDraftsData(finalData);
                setDraftsDataLoader(false);
                setDraftTabCount(finalData.length)
            }
        });
    };

    const redirectPage = () => {
        window.location.href = '/home'
    };

    const handleTabChange = (key) => {
        setModelsTabKey(key);
        // When tab changes, update the query params to avoid redirection issues on reload
        let url = new URL(window.location.href);
        url.searchParams.set('tab', key);
        window.history.replaceState({}, '', url);
    };

    const closeMaterialModal = () => {
        setMaterialModal(false);
    };

    const changeSearchValue = (value) => {
        setSearchValue(value);
    };

    const openFilters = () => {
        setFiltersDrawer(true);
    };

    const closeFiltersDrawer = () => {
        setFiltersDrawer(false);
    };
    
    return (
        <>
            <CustomerMainLayout selected='9'>
                <div>
                    <Row style={{ marginTop: 25 }}>
                        <Col span={24}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                                <h2 className="manrope f-14 w-500" style={{ marginBottom: 10, display: 'flex', cursor: 'pointer' }}>
                                    <span onMouseDown={redirectPage} className="store-bd">Dashboard</span>
                                    <span onMouseDown={redirectPage} className="store-bd" style={{ marginLeft: '8px' }}>/</span>
                                    <span className="active-route-link-color" style={{ marginLeft: '8px' }}> Active Models</span>
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-in-center">
                        <Col span={17}>
                            <div className="home-txt-heading">Model Activity</div>
                        </Col>
                        <Col span={7}>
                            <Form>
                                <Input.Group compact size="large" className="d-inline">
                                    <Input placeholder="Search your activity" onChange={e => { changeSearchValue(e.target.value); }} className="manrope f-14 mat-search-bar"></Input>
                                </Input.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} offset={12}>
                            <div className="manrope f-14 black-55 d-flex float-right mt-10 j-center-a-baseline" style={{position:'relative', zIndex: 1000}}>
                                <span className="shared-models-tab-span mr-10">
                                    Show Items:
                                </span>
                                <Radio.Group onChange={(e) => setModelTypeFilterValue(e.target.value)} value={modelTypeFilterValue}>
                                    <Radio value='all'>
                                        All
                                    </Radio>
                                    <Radio value='Product'>
                                        Products
                                    </Radio>
                                    <Radio value='Space'>
                                        Spaces
                                    </Radio>
                                    <Radio value='Material'>
                                        Material
                                    </Radio>
                                </Radio.Group>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: -45}}>
                        <Col span={24}>
                        <Tabs
                            defaultActiveKey={modelsTabKey}
                            activeKey={modelsTabKey}
                            size="large"
                            tabBarGutter={40}
                            onChange={handleTabChange}>
                                <TabPane tab={`In Review (${reviewTabCount})`} key={ActiveModelsKeys.review}>
                                    {
                                        reviewDataLoader ? 
                                        <div>
                                            <LoadingOutlined style={{ fontSize: 50 }} className="loading-center"/>
                                        </div> 
                                        :
                                        <ActiveModelsList
                                        data={reviewData}
                                        type={ActiveModelsKeys.review}
                                        modelsTabKey={modelsTabKey}
                                        setMaterialModal={setMaterialModal}
                                        setSelectedMaterial={setSelectedMaterial}
                                        searchValue={searchValue}
                                        filtersApplied={filtersApplied}
                                        filtersAppliedFlag={filtersAppliedFlag}
                                        modelTypeFilterValue={modelTypeFilterValue}
                                        setReviewTabCount={setReviewTabCount}
                                        setInProgressTabCount={setInProgressTabCount}
                                        setDraftTabCount={setDraftTabCount}
                                        
                                        />
                                    }
                                </TabPane >
                                <TabPane tab={`In Progress (${inProgressTabCount})`} key={ActiveModelsKeys.progress}>
                                    {
                                        progressDataLoader ?
                                        <div>
                                            <LoadingOutlined style={{ fontSize: 50 }} className="loading-center"/> 
                                        </div>
                                        :
                                        <ActiveModelsList
                                        data={progressData}
                                        type={ActiveModelsKeys.progress}
                                        modelsTabKey={modelsTabKey}
                                        setMaterialModal={setMaterialModal}
                                        setSelectedMaterial={setSelectedMaterial}
                                        searchValue={searchValue}
                                        filtersApplied={filtersApplied}
                                        filtersAppliedFlag={filtersAppliedFlag}
                                        modelTypeFilterValue={modelTypeFilterValue}
                                        setReviewTabCount={setReviewTabCount}
                                        setInProgressTabCount={setInProgressTabCount}
                                        setDraftTabCount={setDraftTabCount}/>
                                    }
                                </TabPane>
                                <TabPane tab={`Drafts (${draftTabCount})`} key={ActiveModelsKeys.drafts}>
                                    {
                                        draftsDataLoader ?
                                        <div><LoadingOutlined style={{ fontSize: 50 }} className="loading-center"/></div> :
                                        <ActiveModelsList
                                        data={draftsData}
                                        type={ActiveModelsKeys.drafts}
                                        modelsTabKey={modelsTabKey}
                                        setMaterialModal={setMaterialModal}
                                        setSelectedMaterial={setSelectedMaterial}
                                        searchValue={searchValue}
                                        filtersApplied={filtersApplied}
                                        filtersAppliedFlag={filtersAppliedFlag}
                                        modelTypeFilterValue={modelTypeFilterValue}
                                        setReviewTabCount={setReviewTabCount}
                                        setInProgressTabCount={setInProgressTabCount}
                                        setDraftTabCount={setDraftTabCount}/>
                                    }
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            </CustomerMainLayout>
            <MaterialDetailModal
            materialModal={materialModal}
            closeMaterialModal={closeMaterialModal}
            selectedMaterial={selectedMaterial}
            editable={false}
            errorMessage={''}
            />
        </>
    );
};

export default ActiveModels;