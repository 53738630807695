import React, { useEffect, useState } from "react";
import { Button, message } from 'antd';
import {
    LoadingOutlined
} from '@ant-design/icons';
import axios from 'axios';
import SuccessModal from '../../SuccessModal/SuccessModal';
import WarningModal from '../../WarningModal/WarningModal';
import ENVIRONMENT from '../../../../environments'
import FileConstants from '../../../../FileConstants'

const SpaceUpdateRequestModal = (props) => {

    const [requestSuccessModal, setRequestSuccessModal] = useState(false);
    const [requestConfirmationModal, setRequestConfirmationModal] = useState(false);
    const [requestFailureModal, setRequestFailureModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [existingConfiguration, setExistingConfiguration] = useState(null);
    const [failureMessage, setFailureMessage] = useState(null);
    const showFailMessages = {
        OwnerCheck: "Changes can not be made to this space since you are not the owner of this space.",
        PropertyCheck: "Changes cannot be made to this space since it is an All3D original space.",
        StatusCheck: "Changes cannot be made to this space since it is being worked on by an artist."
    }

    const closeRequestModal = () => {
        props.setSpaceUpdateModal(false);
    }

    const submitRequest = () => {
        // if existing configuration for the space is the same as previous one then no need for repetition
        if (existingConfiguration && compareConfigurations(props.space_configuration, existingConfiguration.request_details)) {
            setRequestConfirmationModal(false);
            setRequestSuccessModal(true);
            props.setLoader(false);
            console.log("request status: existing")
        }
        else {
            console.log("request status: new")
            var room_id = props.space_id;
            var payload = {};
            payload['room_id'] = room_id;
            payload['room_variation'] = props.space_configuration;
            payload['immediate_parent_variant'] = room_id;
            payload['automated_variation'] = true;
            setLoader(true);
            axios.post(ENVIRONMENT.ROOM_VARIATION_REQUEST_CREATE, payload)
            .then(res => {
              setLoader(false);  
              if (res && res.data.statusCode == 200) {      
                  setRequestConfirmationModal(false);
                  setRequestSuccessModal(true);
                  props.setLoader(false);
              }
              else {
                  props.setLoader(false);
                  message.error('Request failed. Please try again!');
              }
            }).catch(e=> {
                props.setLoader(false);
                message.error('Request failed. Please try again!');
            })
        }
    }

    const verifyValidRequest = () => {
        const all3dCompanyIDs = FileConstants.ALL3D_COMPANY_IDS();
        let validRequest = false;
        if ((props.space_details.model_status >=4 || props.space_details.model_status <=6) && !all3dCompanyIDs.includes(props.space_details.company_id) &&
            (props.username == props.space_details.customer_username || props.company_id == props.space_details.company_id)) {
                validRequest = true;
        }
        if(validRequest) {
            if (existingConfiguration) {
                submitRequest();
            }
            else {
                setRequestConfirmationModal(true);
                props.setLoader(false);
            }
        }
        else {
            if (all3dCompanyIDs.includes(props.space_details.company_id)) {
                setFailureMessage(showFailMessages["PropertyCheck"]);
            }
            else if (props.username != props.space_details.customer_username 
                && props.company_id != props.space_details.company_id) {
                setFailureMessage(showFailMessages["OwnerCheck"]);
            }
            else if (props.space_details.model_status != 5 && props.space_details.model_status != 6) {
                setFailureMessage(showFailMessages["StatusCheck"]);
            }
            props.setLoader(false);
            setRequestFailureModal(true);
        }
    }

    const fetchExistingConfiguration = () => {
        let payload = {
            "required_fields": ["room_id", "request_details"],
            "order_by": "room_id desc",
        }
        payload["filter_string"] = "(room_id__exact='" + props.space_id + "')";
        axios.post(ENVIRONMENT.LIST_ROOMS, payload)
        .then(res => {
            if (res && res.data && res.data.length == 1) {
                setExistingConfiguration(res.data[0]);
            }
            else {
                setExistingConfiguration(false);
            }
        }).catch(e => {
            setExistingConfiguration(false);
        })
    }

    const compareObjects = (obj1, obj2) => {
        if (obj1 === obj2) return true; // Check for strict equality
        if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false;
        
        let keys1 = Object.keys(obj1);
        let keys2 = Object.keys(obj2);
        
        if (keys1.length !== keys2.length) return false;
        
        for (let key of keys1) {
            if (!keys2.includes(key) || !compareObjects(obj1[key], obj2[key])) return false;
        }
        return true;
    }

    const compareConfigurations = (config1, config2) => {
        if (config1.length !== config2.length) return false;
        
        // Sort and compare each object in the arrays
        const sortedArr1 = config1.slice().sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
        const sortedArr2 = config2.slice().sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
        
        return sortedArr1.every((obj, index) => compareObjects(obj, sortedArr2[index]));
    }

    useEffect(()=> {
        props.setLoader(true);
        fetchExistingConfiguration();
    },[]);

    useEffect(()=> {
        if (existingConfiguration != null) {
            verifyValidRequest();
        }
    },[existingConfiguration]);

    return <div>
        <WarningModal
            visible={requestConfirmationModal}
            onCancel={()=>setRequestConfirmationModal(false)}
            heading={"Review before saving changes!"}
            text={"Please note that saving changes to this space will affect all the existing scenes using this space."}
            footer={[
            <div className="justify-in-end">
                <Button className="modal-okay-gray square font-14" onClick={closeRequestModal}>
                    Cancel
                </Button>
                <Button disabled={loader} key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => submitRequest()}>
                    Confirm
                    {loader && <LoadingOutlined />}
                </Button>
            </div>
        ]}/>

        <WarningModal
            visible={requestFailureModal}
            onCancel={()=>setRequestFailureModal(false)}
            heading={"Unable to request changes to current space!"}
            text={failureMessage}
            footer={[
            <div className="justify-in-end">
                <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={closeRequestModal}>
                    Okay
                </Button>
            </div>
        ]}/>

        <SuccessModal
            visible={requestSuccessModal}
            onCancel={closeRequestModal}
            heading={"Successfully Saved your Space!"}
            text={""}
            footer={[
                <div className="justify-in-center">
                    <Button className="modal-okay square font-14" htmlType="submit" onClick={() => closeRequestModal()}>
                    Okay
                    </Button>
                </div>
            ]}
        />

    </div>
}

export default SpaceUpdateRequestModal;

