import React, { useContext, useState } from 'react';
import CollaborateContext from '../../ContextFiles/CollaborateContext';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';
import { CloseOutlined } from '@ant-design/icons';
import CollaborateConstants from './Constants';
import { Row, Col, Card, Tooltip, Empty, Image, Checkbox, Divider, Space, Button, Dropdown, Menu } from 'antd';
import * as Utilities from '../../Utilities';
import ENVIRONMENT from '../../../../environments';
import Draggable from "react-draggable";

const CUSTOMER_USERNAME = localStorage.getItem("username");

const CommentDrawerHeader = ({ onClose, title, toggleResolveComments, selectedCommentOption, handleChangeCommentOption }) => {

    const onMouseOut = (evt) => {
        let element = document.getElementById('filter');
        if (element) {
            element.setAttribute("src", "/img/collaborate_tool/filter.svg");
            element.setAttribute("height", "20px");
            element.setAttribute("width", "20px");

        }
    }

    const onMouseOver = (evt) => {
        let element = document.getElementById('filter')
        if (element) {
            element.setAttribute("src", "/img/collaborate_tool/filter_blue.svg");
            element.setAttribute("height", "20px");
            element.setAttribute("width", "20px");
        }
    }

    return (
        <div className='display-flex j-s-b a-c comment-drawer-header'>
            <h3 className='manrope f-18 black-33 mb-0'>{title}</h3>
            <div className='display-flex a-c'>
                <Dropdown overlay={<div className='comment-option-dropdown'>
                    {CollaborateConstants.comment_history_options.map((option, index) => (
                        <Menu selectedKeys={[selectedCommentOption]} className='comment-selector'>
                            <Menu.Item key={option.value} onClick={() => handleChangeCommentOption(option.value)}>
                                <span style={{ cursor: "pointer" }}>{option.label}</span>
                            </Menu.Item>
                        </Menu>
                    ))}
                    <Divider
                        className='comment-option-divider' />
                    <Space
                        className='comment-pd-space'>
                        <Checkbox className='resolved-comment-checkbox' onChange={toggleResolveComments}>Resolved comments only</Checkbox>
                    </Space>
                </div>} trigger={['click']}>
                    <Tooltip title={<span className='manrope f-12 white'>Comment Filter</span>}>
                        <Button className='action-icon-btn' ghost onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
                            <img height='20px' width='20px' src="/img/collaborate_tool/filter.svg" id="filter" />
                        </Button>
                    </Tooltip>
                </Dropdown>

                <CloseOutlined className='manrope f-18' onClick={onClose} />
            </div>
        </div>
    );
};


const CommentHistory = (props) => {
    const { displayedCommentList, openComment, closeCommentHistoryDrawer, initialLoader,
        handleChangeCommentOption, toggleResolveComments, selectedCommentOption, platform, access_level,
        usersAndCustomGroupsList} = useContext(props.context_for == "collaborate_tool" ? CollaborateContext : CollaborateQAContext);

    const [activeDrags, setActiveDrags] = useState(0);

    const onStart = (e, pos) => {
        let drags = activeDrags;
        drags = drags + 1;
        setActiveDrags(drags);
    };

    const onStop = (e, pos) => {
        let drags = activeDrags;
        drags = drags - 1;
        setActiveDrags(drags);
    };

    const dragHandlers = { onStart: onStart, onStop: onStop };

    return (
        <Draggable handle="#comment_drawer" {...dragHandlers}>
            <div
            className={`comment-history-drawer ${initialLoader || access_level == "restricted" ? `blur-canvas` : ``} ${props.context_for == "collaborate_qa" ? "qa" : ""}`}
            id="comment_drawer">
                <CommentDrawerHeader
                    selectedCommentOption={selectedCommentOption}
                    toggleResolveComments={toggleResolveComments}
                    onClose={closeCommentHistoryDrawer}
                    handleChangeCommentOption={handleChangeCommentOption}
                    title="Comments History" />
                {displayedCommentList.length > 0 ?
                    <div className={`custom-scroll comment-list-container ${props.context_for == "collaborate_qa" ? "qa" : ""}`}>
                        {displayedCommentList.map((comment, index) => (
                            <Row key={index} className={`comment-item-container ${comment.tagged_users.includes(CUSTOMER_USERNAME) ? `blue` : ``} pd-16`}>
                                <Col span={24} className='display-flex a-c j-s-b'>
                                    <p className="manrope f-14 w-700 black-00 mb-4">{comment.created_by}</p>
                                    <p className="manrope f-14 blue hover-change mb-4" onClick={() => openComment(comment)}>Issue#{comment.sequence_no}</p>
                                </Col>
                                <Col span={24}>
                                    <p className="manrope f-14 w-400 grey-73 mb-4">
                                        {Utilities.convertToCustomFormat(comment.created_on)}
                                    </p>
                                </Col>
                                <Col span={24}>
                                    <p className="manrope f-14 w-400 black-33 mb-4"
                                        dangerouslySetInnerHTML={{ __html: Utilities.makeMentionsBold(comment.message, usersAndCustomGroupsList.map(item => item.display)) }} />
                                </Col>
                                <Col span={24} className='display-flex a-c j-s wrap'>
                                    {comment.attachment && comment.attachment.length > 0 &&
                                        comment.attachment.map((file) => (
                                            <Card className="comment-img-container">
                                                {Utilities.isImage(file.name) ?
                                                    <Image className="comment-img" src={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)} /> :
                                                    <a href={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)} target='_blank'>
                                                        <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                                                            <img
                                                                className="comment-img pd-10" src={'/img/file-icon.png'}
                                                            />
                                                        </Tooltip>
                                                    </a>}
                                            </Card>
                                        ))
                                    }
                                </Col>
                            </Row>
                        ))}
                    </div>
                    :
                    <Empty
                        description={"No comments to display"}
                        className="manrope f-14 display-flex j-c a-c dir-col pd-16 p-b-32" />}
            </div>
        </Draggable>
    );
}


export default CommentHistory;
