import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import ResolutionRadioButtons from "./ResolutionRadioButtons";
import "./TwoDImages.scss";
import InfiniteScrollGallery from "./InfiniteScrollGallery";

const { TabPane } = Tabs;

const TabContent = ({ images, loading, showModal }) => {
  return (
    <InfiniteScrollGallery
      images={images}
      loading={loading}
      onImageClick={(index) => showModal(index)}
    />
  );
};

const ImageTabs = ({
  filteredImages,
  imagesInitialLoading,
  showModal,
  setImageType,
  Type,
  imagesCount,
}) => {
  const [activeKey, setActiveKey] = useState(Type || "all");

  // Update local state when Type changes
  useEffect(() => {
    setActiveKey(Type || "all");
  }, [Type]);

  const handleTabChange = (key) => {
    setActiveKey(key);
    setImageType(key); // Update external state as well
  };

  return (
    <Tabs
      activeKey={activeKey}
      className="product-tab library"
      tabBarGutter={16}
      onChange={handleTabChange} // Update local state on tab change
    >
      <TabPane tab={`All (${imagesCount["all"]})`} key="all">
        <ResolutionRadioButtons />
        <TabContent
          images={filteredImages}
          loading={imagesInitialLoading}
          showModal={showModal}
        />
      </TabPane>
      <TabPane
        tab={`Favorites (${imagesCount["favourites"]})`}
        key="favourites"
      >
        <ResolutionRadioButtons />
        <TabContent
          images={filteredImages}
          loading={imagesInitialLoading}
          showModal={showModal}
        />
      </TabPane>
      <TabPane tab={`Silos (${imagesCount["silo"]})`} key="silo">
        <ResolutionRadioButtons />
        <TabContent
          images={filteredImages}
          loading={imagesInitialLoading}
          showModal={showModal}
        />
      </TabPane>
      <TabPane tab={`Lifestyles (${imagesCount["lifestyle"]})`} key="lifestyle">
        <ResolutionRadioButtons />
        <TabContent
          images={filteredImages}
          loading={imagesInitialLoading}
          showModal={showModal}
        />
      </TabPane>
      <TabPane tab={`360 Spins (${imagesCount["spin_360"]})`} key="spin_360">
        <ResolutionRadioButtons />
        <TabContent
          images={filteredImages}
          loading={imagesInitialLoading}
          showModal={showModal}
        />
      </TabPane>
      <TabPane tab={`MP4 Videos (${imagesCount["mp4"]})`} key="mp4">
        <ResolutionRadioButtons />
        <TabContent
          images={filteredImages}
          loading={imagesInitialLoading}
          showModal={showModal}
        />
      </TabPane>
    </Tabs>
  );
};

export default ImageTabs;
