import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, Carousel, Tooltip, Card, message, Image } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, EditOutlined, FullscreenOutlined, FlagOutlined, DeleteOutlined } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants';
import ImageZoomAdmin from '../../CustomerComponents/ImageZoomAdmin';
import SceneImagePopupContext from '../../ContextFiles/SceneImagePopupContext';

const image_style = { objectFit: "contain", width: "100%", height: "70vh" };
const image_style_blur = { objectFit: "contain", width: "100%", height: "70vh", "filter": "blur(3px)" };

const flag_text_color = FileConstants.flag_text_color;

const getImageUrl = (platform, match, img, white_balance_renders_switch) => {
  if (img?.status == 'rendered') {
    if (white_balance_renders_switch?.[img?.filename]) {
      return `${ENVIRONMENT.getBaseURL(platform)}${ENVIRONMENT.SCENE_THUMBNAIL_URI}${match}/${img?.white_balanced_image.replace('tiff', 'jpg')}`;
    }
    return `${ENVIRONMENT.getBaseURL(platform)}${ENVIRONMENT.SCENE_THUMBNAIL_URI}${match}/${img?.filename.replace('tiff', 'jpg')}`;
  }
  return `${ENVIRONMENT.getBaseURL(platform)}${ENVIRONMENT.SCENE_THUMBNAIL_URI}${match}/${img?.preview_render.replace('tiff', 'jpg')}`;
};

const ImageListType = ({ imagesArray, type, name, selectImage, selectedProductImageLink, match, platform, white_balance_renders_switch }) => {

  return (
    <>
      {imagesArray.map((image, index) => {
        const imageUrl = getImageUrl(platform, match, image, white_balance_renders_switch);
        return (
          <Card className={`product-img-grid-card display-flex ${selectedProductImageLink === imageUrl ? 'selected' : ''}`} onClick={() => selectImage(imageUrl, image.status)} key={index}>
            <img className='product-img-grid-img' src={imageUrl} alt={`${type} - ${name}`} style={{ objectFit: 'contain' }} />
          </Card>
        );
      })}
    </>
  );
};

const LifeStyleImagesCarousel = (props) => {
  const { currentfile, lifestyleImagesArray, match, platform, renderReportButton,
    handleDelete, handleDownload, handleGenerateHighResImage, renderCollaborationTooltip,
    expandImage, handleImageNameUpdate, handleAutoWhiteSwitch, setSelectedLifestyle,
    image_visible, changeVisibility, white_balance_renders_switch, render_generation,
    getFlagIcon, getFlagStatus, imgAccessedFromCarousel, setImgAccessedFromCarousel } = useContext(SceneImagePopupContext);
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedProductImageLink, setSelectedProductImageLink] = useState(getImageUrl(platform, match, currentfile, white_balance_renders_switch));
  const [lifestyleImages, setLifestyleImages] = useState([]);
  let carouselRef = React.createRef();

  useEffect(() => {
    setSelectedImg(currentfile);
    setSelectedProductImageLink(getImageUrl(platform, match, currentfile, white_balance_renders_switch))
  }, [currentfile, platform, match, ...Object.values(white_balance_renders_switch)]);

  useEffect(() => {
    const updatedImages = constructImagesWithUrls(lifestyleImagesArray);
    setLifestyleImages(updatedImages);
  }, [lifestyleImagesArray, platform, match, ...Object.values(white_balance_renders_switch)]);


  useEffect(() => {
    if (imgAccessedFromCarousel === false && selectedProductImageLink && selectedImg) {
      const imageIndex = lifestyleImages.findIndex((img) => img.url === selectedProductImageLink && img.status == selectedImg.status);
      if (carouselRef && imageIndex !== -1) {
        carouselRef.goTo(imageIndex, true);
        setSelectedImg(lifestyleImages[imageIndex]);
      }
    }
  }, [selectedImg, selectedProductImageLink, imgAccessedFromCarousel, lifestyleImages, platform, match, carouselRef]);

  const constructImagesWithUrls = (imagesArray) => {
    return imagesArray.map(img => ({
      ...img,
      url: getImageUrl(platform, match, img, white_balance_renders_switch)
    }));
  };

  const selectImage = (url, status) => {
    setSelectedProductImageLink(url);
    const imageIndex = lifestyleImages.findIndex((img) => img.url === url && img.status == status);
    if (imageIndex !== -1 && carouselRef) {
      carouselRef.goTo(imageIndex, true);
      setSelectedLifestyle(lifestyleImages[imageIndex]);
      setSelectedImg(lifestyleImages[imageIndex]);
      setImgAccessedFromCarousel(true);
    }
  };

  const onChangeCarousel = (before, to) => {
    if (before == (lifestyleImages.length - 1) && to == 0 && carouselRef) {
      carouselRef.goTo(0, true);
      const selectedImage = lifestyleImages[to];
      setSelectedLifestyle(selectedImage);
      setSelectedImg(selectedImage);
      setSelectedProductImageLink(selectedImage.url);
      setImgAccessedFromCarousel(true);
    } else if (before == 0 && to == (lifestyleImages.length - 1) && carouselRef) {
      carouselRef.goTo(lifestyleImages.length - 1, true);
      const selectedImage = lifestyleImages[lifestyleImages.length - 1];
      setSelectedLifestyle(selectedImage);
      setSelectedImg(selectedImage);
      setSelectedProductImageLink(selectedImage.url);
      setImgAccessedFromCarousel(true);
    }
  };

  const next = () => {
    let imageIndex = lifestyleImages.findIndex((img) => img.url === selectedProductImageLink && img.status == selectedImg.status);
    if ((imageIndex + 1) == lifestyleImages.length) {
      selectImage(lifestyleImages[0].url, lifestyleImages[0].status);
    } else {
      selectImage(lifestyleImages[imageIndex + 1].url, lifestyleImages[imageIndex + 1].status);
    }
  };

  const prev = () => {
    let imageIndex = lifestyleImages.findIndex((img) => img.url === selectedProductImageLink && img.status == selectedImg.status);
    if ((imageIndex - 1) < 0) {
      selectImage(lifestyleImages[lifestyleImages.length - 1].url, lifestyleImages[lifestyleImages.length - 1].status);
    } else {
      selectImage(lifestyleImages[imageIndex - 1].url, lifestyleImages[imageIndex - 1].status);

    }
  };

  const renderFlagState = () => {
    return (<Tooltip
      title={<span className='manrope f-12 white' dangerouslySetInnerHTML={{ __html: getFlagStatus(selectedImg) }}></span>}>
      <div
        className={`lifestyle-image-collaborate-icon white border justify-in-start`} style={{ width: 'fit-content', left: 150, cursor: 'default', opacity: 0.6 }}>
        <img
          className='collaborate-img'
          style={{ height: 22, width: 22 }}
          src={getFlagIcon(selectedImg)}
          alt="Flag Icon"
        />
        <span className={`manrope f-14 w-500 ${flag_text_color[getFlagStatus(selectedImg)]}`}>
          {getFlagStatus(selectedImg)}
        </span>
      </div>
    </Tooltip>);
  }

  const renderInProgressTooltip = () => {
    return (<Tooltip
      title={<span className='manrope f-12 white' dangerouslySetInnerHTML={{ __html: selectedImg.preview_render.includes("preview") ? 'Render in Progress' : 'High Res Render In Progress' }}></span>}>
      <div
        className={`lifestyle-image-collaborate-icon white border justify-in-start`} style={{ width: 'fit-content' }}>
        <img
          className='collaborate-img'
          src={'/img/time-clock-img.svg'}
          alt="In Progress Icon"
        />
        <span className={`manrope f-14 w-500`}>
          {selectedImg?.preview_render.includes("preview") ? "Render In Progress" : "High Res Render In Progress"}
        </span>
      </div>
    </Tooltip>);
  }


  return (
    selectedImg ?
      <Row>
        <Col md={7} lg={7} xl={5} className='display-flex j-s a-s wrap sa-s gg-16 custom-scroll product-grid-list'>
          <ImageListType
            white_balance_renders_switch={white_balance_renders_switch}
            imagesArray={lifestyleImages}
            type={'lifestyle'}
            name={'Lifestyle'}
            selectImage={selectImage}
            selectedProductImageLink={selectedProductImageLink}
            match={match}
            platform={platform}
          />
        </Col>
        <Col md={17} lg={17} xl={19} className="w-100" style={{ paddingLeft: 20 }}>
          <Row>
            <Col span={19}>
              <div className="display-flex justify-start align-baseline" >
                {handleImageNameUpdate(selectedImg)}
              </div>
            </Col>
            {selectedImg.status != "pending"
              && white_balance_renders_switch[selectedImg?.filename] != undefined
              && render_generation == true &&
              <Col span={5}>
                <div className="justify-in-end" style={{ paddingTop: 12 }}>
                  <span className='manrope f-12 black-33 mr-8'>Auto white balance</span>
                  {handleAutoWhiteSwitch(selectedImg)}
                </div>
              </Col>}
          </Row>
          <div>
            <Button className="carousel-btns left-btn" onClick={prev} style={{ marginLeft: 20 }}>
              <ArrowLeftOutlined className="arrow" />
            </Button>
            <Carousel
              autoplay={false}
              ref={node => (carouselRef = node)}
              className="w-100"
              beforeChange={onChangeCarousel}>
              {lifestyleImages.map((image, index) => (
                <Card key={index} className='lifestyle-carousel-card' style={{ height: 100 }}>
                  {image.status == 'rendered' && render_generation == true && renderCollaborationTooltip(selectedImg)}
                  {image.status == 'pending' && renderInProgressTooltip()}
                  {image.status == 'pending' || (!image.generated_by_admin && !image.flagged_by_user && !image.flag_rejected_by_admin) ?
                    "" : renderFlagState()}
                  {image.status == 'rendered' &&
                    <FullscreenOutlined className='lifestyle-image-expand' onClick={() => expandImage(image.filename)} />}
                  <div>
                    <ImageZoomAdmin custom_class={image.status == 'rendered' ? image_style : image_style_blur} compare={true} image={image.url} notShowToolButtons={true} />
                  </div>
                  <div className="carousel-index-display">
                    <span className="manrope f-14 w-500">{index + 1} of {lifestyleImages.length}</span>
                  </div>
                  <Image
                    preview={{
                      visible: image_visible[image.filename.split(".")[0]],
                      onVisibleChange: changeVisibility,
                    }}
                    className="new-lifestyle-image-settings"
                    src={`${ENVIRONMENT.getBaseURL(platform)}${ENVIRONMENT.SCENE_THUMBNAIL_URI}${match}/${image.filename.replace('tiff', 'jpg')}`}
                  />
                </Card>
              ))}
            </Carousel>
            <Button className="carousel-btns right-btn" onClick={next}>
              <ArrowRightOutlined className="arrow" />
            </Button>
          </div>
          {selectedImg?.status != 'pending' && render_generation == true &&
            <>
              <Row style={{ paddingTop: 20 }}>
                <Col span={12}>
                  <div className='justify-in-start'>
                    {renderReportButton(selectedImg)}
                    {handleDelete(selectedImg)}
                  </div>
                </Col>
                <Col span={12}>
                  <div className='justify-in-end'>
                    {handleDownload(selectedImg)}
                    {handleGenerateHighResImage(selectedImg)}
                  </div>
                </Col>
              </Row>
            </>}
        </Col>
      </Row> : ""
  );
};

export default LifeStyleImagesCarousel;
